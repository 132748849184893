import { BaseGetRequest } from './base-get.request';

export class BaseGetByFileIdRequest extends BaseGetRequest {
  fileId: number;

  constructor(fileId: number) {
    super();
    this.fileId = fileId;
  }
}
