import { BaseGetRequest } from '../common/base-get.request';

export class MailFolderGetUnreadCountRequest extends BaseGetRequest {
  folderId: number;
  hideNotification: boolean;

  constructor(folderId: number, hideNotification: boolean) {
    super();
    this.folderId = folderId;
    this.hideNotification = hideNotification;
  }
}
