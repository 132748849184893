import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountSettingsComponent } from './components/account-settings/account-settings.component';
import { DefaultPageComponent } from './pages/default-page/default-page.component';
import { EmailPrintComponent } from './components/email/email-print/email-print.component';
import { EmailsContainerComponent } from './components/emails-container/emails-container.component';
import { EmailsSideNavComponent } from './components/emails-sidenav/emails-side-nav.component';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { ErrorNotExistedPageComponent } from './pages/errorNotExisted-page/errorNotExisted-page.component';
import { MainComponent } from './components/main/main.component';
import { PluginsComponent } from './components/plugins/plugins.component';
import { ReportsComponent } from './components/reports/reports.component';
import { DispatchingRuleComponent } from './components/settings/organization/mail-account/dispatching-rule/dispatching-rule.component';
import { MailAccountFoldersSettingsComponent } from './components/settings/organization/mail-account/mail-account-folders-settings/mail-account-folders-settings.component';
import { MailAccountGeneralSettingsComponent } from './components/settings/organization/mail-account/mail-account-general-settings/mail-account-general-settings.component';
import { MailAccountTagsComponent } from './components/settings/organization/mail-account/mail-account-tags/mail-account-tags.component';
import { MailAccountTemplatesComponent } from './components/settings/organization/mail-account/mail-account-templates/mail-account-templates.component';
import { MailAccountUsersComponent } from './components/settings/organization/mail-account/mail-account-users/mail-account-users.component';
import { MailAccountComponent } from './components/settings/organization/mail-account/mail-account.component';
import { OrganizationGeneralSettingsComponent } from './components/settings/organization/organization-general-settings/organization-general-settings.component';
import { OrganizationGroupsComponent } from './components/settings/organization/organization-groups/organization-groups.component';
import { OrganizationUsersComponent } from './components/settings/organization/organization-users/organization-users.component';
import { OrganizationComponent } from './components/settings/organization/organization.component';
import { ProviderComponent } from './components/settings/provider/provider.component';
import { SettingsComponent } from './components/settings/settings.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { WebCodeContextComponent } from './components/web-code-context/web-code-context.component';
import { AuthGuard } from './services/auth/auth.guard';
import { RootAuthGuard } from './services/auth/root.auth.guard';

export const routes: Routes = [
  {
    path: '',
    component: DefaultPageComponent, // +
  },
  {
    path: ':webCode/sign-in',
    component: SignInComponent, // +
  },
  {
    path: ':webCode/403',
    component: ErrorPageComponent, // +
  },
  {
    path: ':webCode/401',
    component: ErrorPageComponent, // +
  },
  {
    path: ':webCode/not-exists',
    component: ErrorNotExistedPageComponent, // +
  },
  {
    path: ':webCode/500',
    component: ErrorPageComponent, // +
  },
  {
    path: 'requesterror',
    component: ErrorPageComponent, // +
  },
  {
    path: ':webCode',
    component: WebCodeContextComponent,
    children: [
      {
        path: '',
        component: MainComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: SettingsComponent,
            canActivate: [AuthGuard],
            children: [
              {
                path: 'settings/organizations',
                component: OrganizationComponent,
                canActivate: [AuthGuard],
              },
              {
                path: 'settings/providers',
                component: ProviderComponent,
                canActivate: [RootAuthGuard],
              },
              {
                path: 'settings/organization/:organizationId/general',
                component: OrganizationGeneralSettingsComponent,
                canActivate: [AuthGuard],
              },
              {
                path: 'settings/organization/:organizationId/mail-accounts',
                component: MailAccountComponent,
                canActivate: [AuthGuard],
              },
              {
                path: 'settings/organization/:organizationId/users',
                component: OrganizationUsersComponent,
                canActivate: [AuthGuard],
              },
              {
                path: 'settings/organization/:organizationId/groups',
                component: OrganizationGroupsComponent,
                canActivate: [AuthGuard],
              },
              {
                path: 'settings/organization/:organizationId/mail-account/:mailAccountId/general',
                component: MailAccountGeneralSettingsComponent,
                canActivate: [AuthGuard],
              },
              {
                path: 'settings/organization/:organizationId/mail-account/:mailAccountId/users',
                component: MailAccountUsersComponent,
                canActivate: [AuthGuard],
              },
              {
                path: 'settings/organization/:organizationId/mail-account/:mailAccountId/dispatching-rules',
                component: DispatchingRuleComponent,
                canActivate: [AuthGuard],
              },
              {
                path: 'settings/organization/:organizationId/mail-account/:mailAccountId/tags',
                component: MailAccountTagsComponent,
                canActivate: [AuthGuard],
              },
              {
                path: 'settings/organization/:organizationId/mail-account/:mailAccountId/templates',
                component: MailAccountTemplatesComponent,
                canActivate: [AuthGuard],
              },
              {
                path: 'settings/organization/:organizationId/mail-account/:mailAccountId/folders',
                component: MailAccountFoldersSettingsComponent,
                canActivate: [AuthGuard],
              },
            ],
          },
          {
            path: 'emails',
            component: EmailsContainerComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'reports',
            component: ReportsComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'plugins',
            component: PluginsComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'account-settings',
            component: AccountSettingsComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'mail-accounts',
        component: EmailsSideNavComponent, /// ???? need
        canActivate: [AuthGuard],
      },
      {
        path: 'mail-account/:mailAccountId/mail-folder/:mailFolderId/emails-content/:emailId/print',
        component: EmailPrintComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: '**',
    pathMatch: 'full',
    component: ErrorPageComponent, // +
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
