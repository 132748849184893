import { Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgStyle } from '@angular/common';

@Component({
  selector: 'app-loading-button',
  standalone: true,
  imports: [
    MatButtonModule,
    MatProgressSpinnerModule,
    NgStyle
  ],
  templateUrl: './loading-button.component.html',
  styleUrls: ['./loading-button.component.scss'],
})
export class LoadingButtonComponent implements OnInit {
  @Input() text: string;
  @Input() loading: boolean;

  constructor() {}

  ngOnInit(): void {}
}
