import { ApiException } from './api.exception';
import { ErrorsPipe } from '../../shared/pipes/error-code.pipe';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from '../../components/error/error-dialog/error-dialog.component';
import { Injectable } from '@angular/core';
import { LoggingClient } from '../../sdk/clients/logging.client';
import { GlobalErrorHandler } from './error-handler.service';

@Injectable()
export class MatchError {
  constructor(
    public dialog: MatDialog,
    private globalErrorHandler: GlobalErrorHandler,
    private errorsPipe: ErrorsPipe,
    public loggingClient: LoggingClient
  ) {}

  public errorHandler(error): void {
    if (!error.Code) {
      this.openErrorDialog('error', error?.Description || 'somethingWentWrongErrorSubtitle');
    } else {
      this.openErrorDialog(
        'error',
        this.errorsPipe.transform(error.Code) || `Error code: ${error.Code}: ${error.Description || 'something went wrong'}`
      );
    }
  }

  public logError(e): void {
    if (e instanceof ApiException) {
      if (e.Status === 500) {
        this.globalErrorHandler.handleError(e);
      }
    } else {
      this.globalErrorHandler.handleError(e);
    }
  }

  public openErrorDialog(title: string, description: string): void {
    this.dialog.open(ErrorDialogComponent, {
      width: '400px',
      data: { title, description },
      backdropClass: 'error-backdrop',
    });
  }
}
