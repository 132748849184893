import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ApiRoutes } from '../routes/api.routes';
import { ApiRequest } from '../../services/api/api.request';
import { PermissionsOverResources } from '../contracts/permission/permissions-over-resources';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class PermissionClient {
  constructor(private http: HttpClient, private apiRequest: ApiRequest) {}
  async get(): Promise<PermissionsOverResources> {
    return this.apiRequest.get<PermissionsOverResources>(`${API_URL}${ApiRoutes.permission.get}`);
  }
}
