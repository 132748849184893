import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable, NgZone } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SnackbarHelper {
  constructor(private zone: NgZone, private snackBar: MatSnackBar) {}

  public openSnackBar(message: string): void {
    this.zone.run(() => {
      const snackBar = this.snackBar.open(message, 'OK', {
        verticalPosition: 'bottom',
        horizontalPosition: 'center',
        duration: 5000,
      });
      snackBar.onAction().subscribe(() => {
        snackBar.dismiss();
      });
    });
  }
  public openSnackBarTop(message: string): void {
    this.zone.run(() => {
      const snackBar = this.snackBar.open(message, 'OK', {
        verticalPosition: 'top',
        horizontalPosition: 'center',
      });
      snackBar.onAction().subscribe(() => {
        snackBar.dismiss();
      });
    });
  }
}
