import { Pipe, PipeTransform } from '@angular/core';
import { EmailSearchOrder } from 'src/app/sdk/contracts/Email/email.search-order';

@Pipe({
  name: 'formatSearchOrders',
})
export class FormatSearchOrders implements PipeTransform {
  transform(order: EmailSearchOrder): string {
    switch (order) {
      case EmailSearchOrder.OrderBySentDateTime:
        return 'date';
      case EmailSearchOrder.OrderByStateDateTime:
        return 'date';
      case EmailSearchOrder.OrderBySeen:
        return 'seen';
      case EmailSearchOrder.OrderByFrom:
        return 'from';
      case EmailSearchOrder.OrderByTo:
        return 'to';
      default:
        return 'Not implemented';
    }
  }
}
