import { NgModule, ErrorHandler } from '@angular/core';
import { Injectable } from '@angular/core';
import * as ErrorStackParser from 'error-stack-parser';
import * as StackTraceGPS from 'stacktrace-gps';
import { LoggingHandler } from 'src/app/services/errors/logging.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private loggingHandler: LoggingHandler,
  ) {}

  transformInfoStack(result, info): string {
    const { functionName, fileName, lineNumber } = info;
    return `${result}\n at ${functionName || ''} (${fileName}:${lineNumber})`;
  }

  async getErrorStackString(stackFrames): Promise<string> {
    const gps = new StackTraceGPS();

    return await stackFrames.reduce(async (promise, frame) => {
      const result = await promise;
      try {
        const info = await gps.pinpoint(frame);
        return this.transformInfoStack(result, info);
      } catch (err) {
        return `${result}\n at Unable to get data from source map for ${frame}`;
      }
    }, '');
  }

  async handleError(error): Promise<void> {
    const stackFrames = ErrorStackParser.parse(error);
    const stack = await this.getErrorStackString(stackFrames);

    await this.loggingHandler.create('error', error.message, stack);
  }
}

@NgModule({
  providers: [{ provide: ErrorHandler, useClass: GlobalErrorHandler }],
})
export class GlobalErrorHandlerModule {}
