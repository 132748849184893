import {
  Component,
  Input,
  OnInit,
  EventEmitter,
  Output,
  ViewChild,
  ViewContainerRef,
  TemplateRef
} from '@angular/core';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-drawer',
  standalone: true,
  imports: [
    MatSidenavModule,
    MatDrawer,
    MatButtonModule,
    MatIconModule,
  ],
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
})
export class DrawerComponent implements OnInit {
  @Input() title: string | null;
  @Input() width: string | null;
  @Input() showBackground: boolean = true;
  @Input() isOffset: boolean = false;
  @Input() contentPercentOfScreen: number | null;
  @Output() closeEventEmitter = new EventEmitter();

  @ViewChild('drawer') drawer: any;
  @ViewChild('drawerContent', { read: ViewContainerRef }) drawerContent!: ViewContainerRef;

  disableClose: boolean = false;

  ngOnInit(): void {}

  public closeDrawer(): void {
    !this.disableClose && this.drawer?.close();
  }

  openDrawer(template: TemplateRef<any>): void {
    this.drawerContent?.clear();
    this.drawerContent?.createEmbeddedView(template);
    this.drawer?.toggle();
  }

  disableCloseDrawer(isDisable: boolean): void {
    this.disableClose = isDisable;
  }
}
