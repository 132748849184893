import { BaseGetRequest } from './base-get.request';

export class BaseGetCollectionRequest extends BaseGetRequest {
  page: number;
  pageSize: number;

  constructor(page: number, pageSize: number) {
    super();
    this.page = page;
    this.pageSize = pageSize;
  }
}
