import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { EventsService } from '../../services/events/events.service';
import { SearchContextStorageService } from '../../services/search/search-context-storage.service';
import { MobileObserverService } from '../../services/adaptive/mobile-observer.service';
import { filter } from 'rxjs/operators';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { EmailSearchInputComponent } from '../email-search-input/email-search-input.component';
import { UserMailAccountSelected } from '../../sdk/contracts/common';

@Component({
  selector: 'app-main-header',
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    EmailSearchInputComponent,
  ],
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss'],
})
export class MainHeaderComponent implements OnInit, OnDestroy {
  @Output() navMenuOpen = new EventEmitter();
  @Output() mailAccountMenuOpen = new EventEmitter();
  public userMailAccountSelected: UserMailAccountSelected = null;
  public signalRState: boolean;
  private userMailAccountSelectedSubscription: Subscription;
  private signalConnectionsStateSubscription: Subscription;
  public webCode: string;
  public searchValue = '';
  public useMobileView = false;
  private useMobileViewSubscription: Subscription;
  private searchContextSubscription: Subscription;
  public hideNotifications = true;
  public isDefaultSearchParams = true;
  public isAnyFolder = false;

  constructor(
    private router: Router,
    private activateRoute: ActivatedRoute,
    private eventsService: EventsService,
    private searchContextStorageService: SearchContextStorageService,
    private mobileObserverService: MobileObserverService
  ) {
    this.searchContextSubscription = this.searchContextStorageService.searchContext$
      .pipe(filter((searchContext) => !!searchContext.selectedMailFolderIds))
      .subscribe((searchContext) => {
        this.searchValue = searchContext.searchParams.fullText;
        this.hideNotifications = searchContext.searchParams.hideNotifications;
        this.isAnyFolder = searchContext?.selectedMailFolderIds?.length > 0;
      });
    this.searchContextStorageService.isDefaultSearchParams$.subscribe((isDefaultSearchParams) => {
      this.isDefaultSearchParams = isDefaultSearchParams;
    });
  }

  async ngOnInit(): Promise<any> {
    this.useMobileViewSubscription = this.mobileObserverService.mobileObserver().subscribe((isMobile) => (this.useMobileView = isMobile));
    this.webCode = this.activateRoute.parent.snapshot.paramMap.get('webCode');
    this.userMailAccountSelectedSubscription = this.eventsService.UserMailAccountSelected.subscribe((x) => {
      this.userMailAccountSelected = x;
    });

    this.signalConnectionsStateSubscription = this.eventsService.signalConnectionsState.subscribe((value: boolean) => {
      this.signalRState = value;
    });
  }

  async ngOnDestroy(): Promise<void> {
    this.userMailAccountSelectedSubscription?.unsubscribe();
    this.signalConnectionsStateSubscription?.unsubscribe();
    this.useMobileViewSubscription?.unsubscribe();
    this.searchContextSubscription?.unsubscribe();
  }

  public async search(): Promise<void> {
    const nullableSearchValue = this.searchValue?.length === 0 ? null : this.searchValue;
    this.searchContextStorageService.setFullTextParam(nullableSearchValue, this.hideNotifications);
    await this.router.navigate([this.webCode, 'emails']);
  }

  public async createMassiveEmail(): Promise<void> {
    await this.router.navigate([
      `/${this.webCode}/mail-account/${this.userMailAccountSelected.MailAccountId}/mail-folders/create-massive-email`,
    ]);
  }

  public leftMenuClicked(): void {
    this.mailAccountMenuOpen.emit();
  }

  public rightMenuClicked(): void {
    this.navMenuOpen.emit();
  }

  public onSearchValueChanged(newSearchValue: string): void {
    this.searchValue = newSearchValue;
  }

  public onHideNotificationsChanged(hideNotifications: boolean): void {
    this.hideNotifications = hideNotifications;
    this.eventsService.changeUnreadCountValue(!hideNotifications);
  }
}
