<div class="close-button">
  <button [disabled]="isLoading" tabIndex="-1" class="" mat-icon-button (click)="onNoClick()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<h1 mat-dialog-title>{{ "permanentlyDelete" | translate }}</h1>
<mat-dialog-content class="mat-mdc-dialog-content mdc-dialog__content mat-container">
  <p>
    <b>{{ "attention" | translate }}:</b>
    {{ "selectedMessagePermanentlyDeleted" | translate }}
    <b>{{ "trashUpperCase" | translate }}</b>
    {{ "andNoPossibleRecover" | translate }}
  </p>
</mat-dialog-content>
<mat-dialog-actions class="mat-mdc-dialog-actions mdc-dialog__actions">
  <div class="w-full flex justify-between">
    <button mat-flat-button [disabled]="isLoading" color="primary" (click)="onOkClick()">
<!--      <app-loading-button text="{{ 'yes' | translate }}" [loading]="isLoading"></app-loading-button>-->
    </button>
    <button mat-flat-button [disabled]="isLoading" color="primary" (click)="onNoClick()">
      {{ 'no' | translate }}
    </button>
    <!-- <button mat-flat-button color="primary" (click)="onOkClick()">{{ "yes" | translate }}</button>
    <button mat-flat-button color="primary" (click)="onNoClick()">{{ "no" | translate }}</button> -->
  </div>
</mat-dialog-actions>
