import { Component, OnInit } from '@angular/core';
import { OrganizationContract } from '../../sdk/contracts/organization/organization.contract';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-default-page',
  standalone: true,
  imports: [
    MatButtonModule
  ],
  templateUrl: './default-page.component.html',
  styleUrls: ['./default-page.component.scss'],
})
export class DefaultPageComponent implements OnInit {
  public links: OrganizationContract[] = [];
  authError: boolean;

  constructor() {}

  ngOnInit(): void {
    const parameters = new URLSearchParams(window.location.search);
    const authError = parameters.get('status');
    this.authError = !!authError && authError === 'auth_error';
    this.links = JSON.parse(localStorage.getItem('organizations'));
  }

  public get currentUrl(): string {
    const urlObj = new URL(document.URL);
    urlObj.search = '';
    return urlObj.toString();
  }
}
