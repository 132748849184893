import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { HubConnectionState } from '@microsoft/signalr';
import { AuthenticationService } from '../auth/authentication.service';
import { EventsService } from '../events/events.service';

@Injectable()
export class SignalRService {
  private hubConnection: signalR.HubConnection;

  constructor(private authenticationService: AuthenticationService, private eventsService: EventsService) {}

  public async startConnection(): Promise<any> {
    // this.hubConnection = new signalR.HubConnectionBuilder()
    //   .withUrl(environment.apiUrl + 'bigEvents',
    //     {
    //       skipNegotiation: true,
    //       transport: signalR.HttpTransportType.WebSockets,
    //       accessTokenFactory: () => this.authenticationService.currentTokenValue.token
    //     })
    //   .withAutomaticReconnect()
    //   .build();
    //
    // this.hubConnection
    //   .start()
    //   .then(() => {
    //
    //     this.eventsService.signalConnectionsState.emit(true);
    //   })
    //   .catch(err =>
    //
    // this.hubConnection.onclose(error => {
    //   this.eventsService.signalConnectionsState.emit(false);
    // });
  }

  public addListener = (eventName, callback: (s: string) => void) => {
    this.hubConnection.on(eventName, (data) => {
      callback(data);
    });
  };

  async stopConnection(): Promise<any> {
    await this.hubConnection.stop();
  }

  public connected(): boolean {
    if (this.hubConnection && this.hubConnection.state === HubConnectionState.Connected) {
      return true;
    }
    return false;
  }
}
