export enum EmailState {
  Draft = 1,
  ReadyToSend = 2,
  Sending = 3,
  Sent = 4,
  SendError = 5,
  ErrorSavingEml = 6,
  ReadyToValidate = 7,
  ValidationRejected = 8,
  NotAccepted = 9,
  Accepted = 10,
  Delivered = 11,
  DeliveryError = 12,
  VirusDetected = 13,
  Downloaded = 14,
  Dispatching = 15,
  Dispatched = 16,
  DispatchError = 17,
  NotDispatched = 18,
  ArchiveError = 19,
  PreDraft = 20,
  CheckVirusInProgress = 21,
}
