import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-loading-icon-button',
  templateUrl: './loading-icon-button.component.html',
  styleUrls: ['./loading-icon-button.component.scss'],
})
export class LoadingIconButtonComponent implements OnInit {
  @Output() onClick: EventEmitter<any> = new EventEmitter();
  @Input() label: string;
  @Input() icon: string;
  @Input() showLoading: boolean;
  @Input() showButton: boolean;
  @Input() disabled: boolean;

  constructor() {}

  ngOnInit(): void {}

  clickEmit(): void {
    this.onClick.emit();
  }
}
