<div class="flex h-full">
  <button
    *ngIf="showButton"
    mat-icon-button
    (click)="clickEmit()"
    [disabled]="disabled"
    [aria-label]="label | translate"
    [matTooltip]="label | translate">
    <mat-icon>{{ icon }}</mat-icon>
  </button>
  <div *ngIf="showLoading" style="width: 40px">
    <app-loader padding="0" [diameter]="22" height="100%"></app-loader>
  </div>
</div>
