export abstract class FeRoutes {
  public static user: any = {
    login: 'user/login',
    getIdentities: 'user/getidentities',
    registration: 'user/registration',
    restorePassword: 'user/restorepassword',
    resetPassword: 'user/resetpassword',
    canResetPassword: 'user/canresetpassword',
    resetExpiredPassword: 'user/resetexpiredpassword',
    logout: 'user/signout',
    logoutAll: 'user/signoutall',
  };
}
