import { Pipe, PipeTransform } from '@angular/core';
import { MailAccountSearchOrder } from 'src/app/sdk/contracts/mail-account/MailAccountSearchOrder';

@Pipe({
  name: 'formatMailAccountSearchOrders',
})
export class FormatMailAccountSearchOrders implements PipeTransform {
  transform(order: MailAccountSearchOrder): string {
    switch (order) {
      case MailAccountSearchOrder.OrderByMailAccountId:
        return 'id';
      case MailAccountSearchOrder.OrderByProvider:
        return 'providerLabel';
      case MailAccountSearchOrder.OrderByName:
        return 'name';
      case MailAccountSearchOrder.OrderByState:
        return 'state';
      case MailAccountSearchOrder.OrderByArchive:
        return 'archive';
      case MailAccountSearchOrder.OrderBySpaceUsed:
        return 'spaceUsed';
      case MailAccountSearchOrder.OrderByConnection:
        return 'connect';
      default:
        return 'Not implemented';
    }
  }
}
