<h1 mat-dialog-title>{{ "confirmCloseUpload" | translate }}</h1>
<mat-dialog-content class="mat-mdc-dialog-content mdc-dialog__content mat-container">
  <p>
    {{ "uploadDeletion" | translate }} !
  </p>
</mat-dialog-content>
<mat-dialog-actions class="mat-mdc-dialog-actions mdc-dialog__actions">
  <div class="w-full flex justify-between">
    <button mat-button (click)="onCancelBtnClicked(false)">{{ "no" | translate }}</button>
    <button mat-flat-button color="primary" (click)="onCancelBtnClicked(true)">
      {{ 'yes' | translate }}
    </button>
  </div>
</mat-dialog-actions>
