<div [class]="'bg-white flex text-gray-600 p-0.5 border border-gray-300 rounded-md' + widthClass">
  <div class="flex-none">
    <button type="submit" class="h-full flex flex-wrap content-center px-2" (click)="onSearchBtnClicked()">
      <mat-icon>search</mat-icon>
    </button>
  </div>
  <div class="flex items-center search-flex w-full">
    <input
      class="w-full h-8 text-sm focus:outline-none focus:ring-0 pr-2"
      type="search"
      name="search"
      placeholder="{{ 'search' | translate }}"
      [value]="search"
      (change)="onValueChanged($event)"
      (keyup)="isDynamicSearch && onValueChanged($event)"
      (keyup.enter)="onSearchBtnClicked()" />
    <mat-icon class="search-x" (click)="onXClicked()">close</mat-icon>
  </div>
</div>
