<div class="breadcrumbs-container">
  <app-breadcrumb [items]="breadcrumbItems"></app-breadcrumb>
</div>

<div>
  <app-navigation-tabs [tabItems]="tabItems"></app-navigation-tabs>
</div>

<!--<app-drawer [isOpen]="createDrawerIsOpen" (closeEventEmitter)="onCloseCreateModalForm()">-->
<!--  <app-provider-create-dialog-->
<!--    *ngIf="createDrawerIsOpen"-->
<!--    (closeEventEmitter)="onCloseCreateModalForm()"-->
<!--    (creationSubmit)="onCreateProvider()">-->
<!--  </app-provider-create-dialog>-->
<!--</app-drawer>-->

<!--<app-drawer [isOpen]="updateDrawerIsOpen && !!updatingProvider" (closeEventEmitter)="onCloseUpdatingModalForm()">-->
<!--  <app-provider-update-dialog-->
<!--    *ngIf="updateDrawerIsOpen && !!updatingProvider"-->
<!--    [provider]="updatingProvider"-->
<!--    (closeEventEmitter)="onCloseUpdatingModalForm()"-->
<!--    (updatingSubmit)="onUpdateProvider()">-->
<!--  </app-provider-update-dialog>-->
<!--</app-drawer>-->

<div class="page-content">
  <div *ngIf="isError" class="half-of-screen">
    <app-something-went-wrong
      description="Cannot load providers"
      [showPicture]="false"
      (retryEmitter)="loadProviders()"></app-something-went-wrong>
  </div>
  <app-loader *ngIf="isLoading"></app-loader>
  <!-- <div *ngIf="isError && !isLoading" class="h-full">
    <app-something-went-wrong description="Cannot load data" [showPicture]="true" (retryEmitter)="ngOnInit()"></app-something-went-wrong>
  </div> -->
  <div *ngIf="!isLoading && !isError">
    <div class="search-panel">
      <div *ngIf="!useMobileView" class="button-group">
        <button
          mat-raised-button
          color="primary"
          class="page-block-button"
          aria-label="Create provider"
          (click)="onCreateProviderBtnClicked()">
          <mat-icon>add</mat-icon>{{ "newProvider" | translate }}
        </button>
      </div>
      <div *ngIf="useMobileView" class="button-group">
        <button
          class="settings-mobile-button"
          mat-mini-fab
          color="primary"
          class="page-block-button"
          aria-label="Create provider"
          (click)="onCreateProviderBtnClicked()">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>

    <div *ngIf="useMobileView" class="settings-mobile-block">
      <div class="mt-4 page-block" *ngFor="let provider of providers">
        <mat-card>
          <!-- <mat-card-title>{{provider.name}}</mat-card-title> -->
          <mat-card-content class="cursor-default">
            <div class="card-table-row">
              <div class="card-table-row-header">{{ "name" | translate }}:</div>
              <div class="card-table-row-value">{{ provider.name }}</div>
            </div>
            <div class="card-table-row">
              <div class="card-table-row-header">{{ "smtp" | translate }}:</div>
              <div class="card-table-row-value">{{ provider.smtp }}</div>
            </div>
            <div class="card-table-row">
              <div class="card-table-row-header">{{ "smtpPort" | translate }}:</div>
              <div class="card-table-row-value">{{ provider.smtpPort }}</div>
            </div>
            <div class="card-table-row">
              <div class="card-table-row-header">{{ "imap" | translate }}:</div>
              <div class="card-table-row-value">{{ provider.imap }}</div>
            </div>
            <div class="card-table-row">
              <div class="card-table-row-header">{{ "imapPort" | translate }}:</div>
              <div class="card-table-row-value">{{ provider.imapPort }}</div>
            </div>
          </mat-card-content>
          <mat-card-actions>
            <div class="flex justify-end">
              <button mat-flat-button color="primary" aria-label="Details" (click)="openUpdateProviderDialog(provider)">
                <mat-icon>edit_outline</mat-icon>
              </button>
              <span class="mr-2"></span>
              <button mat-flat-button color="primary" aria-label="Delete" (click)="deleteProvider(provider.providerId, provider.name)">
                <mat-icon>delete_outline</mat-icon>
              </button>
            </div>
          </mat-card-actions>
        </mat-card>
      </div>
      <app-no-data *ngIf="providers.length === 0"></app-no-data>
    </div>

    <div *ngIf="!useMobileView">
      <div class="table-container page-block">
        <table mat-table [dataSource]="dataSource" class="w-full">
          <ng-container matColumnDef="name">
            <th class="flex-1" mat-header-cell *matHeaderCellDef>{{ "name" | translate }}</th>
            <td class="flex-1" mat-cell *matCellDef="let provider">{{ provider.name }}</td>
          </ng-container>
          <ng-container matColumnDef="smtp">
            <th mat-header-cell *matHeaderCellDef>{{ "smtp" | translate }}</th>
            <td mat-cell *matCellDef="let provider">{{ provider.smtp }}</td>
          </ng-container>
          <ng-container matColumnDef="smtp-port">
            <th mat-header-cell *matHeaderCellDef>{{ "smtpPort" | translate }}</th>
            <td mat-cell *matCellDef="let provider">{{ provider.smtpPort }}</td>
          </ng-container>
          <ng-container matColumnDef="imap">
            <th mat-header-cell *matHeaderCellDef>{{ "imap" | translate }}</th>
            <td mat-cell *matCellDef="let provider">{{ provider.imap }}</td>
          </ng-container>
          <ng-container matColumnDef="imap-port">
            <th mat-header-cell *matHeaderCellDef>{{ "imapPort" | translate }}</th>
            <td mat-cell *matCellDef="let provider">{{ provider.imapPort }}</td>
          </ng-container>
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>{{ "actions" | translate }}</th>
            <td mat-cell *matCellDef="let provider" class="action-link">
              <button mat-icon-button aria-label="Details" (click)="openUpdateProviderDialog(provider)">
                <mat-icon>edit_outline</mat-icon>
              </button>
              <span class="mr-2"></span>
              <button mat-icon-button aria-label="Delete" (click)="deleteProvider(provider.providerId, provider.name)">
                <mat-icon>delete_outline</mat-icon>
              </button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns" class="cursor-default"></tr>
        </table>
        <app-no-data *ngIf="providers.length === 0"></app-no-data>
      </div>
    </div>
  </div>
</div>
