<!--<app-drawer [width]="'width: 70%'" [isOpen]="createOrUpdateDrawerIsOpen" (closeEventEmitter)="isClosingCreateOrUpdateModalForm()">-->
<!--  <app-email-create-or-update-->
<!--    *ngIf="createOrUpdateDrawerIsOpen"-->
<!--    [mailAccountId]="selectedMailAccountId"-->
<!--    [emailId]="updatedEmail?.emailId"-->
<!--    [useMobileView]="useMobileView"-->
<!--    [isDrawerClosing]="isDrawerClosing"-->
<!--    (submitted)="closeCreateOrUpdateModalForm(false)"-->
<!--    (deleteFromData)="deleteFromData($event)"-->
<!--    (changeDraftData)="changeDraftData($event)">-->
<!--  </app-email-create-or-update>-->
<!--</app-drawer>-->

<!--<app-drawer [isOpen]="notificationDrawerIsOpen" (closeEventEmitter)="closeNotification()" [width]="'width: 30%'">-->
<!--  <app-email-notifications-dialog-->
<!--    [title]="'notifications'"-->
<!--    *ngIf="notificationDrawerIsOpen"-->
<!--    [emailId]="notificationMailId"-->
<!--    [webCode]="webCode"-->
<!--    (openEmailDetails)="openEmailDetails($event)">-->
<!--  </app-email-notifications-dialog>-->
<!--</app-drawer>-->

<!--<app-drawer [isOpen]="notesDrawerIsOpen" (closeEventEmitter)="closeNotes()" [width]="'width: 30%'">-->
<!--  <app-email-note-list *ngIf="notesDrawerIsOpen" [emailId]="notesMailId" [title]="'notes'" (refreshNotes)="refreshNotes()">-->
<!--  </app-email-note-list>-->
<!--</app-drawer>-->

<!--<app-drawer [isOpen]="auditDrawerIsOpen" (closeEventEmitter)="closeAudit()" [width]="'width: 30%'">-->
<!--  <app-email-audit *ngIf="auditDrawerIsOpen" [title]="'audit'" [emailId]="auditMailId"></app-email-audit>-->
<!--</app-drawer>-->

<!--<app-drawer [isOpen]="metaDrawerIsOpen" (closeEventEmitter)="closeMeta()" [width]="'width: 30%'">-->
<!--  <app-email-meta *ngIf="metaDrawerIsOpen" [emailId]="metaMailId" [title]="'meta'"> </app-email-meta>-->
<!--</app-drawer>-->

<!--<app-drawer [isOpen]="importEMLIsOpen" (closeEventEmitter)="closeImportEml()" [width]="'width: 70%'">-->
<!--  <app-import-eml-->
<!--    (loading)="loadingImportEML($event)"-->
<!--    *ngIf="importEMLIsOpen"-->
<!--    [folder]="currentFolderName"-->
<!--    [mailAccountId]="selectedMailAccountId"-->
<!--    [mailFolderId]="selectedMailFolderId"-->
<!--  ></app-import-eml>-->
<!--</app-drawer>-->

<!--<app-drawer [title]="openedEmail?.subject" [isOpen]="emailDrawerIsOpen" (closeEventEmitter)="closeEmailModalForm()" [width]="'width: 70%'">-->
<!--  <app-email-detail-dialog-->
<!--    *ngIf="emailDrawerIsOpen"-->
<!--    [emailId]="openedEmail.emailId"-->
<!--    (close)="closeEmailModalForm()"-->
<!--    [useMobileView]="useMobileView"-->
<!--    (getInfoAboutOpenedEmail)="getInfoAboutOpenedEmail($event)"-->
<!--    (setSeenOpened)="setSeenOpened($event)"-->
<!--    (setValidationStateForEmail)="setValidationStateForEmail($event)"-->
<!--    (deleteFromData)="deleteFromData($event)">-->
<!--  </app-email-detail-dialog>-->
<!--</app-drawer>-->

<div
  style="visibility: hidden; position: fixed"
  [style.left]="menuTopLeftPosition.x"
  [style.top]="menuTopLeftPosition.y"
  [matMenuTriggerFor]="mailMenu"></div>

<mat-menu #mailMenu="matMenu" class="menu">
  <ng-template matMenuContent let-item="item">
    <button *ngIf="showIconById(item.mailFolderId, emailIcons.Reply)" mat-menu-item (click)="reply(item)">
      <mat-icon>reply</mat-icon>{{ "reply" | translate }}
    </button>
    <button
      *ngIf="showIconById(item.mailFolderId, emailIcons.ReplyAll)"
      mat-menu-item
      aria-label="{{ 'replyAll' | translate }}"
      (click)="replyAll(item)">
      <mat-icon>reply_all_outline</mat-icon>{{ "replyAll" | translate }}
    </button>
    <button
      *ngIf="showIconById(item.mailFolderId, emailIcons.Forward)"
      mat-menu-item
      aria-label="{{ 'forward' | translate }}"
      (click)="forward(item.emailId)">
      <mat-icon>shortcut</mat-icon>{{ "forward" | translate }}
    </button>
    <button
      *ngIf="showIconById(item.mailFolderId, emailIcons.Reassign) && !reassigned"
      mat-menu-item
      aria-label="Reassign"
      (click)="reassign(item.emailId)">
      <mat-icon>forward_to_inbox</mat-icon>{{ "reassign" | translate }}
    </button>
    <button *ngIf="showIconById(item.mailFolderId, emailIcons.Move)" mat-menu-item aria-label="Move" (click)="move(item.emailId)">
      <mat-icon>folder_open</mat-icon> {{ "moveToFolder" | translate }}
    </button>
    <button
      *ngIf="showIconById(item.mailFolderId, emailIcons.Groups)"
      mat-menu-item
      aria-label="{{ 'groups' | translate }}"
      (click)="openAddGroupDialog(item)">
      <mat-icon>groups</mat-icon>{{ 'groups' | translate }}
    </button>
    <button
      *ngIf="showIconById(item.mailFolderId, emailIcons.Archive)"
      mat-menu-item
      aria-label="Archive"
      (click)="setSelectedArchive(item.emailId)">
      <mat-icon>archive</mat-icon>{{ "archiveIconLabel" | translate }}
    </button>
    <button *ngIf="showIconById(item.mailFolderId, emailIcons.Tags)" mat-menu-item aria-label="Tags" (click)="openAddTagDialog(item)">
      <mat-icon>local_offer</mat-icon>{{ "tags" | translate }}
    </button>
    <button *ngIf="showIconById(item.mailFolderId, emailIcons.Seen)" mat-menu-item (click)="openAudit(item.emailId)">
      <mat-icon>verified_outline</mat-icon>{{ "audit" | translate }}
    </button>
    <button *ngIf="showIconById(item.mailFolderId, emailIcons.Seen) && !item.seen" mat-menu-item (click)="setSeen(true, item)">
      <mat-icon>visibility_outline</mat-icon>{{ "setRead" | translate }}
    </button>
    <button *ngIf="showIconById(item.mailFolderId, emailIcons.Seen) && item.seen" mat-menu-item (click)="setSeen(false, item)">
      <mat-icon>visibility_off_outline</mat-icon>{{ "setUnread" | translate }}
    </button>
    <button
      *ngIf="showIconById(item.mailFolderId, emailIcons.RetrySending) && item.state === 5"
      mat-menu-item
      aria-label="{{ 'retrySending' | translate }}"
      matTooltip="{{ 'retrySending' | translate }}"
      (click)="retrySend(item.emailId)">
      <mat-icon>redo</mat-icon>{{ "retrySending" | translate }}
    </button>
    <button *ngIf="showIconById(item.mailFolderId, emailIcons.Spam) && !item.isSpam" mat-menu-item (click)="setSpam(item.emailId)">
      <mat-icon>report_outline</mat-icon>{{ "markAsSpam" | translate }}
    </button>
    <button
      *ngIf="(showIconById(item.mailFolderId, emailIcons.Delete)) && !item.deleted"
      mat-menu-item
      (click)="delete(item.emailId)">
      <mat-icon>delete</mat-icon>{{ "delete" | translate }}
    </button>
    <button
      *ngIf="showIconById(item.mailFolderId, emailIcons.RetrySending)
        && (item.state === emailStates.VirusDetected || item.state === emailStates.SendError)"
      mat-menu-item
      (click)="moveToDraft(item.emailId)">
      <mat-icon>folder_open</mat-icon>{{ "moveToDraft" | translate }}
    </button>
    <button
      *ngIf="showIconById(item.mailFolderId, emailIcons.Restore) && item.deleted"
      mat-menu-item
      (click)="restoreFromTrash(item.emailId)">
      <mat-icon>restore_outline</mat-icon>{{ "restore" | translate }}
    </button>
    <button
      *ngIf="showIconById(item.mailFolderId, emailIcons.DownloadEML)"
      mat-menu-item
      aria-label="{{ 'downloadSingleEml' | translate }}"
      (click)="downloadSingleEML(item.emailId)">
      <mat-icon>download</mat-icon>{{ "downloadSingleEml" | translate }}
    </button>
    <button
      *ngIf="showIconById(item.mailFolderId, emailIcons.DownloadEML)"
      mat-menu-item
      aria-label="{{ 'downloadEml' | translate }}"
      (click)="downloadEML(item.emailId)">
      <mat-icon>download_for_offline</mat-icon>{{ "downloadEml" | translate }}
    </button>
    <button
      *ngIf="showIconById(item.mailFolderId, emailIcons.NotSpam) && item.isSpam"
      mat-menu-item
      (click)="restoreMoveFolderChoose([item.emailId], 'restoreOneSpam')">
      <mat-icon>check</mat-icon>{{ "markAsNotSpam" | translate }}
    </button>
    <button
      *ngIf="showIconById(item.mailFolderId, emailIcons.RestoreFromArchive)"
      mat-menu-item
      aria-label="{{ 'restoreFromArchive' | translate }}"
      (click)="restoreFromArchive(item.emailId)">
      <mat-icon>assignment_turned_in</mat-icon>{{ "restoreArchive" | translate }}
    </button>
    <button
      *ngIf="
        showIconById(item.mailFolderId, emailIcons.Validate) &&
        (item.validationState === emailValidationState.NotValidated || item.validationState === emailValidationState.New) &&
        !isEmailValidating(item.emailId)
      "
      mat-menu-item
      aria-label="{{ 'validate' | translate }}"
      [disabled]="!!validatingEmailIds.length"
      (click)="setValidated(emailValidationState.Validated, item.emailId)">
      <mat-icon>check_circle_outline</mat-icon>{{ "validate" | translate }}
    </button>
  </ng-template>
</mat-menu>

<div class="flex my-1 justify-between flex-wrap primary-container" style="padding-bottom: 0">
  <div *ngIf="!useMobileView" class="icon-button-group flex items-center">
    <button class="h-[40px]" mat-raised-button color="primary" (click)="createEmail()" matTooltip="{{ 'createEmail' | translate }}">
      <mat-icon class="mr-2">mail_outline</mat-icon>{{ "newEmail" | translate }}
    </button>
    <button
      *ngIf="currentFolderType !== mailFolderType.Outbox && !isExporting"
      mat-icon-button
      aria-label="{{ 'exportExcel' | translate }}"
      (click)="exportExcel()"
      matTooltip="{{ 'exportExcelLabel' | translate }}">
      <i class="far fa-file-excel fa-xs"></i>
    </button>
    <div *ngIf="isExporting" style="width: 40px">
      <app-loader padding="0" [diameter]="22" height="100%"></app-loader>
    </div>
    <button
      *ngIf="currentFolderType === mailFolderType.Inbox || currentFolderType === mailFolderType.Sent"
      mat-icon-button
      aria-label="{{ 'importEML' | translate }}"
      (click)="importEML()"
      matTooltip="{{ 'importEML' | translate }}">
      <i class="fa-solid fa-upload fa-xs"></i>
    </button>
    <div *ngIf="selection.selected.length" style="display: flex">
      <app-loading-icon-button
        label="validate"
        icon="check_circle_outline"
        [showButton]="
          showIconByIdSelected(emailIcons.Validate) &&
          !selection.isEmpty() &&
          validateIsAvailable() &&
          selectedMailAccountId &&
          !validatingEmailIds.length
        "
        [showLoading]="validatingEmailIds.length"
        (onClick)="setSelectedValidated(emailValidationState.Validated, emails.emailId)">
      </app-loading-icon-button>
      <button
        *ngIf="(currentFolderType === mailFolderType.Trash ||
          currentFolderType === mailFolderType.Spam) && canFinalDelete"
        mat-icon-button
        aria-label="{{ 'deleteFinalMail' | translate }}"
        matTooltip="{{ 'deleteFinalMail' | translate }}"
        (click)="finalDeleteAllSelected()">
      <mat-icon>delete_forever</mat-icon>
      </button>
      <button
        *ngIf="
          showIconByIdSelected(emailIcons.RetrySending) &&
          !selection.isEmpty() &&
          selectedMailAccountId &&
          isSelectedRetrySendingNeededState()
        "
        mat-icon-button
        aria-label="{{ 'retrySending' | translate }}"
        matTooltip="{{ 'retrySending' | translate }}"
        (click)="retrySendSelected()">
        <mat-icon>redo</mat-icon>
      </button>
      <app-loading-icon-button
        label="reply"
        icon="reply"
        [showButton]="!replyingEmailId && showIconByIdSelected(emailIcons.Reply) && !selection.isEmpty() && selection.selected.length === 1"
        [showLoading]="replyingEmailId"
        [disabled]="replyingAllEmailId || forwardingEmailId || replyingEmailId"
        (onClick)="reply(selection.selected)">
      </app-loading-icon-button>
      <app-loading-icon-button
        label="replyAll"
        icon="reply_all_outline"
        [showButton]="
          !replyingAllEmailId && showIconByIdSelected(emailIcons.ReplyAll) && !selection.isEmpty() && selection.selected.length === 1
        "
        [showLoading]="replyingAllEmailId"
        [disabled]="replyingAllEmailId || forwardingEmailId || replyingEmailId"
        (onClick)="replyAll(selection.selected)">
      </app-loading-icon-button>
      <app-loading-icon-button
        label="forward"
        icon="shortcut"
        [showButton]="!forwardingEmailId && showIconByIdSelected(emailIcons.Forward) && !selection.isEmpty()"
        [showLoading]="forwardingEmailId"
        [disabled]="replyingAllEmailId || forwardingEmailId || replyingEmailId"
        (onClick)="forward()">
      </app-loading-icon-button>
      <button
        *ngIf="showIconByIdSelected(emailIcons.Reassign) && !selection.isEmpty() && multipleReassignIsAvailable && !reassigned"
        mat-icon-button
        aria-label="{{ 'reassign' | translate }}"
        (click)="reassignSelected()"
        matTooltip="{{ 'reassign' | translate }}">
        <mat-icon>forward_to_inbox</mat-icon>
      </button>
      <button
        *ngIf="showIconByIdSelected(emailIcons.Move) && !selection.isEmpty() && selectedMailAccountId"
        mat-icon-button
        aria-label="Move"
        (click)="move()"
        matTooltip="{{ 'moveToFolder' | translate }}">
        <i class="far fa-folder fa-xs"></i>
      </button>
      <button
        *ngIf="showIconByIdSelected(emailIcons.Archive) && !selection.isEmpty() && selectedMailAccountId"
        mat-icon-button
        aria-label="Archive"
        (click)="setSelectedArchive()"
        matTooltip="{{ 'archiveIconLabel' | translate }}">
        <mat-icon>archive</mat-icon>
      </button>
      <button
        *ngIf="showIconByIdSelected(emailIcons.Groups) && !selection.isEmpty() && selectedMailAccountId"
        mat-icon-button
        aria-label="{{ 'groups' | translate }}"
        matTooltip="{{ 'groups' | translate }}"
        (click)="openAddGroupDialogSelected()">
        <mat-icon>groups</mat-icon>
      </button>
      <button
        *ngIf="showIconByIdSelected(emailIcons.Tags) && !selection.isEmpty() && selectedMailAccountId"
        mat-icon-button
        aria-label="{{ 'tags' | translate }}"
        matTooltip="{{ 'tags' | translate }}"
        (click)="openAddTagDialogSelected()">
        <mat-icon>local_offer</mat-icon>
      </button>
      <button
        *ngIf="showIconByIdSelected(emailIcons.Seen) && !selection.isEmpty() && selection.selected.length === 1"
        mat-icon-button
        aria-label="Audit"
        (click)="openAudit()"
        matTooltip="{{ 'audit' | translate }}">
        <mat-icon>verified_outline</mat-icon>
      </button>
      <button
        *ngIf="showIconByIdSelected(emailIcons.Seen) && !selection.isEmpty() && selectedMailAccountId"
        mat-icon-button
        aria-label="Read"
        (click)="setSelectedSeen(true)"
        matTooltip="{{ 'read' | translate }}">
        <mat-icon>visibility_outline</mat-icon>
      </button>
      <button
        *ngIf="showIconByIdSelected(emailIcons.Seen) && !selection.isEmpty() && selectedMailAccountId"
        mat-icon-button
        aria-label="Unread"
        (click)="setSelectedSeen(false)"
        matTooltip="{{ 'unread' | translate }}">
        <mat-icon>visibility_off_outline</mat-icon>
      </button>
      <button
        *ngIf="showIconByIdSelected(emailIcons.Spam) && !selection.isEmpty() && selectedMailAccountId"
        mat-icon-button
        aria-label="{{ 'markAsSpam' | translate }}"
        (click)="setSelectedSpam()"
        matTooltip="{{ 'markAsSpam' | translate }}">
        <mat-icon>report_outline</mat-icon>
      </button>
      <button
        *ngIf="showIconByIdSelected(emailIcons.Delete) && !selection.isEmpty() && selectedMailAccountId"
        mat-icon-button
        aria-label="{{ 'delete' | translate }}"
        matTooltip="{{ 'delete' | translate }}"
        (click)="deleteAllSelected()">
        <mat-icon>delete</mat-icon>
      </button>
      <app-loading-icon-button
        label="downloadSingleEml"
        icon="download"
        [showButton]="
          showIconByIdSelected(emailIcons.DownloadEML) && !selection.isEmpty() && selectedMailAccountId && !isDownloadingSingleEML
        "
        [showLoading]="isDownloadingSingleEML"
        (onClick)="downloadSingleEMLSelection()">
      </app-loading-icon-button>
      <app-loading-icon-button
        label="downloadEml"
        icon="download_for_offline"
        [showButton]="showIconByIdSelected(emailIcons.DownloadEML) && !selection.isEmpty() && selectedMailAccountId && !isDownloadingEML"
        [showLoading]="isDownloadingEML"
        (onClick)="downloadEMLSelection()">
      </app-loading-icon-button>
      <button
        *ngIf="showIconByIdSelected(emailIcons.Restore) && !selection.isEmpty() && selectedMailAccountId"
        mat-icon-button
        aria-label="{{ 'restore' | translate }}"
        matTooltip="{{ 'restore' | translate }}"
        (click)="restoreFromTrash()">
        <mat-icon>restore_outline</mat-icon>
      </button>
      <button
        *ngIf="showIconByIdSelected(emailIcons.RestoreFromArchive) && !selection.isEmpty() && selectedMailAccountId"
        mat-icon-button
        aria-label="{{ 'restoreFromArchive' | translate }}"
        (click)="restoreFromArchive()"
        matTooltip="{{ 'restoreFromArchive' | translate }}">
        <mat-icon>assignment_turned_in</mat-icon>
      </button>
      <button
        *ngIf="showIconByIdSelected(emailIcons.NotSpam) && !selection.isEmpty() && selectedMailAccountId"
        mat-icon-button
        aria-label="{{ 'markAsNotSpam' | translate }}"
        (click)="restoreMoveFolderChoose(selection.selected, 'restoreSelectedSpam')"
        matTooltip="{{ 'markAsNotSpam' | translate }}">
        <mat-icon>check</mat-icon>
      </button>
    </div>
  </div>
  <div *ngIf="useMobileView" class="icon-button-group flex justify-between items-center w-full">
    <div class="flex">
      <button class="h-[40px]" mat-raised-button color="primary" (click)="createEmail()" matTooltip="{{ 'createEmail' | translate }}">
        <mat-icon class="mr-2">mail_outline</mat-icon>{{ "newEmailMobile" | translate }}
      </button>
      <button
        *ngIf="currentFolderType !== mailFolderType.Outbox"
        mat-icon-button
        aria-label="Export excel"
        (click)="exportExcel()"
        matTooltip="{{ 'exportExcel' | translate }}">
        <i class="far fa-file-excel fa-xs"></i>
      </button>

      <div *ngIf="selection.selected.length" class="flex">
        <app-loading-icon-button
          label="validate"
          icon="check_circle_outline"
          [showButton]="
            showIconByIdSelected(emailIcons.Validate) && !selection.isEmpty() && validateIsAvailable() && !validatingEmailIds.length
          "
          [showLoading]="validatingEmailIds.length"
          (onClick)="setSelectedValidated(emailValidationState.Validated, emails.emailId)">
        </app-loading-icon-button>
        <button *ngIf="selection?.selected?.length !== 0" mat-icon-button aria-label="Menu" [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button
            *ngIf="showIconByIdSelected(emailIcons.Reply) && !selection.isEmpty() && selection.selected.length === 1"
            mat-menu-item
            aria-label="{{ 'reply' | translate }}"
            (click)="reply(selection.selected)">
            <mat-icon>reply</mat-icon>{{ "reply" | translate }}
          </button>
          <button
            *ngIf="showIconByIdSelected(emailIcons.ReplyAll) && !selection.isEmpty() && selection.selected.length === 1"
            mat-menu-item
            aria-label="{{ 'replyAll' | translate }}"
            (click)="replyAll(selection.selected)">
            <mat-icon>reply_all_outline</mat-icon>{{ "replyAll" | translate }}
          </button>
          <button
            *ngIf="showIconByIdSelected(emailIcons.Forward) && !selection.isEmpty() && selectedMailAccountId"
            mat-menu-item
            aria-label="{{ 'forward' | translate }}"
            (click)="forward()">
            <mat-icon>shortcut</mat-icon>{{ "forward" | translate }}
          </button>
          <button
            *ngIf="showIconByIdSelected(emailIcons.Reassign) && !selection.isEmpty() && multipleReassignIsAvailable && !reassigned"
            mat-menu-item
            aria-label="{{ 'reassign' | translate }}"
            (click)="reassignSelected()">
            <mat-icon>forward_to_inbox</mat-icon>{{ "reassign" | translate }}
          </button>
          <button
            *ngIf="showIconByIdSelected(emailIcons.Move) && !selection.isEmpty() && selectedMailAccountId"
            mat-menu-item
            aria-label="Move"
            (click)="move()">
            <mat-icon>folder_open</mat-icon> {{ "moveToFolder" | translate }}
          </button>
          <button
            *ngIf="showIconByIdSelected(emailIcons.Archive) && !selection.isEmpty() && selectedMailAccountId"
            mat-menu-item
            aria-label="Archive"
            (click)="setSelectedArchive()">
            <mat-icon>archive</mat-icon>{{ "archiveIconLabel" | translate }}
          </button>
          <button
            *ngIf="showIconByIdSelected(emailIcons.Groups) && !selection.isEmpty() && selectedMailAccountId"
            mat-menu-item
            aria-label="Groups"
            (click)="openAddGroupDialogSelected()">
            <mat-icon>groups</mat-icon>{{ "addGroups" | translate }}
          </button>
          <button
            *ngIf="showIconByIdSelected(emailIcons.Tags) && !selection.isEmpty() && selectedMailAccountId"
            mat-menu-item
            aria-label="{{ 'tags' | translate }}"
            (click)="openAddTagDialogSelected()">
            <mat-icon>local_offer</mat-icon>{{ "addTags" | translate }}
          </button>
          <button
            *ngIf="showIconByIdSelected(emailIcons.Seen) && !selection.isEmpty() && selectedMailAccountId"
            mat-menu-item
            aria-label="{{ 'markAsUnread' }}"
            (click)="setSelectedSeen(false)">
            <mat-icon>visibility_off_outline</mat-icon>{{ "markAsUnread" | translate }}
          </button>
          <button
            *ngIf="showIconByIdSelected(emailIcons.Seen) && !selection.isEmpty() && selection.selected.length === 1"
            mat-menu-item
            aria-label="{{ 'audit' }}"
            (click)="openAudit()">
            <mat-icon>verified_outline</mat-icon>{{ "audit" | translate }}
          </button>
          <button
            *ngIf="showIconByIdSelected(emailIcons.Seen) && !selection.isEmpty() && selectedMailAccountId"
            mat-menu-item
            aria-label="{{ 'markAsRead' | translate }}"
            (click)="setSelectedSeen(true)">
            <mat-icon>visibility_outline</mat-icon>{{ "markAsRead" | translate }}
          </button>
          <button
            *ngIf="showIconByIdSelected(emailIcons.Spam) && !selection.isEmpty() && selectedMailAccountId"
            mat-menu-item
            aria-label="{{ 'markAsSpam' | translate }}"
            (click)="setSelectedSpam()">
            <mat-icon>report_outline</mat-icon>{{ "markAsSpam" | translate }}
          </button>
          <button
            *ngIf="showIconByIdSelected(emailIcons.Delete) && !selection.isEmpty() && selectedMailAccountId"
            mat-menu-item
            aria-label="{{ 'deleteMail' | translate }}"
            (click)="deleteAllSelected()">
            <mat-icon>delete</mat-icon>{{ "delete" | translate }}
          </button>
          <button
            *ngIf="showIconByIdSelected(emailIcons.DownloadEML) && !selection.isEmpty() && selectedMailAccountId"
            mat-menu-item
            aria-label="{{ 'downloadSingleEml' | translate }}"
            (click)="downloadSingleEMLSelection()">
            <mat-icon>download</mat-icon>{{ "downloadSingleEml" | translate }}
          </button>
          <button
            *ngIf="showIconByIdSelected(emailIcons.DownloadEML) && !selection.isEmpty() && selectedMailAccountId"
            mat-menu-item
            aria-label="{{ 'downloadEml' | translate }}"
            (click)="downloadEMLSelection()">
            <mat-icon>download_for_offline</mat-icon>{{ "downloadEml" | translate }}
          </button>
          <button
            *ngIf="showIconByIdSelected(emailIcons.RestoreFromArchive) && !selection.isEmpty() && selectedMailAccountId"
            mat-menu-item
            aria-label="{{ 'restoreFromArchive' | translate }}"
            (click)="restoreFromArchive()">
            <mat-icon>assignment_turned_in</mat-icon>{{ "restoreArchive" | translate }}
          </button>
          <button
            *ngIf="
              showIconByIdSelected(emailIcons.RetrySending) &&
              !selection.isEmpty() &&
              selectedMailAccountId &&
              isSelectedRetrySendingNeededState()
            "
            mat-menu-item
            aria-label="{{ 'retrySending' | translate }}"
            (click)="retrySendSelected()">
            <mat-icon>redo</mat-icon>{{ "retrySending" | translate }}
          </button>
          <button
            *ngIf="showIconByIdSelected(emailIcons.Restore) && !selection.isEmpty() && selectedMailAccountId"
            mat-menu-item
            aria-label="{{ 'restore' | translate }}"
            (click)="restoreFromTrash()">
            <mat-icon>restore_outline</mat-icon>{{ "restore" | translate }}
          </button>
          <button
            *ngIf="showIconByIdSelected(emailIcons.NotSpam) && !selection.isEmpty() && selectedMailAccountId"
            mat-menu-item
            aria-label="{{ 'markAsNotSpam' | translate }}"
            (click)="restoreMoveFolderChoose(selection.selected, 'restoreSelectedSpam')">
            <mat-icon>check</mat-icon>{{ "markAsNotSpam" | translate }}
          </button>
        </mat-menu>
      </div>
    </div>
    <div class="flex items-center">
      <button mat-icon-button aria-label="{{ 'reload' | translate }}" (click)="reload()" matTooltip="{{ 'reload' | translate }}">
        <mat-icon>refresh</mat-icon>
      </button>
      <div>
        <mat-form-field style="width: 56px">
          <mat-select class="text-center" [(value)]="searchOrder" (valueChange)="searchOrderChange()">
            <mat-option *ngFor="let searchOrder of searchOrders" [value]="searchOrder.id">
              {{ searchOrder.name | translate | lowercase }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div *ngIf="useMobileView && !isError" class="flex flex-wrap-reverse items-center justify-between w-full md:mt-0 mt-5">
    <div class="w-full flex items-center flex-wrap justify-end">
      <div class="flex items-center justify-between w-full">
        <div class="mr-6 ml-4">
          <mat-checkbox
            (change)="$event ? masterToggle() : null"
            color="primary"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()">
            <!-- {{ "selectAll" | translate }} -->
          </mat-checkbox>
        </div>
        <app-paginator
          [countFrom]="countFrom()"
          [countTo]="countTo()"
          [countTotal]="countTotal()"
          [hasPreviousPage]="canNavigateBack"
          [hasNextPage]="canNavigateForward"
          [pageSize]="pageSize"
          [pageSizeOptions]="[25, 50, 100, 200]"
          (pageSizeChange)="pageSizeChange($event)"
          (goToPreviousPage)="navigateBack()"
          (goToNextPage)="navigateForward()">
        </app-paginator>
      </div>
    </div>
  </div>

  <div *ngIf="!useMobileView && !isError" class="flex flex-wrap-reverse items-center justify-end ml-auto md:mt-0 mt-5">
    <div class="ml-auto flex items-center flex-wrap justify-end">
      <button mat-icon-button aria-label="{{ 'reload' | translate }}" (click)="reload()" matTooltip="{{ 'reload' | translate }}">
        <mat-icon>refresh</mat-icon>
      </button>
      <div class="per-page-block">
        <mat-form-field style="width: 56px">
          <mat-select class="text-center" [(value)]="searchOrder" (valueChange)="searchOrderChange()">
            <mat-option *ngFor="let searchOrder of searchOrders" [value]="searchOrder.id" style="width: auto">
              {{ searchOrder.name | translate | lowercase }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="flex items-center">
        <app-paginator
          [countFrom]="countFrom()"
          [countTo]="countTo()"
          [countTotal]="countTotal()"
          [hasPreviousPage]="canNavigateBack"
          [hasNextPage]="canNavigateForward"
          [pageSize]="pageSize"
          [pageSizeOptions]="[25, 50, 100, 200]"
          (pageSizeChange)="pageSizeChange($event)"
          (goToPreviousPage)="navigateBack()"
          (goToNextPage)="navigateForward()">
        </app-paginator>
      </div>
    </div>
  </div>
</div>
<div class="w-full h-[85%] flex flex-col primary-container" style="padding-top: 0">
  <div class="canvas" *ngIf="isError">
    <app-something-went-wrong
      description="{{ 'cannotLoadEmails' | translate }}"
      (retryEmitter)="loadData()"
      [showPicture]="false"></app-something-went-wrong>
  </div>
  <div class="canvas" *ngIf="!isError">
    <div *ngIf="showLoader" style="height: 100%">
      <app-loader height="65%"></app-loader>
    </div>

    <div *ngIf="!isError && !showLoader && useMobileView" class="cards">
      <div class="text-center mt-3" *ngIf="!dataSource?.data?.length">
        {{ "noEmailsEmailsContentComponent" | translate }}
      </div>
      <div
        *ngFor="let email of dataSource.data"
        [class.!font-bold]="!email.seen"
        class="page-block mb-4"
        (contextmenu)="onRightClick($event, email)">
        <div class="card">
          <div
            [class.row-select]="isRowSelected(email.emailId)"
            [class.row-automatic-reassigned]="email?.sourceEmailId && !isRowWithError(email) ? true : false"
            [class.row-error]="isRowWithError(email)"
            class="checkbox-col">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              color="primary"
              (change)="$event ? selection.toggle(email.emailId) : null"
              [checked]="selection.isSelected(email.emailId)">
            </mat-checkbox>
          </div>

          <mat-card
            style="border-radius: 0"
            class="cursor-pointer mobile-row"
            [class.row-select]="isRowSelected(email.emailId)"
            [class.row-automatic-reassigned]="email?.sourceEmailId && !isRowWithError(email) ? true : false"
            [class.row-error]="isRowWithError(email)">
            <mat-card-content class="flex flex-1" style="margin-bottom: 0" (click)="emailDetails(email)">
              <div *ngIf="!isColumnShow(false)" class="card-table-row">
                <div class="card-table-row-value">{{ formatTo(email.from) }}</div>
              </div>
              <div *ngIf="!isColumnShow(true)" class="card-table-row">
                <div class="card-table-row-value">{{ formatTo(email.to) }}</div>
              </div>
              <div class="card-table-row">
                <div class="card-table-row-value">{{ email.subject }}</div>
              </div>
              <div class="card-table-row flex-start">
                <div class="card-table-row-value">{{ getDate(email) | date : "dd/MM/yyyy HH:mm:ss" }}</div>
              </div>
              <div *ngIf="currentFolderType === mailFolderType.Outbox" class="card-table-row flex-start">
                {{ "emailState" + emailStates[email.state] | translate }}
              </div>
              <mat-chip-listbox *ngIf="email.tags?.length" aria-label="Fish selection">
                <mat-chip-option *ngFor="let tag of email.tags">{{ tag.tag }}</mat-chip-option>
              </mat-chip-listbox>
              <mat-chip-listbox *ngIf="email.groups?.length" aria-label="Fish selection">
                <mat-chip-option style="background: #e9e43b" *ngFor="let group of email.groups">{{ group.group }}</mat-chip-option>
              </mat-chip-listbox>
            </mat-card-content>
            <div style="width: 40px; margin-left: 10px">
              <div
                *ngIf="
                  currentFolderType === mailFolderType.Sent ||
                  currentFolderType === mailFolderType.Archive ||
                  currentFolderType === mailFolderType.Trash
                ">
                <button
                  *ngIf="email.state === emailStates.Sent && isSentStates(email.state)"
                  (click)="openNotifications(email.emailId)"
                  mat-icon-button
                  matTooltip="{{ 'sent' | translate }}"
                  aria-label="{{ 'sent' | translate }}">
                  <mat-icon>done</mat-icon>
                </button>
                <button
                  *ngIf="email.state === emailStates.Delivered && isSentStates(email.state)"
                  (click)="openNotifications(email.emailId)"
                  mat-icon-button
                  matTooltip="{{ 'delivered' | translate }}"
                  aria-label="{{ 'delivered' | translate }}">
                  <mat-icon color="primary">done_all</mat-icon>
                </button>
                <button
                  *ngIf="email.state === emailStates.DeliveryError && isSentStates(email.state)"
                  (click)="openNotifications(email.emailId)"
                  mat-icon-button
                  matTooltip="{{ 'deliveryError' | translate }}"
                  aria-label="{{ 'deliveryError' | translate }}">
                  <mat-icon style="color: red">clear icon</mat-icon>
                </button>
                <button
                  *ngIf="email.state === emailStates.NotAccepted && isSentStates(email.state)"
                  (click)="openNotifications(email.emailId)"
                  mat-icon-button
                  matTooltip="{{ 'notAccepted' | translate }}"
                  aria-label="{{ 'notAccepted' | translate }}">
                  <mat-icon style="color: red">clear icon</mat-icon>
                </button>
                <button
                  *ngIf="email.state === emailStates.Accepted && isSentStates(email.state)"
                  (click)="openNotifications(email.emailId)"
                  mat-icon-button
                  color="primary"
                  matTooltip="{{ 'accepted' | translate }}"
                  aria-label="{{ 'accepted' | translate }}">
                  <mat-icon color="primary">done</mat-icon>
                </button>
              </div>
              <button
                *ngIf="
                  showIcon([
                    mailFolderType.Inbox,
                    mailFolderType.Sent,
                    mailFolderType.Validation,
                    mailFolderType.Spam,
                    mailFolderType.Trash,
                    mailFolderType.Archive
                  ])
                "
                mat-icon-button
                aria-label="{{ 'reply' | translate }}"
                matTooltip="{{ 'reply' | translate }}"
                (click)="reply(email)">
                <mat-icon>reply</mat-icon>
              </button>
              <button
                *ngIf="
                  showIcon([
                    mailFolderType.Inbox,
                    mailFolderType.Sent,
                    mailFolderType.Validation,
                    mailFolderType.Spam,
                    mailFolderType.Trash,
                    mailFolderType.Archive
                  ])
                "
                mat-icon-button
                aria-label="{{ 'replyAll' | translate }}"
                matTooltip="{{ 'replyAll' | translate }}"
                (click)="replyAll(email)">
                <mat-icon>reply_all_outline</mat-icon>
              </button>
            </div>
          </mat-card>
        </div>
      </div>
    </div>

    <table *ngIf="!isError && !showLoader && !useMobileView" mat-table [dataSource]="dataSource" class="page-block">
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
          <div class="text-center mt-3">
            {{ "noEmailsEmailsContentComponent" | translate }}
          </div>
        </td>
      </tr>
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef style="min-width: 64px">
          <mat-checkbox
            (change)="$event ? masterToggle() : null"
            color="primary"
            style="margin-left: 14px"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td style="min-width: 64px" mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            color="primary"
            style="margin-left: 14px"
            (change)="$event ? selection.toggle(row.emailId) : null"
            [checked]="selection.isSelected(row.emailId)">
          </mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="attachment">
        <th [hidden]="!hasAttachments" mat-header-cell *matHeaderCellDef></th>
        <td [hidden]="!hasAttachments" mat-cell *matCellDef="let emails" class="text-center">
          <button
            *ngIf="emails.hasAttachments"
            mat-icon-button
            aria-label="{{ 'attachment' | translate }}"
            matTooltip="{{ 'attachment' | translate }}">
            <mat-icon>attachment_outline</mat-icon>
          </button>
        </td>
      </ng-container>
      <ng-container matColumnDef="notes">
        <th mat-header-cell *matHeaderCellDef></th>
        <td *matCellDef="let emails" mat-cell>
          <button mat-icon-button aria-label="notes" matTooltip="{{ 'notes' | translate }}" (click)="openNotes(emails.emailId)">
            <mat-icon *ngIf="emails.notesCount > 0">sticky_note_2 </mat-icon>
            <mat-icon *ngIf="emails.notesCount === 0">note_outline </mat-icon>
          </button>
        </td>
      </ng-container>
      <ng-container matColumnDef="to">
        <th [hidden]="isColumnShow(true)" [class.first-row]="isColumnShow(false, true)" mat-header-cell *matHeaderCellDef>
          {{ "tableRowHeaderTo" | translate }}
        </th>
        <td
          [hidden]="isColumnShow(true)"
          [class.first-row-content]="isColumnShow(false, true)"
          mat-cell
          *matCellDef="let emails"
          class="cursor-pointer"
          matTooltip="{{ formatTo(emails.to) }}"
          (mousedown)="onStartRecording($event)"
          (mouseup)="onFinishRecording($event, emails)">
          <div class="to-limitation">
            {{ formatTo(emails.to) }}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="from">
        <th [hidden]="isColumnShow(false)" [class.first-row]="isColumnShow(true, true)" mat-header-cell *matHeaderCellDef>
          {{ "from" | translate }}
        </th>
        <td
          [hidden]="isColumnShow(false)"
          [class.first-row-content]="isColumnShow(true, true)"
          mat-cell
          *matCellDef="let emails"
          class="cursor-pointer"
          (mousedown)="onStartRecording($event)"
          (mouseup)="onFinishRecording($event, emails)">
          {{ formatTo(emails.from) }}
        </td>
      </ng-container>
      <ng-container matColumnDef="subject">
        <th style="width: 100%; min-width: 200px" mat-header-cell *matHeaderCellDef>{{ "subject" | translate }}</th>
        <td
          style="width: 100%; min-width: 200px"
          mat-cell
          *matCellDef="let emails"
          class="cursor-pointer"
          (mousedown)="onStartRecording($event)"
          (mouseup)="onFinishRecording($event, emails)">
          {{ emails.subject }}
          <mat-chip-listbox *ngIf="emails.tags?.length" aria-label="Fish selection">
            <mat-chip-option [disableRipple]="true" *ngFor="let tag of emails.tags">{{ tag.tag }}</mat-chip-option>
          </mat-chip-listbox>
          <mat-chip-listbox *ngIf="emails.groups?.length" aria-label="Fish selection">
            <mat-chip-option [disableRipple]="true" style="background: #e9e43b" *ngFor="let group of emails.groups">{{ group.group }}</mat-chip-option>
          </mat-chip-listbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="source">
        <th
          [hidden]="currentFolderType !== 9 && currentFolderType !== 8"
          [class.source-table]="currentFolderType === 9 || currentFolderType === 8"
          mat-header-cell
          *matHeaderCellDef>
          {{ "sourceTableRow" | translate }}
        </th>
        <td
          [hidden]="currentFolderType !== 9 && currentFolderType !== 8"
          [class.source-table]="currentFolderType === 9 || currentFolderType === 8"
          mat-cell
          *matCellDef="let emails"
          class="cursor-pointer"
          (click)="emailDetails(emails)">
          {{ sourceRaw(emails) | translate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="meta">
        <th [hidden]="currentFolderType !== 5" [class.source-table]="currentFolderType === 5" class="w-fit" mat-header-cell *matHeaderCellDef>
          {{ "meta" | translate }}
        </th>
        <td
          [hidden]="currentFolderType !== 5"
          [class.source-table]="currentFolderType === 5"
          mat-cell
          *matCellDef="let emails"
          class="cursor-pointer">
          <div class="flex">
            <button
              *ngIf="showIconById(emails.mailFolderId, emailIcons.RetrySending) && emails.state === 5"
              mat-icon-button
              aria-label="{{ 'retrySending' | translate }}"
              matTooltip="{{ 'retrySending' | translate }}"
              (click)="retrySend(emails.emailId)">
              <mat-icon>redo</mat-icon>
            </button>
            <button
              *ngIf="showIconById(emails.mailFolderId, emailIcons.RetrySending) && isRowWithError(emails)"
              mat-icon-button
              aria-label="{{ 'moveToDraft' | translate }}"
              matTooltip="{{ 'moveToDraft' | translate }}"
              (click)="moveToDraft(emails.emailId)">
              <mat-icon>folder_open</mat-icon>
            </button>
            <button
              mat-icon-button
              aria-label="{{ 'meta' | translate }}"
              matTooltip="{{ 'meta' | translate }}"
              (click)="openMeta(emails.emailId)">
              <mat-icon>info_outline </mat-icon>
            </button>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="folder">
        <th [hidden]="!isShowFolderColumn" [class.source-table]="isShowFolderColumn" mat-header-cell *matHeaderCellDef>
          {{ "folder" | translate }}
        </th>
        <td
          [hidden]="!isShowFolderColumn"
          [class.source-table]="isShowFolderColumn"
          mat-cell
          *matCellDef="let emails"
          class="cursor-pointer"
          (click)="emailDetails(emails)">
          {{ folderName(emails.mailFolderId) | translate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="date">
        <th style="min-width: 170px" mat-header-cell *matHeaderCellDef>{{ "date" | translate }}</th>
        <td style="min-width: 170px" mat-cell *matCellDef="let emails" class="cursor-pointer">
          {{ getDate(emails) | date : "dd/MM/yyyy HH:mm:ss" }}
          <div
            [style.right]="
              currentFolderType !== mailFolderType.Sent &&
              currentFolderType !== mailFolderType.Outbox &&
              currentFolderType !== mailFolderType.Archive &&
              currentFolderType !== mailFolderType.Trash &&
              !isShowFolderColumn
                ? '63px'
                : '160px'
            "
            class="right-hover-menu">
            <app-loading-icon-button
              label="validate"
              icon="check_circle_outline"
              [showButton]="
                showIconById(emails.mailFolderId, emailIcons.Validate) &&
                (emails.validationState === emailValidationState.NotValidated || emails.validationState === emailValidationState.New) &&
                !isEmailValidating(emails.emailId)
              "
              [disabled]="!!validatingEmailIds.length"
              [showLoading]="isEmailValidating(emails.emailId)"
              (onClick)="setValidated(emailValidationState.Validated, emails.emailId)">
            </app-loading-icon-button>
            <app-loading-icon-button
              label="deleteFinalMail"
              icon="delete_forever"
              [showButton]="(currentFolderType === mailFolderType.Trash ||
                currentFolderType === mailFolderType.Spam) && canFinalDelete"
              mat-icon-button
              [showLoading]="replyingEmailId === emails.emailId"
              (onClick)="finalDelete(emails.emailId)">
            </app-loading-icon-button>
            <app-loading-icon-button
              label="reply"
              icon="reply"
              [showButton]="replyingEmailId !== emails.emailId && showIconById(emails.mailFolderId, emailIcons.Reply)"
              [disabled]="replyingAllEmailId || forwardingEmailId || replyingEmailId"
              [showLoading]="replyingEmailId === emails.emailId"
              (onClick)="reply(emails)">
            </app-loading-icon-button>
            <app-loading-icon-button
              label="replyAll"
              icon="reply_all_outline"
              [showButton]="replyingAllEmailId !== emails.emailId && showIconById(emails.mailFolderId, emailIcons.ReplyAll)"
              [disabled]="replyingAllEmailId || forwardingEmailId || replyingEmailId"
              [showLoading]="replyingAllEmailId === emails.emailId"
              (onClick)="replyAll(emails)">
            </app-loading-icon-button>
            <app-loading-icon-button
              label="forward"
              icon="shortcut"
              [showButton]="forwardingEmailId !== emails.emailId && showIconById(emails.mailFolderId, emailIcons.Forward)"
              [disabled]="replyingAllEmailId || forwardingEmailId || replyingEmailId"
              [showLoading]="forwardingEmailId === emails.emailId"
              (onClick)="forward(emails.emailId)">
            </app-loading-icon-button>
            <button
              *ngIf="showIconById(emails.mailFolderId, emailIcons.Reassign) && !reassigned"
              mat-icon-button
              aria-label="{{ 'reassign' | translate }}"
              (click)="reassign(emails.emailId)"
              matTooltip="{{ 'reassign' | translate }}">
              <mat-icon>forward_to_inbox</mat-icon>
            </button>
            <button
              *ngIf="showIconById(emails.mailFolderId, emailIcons.Groups)"
              mat-icon-button
              aria-label="{{ 'groups' | translate }}"
              matTooltip="{{ 'groups' | translate }}"
              (click)="openAddGroupDialog(emails)">
              <mat-icon>groups</mat-icon>
            </button>
            <button
              *ngIf="showIconById(emails.mailFolderId, emailIcons.Tags)"
              mat-icon-button
              aria-label="{{ 'tags' | translate }}"
              matTooltip="{{ 'tags' | translate }}"
              (click)="openAddTagDialog(emails)">
              <mat-icon>local_offer</mat-icon>
            </button>
            <button
              *ngIf="showIconById(emails.mailFolderId, emailIcons.Seen) && !emails.seen"
              mat-icon-button
              matTooltip="{{ 'setRead' | translate }}"
              (click)="setSeen(true, emails)">
              <mat-icon>visibility_outline</mat-icon>
            </button>
            <button
              *ngIf="showIconById(emails.mailFolderId, emailIcons.Seen) && emails.seen"
              mat-icon-button
              matTooltip="{{ 'setUnread' | translate }}"
              (click)="setSeen(false, emails)">
              <mat-icon>visibility_off_outline</mat-icon>
            </button>
            <button
              *ngIf="showIconById(emails.mailFolderId, emailIcons.Spam) && !emails.isSpam"
              mat-icon-button
              matTooltip="{{ 'markAsSpam' | translate }}"
              (click)="setSpam(emails.emailId)">
              <mat-icon>report_outline</mat-icon>
            </button>
            <button
              *ngIf="
                (showIconById(emails.mailFolderId, emailIcons.Delete)) && !emails.deleted
              "
              mat-icon-button
              aria-label="{{ 'delete' | translate }}"
              matTooltip="{{ 'delete' | translate }}"
              (click)="delete(emails.emailId)">
              <mat-icon>delete</mat-icon>
            </button>
            <app-loading-icon-button
              label="downloadSingleEml"
              icon="download"
              [showButton]="showIconById(emails.mailFolderId, emailIcons.DownloadEML) && downloadingSingleEMLEmailId !== emails.emailId"
              [showLoading]="downloadingSingleEMLEmailId === emails.emailId"
              (onClick)="downloadSingleEML(emails.emailId)">
            </app-loading-icon-button>
            <app-loading-icon-button
              label="downloadEml"
              icon="download_for_offline"
              [showButton]="showIconById(emails.mailFolderId, emailIcons.DownloadEML) && downloadingEMLEmailId !== emails.emailId"
              [showLoading]="downloadingEMLEmailId === emails.emailId"
              (onClick)="downloadEML(emails.emailId)">
            </app-loading-icon-button>
            <button
              *ngIf="showIconById(emails.mailFolderId, emailIcons.Restore) && emails.deleted"
              mat-icon-button
              aria-label="{{ 'restore' | translate }}"
              matTooltip="{{ 'restore' | translate }}"
              (click)="restoreFromTrash(emails.emailId)">
              <mat-icon>restore_outline</mat-icon>
            </button>
            <button
              *ngIf="showIconById(emails.mailFolderId, emailIcons.NotSpam) && emails.isSpam"
              mat-icon-button
              aria-label="{{ 'markAsNotSpam' | translate }}"
              (click)="restoreMoveFolderChoose([emails.emailId], 'restoreOneSpam')"
              matTooltip="{{ 'markAsNotSpam' | translate }}">
              <mat-icon>check</mat-icon>
            </button>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="state">
        <th
          [hidden]="
            currentFolderType !== mailFolderType.Sent &&
            currentFolderType !== mailFolderType.Archive &&
            currentFolderType !== mailFolderType.Outbox &&
            currentFolderType !== mailFolderType.Trash
          "
          [style]="currentFolderType === mailFolderType.Outbox ? 'min-width: 200px' : 'min-width: 100px'"
          mat-header-cell
          *matHeaderCellDef>
          {{ "state" | translate }}
        </th>
        <td
          [hidden]="
            currentFolderType !== mailFolderType.Sent &&
            currentFolderType !== mailFolderType.Archive &&
            currentFolderType !== mailFolderType.Outbox &&
            currentFolderType !== mailFolderType.Trash
          "
          [style]="currentFolderType === mailFolderType.Outbox ? 'min-width: 200px' : 'min-width: 100px'"
          mat-cell
          *matCellDef="let emails"
          class="cursor-pointer">
          <ng-container *ngIf="currentFolderType !== mailFolderType.Outbox">
            <button
              *ngIf="emails.state === emailStates.Sent && isSentStates(emails.state)"
              (click)="openNotifications(emails.emailId)"
              mat-icon-button
              matTooltip="{{ 'sent' | translate }}"
              aria-label="{{ 'sent' | translate }}">
              <mat-icon>done</mat-icon>
            </button>
            <button
              *ngIf="emails.state === emailStates.Delivered && isSentStates(emails.state)"
              (click)="openNotifications(emails.emailId)"
              mat-icon-button
              matTooltip="{{ 'delivered' | translate }}"
              aria-label="{{ 'delivered' | translate }}">
              <mat-icon color="primary">done_all</mat-icon>
            </button>
            <button
              *ngIf="emails.state === emailStates.DeliveryError && isSentStates(emails.state)"
              (click)="openNotifications(emails.emailId)"
              mat-icon-button
              matTooltip="{{ 'deliveryError' | translate }}"
              aria-label="{{ 'deliveryError' | translate }}">
              <mat-icon style="color: red">clear icon</mat-icon>
            </button>
            <button
              *ngIf="emails.state === emailStates.NotAccepted && isSentStates(emails.state)"
              (click)="openNotifications(emails.emailId)"
              mat-icon-button
              matTooltip="{{ 'notAccepted' | translate }}"
              aria-label="{{ 'notAccepted' | translate }}">
              <mat-icon style="color: red">clear icon</mat-icon>
            </button>
            <button
              *ngIf="emails.state === emailStates.Accepted && isSentStates(emails.state)"
              (click)="openNotifications(emails.emailId)"
              mat-icon-button
              color="primary"
              matTooltip="{{ 'accepted' | translate }}"
              aria-label="{{ 'accepted' | translate }}">
              <mat-icon color="primary">done</mat-icon>
            </button>
          </ng-container>
          <ng-container *ngIf="currentFolderType === mailFolderType.Outbox">
            {{ "emailState" + emailStates[emails.state] | translate }}
          </ng-container>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        [class.!font-bold]="!row.seen"
        [class.row-select]="isRowSelected(row.emailId)"
        [class.row-automatic-reassigned]="row?.sourceEmailId && !isRowWithError(row) ? true : false"
        [class.row-error]="isRowWithError(row)"
        (contextmenu)="onRightClick($event, row)"></tr>
    </table>
  </div>
</div>
