import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-error-hint',
  templateUrl: './error-hint.component.html',
  styleUrls: ['./error-hint.component.scss'],
})
export class ErrorHintComponent implements OnInit {
  @Input() errorText = '';
  @Input() centerAlign = true;

  constructor() {}

  ngOnInit(): void {}
}
