export enum SpaceUsedPaymentPlanType {
  AvailableSpace25Gb = 1,
  AvailableSpace50Gb = 2,
  AvailableSpace100Gb = 3,
  AvailableSpace250Gb = 4,
  AvailableSpace500Gb = 5,
  AvailableSpace1Tb = 6,
}

export interface SpaceUsedPaymentPlan {
  value: number;
  viewValue: string;
}

export const SpaceUsedPaymentPlanSelectTypes: SpaceUsedPaymentPlan[] = [
  {
    value: 1,
    viewValue: '25 GB',
  },
  {
    value: 2,
    viewValue: '50 GB',
  },
  {
    value: 3,
    viewValue: '100 GB',
  },
  {
    value: 4,
    viewValue: '250 GB',
  },
  {
    value: 5,
    viewValue: '500 GB',
  },
  {
    value: 6,
    viewValue: '1 TB',
  },
];

export const SpaceUsedPaymentPlanSelectTypesWithNull: SpaceUsedPaymentPlan[] = [
  {
    value: null,
    viewValue: '',
  },
  {
    value: 1,
    viewValue: '25 GB',
  },
  {
    value: 2,
    viewValue: '50 GB',
  },
  {
    value: 3,
    viewValue: '100 GB',
  },
  {
    value: 4,
    viewValue: '250 GB',
  },
  {
    value: 5,
    viewValue: '500 GB',
  },
  {
    value: 6,
    viewValue: '1 TB',
  },
];

export const SpaceUsedPaymentPlanDetailTypes = {
  1: {
    viewValue: 'included 25 GB',
    titleValue: '25 GB',
    value: 25,
  },
  2: {
    viewValue: 'included 50 GB',
    titleValue: '50 GB',
    value: 50,
  },
  3: {
    viewValue: 'included 100 GB',
    titleValue: '100 GB',
    value: 100,
  },
  4: {
    viewValue: 'included 150 GB',
    titleValue: '250 GB',
    value: 250,
  },
  5: {
    viewValue: 'included 250 GB',
    titleValue: '500 GB',
    value: 500,
  },
  6: {
    viewValue: 'included 1 TB',
    titleValue: '1 TB',
    value: 1000,
  },
};
