<div>
  @if(loading) {
    <div class="top-0 left-0 w-full h-full flex justify-center flex-wrap content-center write-loader absolute">
      <mat-spinner diameter="20"></mat-spinner>
    </div>
  }

  <div class="inline" [ngStyle]="{ color: loading ? 'transparent' : '' }">
    {{ text }}
  </div>
</div>
