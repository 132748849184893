import { Component, OnInit, ViewChild } from '@angular/core';
import { SignalRService } from './services/signalR/signalR.service';
import { AuthenticationService } from './services/auth/authentication.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { EventsService } from './services/events/events.service';
import { SnackbarHelper } from './shared/helpers/snackbar.helper';
import { UrlService } from './services/url.service';
import { filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ApiRequest } from './services/api/api.request';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './services/local-storage.service';
import { STORAGE_NAMES } from './shared/constants/constants';
// import { DrawerComponent } from './components/drawer/drawer.component';
// import { DrawerService } from './services/drawer.service';

const { PRE_RELEASE, USER_LOCALE } = STORAGE_NAMES;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  // @ViewChild(DrawerComponent)
  // set drawer(drawer: DrawerComponent) {
  //   this.drawerService.setDrawerComponent(drawer);
  // }

  previousUrl: string = null;
  currentUrl: string = null;

  constructor(
    private router: Router,
    private activateRoute: ActivatedRoute,
    private eventsService: EventsService,
    private signalrService: SignalRService,
    private authenticationService: AuthenticationService,
    private snackBarHelper: SnackbarHelper,
    private apiRequest: ApiRequest,
    private urlService: UrlService,
    public translate: TranslateService,
    private localStorageService: LocalStorageService,
  ) {
    translate.addLangs(['it', 'en']);
  }

  async ngOnInit(): Promise<any> {
    if (!this.localStorageService.getData(USER_LOCALE)) {
      this.localStorageService.setData(USER_LOCALE, environment.language);
    }
    if (!this.localStorageService.getData(PRE_RELEASE)) {
      this.localStorageService.setData(PRE_RELEASE, false);
    }
    this.translate.use(this.localStorageService.getData(USER_LOCALE));
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
        this.urlService.setPreviousUrl(this.previousUrl);
      });
  }
}
