import { Pipe, PipeTransform } from '@angular/core';
import { ErrorCode } from '../../sdk/api-errors/error-code';

@Pipe({
  name: 'formatMessageImportEml',
})
export class FormatMessageImportEml implements PipeTransform {
  transform(state: ErrorCode): string {
    switch (state) {
      case ErrorCode.ValidationError:
        return 'importEmlValidationError';
      case ErrorCode.ImportEmlTempPathNotConfigured:
        return 'importEmlTempPathNotConfigured';
      case ErrorCode.UnknownFileType:
        return 'importEmlUnknownFileType';
      case ErrorCode.EntityDoesNotExists:
        return 'importEntityDoesNotExists';
      case ErrorCode.ImailBuildError:
        return 'importEmlImailBuildError';
      case ErrorCode.EmailExistsByHash:
        return 'importEmlEmailExistsByHash';
      case ErrorCode.InvalidFolder:
        return 'importEmlInvalidFolder';
      case ErrorCode.MailAccountDontExist:
        return 'importEmlMailAccountDontExist';
      case ErrorCode.ExtractFileError:
        return 'importEmlExtractFileError';
      case ErrorCode.UnhandledError:
        return 'importEmlUnhandledError';
      default:
        return '';
    }
  }
}
