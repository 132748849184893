import { SearchParams } from '../../../services/search/search-params';

export class EmailSearchRequest {
  page: number;
  pageSize: number;
  filter: SearchParams;
  async: boolean;
  mailFolderIds: number[];

  constructor(page: number, pageSize: number, filter: any, mailFolderIds: number[]) {
    this.page = page;
    this.pageSize = pageSize;
    this.filter = filter;
    this.mailFolderIds = mailFolderIds;
  }
}
