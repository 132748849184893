export const LOCALE_IDS = [
  {
    language: 'English',
    id: 'en',
  },
  {
    language: 'Deutsch',
    id: 'de',
  },
  {
    language: 'Italiano',
    id: 'it',
  },
  {
    language: 'Français',
    id: 'fr',
  },
];

export const MENU_STATE = [
  {
    title: 'active',
    field: 'isActive',
    value: true,
    fieldRemove: 'isConfigurationCompleted'
  },
  {
    title: 'notActive',
    field: 'isActive',
    value: false,
    fieldRemove: 'isConfigurationCompleted'
  },
  {
    title: 'notConfigured',
    field: 'isConfigurationCompleted',
    value: false,
    fieldRemove: 'isActive'
  }
];

export const WEB_CODE = 'webCode';

export const STORAGE_NAMES = {
  CLIENT_ID: 'clientId',
  PRE_RELEASE: 'preRelease',
  USER_LOCALE: 'userLocale',
  ORGANISATIONS: 'organizations',
  AUTH_TYPE: 'authType'
};

export const USER_ROLE = {
  RTA: 'RTA',
  ROOT: 'Root',
  USER: 'User'
};

export const EMAIL_QUERY_PARAM = 'mail';
