import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { ApiRequest } from '../../services/api/api.request';
import { EmailSearchRequest } from '../contracts/Email/email.search.request';
import { ApiRoutes } from '../routes/api.routes';
import { BaseGetByIdResponse } from '../contracts/common/base-get-by-id.response';
import { FileRefContract } from '../contracts/file/file-ref.contract';
const API_URL = environment.apiUrl;

@Injectable()
export class EmailExportClient {
  constructor(private apiRequest: ApiRequest) {}

  async exportExcel(request: EmailSearchRequest): Promise<BaseGetByIdResponse<FileRefContract>> {
    return this.apiRequest.post<EmailSearchRequest, BaseGetByIdResponse<FileRefContract>>(
      request,
      `${API_URL}${ApiRoutes.emailExport.exportExcel}`
    );
  }
}
