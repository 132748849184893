import { Component, Inject, OnInit } from '@angular/core';
import { ErrorCode } from '../../../../../sdk/api-errors/error-code';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MailAccountClient } from '../../../../../sdk/clients/mail-account.client';
import { MatchError } from 'src/app/services/errors/error-matcher';

@Component({
  selector: 'app-mail-account-delete-dialog',
  templateUrl: './mail-account-delete-dialog.component.html',
  styleUrls: ['./mail-account-delete-dialog.component.scss'],
})
export class MailAccountDeleteDialogComponent implements OnInit {
  public isLoading = false;
  public hasError = false;
  public errorCode: ErrorCode | null = null;
  public errorText: string;

  public handlingErrorCodes = new Map<number, string>([
    [ErrorCode.EmailAlreadyDeleted, 'Cannot delete mail account: mail account already deleted'],
  ]);

  constructor(
    public dialogRef: MatDialogRef<MailAccountDeleteDialogComponent>,
    public matchError: MatchError,
    @Inject(MAT_DIALOG_DATA) public data: { mailAccountId: number; mailAccountName: string },
    public mailAccountClient: MailAccountClient,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {}

  onCancelBtnClicked(): void {
    this.dialogRef.close({ isDeleted: false });
  }

  async onDeleteBtnClicked(): Promise<void> {
    this.isLoading = true;
    this.hasError = false;
    this.errorCode = null;
    try {
      await this.mailAccountClient.delete({ id: this.data.mailAccountId });
      this.dialogRef.close({ isDeleted: true });
    } catch (e) {
      this.hasError = true;
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.isLoading = false;
    }
  }
}
