import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MailFolderContract } from '../../../sdk/contracts/mail-folder/mail-folder.contract';
import { MailFolderService } from '../../../services/mail-folder/mail.folder.service';
import { EmailMoveRequest } from '../../../sdk/contracts/Email/email.move.request';
import { EmailClient } from '../../../sdk/clients/email-client';
import { MailFolderType } from '../../../sdk/contracts/mail-folder/mail-folder.type';
import { MatchError } from 'src/app/services/errors/error-matcher';

@Component({
  selector: 'app-mail-folder-select-dialog',
  templateUrl: './mail-folder-select-dialog.component.html',
  styleUrls: ['./mail-folder-select-dialog.component.scss'],
})
export class MailFolderSelectDialogComponent implements OnInit {
  public isLoading = true;
  public cannotLoad = false;
  public moveInProgress = false;
  public cannotSave = false;
  public folders: MailFolderContract[] = [];
  public selectedFolderId: number;
  constructor(
    public matchError: MatchError,
    private mailFolderService: MailFolderService,
    private emailClient: EmailClient,
    public dialogRef: MatDialogRef<MailFolderSelectDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      mailAccountId: number;
      ids: number[];
      isRestore: boolean;
      title: string;
      folderType: number;
      isArchive: boolean;
      sourceFoldersType: number;
      isTrash: boolean;
    }
  ) {}

  async ngOnInit(): Promise<any> {
    await this.loadFolders();
  }

  async loadFolders(): Promise<void> {
    this.isLoading = true;
    this.cannotLoad = false;
    try {
      const response = await this.mailFolderService.getFoldersForAccountFlat(this.data.mailAccountId);

      if (this.data.isArchive === true) {
        this.folders = response.filter((e) => e.folderType === this.data.sourceFoldersType);
      } else if (this.data.isTrash === true) {
        this.folders = response.filter((e) => e.folderType === this.data.sourceFoldersType);
      } else if (this.data.folderType === MailFolderType.Spam) {
        this.folders = response.filter((e) => e.folderType === MailFolderType.Inbox);
      } else {
        this.folders = response.filter((e) => e.folderType === this.data.folderType);
      }

      if (this.folders.length > 0) {
        this.selectedFolderId = this.folders[0]?.mailFolderId;
      }
    } catch (e) {
      this.cannotLoad = true;
      this.matchError.logError(e);
    } finally {
      this.isLoading = false;
    }
  }

  async confirm(): Promise<void> {
    if (!this.data.isRestore) {
      this.moveInProgress = true;
      this.cannotSave = false;
      try {
        await this.emailClient.move(new EmailMoveRequest(this.data.ids, this.selectedFolderId));
        this.dialogRef.close({ confirmed: true });
      } catch (e) {
        this.cannotSave = true;
        this.matchError.logError(e);
      } finally {
        this.moveInProgress = false;
      }
    }
    if (this.data.isRestore) {
      this.dialogRef.close({ confirmed: true, folderId: this.selectedFolderId });
    }
  }

  cancel(): void {
    this.dialogRef.close({ confirmed: false });
  }
}
