import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { OrganizationContract } from '../../../sdk/contracts/organization/organization.contract';
import { Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizationClient } from '../../../sdk/clients/organization.client';
import { MatPaginator } from '@angular/material/paginator';
import { BaseCollectionSearchRequest } from '../../../sdk/contracts/common/base-collection-search.request';
import { BaseGetSearchCountRequest } from '../../../sdk/contracts/common/base-get-search-count.request';
import { BreadcrumbItem } from '../../../shared/breadcrumb-item';
import { TabItem } from '../../../shared/tab-item';
import { PermissionNavTabHelper } from '../../../services/permission/permission-nav-tab-helper';
import { MatDialog } from '@angular/material/dialog';
import { OrganizationDeleteDialogComponent } from './organization-delete-dialog/organization-delete-dialog.component';
import { OrganizationType } from '../../../sdk/contracts/organization/organization-type';
import { MobileObserverService } from '../../../services/adaptive/mobile-observer.service';
import { MatchError } from 'src/app/services/errors/error-matcher';

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss'],
})
export class OrganizationComponent implements OnInit, AfterViewInit {
  public organizations: OrganizationContract[] = [];
  public dataSource = new MatTableDataSource<OrganizationContract>(this.organizations);
  public displayedColumns: string[] = ['name', 'web-code', 'license-id', 'code-sia', 'anagId', 'actions'];
  public organizationCreatedEventSubscription: Subscription;
  public organizationUpdatedEventSubscription: Subscription;
  public isLoading = false;
  public isError = false;
  public pageSize = 25;
  public pageIndex = 0;
  public totalSize = 0;
  public searchValue = '';
  public createDrawerIsOpen = false;
  public breadcrumbItems: BreadcrumbItem[];
  public tabItems: TabItem[] = [];
  public organizationTypes = OrganizationType;
  public useMobileView = false;
  public webCode: string;
  public organizationId: number;

  public changedOrganizationId: number | null = null;
  public createMailAccountDrawerIsOpen = false;
  public createUserOrganizationDrawerIsOpen = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  public updateDrawerIsOpen = false;
  public organization: OrganizationContract;

  constructor(
    public matchError: MatchError,
    private organizationClient: OrganizationClient,
    protected router: Router,
    private permissionNavTabHelper: PermissionNavTabHelper,
    public dialog: MatDialog,
    private mobileObserverService: MobileObserverService,
    private route: ActivatedRoute
  ) {}

  async ngOnInit(): Promise<void> {
    this.pageSize = JSON.parse(localStorage.getItem('pageSizeSettings')) || 25;
    this.webCode = this.route.parent.snapshot.paramMap.get('webCode');
    this.loadNavigation();
    this.organizationId = parseInt(this.route.snapshot.paramMap.get('organizationId'), 10);
    this.tabItems = this.permissionNavTabHelper.getSettingPageTabs(this.webCode);
    await this.fetchOrganizations(true);
  }

  public loadNavigation(): void {
    this.breadcrumbItems = [
      { index: 0, label: 'settingsBreadCrumbs', route: `/${this.webCode}/settings/organization/${this.organizationId}/general` },
      { index: 1, label: 'companiesBreadCrumbs', route: null },
    ];
  }

  ngAfterViewInit(): void {}

  public async fetchOrganizations(fetchSearchCounter): Promise<void> {
    this.isLoading = true;
    this.isError = false;
    const nullableSearchValue = this.searchValue.length > 0 ? this.searchValue : null;
    const request = new BaseCollectionSearchRequest(this.pageIndex + 1, this.pageSize, nullableSearchValue);

    try {
      const response = await this.organizationClient.search(request);
      this.organizations = response.data;
      this.dataSource.data = this.organizations;
      if (fetchSearchCounter) {
        const counterRequest = new BaseGetSearchCountRequest(nullableSearchValue);
        const counterResponse = await this.organizationClient.getSearchCount(counterRequest);
        this.totalSize = counterResponse.result;
      }
      this.dataSource.paginator = this.paginator;
    } catch (e) {
      this.isError = true;
      this.matchError.logError(e);
    } finally {
      this.isLoading = false;
    }
  }

  public async handlePage(e: any): Promise<void> {
    localStorage.setItem('pageSizeSettings', JSON.stringify(e.pageSize));
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    await this.fetchOrganizations(false);
  }

  public onSearchValueChanged(newSearchValue: string): void {
    this.searchValue = newSearchValue;
  }

  async onCreateOrganizationBtnClicked(): Promise<void> {
    this.createDrawerIsOpen = true;
  }

  onCloseCreateModalForm(): void {
    this.createDrawerIsOpen = false;
  }

  async onCreateOrganization(): Promise<void> {
    this.createDrawerIsOpen = false;
    this.totalSize += 1;
    await this.fetchOrganizations(false);
  }

  public getDetailsLink(organizationId: number): string {
    return `/${this.webCode}/settings/organization/${organizationId}/general`;
  }

  public async redirectToDetails(organizationId: number): Promise<void> {
    await this.router.navigateByUrl(this.getDetailsLink(organizationId));
  }

  public async deleteOrganization(organizationId: number, organizationName: string): Promise<void> {
    const dialogRef = this.dialog.open(OrganizationDeleteDialogComponent, {
      width: '450px',
      data: { organizationId, organizationName },
    });

    dialogRef.afterClosed().subscribe(async (x) => {
      if (x.isDeleted) {
        this.totalSize -= 1;
        await this.fetchOrganizations(false);
      }
    });
  }

  public hasParentOrganization(organization: OrganizationContract): boolean {
    return !!organization.parentOrganizationId;
  }

  public openCreateMailAccountDrawer(organizationId: number): void {
    this.changedOrganizationId = organizationId;
    this.createMailAccountDrawerIsOpen = true;
  }

  public openCreateUserOrganizationDrawer(organizationId: number): void {
    this.changedOrganizationId = organizationId;
    this.createUserOrganizationDrawerIsOpen = true;
  }

  public onCloseCreateMailAccountModalForm(): void {
    this.changedOrganizationId = null;
    this.createMailAccountDrawerIsOpen = false;
  }

  public onCloseCreateUserOrganizationModalForm(): void {
    this.changedOrganizationId = null;
    this.createUserOrganizationDrawerIsOpen = false;
  }

  public onCreateMailAccount(): void {
    // todo increment mail account counter
    this.changedOrganizationId = null;
    this.createMailAccountDrawerIsOpen = false;
  }

  public onCreateOrganizationUser(): void {
    // todo increment user counter
    this.changedOrganizationId = null;
    this.createUserOrganizationDrawerIsOpen = false;
  }

  openUpdateOrganizationDialog(organization: OrganizationContract): void {
    this.organization = organization;
    this.updateDrawerIsOpen = true;
  }

  onCloseUpdatingModalForm(): void {
    this.updateDrawerIsOpen = false;
  }

  async onUpdateOrganization(organizationContract: OrganizationContract): Promise<void> {
    this.updateDrawerIsOpen = false;
    this.organization.name = organizationContract.name;
    this.organization.code = organizationContract.code;
    this.organization.type = organizationContract.type;
    this.organization.codeSia = organizationContract.codeSia;
    this.organization.licenseId = organizationContract.licenseId;
    this.organization.webCode = organizationContract.webCode;
    this.organization.anagId = organizationContract.anagId;
    this.organization.pignorState = organizationContract.pignorState;
    this.organization.spaceUsedPaymentPlanType = organizationContract.spaceUsedPaymentPlanType;
  }
}
