import { MailAccountConnectionStatusType } from '../mail-account/mail-account-connection-status-type';

export interface FilterFields {
  search: null | string;
  ProviderId: null | number;
  isActive: null | boolean;
  isArchive: null | boolean;
  searchOrder: number;
  orderDirection: number;
  isConfigurationCompleted?: null | boolean;
  connectionStatus?: MailAccountConnectionStatusType;
}

export const defaultFilterFields: FilterFields = {
  search: null,
  ProviderId: null,
  isActive: null,
  isArchive: null,
  searchOrder: 1,
  orderDirection: 1,
};
