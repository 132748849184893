import { Component, OnInit, Input, ViewChild, Output, EventEmitter, TemplateRef, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { TreeItem } from '../../sdk/contracts/tree.item';
import { MailFolderContract } from '../../sdk/contracts/mail-folder/mail-folder.contract';
import { MailFolderClient } from '../../sdk/clients/mail-folder.client';
import { NestedTreeControl } from '@angular/cdk/tree';
import { FoldersTypeCheck } from '../../services/folders-utility/folders-type-check.service';
import { MailFolderType } from '../../sdk/contracts/mail-folder/mail-folder.type';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MailFolderContractWithFullNameForResponse } from '../../sdk/contracts/mail-folder/mail-folder-contract-with-full-name.response';
import { MatDialog } from '@angular/material/dialog';
import { SearchContextStorageService } from '../../services/search/search-context-storage.service';
import { SearchContext } from '../../services/search/search-context';
import { MatchError } from 'src/app/services/errors/error-matcher';
import { MatTreeModule } from '@angular/material/tree';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderComponent } from '../common/loader/loader.component';
import { MatIconModule } from '@angular/material/icon';
import { EMAIL_QUERY_PARAM, WEB_CODE } from '../../shared/constants/constants';
import { MailFolderGetUnreadCountRequest } from '../../sdk/contracts/mail-folder/mail-folder.get-folder-unreadcount.request';
import { encodeInfoMailAccount } from '../../shared/utils/search-params';
import { DrawerComponent } from '../common/drawer/drawer.component';
import { DrawerService } from '../../services/drawer.service';
import { MailFolderCreateDialogComponent } from './mail-folder-create-dialog/mail-folder-create-dialog.component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-mail-account-folders',
  standalone: true,
  imports: [
    MatTreeModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    TranslateModule,
    LoaderComponent,
    DrawerComponent,
    MailFolderCreateDialogComponent
  ],
  templateUrl: './mail-account-folders.component.html',
  styleUrls: ['./mail-account-folders.component.scss'],
})
export class MailAccountFoldersComponent implements OnInit, OnChanges {
  @Input() mailAccountId: number;
  @Input() isOpen: boolean;
  @Input() tree: TreeItem<number, MailFolderContract>;
  @Input() folderCounts = [];
  @Input() infoOpenedMailAccount;
  @Output() refreshUnread = new EventEmitter();
  @Output() getBadgesForMailAccounts = new EventEmitter();
  @Output() updateDataSideNav = new EventEmitter();

  @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger: MatMenuTrigger;
  @ViewChild('createFolderTemplate') createFolderTemplate!: TemplateRef<any>;
  @ViewChild('renameFolderTemplate') renameFolderTemplate!: TemplateRef<any>;

  public searchContext: SearchContext;
  public loadError = false;
  public webCode: string;
  public dataSource = new MatTableDataSource<TreeItem<number, MailFolderContract>>();

  public apiError: string;
  treeControl = new NestedTreeControl<TreeItem<number, MailFolderContract>>((node) => node.children);
  public selectedItem: TreeItem<number, MailFolderContract>;
  public selectedFolderId: number;

  public tempFolderCounts = {};
  public mailFolderTypes = MailFolderType;

  menuTopLeftPosition = { x: '0', y: '0' };

  public parentMailFolderIdForCreation: number | null;
  public editingFolder: MailFolderContractWithFullNameForResponse | null;
  public hideNotification = true;
  public currentMailAccountId: number;
  public parentMailFolderTypeForCreation: number;
  public openedFoldersIds = [];

  constructor(
    public matchError: MatchError,
    private activateRoute: ActivatedRoute,
    private router: Router,
    private mailFolderClient: MailFolderClient,
    public dialog: MatDialog,
    public searchContextStorageService: SearchContextStorageService,
    private readonly foldersTypeCheck: FoldersTypeCheck,
    private drawerService: DrawerService
  ) {}

  async ngOnInit(): Promise<any> {
    this.webCode = this.activateRoute.parent.snapshot.paramMap.get(WEB_CODE);
    this.tree && (this.dataSource.data = this.tree.children);
    this.isOpen && await this.refreshUnread.emit({
      mailAccountId: this.mailAccountId,
      updateBadges: false
    });

    if (this.infoOpenedMailAccount?.folder) {
      const openFolders = this.openFolder(this.tree.children, this.infoOpenedMailAccount?.folder);
      this.openedFoldersIds.push(...openFolders);
    }

    // this.eventsService.unreadCountEvent.subscribe(() => {
    //   const isBadgesUpdate = this.hideNotification !== this.eventsService.unreadCountValue;
    //   this.hideNotification = !this.eventsService.unreadCountValue;
    //   if (this.currentMailAccountId === this.mailAccountId) {
    //     if (this.eventsService.unreadCountValue) {
    //       this.refreshUnread(isBadgesUpdate);
    //     } else {
    //       this.refreshUnread(isBadgesUpdate);
    //     }
    //   }
    // });

    // this.eventsService.unreadCountFolderEvent.subscribe(() => {
    //   if (this.currentMailAccountId === this.mailAccountId) {
    //     if (this.eventsService.unreadCountValue) {
    //       this.refreshUnreadForFolder(this.eventsService.unreadCountFolderId);
    //     } else {
    //       this.refreshUnreadForFolder(this.eventsService.unreadCountFolderId);
    //     }
    //   }
    // });
  }

  openFolder(folders, openFolder: number): number[] {
    let result = [];

    folders.forEach(({ children, key, parentKey }) => {
      key === openFolder && parentKey && result.push(parentKey);

      if (children && children.length) {
        const childResult = this.openFolder(children, openFolder);

        if (childResult.length) {
          result = [...result, ...childResult];
          parentKey && result.push(parentKey);
        }
      }
    });

    return result;
  }

  public isOpenedFolder(folderId: number): boolean {
    return !this.openedFoldersIds.includes(folderId);
  }

  expandCollapseTree(item): void {
    const isOpened = this.openedFoldersIds.includes(item.data.mailFolderId);
    if (!isOpened) {
      this.openedFoldersIds.push(item.data.mailFolderId);
    } else {
      this.openedFoldersIds = this.openedFoldersIds.filter((e) => e !== item.data.mailFolderId);
    }
  }

  hasChild = (_: number, node: TreeItem<number, MailFolderContract>) => !!node.children && node.children.length > 0;

  async selectFolder(folderItem: any): Promise<void> {
    this.selectedItem = folderItem;
    this.selectedFolderId = folderItem.data.mailFolderId;
    // this.foldersTypeCheck.isInboxFolderOrSubfolder(this.dataSource, this.selectedItem);
    // this.searchContextStorageService.selectMailFolders([folderItem.data.mailFolderId], true, this.hideNotification);
    const infoMailAcc = encodeInfoMailAccount(this.mailAccountId, this.selectedFolderId);
    this.router.navigate([this.webCode,  'emails'], { queryParams: infoMailAcc ? {
      [EMAIL_QUERY_PARAM]: infoMailAcc
    } : {} });
    this.refreshUnreadForFolder(folderItem.data.mailFolderId, folderItem.data.folderType === MailFolderType.Inbox);
  }

  public getCountForFolder(folderId: number): number | null {
    return this.folderCounts.find((e) => e.mailFolderId === folderId)?.count || null;
  }

  private async refreshUnreadForFolder(folderId: number, updateBadges = true): Promise<any> {
    if (updateBadges) {
      this.getBadgesForMailAccounts.emit([this.mailAccountId]);
    }
    try {
      const counts = await this.mailFolderClient.getFolderUnreadCount(new MailFolderGetUnreadCountRequest(folderId, this.hideNotification));
      if (counts === null) {
        this.folderCounts = this.folderCounts.filter((e) => e.mailFolderId !== folderId);
      } else {
        this.folderCounts = this.folderCounts.filter((e) => e.mailFolderId !== counts.mailFolderId);
        this.folderCounts.push(counts);
      }
    } catch (e) {
      this.matchError.logError(e);
    }
  }

  onRightClick(event: MouseEvent, item): void {
    // preventDefault avoids to show the visualization of the right-click menu of the browser
    event.preventDefault();

    // we record the mouse position in our object
    this.menuTopLeftPosition.x = event.clientX + 'px';
    this.menuTopLeftPosition.y = event.clientY + 'px';

    // we open the menu
    // we pass to the menu the information about our object
    this.matMenuTrigger.menuData = { item };
    // we open the menu
    if (item.folderType !== MailFolderType.Inbox && item.folderType !== MailFolderType.Archive && item.folderType !== MailFolderType.Sent) {
      return null;
    } else {
      this.matMenuTrigger.openMenu();
    }
  }

  public createFolder(item: MailFolderContract): void {
    this.parentMailFolderTypeForCreation = item.folderType;
    this.parentMailFolderIdForCreation = item.mailFolderId;
    this.drawerService.openDrawer(this.createFolderTemplate);
  }

  public async onSubmitCreation(): Promise<void> {
    this.updateDataSideNav.emit();
    this.parentMailFolderIdForCreation = null;
    this.parentMailFolderTypeForCreation = null;
  }

  public renameFolder(folder: MailFolderContractWithFullNameForResponse): void {
    this.parentMailFolderTypeForCreation = folder.folderType;
    this.editingFolder = folder;
    this.drawerService.openDrawer(this.renameFolderTemplate);
  }

  public async onSubmitRename(): Promise<void> {
    this.updateDataSideNav.emit();
    this.editingFolder = null;
    this.parentMailFolderTypeForCreation = null;
  }

  public async onDeleteBtnClicked(mailFolderId: number): Promise<{ error?: string }> {
    try {
      await this.mailFolderClient.delete({ id: mailFolderId });
      return {};
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
      return { error: e.message };
    }
  }

  public deleteFolder(folder: MailFolderContractWithFullNameForResponse): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '450px',
      autoFocus: false,
      data: {
        title: 'confirmFolderDeletion',
        name: 'folderDeletion',
        itemName: folder.name,
        isCreate: false,
        isDontNeedConfirmWord: true,
        submit: () => this.onDeleteBtnClicked(folder.mailFolderId),
      },
    });

    dialogRef.afterClosed().subscribe(async (res) => {
      if (res) {
        this.updateDataSideNav.emit();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.tree) {
      this.dataSource.data = changes?.tree.currentValue?.children ?? [];
    }
  }
}
