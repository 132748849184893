import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { MailFolderClient } from '../../sdk/clients/mail-folder.client';
import { UserFolderContract } from '../../sdk/contracts/mail-folder/user-folder.contract';
import { SearchParams } from './search-params';
import { UserFolderNode } from './user-folder-node';
import { SearchContext } from './search-context';
import { MailFolderType } from '../../sdk/contracts/mail-folder/mail-folder.type';
import { TreeItem } from '../../sdk/contracts/tree.item';
import { MailFolderContract } from '../../sdk/contracts/mail-folder/mail-folder.contract';

@Injectable({
  providedIn: 'root',
})
export class SearchContextStorageService {
  public userFoldersTree: UserFolderNode[]; // all user folders tree
  public tree: TreeItem<number, MailFolderContract>[] = null;
  public defaultSearchParams = new SearchParams();
  public foldersMap: Map<number, number[]>; // mail account id - [mail folder ids]
  private userMailFolderIds: number[];
  public currentFolderId: number;
  public currentMailAccountId: number;
  public hideNotifications: boolean;

  private searchContextSubject: BehaviorSubject<SearchContext> = new BehaviorSubject<SearchContext>(new SearchContext());
  searchContext$: Observable<SearchContext> = this.searchContextSubject.asObservable();

  constructor() {}

  public currentMailAccountId$ = new Subject<number>();
  public isDefaultSearchParams$ = new BehaviorSubject<boolean>(true);
  // public isTreeLoaded$ = new BehaviorSubject<boolean>(false);

  public getFolderByType(mailAccountId: number, folderType: MailFolderType): number {
    // const foundFolder = this.userFolders.filter((x) => x.folderType === folderType && x.mailAccountId === mailAccountId);
    // if (foundFolder && foundFolder.length > 0) {
    //   return foundFolder[0].mailFolderId;
    // }
    return 0;
  }
  public getIsDefaultSearchParams(): void {
    const newSearchParams = this.searchContextSubject.value.searchParams;
    !newSearchParams.attachmentName &&
    !newSearchParams.body &&
    this.defaultSearchParams.deleted === newSearchParams.deleted &&
    !newSearchParams.downloadDateTime?.end &&
    !newSearchParams.downloadDateTime?.start &&
    !newSearchParams.from &&
    !newSearchParams.fullText &&
    this.defaultSearchParams.isSpam === newSearchParams.isSpam &&
    this.defaultSearchParams.parentId === newSearchParams.parentId &&
    this.defaultSearchParams.reassignedId === newSearchParams.reassignedId &&
    this.defaultSearchParams.source === newSearchParams.source &&
    this.defaultSearchParams.searchOrder === newSearchParams.searchOrder &&
    this.defaultSearchParams.seen === newSearchParams.seen &&
    !newSearchParams.sentDateTime?.end &&
    !newSearchParams.sentDateTime?.start &&
    !newSearchParams.subject &&
    this.defaultSearchParams.tags.length === newSearchParams.tags.length &&
    !newSearchParams.to &&
    this.searchContextSubject.value.selectedMailFolderIds?.length === 1 &&
    this.defaultSearchParams.withAttachments === newSearchParams.withAttachments
      ? this.isDefaultSearchParams$.next(true)
      : this.isDefaultSearchParams$.next(false);
  }

  public getDefaultMailAccount(): UserFolderContract {
    // const firstMailAccount = this.userFolders.filter((x) => x.mailAccountId && x.mailAccountId !== 0);
    // if (firstMailAccount && firstMailAccount.length > 0) {
    //   return firstMailAccount[0];
    // }
    return null;
  }

  private async loadData(firstTime: boolean): Promise<void> {
    // try {
    //   this.userFolders = folders;
    //   const foldersMap = new Map<number, number[]>();
    //   for (const folder of folders) {
    //     if (foldersMap.has(folder.mailAccountId)) {
    //       const addedMailFolderIds = foldersMap.get(folder.mailAccountId);
    //       foldersMap.set(folder.mailAccountId, [...addedMailFolderIds, folder.mailFolderId]);
    //     } else {
    //       foldersMap.set(folder.mailAccountId, [folder.mailFolderId]);
    //     }
    //   }
    //   this.foldersMap = foldersMap;
    //   this.userMailFolderIds = folders.map((f) => f.mailFolderId);
    //   if (firstTime) {
    //     this.initSearchStateInFirstTime();
    //   }
    //   this.userFoldersTree = this.buildUserFolderTree(this.userFolders);
    //   this.tree = this.buildFolderTree(this.userFolders);
    // } catch (e) {}
  }

  public async refreshData(): Promise<void> {
    await this.loadData(false);
  }

  private buildUserFolderTree(folders: UserFolderContract[]): UserFolderNode[] {
    const tree: UserFolderNode[] = [];

    // root mail account nodes
    for (const f of folders) {
      if (!tree.find((x) => x.id === f.mailAccountId)) {
        tree.push({
          isAccountNode: true,
          id: f.mailAccountId,
          name: f.mailAccountName,
          level: 0,
          children: [],
          parentKey: f.parentFolderId,
          folderType: f.folderType,
        });
      }
    }

    // mail folder nodes
    for (const mailAccountNode of tree) {
      const mailAccountId = mailAccountNode.id;
      const mailFolders = folders.filter((f) => f.mailAccountId === mailAccountId);
      const rootFolders = mailFolders.filter((f) => !f.parentFolderId);
      for (const rootFolder of rootFolders) {
        const rootFolderNode = this.buildUserFolderNode(rootFolder, mailFolders);
        mailAccountNode.children.push(rootFolderNode);
      }
    }

    return tree;
  }

  private buildUserFolderNode(folder: UserFolderContract, folders: UserFolderContract[], level = 1): UserFolderNode {
    const node = new UserFolderNode();
    node.isAccountNode = false;
    node.id = folder.mailFolderId;
    node.name = folder.mailFolderName;
    node.level = level;
    node.children = [];
    (node.parentKey = folder.parentFolderId), (node.folderType = folder.folderType);

    const children = folders.filter((f) => f.parentFolderId == folder.mailFolderId);

    for (const childFolder of children) {
      node.children.push(this.buildUserFolderNode(childFolder, folders, level + 1));
    }
    return node;
  }

  private initSearchStateInFirstTime(): void {
    this.selectMailAccount(this.getDefaultMailAccount()?.mailAccountId);
  }

  public selectMailAccount(mailAccountId: number): void {
    this.currentMailAccountId$.next(mailAccountId);
    this.currentMailAccountId = mailAccountId;
    if (!mailAccountId) {
      return;
    }

    const mailAccountFolders = this.foldersMap.get(mailAccountId);
    if (!mailAccountFolders) {
      return;
    }

    const defaultInboxFolder = this.getFolderByType(mailAccountId, MailFolderType.Inbox);
    if (!defaultInboxFolder) {
      return;
    }
    const hideNotification = this.searchContextSubject.value.searchParams.hideNotifications;
    const newSearchContext = new SearchContext();
    this.currentFolderId = defaultInboxFolder;
    newSearchContext.selectedMailFolderIds = [defaultInboxFolder];
    newSearchContext.searchParams.hideNotifications = hideNotification;
    this.searchContextSubject.next(newSearchContext);
    this.getIsDefaultSearchParams();
  }

  selectMailFolders(mailFolderIds: number[], resetState: boolean, hideNotifications: boolean = false): void {
    const newSearchContext = resetState ? new SearchContext() : this.searchContextSubject.value;
    newSearchContext.selectedMailFolderIds = mailFolderIds;
    newSearchContext.searchParams.hideNotifications = hideNotifications;
    this.currentFolderId = mailFolderIds[0];
    this.searchContextSubject.next(newSearchContext);
    this.getIsDefaultSearchParams();
  }

  setFullTextParam(fullText: string | null, hideNotifications: boolean): void {
    const newSearchContext = this.searchContextSubject.value;
    newSearchContext.searchParams.fullText = fullText;
    newSearchContext.searchParams.hideNotifications = hideNotifications;
    this.searchContextSubject.next(newSearchContext);
    this.getIsDefaultSearchParams();
  }

  setSearchContext(selectedMailFolderIds: number[], searchParams: SearchParams, page: number): void {
    const newSearchContext = new SearchContext();
    newSearchContext.selectedMailFolderIds = selectedMailFolderIds;
    newSearchContext.searchParams = searchParams;
    newSearchContext.page = page;
    this.searchContextSubject.next(newSearchContext);
    this.getIsDefaultSearchParams();
  }

  resetParams(): void {
    const newSearchContext = this.searchContextSubject.value;
    newSearchContext.searchParams = new SearchParams();
  }

  setPage(page: number): void {
    const newSearchContext = this.searchContextSubject.value;
    newSearchContext.page = page;
    this.searchContextSubject.next(newSearchContext);
    this.getIsDefaultSearchParams();
  }
}
