<div *ngIf="isInbox && isAnyFolders && !isAllFoldersSelected" class="folder-title show-folder" style="font-size: 25px; color: #1565c0c7">
  <div>{{ currentFolder?.creationType === 1 ? ("folderType" + currentFolder?.folderType | translate) : currentFolderName }}</div>
</div>
<mat-tab-group
  [(selectedIndex)]="selectedTabIndex"
  selectedIndex="0"
  (selectedTabChange)="changeTab($event)"
  *ngIf="isInbox && isAnyFolders && !isAllFoldersSelected"
  class="emails-tab-container"
  mat-align-tabs="end"
  animationDuration="0s">
  <mat-tab label="{{ 'inInboxTabItem' | translate }}"> </mat-tab>
  <mat-tab label="{{ 'toValidateInboxTabItem' | translate }}"> </mat-tab>
  <mat-tab label="{{ 'ReassignedInboxTabItem' | translate }}"> </mat-tab>
</mat-tab-group>
<div *ngIf="isInbox && isAnyFolders && !isAllFoldersSelected">
  <app-mails-content
    *ngIf="tabIndex === 0"
    [validated]="validated"
    [isAllFoldersSelected]="isAllFoldersSelected"
    [reassigned]="false"></app-mails-content>
  <app-mails-content
    *ngIf="tabIndex === 1"
    [validated]="notValidated"
    [isAllFoldersSelected]="isAllFoldersSelected"
    [reassigned]="false"></app-mails-content>
  <app-mails-content
    *ngIf="tabIndex === 2"
    [validated]="all"
    [isAllFoldersSelected]="isAllFoldersSelected"
    [reassigned]="true"></app-mails-content>
</div>

<div class="show-folder" *ngIf="!isInbox && isAnyFolders && !isAllFoldersSelected">
  <div class="px-[15px]" style="font-size: 25px; color: #1565c0c7">
    <div>{{ currentFolder?.creationType === 1 ? ("folderType" + currentFolder?.folderType | translate) : currentFolderName }}</div>
  </div>
</div>
<app-mails-content
  *ngIf="!isInbox && isAnyFolders && !isAllFoldersSelected"
  [validated]="all"
  [isAllFoldersSelected]="isAllFoldersSelected"
  [reassigned]="false"></app-mails-content>

<div class="show-folder" *ngIf="isAnyFolders && isAllFoldersSelected">
  <div class="px-[15px]" style="font-size: 25px; color: #1565c0c7">
    <div>{{ "allFoldersLabel" | translate }}</div>
  </div>
</div>
<app-mails-content
  *ngIf="isAnyFolders && isAllFoldersSelected"
  [isAllFoldersSelected]="isAllFoldersSelected"
  [validated]="all"
  [reassigned]="null"></app-mails-content>
<div *ngIf="!isAnyFolders" class="flex h-full">
  <p style="margin: auto" class="text-center">{{ "noMailAccountIsAvailableYet" | translate }}</p>
</div>
