import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { ApiRequest } from '../../services/api/api.request';
import { ApiRoutes } from '../routes/api.routes';
import { BaseGetByIdRequest } from '../contracts/common/base-get-by-id.request';
import { AttachmentGetByIdResponse } from '../contracts/Email/attachment.get-by-id.response';

const API_URL = environment.apiUrl;

@Injectable()
export class AttachmentClient {
  constructor(private apiRequest: ApiRequest) {}

  async getById(request: BaseGetByIdRequest): Promise<AttachmentGetByIdResponse> {
    return this.apiRequest.get(`${API_URL}${ApiRoutes.attachment.get}?${request.format()}`);
  }
}
