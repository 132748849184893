<div class="user-info-block">
  <div class="flex flex-col">
    @if(userOrganisaions.length === 1) {
      <span class="font-medium tracking-wider mb-1 text-lg">
        {{ userOrganisaions[0].name }}
      </span>
    }

    @if(userOrganisaions.length > 1) {
      <mat-form-field class="font-medium tracking-wider mb-1 text-lg main-header-select">
        <mat-select (selectionChange)="onChangeOrganization($event)" [(value)]="webCode">
          @for(userOrganisaion of userOrganisaions; track userOrganisaion) {
            <mat-option [value]="userOrganisaion.webCode">
              {{ userOrganisaion.name }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    }

    <span class="font-medium tracking-wider mb-1 text-lg">
      {{ user?.firstName ?? '' + " " + user?.lastName ?? '' }}
    </span>
    <span class="additional-user-info">
      {{ user?.email ?? '' }}
    </span>
    <span class="additional-user-info">
      @if(organizationName && userRole) {
          <span></span>
      }

      @if(userRole) {
        <span>{{ userRole }}</span>
      }
    </span>
  </div>
</div>
<mat-nav-list>
  @if (userRole !== 'Root') {
    <a
      [class.current-select]="isCurrentSelect('emails')"
      mat-list-item
      [routerLink]="'/' + webCode + '/emails'"
      (click)="onEmailsClick()"
    >
      {{ "emails" | translate }}
    </a>
  }
  @if(settingRoute) {
    <a
      [class.current-select]="isCurrentSelect('settings')"
      mat-list-item
      [routerLink]="settingRoute"
      (click)="sidenavOpen()"
    >
      {{ "settings" | translate }}
    </a>
  }

  @if(userRole !== 'Root') {
    <a
      [class.current-select]="isCurrentSelect('reports')"
      mat-list-item
      [routerLink]="'/' + webCode + '/reports'"
      (click)="sidenavOpen()"
    >
      {{ "reports" | translate }}
    </a>
  }

  @if(userRole !== 'Root' && pluginsPermission()) {
    <a
      [class.current-select]="isCurrentSelect('plugins')"
      mat-list-item
      [routerLink]="'/' + webCode + '/plugins'"
      (click)="sidenavOpen()"
    >
      {{ "plugins" | translate }}
    </a>
  }

  <a
    [class.current-select]="isCurrentSelect('account-settings')"
    mat-list-item
    [routerLink]="accountSettingsRoute"
    (click)="sidenavOpen()"
  >
    {{ "profile" | translate }}
  </a>

  <a mat-list-item (click)="logout(); sidenavOpen()">{{ "logout" | translate }}</a>
</mat-nav-list>

@if(userOrganisaions && userOrganisaions.length !== 0) {
  <div class="space-info">
    <div
      class="space-info__progress-space"
      [class.low-space-main]="spaceInfo.percentage>spaceInfo.alertPercentage"
    >
    <div
      class="progress-space__content"
      [class.low-space-inner]="spaceInfo.percentage>spaceInfo.alertPercentage"
      [ngStyle]="{ 'width': spaceInfo.percentage + '%' }"
    ></div>

    @if(spaceInfo.percentage<100) {
      <span class="progress-space__inner-text">{{spaceInfo.percentage}}%</span>
    }

    @if(spaceInfo.percentage>=100) {
      <span class="progress-space__warning-text">
         {{"exceededSpaceOf"| translate }} {{spaceInfo.maxAvailableSpaceUsed}} GB
      </span>
    }
    </div>
    <div class="flex justify-between">
      {{"totalSpaceOccupies" | translate}}
      <div class="space-info__text" [class.red]="spaceInfo.percentage>spaceInfo.alertPercentage">
        {{spaceInfo.spaceUsed}} GB
      </div>
    </div>

    @if(spaceInfo.percentage>=100) {
      <div class="progress-space__hint red">
        ({{"spaceHasBeenExceeded"| translate}})
      </div>
    }

    @if(spaceInfo.percentage>spaceInfo.alertPercentage && spaceInfo.percentage<100) {
      <div class="progress-space__hint red">
        ({{"spaceIsAboutEnd"| translate}})
      </div>
    }
  </div>
}
