<div class="flex justify-between h-full">
  <div *ngIf="isError" class="h-full w-full">
    <app-something-went-wrong
      [showPicture]="false"
      (retryEmitter)="ngOnInit()"></app-something-went-wrong>
  </div>
  <div class="canvas" *ngIf="isLoading">
    <app-loader height="100%"></app-loader>
  </div>
</div>
<div *ngIf="!isLoading && !isError">
  <div class="close-button">
    <button tabIndex="-1" class="" mat-icon-button (click)="cancel()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <h1 mat-dialog-title>{{ "addGroups" | translate }}</h1>
  <mat-dialog-content class="mat-mdc-dialog-content mdc-dialog__content mat-container">
    <!-- <p>{{'addGroupsToEmail' | translate}}</p> -->
    <div class="p-2">
      <span class="example-list-section">
        <ul>
          <li *ngIf="existedGroups.length < 1">No groups</li>
          <li *ngFor="let group of existedGroups">
            <mat-checkbox color="primary" [checked]="isChecked(group.groupId)" (change)="addGroupToEmails($event.checked, group.groupId)">
              {{ group.group }}
            </mat-checkbox>
          </li>
        </ul>
      </span>
    </div>
  </mat-dialog-content>
</div>
<mat-dialog-actions align="end" class="mat-mdc-dialog-actions mdc-dialog__actions">
  <button mat-flat-button [disabled]="isSavingGroups" color="primary" (click)="ok()">
<!--    <app-loading-button text="{{ 'save' | translate }}" [loading]="isSavingGroups"></app-loading-button>-->
  </button>
</mat-dialog-actions>
