import { AttachmentContract } from '../Attachment/Attachment.contract';
import { EmailValidationState } from './email.validation-state';

export class EmailContract {
  emailId: number;
  mailFolderId: number;
  messageId: string;
  from: string;
  to: string;
  cc: string;
  bcc: string;
  subject: string;
  body: string;
  attachmentFiles: AttachmentContract[];
  sentDateTime: string;
  stateDateTime: string;
  totalSize: number;
  downloadDateTime: string;
  meta: string;
  type: number;
  state: number;
  deleted: boolean;
  isSpam: boolean;
  seen: boolean;
  validated: EmailValidationState;
  validationState: EmailValidationState;
  sourceFolderType: number;
  splitSend: boolean;
}
