import { MailFolderClient } from '../../sdk/clients/mail-folder.client';
import { Injectable } from '@angular/core';
import { MailFolderContract } from '../../sdk/contracts/mail-folder/mail-folder.contract';
import { BaseGetByIdRequest } from '../../sdk/contracts/common/base-get-by-id.request';
import { TreeItem } from '../../sdk/contracts/tree.item';

@Injectable()
export class MailFolderService {
  constructor(private mailFolderClient: MailFolderClient) {}

  public async getFoldersForAccountFlat(mailAccountId: number): Promise<MailFolderContract[]> {
    const folders: MailFolderContract[] = [];

    const response = await this.mailFolderClient.getForAccount(new BaseGetByIdRequest(mailAccountId));
    response.data.forEach((value) => {
      folders.push(value.data);
      if (value.children) {
        value.children.forEach((child) => {
          this.addChildToList(folders, child, '- ');
        });
      }
    });

    return folders;
  }

  public async getFoldersForAccountFlatForSettings(mailAccountId: number): Promise<MailFolderContract[]> {
    const folders: any[] = [];

    const response = await this.mailFolderClient.getForAccount(new BaseGetByIdRequest(mailAccountId));
    response.data.forEach((value) => {
      folders.push({ ...value.data, fullName: value.data.name });
      if (value.children) {
        value.children.forEach((child) => {
          this.addChildToListForSettings(folders, child, '-\xa0\xa0\xa0');
        });
      }
    });

    return folders;
  }

  public async getFoldersForAccountReassign(mailAccountId: number): Promise<MailFolderContract[]> {
    const folders: MailFolderContract[] = [];

    const response = await this.mailFolderClient.getForReassign(new BaseGetByIdRequest(mailAccountId));
    response.data.forEach((value) => {
      folders.push(value.data);
      if (value.children) {
        value.children.forEach((child) => {
          this.addChildToList(folders, child, '- ');
        });
      }
    });

    return folders;
  }

  private addChildToList(folders: MailFolderContract[], item: TreeItem<number, MailFolderContract>, pad: string): void {
    item.data.name = `${pad}${item.data.name}`;
    folders.push(item.data);
    if (item.children) {
      item.children.forEach((child) => {
        this.addChildToList(folders, child, `${pad}- `);
      });
    }
  }
  private addChildToListForSettings(folders: any[], item: TreeItem<number, any>, pad: string): void {
    item.data.fullName = item.data.name;
    item.data.name = `${pad}${item.data.name}`;
    folders.push(item.data);
    if (item.children) {
      item.children.forEach((child) => {
        this.addChildToListForSettings(folders, child, `${pad}-\xa0\xa0\xa0`);
      });
    }
  }
}
