<div class="breadcrumbs-container">
  <app-breadcrumb [items]="breadcrumbItems"></app-breadcrumb>
</div>

<div class="pb-5">
  <app-navigation-tabs [tabItems]="tabItems"></app-navigation-tabs>
</div>
<!--<app-drawer title="Create folder" [isOpen]="createDrawerIsOpen" (closeEventEmitter)="onCloseCreateModalForm()">-->
<!--  <app-mail-folder-create-dialog-->
<!--    *ngIf="createDrawerIsOpen"-->
<!--    (closeEventEmitter)="onCloseCreateModalForm()"-->
<!--    (creationSubmit)="onSubmitCreation()"-->
<!--    [parentMailFolderId]="parentMailFolderIdForCreation"-->
<!--    [mailAccountId]="mailAccountId"-->
<!--    [parentMailFolderType]="parentMailFolderTypeForCreation"-->
<!--    [isSettings]="true">-->
<!--  </app-mail-folder-create-dialog>-->
<!--</app-drawer>-->

<!--<app-drawer title="Rename folder" [isOpen]="renameDrawerIsOpen" (closeEventEmitter)="onCloseRenameModalForm()">-->
<!--  <app-mail-folder-rename-dialog-->
<!--    *ngIf="renameDrawerIsOpen"-->
<!--    [mailFolderId]="editingFolder.mailFolderId"-->
<!--    [mailFolderName]="editingFolder.fullName"-->
<!--    [mailFolderCreationType]="editingFolder.creationType"-->
<!--    (closeEventEmitter)="onCloseRenameModalForm()"-->
<!--    [providerFolderName]="editingFolder.providerFolderName"-->
<!--    (creationSubmit)="onSubmitRename()"-->
<!--    [mailAccountId]="mailAccountId"-->
<!--    [parentMailFolderType]="parentMailFolderTypeForCreation"-->
<!--    [isSettings]="true">-->
<!--  </app-mail-folder-rename-dialog>-->
<!--</app-drawer>-->

<!--<app-drawer title="Folder settings" [isOpen]="settingsDrawerIsOpen" (closeEventEmitter)="onCloseSettingsModalForm()">-->
<!--  <app-mail-folder-settings-dialog-->
<!--    *ngIf="settingsDrawerIsOpen"-->
<!--    [folderType]="editingFolder.folderType"-->
<!--    [mailAccountId]="mailAccountId"-->
<!--    [organizationId]="organizationId"-->
<!--    [mailFolderId]="editingFolder.mailFolderId"-->
<!--    [mailFolderName]="editingFolder.creationType === 1 ? ('folderType' + editingFolder.folderType | translate) : editingFolder.fullName"-->
<!--    (closeEventEmitter)="onCloseSettingsModalForm()"-->
<!--    (creationSubmit)="onSubmitSettings()">-->
<!--  </app-mail-folder-settings-dialog>-->
<!--</app-drawer>-->

<div class="w-full">
  <app-loader *ngIf="isLoading"></app-loader>

  <div *ngIf="cannotLoadData" class="half-of-screen">
    <app-something-went-wrong
      [showPicture]="false"
      (retryEmitter)="ngOnInit()"></app-something-went-wrong>
  </div>

  <div *ngIf="!isLoading && !cannotLoadData" class="flex-none">
    <div class="canvas">
      <div *ngIf="!isLoading && !cannotLoadData">
        <div *ngIf="useMobileView">
          <div class="page-block" *ngFor="let folder of folders">
            <mat-card>
              <mat-card-content class="cursor-default">
                <div class="card-table-row">
                  <div class="card-table-row-value">
                    {{ folder.creationType === 1 ? ("folderType" + folder.folderType | translate) : folder.name }}
                  </div>
                  <div>
                    <div class="flex justify-end">
                      <button
                        *ngIf="
                          inInboxSentOrArchiveFolder(folder) &&
                          (folder.folderType !== mailFolderType.Archive || folder.creationType === mailCreationType.User)
                        "
                        mat-icon-button
                        (click)="renameFolder(folder)">
                        <mat-icon>edit_outline</mat-icon>
                      </button>
                      <button *ngIf="inInboxSentOrArchiveFolder(folder)" mat-icon-button aria-label="Menu" [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                      </button>
                      <mat-menu #menu="matMenu">
                        <button *ngIf="folder.creationType === 2" mat-menu-item (click)="deleteFolder(folder)">
                          <mat-icon>delete_outline</mat-icon>{{ "delete" | translate }}
                        </button>
                        <button *ngIf="inInboxSentOrArchiveFolder(folder)" mat-menu-item (click)="createFolder(folder)">
                          <mat-icon>create_new_folder</mat-icon>{{ "createSubfolder" | translate }}
                        </button>
                        <button *ngIf="inInboxSentOrArchiveFolder(folder)" mat-menu-item (click)="openFolderSettings(folder)">
                          <mat-icon>settings</mat-icon>{{ "settings" | translate }}
                        </button>
                      </mat-menu>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>

        <div *ngIf="!useMobileView" class="page-block">
          <table mat-table [dataSource]="dataSource" class="w-full">
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>{{ "name" | translate }}</th>
              <td mat-cell *matCellDef="let folder">
                {{ folder.creationType === 1 ? ("folderType" + folder.folderType | translate) : folder.name }}
              </td>
            </ng-container>
            <ng-container matColumnDef="providerName">
              <th mat-header-cell *matHeaderCellDef>{{ "providerFolderName" | translate }}</th>
              <td mat-cell *matCellDef="let folder">{{ folder.providerFolderName }}</td>
            </ng-container>
            <ng-container matColumnDef="archive">
              <th mat-header-cell *matHeaderCellDef>{{ "archive" | translate }}</th>
              <td mat-cell *matCellDef="let folder">
                {{ !inInboxSentOrArchiveFolder(folder) ? "" : folder.archive ? ("active" | translate) : ("notActive" | translate) }}
              </td>
            </ng-container>
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef>{{ "actions" | translate }}</th>
              <td mat-cell *matCellDef="let folder" class="action-link">
                <button
                  *ngIf="
                    folder.folderType === mailFolderType.Inbox ||
                    folder.folderType === mailFolderType.Sent ||
                    folder.folderType === mailFolderType.Archive
                  "
                  mat-icon-button
                  aria-label="Create subfolder"
                  matTooltip="{{ 'createSubfolder' | translate }}"
                  (click)="createFolder(folder)">
                  <mat-icon>create_new_folder</mat-icon>
                </button>
                <button
                  *ngIf="
                    folder.folderType === mailFolderType.Inbox ||
                    folder.folderType === mailFolderType.Sent ||
                    folder.folderType === mailFolderType.Archive
                  "
                  mat-icon-button
                  aria-label="Settings"
                  matTooltip="{{ 'settings' | translate }}"
                  (click)="openFolderSettings(folder)">
                  <mat-icon>settings</mat-icon>
                </button>
                <button
                  *ngIf="
                    inInboxSentOrArchiveFolder(folder) &&
                    (folder.folderType !== mailFolderType.Archive || folder.creationType === mailCreationType.User)
                  "
                  mat-icon-button
                  aria-label="Modify"
                  matTooltip="{{ 'modify' | translate }}"
                  (click)="renameFolder(folder)">
                  <mat-icon>edit_outline</mat-icon>
                </button>
                <button
                  *ngIf="folder.creationType === 2"
                  mat-icon-button
                  aria-label="Delete"
                  matTooltip="{{ 'delete' | translate }}"
                  (click)="deleteFolder(folder)">
                  <mat-icon>delete_outline</mat-icon>
                </button>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" class="cursor-default"></tr>
          </table>
          <app-no-data *ngIf="folders.length === 0"></app-no-data>
        </div>
      </div>
    </div>
  </div>
</div>
