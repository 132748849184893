import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiRequest } from '../../services/api/api.request';
import { ApiRoutes } from '../routes/api.routes';
import { BaseGetByIdRequest } from '../contracts/common/base-get-by-id.request';
import { ContactCreateRequest } from '../contracts/contact/contact.create.request';
import { BaseCreateResponse } from '../contracts/common/base-create.response';
import { BaseUpdateResponse } from '../contracts/common/base-update.response';
import { ContactUpdateRequest } from '../contracts/contact/contact.update.request';
import { BaseIdListRequest } from '../contracts/common/base.id-list.request';
import { BaseDeleteRequest } from '../contracts/common/base-delete.request';
import { BaseGetByIdResponse } from '../contracts/common/base-get-by-id.response';
import { ContactContract } from '../contracts/contact/contact.contract';
import { BaseGetCollectionResponse } from '../contracts/common/base-get-collection.response';
import { BaseGetCollectionByIdRequest } from '../contracts/common/base-get-collection-by-id.request';
import { BaseCollectionSearchByIdRequest } from '../contracts/common/base-collection-search-by-id.request';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class ContactClient {
  constructor(private apiRequest: ApiRequest) {}

  async create(request: ContactCreateRequest): Promise<BaseCreateResponse> {
    return this.apiRequest.post<ContactCreateRequest, BaseCreateResponse>(request, `${API_URL}${ApiRoutes.contact.create}`);
  }

  async update(request: ContactUpdateRequest): Promise<BaseUpdateResponse> {
    return this.apiRequest.post<ContactUpdateRequest, BaseUpdateResponse>(request, `${API_URL}${ApiRoutes.contact.update}`);
  }

  async delete(request: BaseIdListRequest): Promise<void> {
    return this.apiRequest.post<BaseIdListRequest, void>(request, `${API_URL}${ApiRoutes.contact.delete}`);
  }

  async deleteForAccount(request: BaseDeleteRequest): Promise<void> {
    return this.apiRequest.post<BaseDeleteRequest, void>(request, `${API_URL}${ApiRoutes.contact.deleteForAccount}`);
  }

  async getForAccount(request: BaseGetCollectionByIdRequest): Promise<BaseGetCollectionResponse<ContactContract>> {
    return this.apiRequest.get<BaseGetCollectionResponse<ContactContract>>(
      `${API_URL}${ApiRoutes.contact.getForAccount}?${request.format()}`
    );
  }

  async searchForAccount(request: BaseCollectionSearchByIdRequest): Promise<BaseGetCollectionResponse<ContactContract>> {
    return this.apiRequest.get<BaseGetCollectionResponse<ContactContract>>(`${API_URL}${ApiRoutes.contact.search}?${request.format()}`);
  }

  async get(request: BaseGetByIdRequest): Promise<BaseGetByIdResponse<ContactContract>> {
    return this.apiRequest.get<BaseGetByIdResponse<ContactContract>>(`${API_URL}${ApiRoutes.contact.get}?${request.format()}`);
  }
}
