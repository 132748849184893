import { Component, Input, OnInit } from '@angular/core';
import { TabItem } from '../../shared/tab-item';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navigation-tabs',
  templateUrl: './navigation-tabs.component.html',
  styleUrls: ['./navigation-tabs.component.scss'],
})
export class NavigationTabsComponent implements OnInit {
  @Input() public tabItems: TabItem[] = [];
  public currentRoute;

  constructor(private router: Router) {
    this.currentRoute = this.router.url;
  }

  ngOnInit(): void {}

  get tabItemsSortedByIndex(): TabItem[] {
    return this.tabItems.sort((a, b) => a.index - b.index);
  }

  get selectedTabIndex(): number {
    const selectedTabItem = this.tabItemsSortedByIndex.find((i) => i.route === this.currentRoute);

    if (!selectedTabItem) {
      return null;
    }

    return selectedTabItem.index;
  }

  async tabSelected(tabIndex: number): Promise<void> {
    const selectedTabItem = this.tabItemsSortedByIndex[tabIndex];
    if (selectedTabItem.route !== this.currentRoute) {
      await this.router.navigate([selectedTabItem.route]);
    }
  }
}
