import { BaseCollectionSearchRequest } from './base-collection-search.request';

export class BaseCollectionSearchByIdRequest extends BaseCollectionSearchRequest {
  id: number;

  constructor(id: number, page: number, pageSize: number, search: string | null) {
    super(page, pageSize, search);
    this.id = id;
  }
}
