import { BaseGetRequest } from './base-get.request';

export class BaseGetByEmailFileIdRequest extends BaseGetRequest {
  fileId: number;
  emailId: number;

  constructor(fileId: number, emailId: number) {
    super();
    this.fileId = fileId;
    this.emailId = emailId;
  }
}
