export class DateTimePeriod {
  start: Date;
  end: Date;

  constructor(start: Date, end: Date) {
    if (start) {
      this.start = start;
    }

    if (end) {
      this.end = end;
    }
  }
}
