@if(isLoading) {
  <div class="w-full h-full">
    <app-loader height="100%"></app-loader>
  </div>
}

@if(cannotLoadData) {
  <div class="w-full h-full">
    <app-something-went-wrong
      [showPicture]="false"
      (retryEmitter)="ngOnInit()"
    ></app-something-went-wrong>
  </div>
}

@if(!isLoading && !cannotLoadData) {
  <div class="w-full h-full flex flex-col">
    <div class="text-2xl mb-10 font-medium">{{ "advancedSearch" | translate }}</div>
      <div class="flex-grow overflow-y-auto">
      <div>
        <div>
          <mat-form-field>
            <mat-label>{{ "folders" | translate }}</mat-label>
            <mat-select (selectionChange)="onSearchTypeChange(searchType)" [(value)]="searchType">
              <mat-option [value]="1">
                {{ "currSelections" | translate }}
              </mat-option>
              <mat-option [value]="2">
                {{ "currSelections" | translate }} + {{ "subfolders" | translate }}
              </mat-option>
              <mat-option [value]="3">
                {{ "allFolders" | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <mat-form-field>
          <mat-label>{{ "subject" | translate }}</mat-label>
          <input id="focusSearch" matInput [(ngModel)]="searchContext.searchParams.subject" />
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ "body" | translate }}</mat-label>
          <input matInput [(ngModel)]="searchContext.searchParams.body" />
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ "from" | translate }}</mat-label>
          <input matInput [(ngModel)]="searchContext.searchParams.from" />
        </mat-form-field>

        <mat-form-field >
          <mat-label>{{ "recipient" | translate }}</mat-label>
          <input matInput [(ngModel)]="searchContext.searchParams.to" />
        </mat-form-field>

        <mat-form-field >
          <mat-label>{{ "sentDateRange" | translate }}</mat-label>
          <mat-date-range-input [formGroup]="filterForm" [rangePicker]="picker">
            <input
              [(ngModel)]="searchContext.searchParams.sentDateTime.start"
              matStartDate
              formControlName="sentStart"
              placeholder="{{ 'startDateTime' | translate }}"
            />
            <input matEndDate formControlName="sentEnd" placeholder="{{ 'endDateTime' | translate }}" />
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ "attachmentName" | translate }}</mat-label>
          <input matInput [(ngModel)]="searchContext.searchParams.attachmentName" />
        </mat-form-field>

        <mat-form-field >
          <mat-label>{{ "attachments" | translate }}</mat-label>
          <mat-select [(value)]="selectedAttachmentsStatus">
            @for(item of attachments; track item) {
              <mat-option [value]="item.value">
                {{ item.text | translate | lowercase }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>

        <mat-form-field >
          <mat-label>{{ "seen" | translate }}</mat-label>
          <mat-select [(value)]="selectedSeenStatus">
            @for(item of seen; track item) {
              <mat-option [value]="item.value">
                {{ item.text | translate | lowercase }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>

        <mat-form-field >
          <mat-label>{{ "reassignedTo" | translate }}</mat-label>
          <mat-select [(value)]="selectedReassignedId">
            <mat-option [value]="0">
            {{ "showAll" | translate }}
            </mat-option>
            @for(account of accounts; track account) {
              <mat-option [value]="account.mailAccountId">
                {{ account.name }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ "source" | translate }}</mat-label>
          <mat-select [(value)]="selectedSourceStatus">
            @for(item of source; track item) {
              <mat-option [value]="item.value">
                {{ item.text | translate | lowercase }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>

        <form [formGroup]="filterForm" autocomplete="off" novalidate>
          @if(existedTags.length > 0) {
            <div class="pr-2">
<!--              <app-email-search-tag-input [existedTags]="existedTags" [selectedTags]="selectedTags"> </app-email-search-tag-input>-->
            </div>
          }
        </form>
      </div>
    </div>
    <div class="flex-none py-2">
        <div class="flex justify-between">
          <button mat-flat-button (click)="resetParams()">{{ "resetFilters" | translate }}</button>
          <button mat-flat-button color="primary" (click)="search()">{{ "search" | translate }}</button>
        </div>
    </div>
  </div>
}


