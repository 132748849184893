export class EmailReassignRequest {
  emailIds: number[];
  mailAccountId: number;
  mailFolderId: number;

  constructor(emailIds: number[], mailAccountId: number, mailFolderId: number) {
    this.emailIds = emailIds;
    this.mailAccountId = mailAccountId;
    this.mailFolderId = mailFolderId;
  }
}
