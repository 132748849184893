import { EventEmitter, Injectable } from '@angular/core';
import { UserLoginResponse } from '../../sdk/contracts/user/user.login.response';
import { EmailExportFileReadyEvent } from '../../sdk/contracts/email-export/email-export-file-ready-event';
import { ReceiveEmailEvent } from '../../sdk/contracts/email-export/receive-email-event';
import { EmailSetSeenEvent } from '../../sdk/contracts/email-export/email-set-seen-event';
import { EmailMoveToFolderEvent } from '../../sdk/contracts/email-export/email-move-tofolder-event';
import { EmailReassignEvent } from '../../sdk/contracts/email-export/email-reassign-event';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EventsService {
  public apiCallEvent: EventEmitter<string>;
  public signInEvent: EventEmitter<UserLoginResponse>;
  // public signOutEvent: EventEmitter<any>;
  // public intervalUpdateBadges: EventEmitter<any>;
  public emailExportFileReadyEvent: EventEmitter<EmailExportFileReadyEvent>;
  public receiveEmailEvent: EventEmitter<ReceiveEmailEvent>;
  public emailMoveToFolderEvent: EventEmitter<EmailMoveToFolderEvent>;
  public emailSetSeenEvent: EventEmitter<EmailSetSeenEvent>;
  public emailReassignEvent: EventEmitter<EmailReassignEvent>;
  public signalConnectionsState: EventEmitter<boolean>;

  // TODO move specific event to own domains
  // public userRole$ = new BehaviorSubject<string>('');

  public reassignedAccountIds$ = new BehaviorSubject<number[]>([]);
  public isLeftMenuOpened = false;
  public unreadCountValue = false;
  public unreadCountFolderId: number;
  public leftMenuClicked: EventEmitter<any>;
  public UserMailAccountSelected: EventEmitter<any>;
  // public rightMenuClicked: EventEmitter<any>;
  public unreadCountEvent: EventEmitter<any>;
  public onEscCloseDetailDialogEvent: EventEmitter<any>;
  public unreadCountFolderEvent: EventEmitter<any>;

  constructor() {
    this.apiCallEvent = new EventEmitter();
    // this.signInEvent = new EventEmitter();
    // this.signOutEvent = new EventEmitter();
    this.emailExportFileReadyEvent = new EventEmitter();
    this.receiveEmailEvent = new EventEmitter();
    this.emailSetSeenEvent = new EventEmitter();
    this.emailMoveToFolderEvent = new EventEmitter();
    this.emailReassignEvent = new EventEmitter();
    this.signalConnectionsState = new EventEmitter();
    // this.intervalUpdateBadges = new EventEmitter();
    this.leftMenuClicked = new EventEmitter<any>();
    this.UserMailAccountSelected = new EventEmitter<any>();
    // this.rightMenuClicked = new EventEmitter();
    this.unreadCountEvent = new EventEmitter();
    this.onEscCloseDetailDialogEvent = new EventEmitter();
    this.unreadCountFolderEvent = new EventEmitter();
  }

  public changeUnreadCountValue(value: boolean): void {
    this.unreadCountValue = value;
    this.unreadCountEvent.emit();
  }

  public unreadCountFolderValue(folderId: number): void {
    this.unreadCountFolderId = folderId;
    this.unreadCountFolderEvent.emit();
  }

  // public changeUserRole(userRole: string): void {
  //   this.userRole$.next(userRole);
  // }

  public reassign(reassignedAccountIds: number[]): void {
    this.reassignedAccountIds$.next(reassignedAccountIds);
  }

  // public openLeftMenu(): void {
  //   if (!this.isLeftMenuOpened) {
  //     this.leftMenuClicked.emit();
  //   }
  //   this.isLeftMenuOpened = true;
  // }

  // public closeLeftMenu(): void {
  //   this.isLeftMenuOpened = false;
  //   this.leftMenuClicked.emit();
  // }

  // public toggleLeftMenu(): void {
  //   if (this.isLeftMenuOpened) {
  //     this.closeLeftMenu();
  //   } else {
  //     this.openLeftMenu();
  //   }
  // }
}
