import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { BaseGetByIdRequest } from '../../../sdk/contracts/common/base-get-by-id.request';
import { MatchError } from 'src/app/services/errors/error-matcher';

import { TagClient } from '../../../sdk/clients/tag.client';
import { EmailTagClient } from '../../../sdk/clients/email-tag.client';
import { EmailTagSaveRequest } from '../../../sdk/contracts/email-tags/email-tag-save.request';

@Component({
  selector: 'email-add-tags-dialog',
  templateUrl: './email-add-tags-dialog.component.html',
  styleUrls: ['./email-add-tags-dialog.component.scss'],
})
export class EmailAddTagsDialogComponent {
  public thisTags: string[] = [];
  public existedTags: any[] = [];
  public isLoading: boolean = true;
  public existedTagsResponse: any;
  public isSave: boolean = false;
  public isSavingTags: boolean = false;
  public tags: string[] = [];
  public isError: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<EmailAddTagsDialogComponent>,
    public tagClient: TagClient,
    public dialog: MatDialog,
    public matchError: MatchError,
    private emailTagClient: EmailTagClient,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  async ngOnInit() {
    this.dialogRef.keydownEvents().subscribe((event) => {
      if (event.key === 'Escape') {
        this.ok();
      }
    });
    await this.loadData();
  }

  async saveTags(): Promise<any> {
    this.isSavingTags = true;
    try {
      await this.emailTagClient.saveTags(new EmailTagSaveRequest(this.data.ids, this.thisTags));
      this.ok();
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.isSavingTags = false;
    }
  }

  public async loadData() {
    this.isError = false;
    this.isLoading = true;
    try {
      this.existedTagsResponse = await this.tagClient.getForMailAccount(new BaseGetByIdRequest(this.data.mailAccountId));

      this.existedTags = this.existedTagsResponse.data.map((c) => c.tag);
      if (this.data.emails.length === 1) {
        let tags = [];
        this.data.emails[0].tags.forEach((e) => {
          tags.push(e.tag);
        });
        this.thisTags = tags;
      }
    } catch (e) {
      this.isError = true;
      this.matchError.logError(e);
    } finally {
      this.isLoading = false;
    }
  }

  setIsSave() {
    this.isSave = true;
  }

  setTags(tags) {
    this.tags = tags;
  }

  public close() {
    this.dialogRef.close();
  }

  async ok(): Promise<any> {
    if (this.isSave) {
      let tags = [];
      this.tags.forEach((e) => tags.push({ tag: e }));
      this.data.tags = tags;
      this.dialogRef.close(this.data);
    } else {
      this.dialogRef.close();
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
