import { Pipe, PipeTransform } from '@angular/core';
import { ErrorCode } from '../../sdk/api-errors/error-code';

@Pipe({
  name: 'errors',
})
export class ErrorsPipe implements PipeTransform {
  transform(value: number): string {
    switch (value) {
      case ErrorCode.ValidationError:
        return 'errorValidationError';

      case ErrorCode.ArgumentError:
        return 'errorArgumentError';

      case ErrorCode.PasswordMismatch:
        return 'errorPasswordMismatch';

      case ErrorCode.EntityDoesNotExists:
        return 'errorEntityDoesNotExists';

      case ErrorCode.NullableRequestError:
        return 'errorNullableRequestError';

      case ErrorCode.AwsFilePutError:
        return 'errorAwsFilePutError';

      case ErrorCode.AwsFileGetError:
        return 'errorAwsFileGetError';

      case ErrorCode.AwsClientConfigError:
        return 'errorAwsClientConfigError';

      case ErrorCode.BadLoginError:
        return 'errorBadLoginError';

      case ErrorCode.UserAlreadyExists:
        return 'errorUserAlreadyExists';

      case ErrorCode.UserIdentityAlreadyExists:
        return 'errorUserIdentityAlreadyExists';

      case ErrorCode.OrganizationAlreadyHasUser:
        return 'errorOrganizationAlreadyHasUser';

      case ErrorCode.InvalidJwtToken:
        return 'errorInvalidJwtToken';

      case ErrorCode.AccessError:
        return 'errorAccessError';

      case ErrorCode.ImapWrongPassword:
        return 'errorImapWrongPassword';

      case ErrorCode.ImapCantConnectToServer:
        return 'errorImapCantConnectToServer';

      case ErrorCode.DeleteError:
        return 'errorDeleteError';

      case ErrorCode.EmailSendingError:
        return 'errorEmailSendingError';

      case ErrorCode.WeakPassword:
        return 'errorWeakPassword';

      case ErrorCode.UserDontHaveIdentity:
        return 'errorUserDontHaveIdentity';

      case ErrorCode.UserDoesNotExist:
        return 'errorUserDoesNotExist';

      case ErrorCode.ProviderEntityAlreadyUse:
        return 'errorProviderEntityAlreadyUse';

      case ErrorCode.OrganizationHasMailAccounts:
        return 'errorOrganizationHasMailAccounts';

      case ErrorCode.OrganizationHasUsers:
        return 'errorOrganizationHasUsers';

      case ErrorCode.InvalidHierarchy:
        return 'errorInvalidHierarchy';

      case ErrorCode.CannotDeleteDefaultMailFolder:
        return 'errorCannotDeleteDefaultMailFolder';

      case ErrorCode.MailFolderHasSubfolders:
        return 'errorMailFolderHasSubfolders';

      case ErrorCode.CannotUpdateCommonMailFolder:
        return 'errorCannotUpdateCommonMailFolder';

      case ErrorCode.DispatchingActionNotExists:
        return 'errorDispatchingActionNotExists';

      case ErrorCode.UpdateError:
        return 'errorUpdateError';

      case ErrorCode.DispatchingRuleFoldersEmpty:
        return 'errorDispatchingRuleFoldersEmpty';

      case ErrorCode.DispatchingRuleActionsEmpty:
        return 'errorDispatchingRuleActionsEmpty';

      case ErrorCode.DispatchingRuleActionNameEmpty:
        return 'errorDispatchingRuleActionNameEmpty';

      case ErrorCode.UnknownDispatchingCondition:
        return 'errorUnknownDispatchingCondition';

      case ErrorCode.MailAccountDontExist:
        return 'errorMailAccountDontExist';

      case ErrorCode.DispatchingRuleObjectIsNull:
        return 'errorDispatchingRuleObjectIsNull';

      case ErrorCode.DispatchingActionArgumentError:
        return 'errorDispatchingActionArgumentError';

      case ErrorCode.EmlBuildError:
        return 'errorEmlBuildError';

      case ErrorCode.DispatchingActionNotProvided:
        return 'errorDispatchingActionNotProvided';

      case ErrorCode.RemoteFolderNotProvided:
        return 'errorRemoteFolderNotProvided';

      case ErrorCode.EmailFieldsNotFetched:
        return 'errorEmailFieldsNotFetched';

      case ErrorCode.AttachmentFileNotExist:
        return 'errorAttachmentFileNotExist';

      case ErrorCode.SendEmailSmtpError:
        return 'errorSendEmailSmtpError';

      case ErrorCode.MailAccountWithEmailExist:
        return 'errorMailAccountWithEmailExist';

      case ErrorCode.SpecialMailFolderNotExist:
        return 'errorSpecialMailFolderNotExist';

      case ErrorCode.EmailWasReceivedByOtherProcess:
        return 'errorEmailWasReceivedByOtherProcess';

      case ErrorCode.FilePathIsNull:
        return 'errorFilePathIsNull';

      case ErrorCode.EmailAlreadyDeleted:
        return 'errorEmailAlreadyDeleted';

      case ErrorCode.EmailNotDeleted:
        return 'errorEmailNotDeleted';

      case ErrorCode.EmailNotExist:
        return 'errorEmailNotExist';

      case ErrorCode.EmailNoteNotExist:
        return 'errorEmailNoteNotExist';

      case ErrorCode.EmailNoteUsersNotEquals:
        return 'errorEmailNoteUsersNotEquals';

      case ErrorCode.EmlForEmailNotProvided:
        return 'errorEmlForEmailNotProvided';

      case ErrorCode.EmlForEmailNotFound:
        return 'errorEmlForEmailNotFound';

      case ErrorCode.MailAccountSettingsAlreadyExist:
        return 'errorMailAccountSettingsAlreadyExist';

      case ErrorCode.MailAccountSettingsNotExist:
        return 'errorMailAccountSettingsNotExist';

      case ErrorCode.MailFolderBelongsToDifferentMailAccount:
        return 'errorMailFolderBelongsToDifferentMailAccount';

      case ErrorCode.MailFolderBelongsToDifferentOrganization:
        return 'errorMailFolderBelongsToDifferentOrganization';

      case ErrorCode.EmailBelongsToDifferentOrganization:
        return 'errorEmailBelongsToDifferentOrganization';

      case ErrorCode.MailFolderDontExist:
        return 'errorMailFolderDontExist';

      case ErrorCode.OrganizationNotEnabledForArchive:
        return 'errorOrganizationNotEnabledForArchive';

      case ErrorCode.OrganizationDontExist:
        return 'errorOrganizationDontExist';

      case ErrorCode.ArchivePathNotConfigured:
        return 'errorArchivePathNotConfigured';

      case ErrorCode.ArchivePathCreationError:
        return 'errorArchivePathCreationError';

      case ErrorCode.FileStreamEmpty:
        return 'errorFileStreamEmpty';

      case ErrorCode.TempPathNotConfigured:
        return 'errorTempPathNotConfigured';

      case ErrorCode.TempPathCreationError:
        return 'errorTempPathCreationError';

      case ErrorCode.ContactCreateError:
        return 'errorContactCreateError';

      case ErrorCode.ContactUpdateError:
        return 'errorContactUpdateError';

      case ErrorCode.ContactGroupCreateError:
        return 'errorContactGroupCreateError';

      case ErrorCode.ContactGroupUpdateError:
        return 'errorContactGroupUpdateError';

      case ErrorCode.ContactNotExist:
        return 'errorContactNotExist';

      case ErrorCode.ContactGroupNotExist:
        return 'errorContactGroupNotExist';

      case ErrorCode.MissingCredentials:
        return 'errorMissingCredentials';

      case ErrorCode.MassiveSendSessionNotCreated:
        return 'errorMassiveSendSessionNotCreated';

      case ErrorCode.MassiveSendFileNotValid:
        return 'errorMassiveSendFileNotValid';

      case ErrorCode.MassiveSendSessionNotFound:
        return 'errorMassiveSendSessionNotFound';

      case ErrorCode.MailFolderNotEnabledForValidation:
        return 'errorMailFolderNotEnabledForValidation';

      case ErrorCode.MailFolderNotEnabledForReassign:
        return 'errorMailFolderNotEnabledForReassign';

      case ErrorCode.ClientIdIsNull:
        return 'errorClientIdIsNull';

      case ErrorCode.TempFileNotFound:
        return 'errorTempFileNotFound';

      case ErrorCode.OAuthOptionsNotProvided:
        return 'errorOAuthOptionsNotProvided';

      case ErrorCode.OAuthInvalidCode:
        return 'errorOAuthInvalidCode';

      case ErrorCode.GoogleUserNotFound:
        return 'errorGoogleUserNotFound';

      case ErrorCode.GoogleAccountNotVerified:
        return 'errorGoogleAccountNotVerified';

      case ErrorCode.GoogleAccountAlreadyLinked:
        return 'errorGoogleAccountAlreadyLinked';

      case ErrorCode.UnknownIdentityType:
        return 'errorUnknownIdentityType';

      case ErrorCode.OAuthAccountLinkedToOtherUserIdentity:
        return 'errorOAuthAccountLinkedToOtherUserIdentity';

      case ErrorCode.PermissionDenied:
        return 'errorPermissionDenied';

      case ErrorCode.EmailBelongsToDifferentMailAccount:
        return 'errorEmailBelongsToDifferentMailAccount';

      case ErrorCode.PasswordRestoreCodeInvalid:
        return 'errorPasswordRestoreCodeInvalid';

      case ErrorCode.PasswordRestoreCodeAlreadyActivated:
        return 'errorPasswordRestoreCodeAlreadyActivated';

      case ErrorCode.PasswordRestoreCodeExpired:
        return 'errorPasswordRestoreCodeExpired';

      case ErrorCode.OldPasswordIsIncorrect:
        return 'errorOldPasswordIsIncorrect';

      case ErrorCode.SearchWidgetNotExists:
        return 'errorSearchWidgetNotExists';

      case ErrorCode.SearchWidgetFilterIsNull:
        return 'errorSearchWidgetFilterIsNull';

      case ErrorCode.EmailDownloadError:
        return 'errorEmailDownloadError';

      case ErrorCode.ImailBuildError:
        return 'errorImailBuildError';

      case ErrorCode.CaptchaIsInvalid:
        return 'errorCaptchaIsInvalid';

      case ErrorCode.MailAccountCantBeSimpleSetup:
        return 'errorMailAccountCantBeSimpleSetup';

      case ErrorCode.RemoteFolderTypeNotAllowedForSimpleConfiguration:
        return 'errorRemoteFolderTypeNotAllowedForSimpleConfiguration';

      case ErrorCode.PasswordExpired:
        return 'errorPasswordExpired';

      case ErrorCode.PasswordNotExpired:
        return 'errorPasswordNotExpired';

      case ErrorCode.ImportWrongJson:
        return 'errorImportWrongJson';

      case ErrorCode.ImportFileNotFoundInArchive:
        return 'errorImportFileNotFoundInArchive';

      case ErrorCode.ImportOrganizationNotFound:
        return 'errorImportOrganizationNotFound';

      case ErrorCode.ImportProviderNotFound:
        return 'errorImportProviderNotFound';

      case ErrorCode.ImportBlankUserEmailAddress:
        return 'errorImportBlankUserEmailAddress';

      case ErrorCode.ImportUserNotExist:
        return 'errorImportUserNotExist';

      case ErrorCode.ImportRoleNotExists:
        return 'errorImportRoleNotExists';

      case ErrorCode.ImportEmptyUserName:
        return 'errorImportEmptyUserName';

      case ErrorCode.ImportInboxNotFound:
        return 'errorImportInboxNotFound';

      case ErrorCode.ImportFolderNotFound:
        return 'errorImportFolderNotFound';

      case ErrorCode.ImportCantParseJson:
        return 'errorImportCantParseJson';

      case ErrorCode.ImportEmptyOrganizationCode:
        return 'errorImportEmptyOrganizationCode';

      case ErrorCode.ImportEmptyUserList:
        return 'errorImportEmptyUserList';

      case ErrorCode.ImportEmptyAccountCode:
        return 'errorImportEmptyAccountCode';

      case ErrorCode.ImportEmptyAccountEmail:
        return 'errorImportEmptyAccountEmail';

      case ErrorCode.ImportEmptyProviderCode:
        return 'errorImportEmptyProviderCode';

      case ErrorCode.ImportAccountSettingsIsNull:
        return 'errorImportAccountSettingsIsNull';

      case ErrorCode.ImportFolderListIsNull:
        return 'errorImportFolderListIsNull';

      case ErrorCode.ImportAccountNotExists:
        return 'errorImportAccountNotExists';

      case ErrorCode.ImportNoEmailsForImport:
        return 'errorImportNoEmailsForImport';

      case ErrorCode.ImportEmailTypeIsNull:
        return 'errorImportEmailTypeIsNull';

      case ErrorCode.ImportEmailStateIsNull:
        return 'errorImportEmailStateIsNull';

      case ErrorCode.ImportEmailValidationStatusIsNull:
        return 'errorImportEmailValidationStatusIsNull';

      case ErrorCode.ImportEmailArchiveSessionNotFound:
        return 'errorImportEmailArchiveSessionNotFound';

      case ErrorCode.ImportEmailReassignAccountIdNotFound:
        return 'errorImportEmailReassignAccountIdNotFound';

      case ErrorCode.ImportEmailHistoryUserNotExists:
        return 'errorImportEmailHistoryUserNotExists';

      case ErrorCode.ImportEmailNoteUserNotExists:
        return 'errorImportEmailNoteUserNotExists';

      case ErrorCode.CannotUpdateEmailAlreadySending:
        return 'errorCannotUpdateEmailAlreadySending';

      case ErrorCode.EmailQueueNotExist:
        return 'errorEmailQueueNotExist';

      case ErrorCode.EmailQueueIterationNotExist:
        return 'errorEmailQueueIterationNotExist';

      case ErrorCode.UserAlreadyBeenAddedToMailAccount:
        return 'errorUserAlreadyBeenAddedToMailAccount';

      case ErrorCode.CannotChangeTypeForOrganizationWithChildren:
        return 'errorCannotChangeTypeForOrganizationWithChildren';

      case ErrorCode.CannotSetParentForParentOrganization:
        return 'errorCannotSetParentForParentOrganization';

      case ErrorCode.CannotDeleteOrganizationWithChildren:
        return 'errorCannotDeleteOrganizationWithChildren';

      case ErrorCode.TagAlreadyExists:
        return 'errorTagAlreadyExists';

      case ErrorCode.TagIsUsed:
        return 'errorTagIsUsed';

      case ErrorCode.WebCodeAlreadyUsed:
        return 'errorWebCodeAlreadyUsed';

      case ErrorCode.LicenseIdAndCodeSiaAlreadyUsed:
        return 'errorLicenseIdAndCodeSiaAlreadyUsed';

      case ErrorCode.UserNotInOrganization:
        return 'errorUserNotInOrganization';

      case ErrorCode.OrganizationHasNotSettings:
        return 'errorOrganizationHasNotSettings';

      case ErrorCode.CannotDeleteRtaFromOrganization:
        return 'errorCannotDeleteRtaFromOrganization';

      case ErrorCode.OidcCreateUserFailed:
        return 'errorOidcCreateUserFailed';

      case ErrorCode.GroupCreateError:
        return 'errorGroupCreateError';

      case ErrorCode.GroupAlreadyExists:
        return 'errorGroupAlreadyExists';

      case ErrorCode.UnknownFolderType:
        return 'errorUnknownFolderType';

      case ErrorCode.EmailEmptyFolder:
        return 'errorEmailEmptyFolder';

      case ErrorCode.EmailMoveOperationNotValid:
        return 'errorEmailMoveOperationNotValid';

      case ErrorCode.EmailAlreadyReassigned:
        return 'errorEmailAlreadyReassigned';

      case ErrorCode.EmailsNotInOneFolder:
        return 'errorEmailsNotInOneFolder';

      case ErrorCode.EmailSourceTypeIsUndefined:
        return 'errorEmailSourceTypeIsUndefined';

      case ErrorCode.ProviderFolderAlreadyBusy:
        return 'errorProviderFolderAlreadyBusy';

      case ErrorCode.FailedToDeterminateEmailDestinationFolder:
        return 'errorFailedToDeterminateEmailDestinationFolder';

      case ErrorCode.InvalidFolder:
        return 'errorInvalidFolder';

      case ErrorCode.BodyCachePathNotConfigured:
        return 'errorBodyCachePathNotConfigured';

      case ErrorCode.InvalidLenghtSubject:
        return 'errorInvalidLenghtSubject';

      case ErrorCode.InvalidToCcCount:
        return 'errorInvalidToCcCount';

      case ErrorCode.EmailsNotInOutbox:
        return 'errorEmailsNotInOutbox';

      case ErrorCode.PasswordIsIncorrect:
        return 'errorPasswordIsIncorrect';

      case ErrorCode.UserDontHaveMailAccounts:
        return 'errorUserDontHaveMailAccounts';

      case ErrorCode.MailAccountIdsListIsNull:
        return 'errorMailAccountIdsListIsNull';

      case ErrorCode.OrganizationIdIsNull:
        return 'errorOrganizationIdIsNull';

      case ErrorCode.AntiEnumerationConvert:
        return 'errorAntiEnumerationConvert';

      case ErrorCode.InsecureExtention:
        return 'errorInsecureExtention';

      case ErrorCode.UnknownFileType:
        return 'errorUnknownFileType';

      case ErrorCode.InvalidRoleForAccount:
        return 'errorInvalidRoleForAccount';

      case ErrorCode.OidcUpdateUserFailed:
        return 'errorFailedToUpdateOidcUser';

      case ErrorCode.NicknameNullExceptions:
        return 'errorNicknameCantBeNull';

      case ErrorCode.PasswordNullExceptions:
        return 'errorPasswordCantBeNull';

      case ErrorCode.UserWithIdentityExist:
        return 'errorUserWithThisIdentityExist';

      case ErrorCode.IdentityCantBeNullOrEmpty:
        return 'errorIdentityCantBeNullOrEmpty';

      case ErrorCode.TemplateAlreadyExists:
        return 'errorTemplateAlreadyExists';

      case ErrorCode.TemplateNotExists:
        return 'errorTemplateNotExists';

      case ErrorCode.MailFolderHasEmails:
        return 'errorMailFolderHasEmails';

      case ErrorCode.ReportEmailsExceedingLimit:
        return 'errorReportEmailsExceedingLimit';

      case ErrorCode.EmailFromToEmpty:
        return 'errorEmailFromToEmpty';

      case ErrorCode.EmailInvalidState:
        return 'errorEmailInvalidState';

      case ErrorCode.FilePathNotConfigured:
        return 'errorFilePathNotConfigured';

      case ErrorCode.MailAccountConfigurationNotCompleted:
        return 'errorAccountNotCompleted';

      case ErrorCode.MailAccountConnectionStatusSettingsNotExist:
        return 'errorConnectionNotExist';
    }
    return '';
  }
}
