import { FilterFields } from './FilterFields';
import { BaseCollectionSearchRequest } from './base-collection-search.request';

export class BaseCollectionFilteredSearchByIdRequest {
  organizationId: number;
  filter: FilterFields;
  page: number;
  pageSize: number;

  constructor(organizationId: number, page: number, pageSize: number, filter: FilterFields | null) {
    this.page = page;
    this.pageSize = pageSize;
    this.organizationId = organizationId;
    this.filter = filter;
  }
}
