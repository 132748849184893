import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiRequest } from '../../services/api/api.request';
import { ApiRoutes } from '../routes/api.routes';
import { BaseGetByFileIdRequest } from '../contracts/common/base-get-by-file-id.request';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class TempFileClient {
  constructor(private apiRequest: ApiRequest) {}

  async get(request: BaseGetByFileIdRequest): Promise<any> {
    return this.apiRequest.get<any>(`${API_URL}${ApiRoutes.tempFile.get}?${request.format()}`);
  }
  async getMeta(request: BaseGetByFileIdRequest): Promise<any> {
    return this.apiRequest.get<any>(`${API_URL}${ApiRoutes.tempFile.getMeta}?${request.format()}`);
  }
  async download(request: BaseGetByFileIdRequest): Promise<void> {
    const anchor: HTMLAnchorElement = document.createElement('a');
    anchor.href = `${API_URL}${ApiRoutes.tempFile.get}?${request.format()}`;
    anchor.click();
  }
}
