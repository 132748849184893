import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MailFolderCreateRequest, MailFolderUpdateRequest } from '../../../sdk/contracts/mail-folder/mail-folder.create.request';
import { MailFolderType } from '../../../sdk/contracts/mail-folder/mail-folder.type';
import { MailFolderClient } from '../../../sdk/clients/mail-folder.client';
import { MatchError } from 'src/app/services/errors/error-matcher';
import { BaseGetByIdRequest } from '../../../sdk/contracts/common/base-get-by-id.request';
import { RemoteClient } from '../../../sdk/clients/remote.client';
import { ErrorsPipe } from '../../../shared/pipes/error-code.pipe';
import { LoaderComponent } from '../../common/loader/loader.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { LoadingButtonComponent } from '../../common/loading-button/loading-button.component';
import { SomethingWentWrongComponent } from '../../common/error/something-went-wrong/something-went-wrong.component';
import { MailCreationType } from '../../../sdk/contracts/mail-folder/mail-folder-creation.type';
import { DrawerService } from '../../../services/drawer.service';

@Component({
  selector: 'app-mail-folder-create-dialog',
  standalone: true,
  imports: [
    SomethingWentWrongComponent,
    LoaderComponent,
    TranslateModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    MatSelectModule,
    MatButtonModule,
    LoadingButtonComponent,
  ],
  templateUrl: './mail-folder-create-dialog.component.html',
  styleUrls: ['./mail-folder-create-dialog.component.scss'],
})
export class MailFolderCreateDialogComponent implements OnInit {
  @Input() mailFolderId: number;
  @Input() mailFolderCreationType: MailCreationType;
  @Input() mailFolderName: string;
  @Input() providerFolderName: string | null = null;
  @Input() parentMailFolderId: number | null;
  @Input() parentMailFolderType: number;
  @Input() mailAccountId: number;
  @Input() isSettings: boolean;
  @Output() public creationSubmit = new EventEmitter();

  public form: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required, Validators.maxLength(256)]),
  });
  public isLoading = false;
  public errorText: string;
  public mailCreationType = MailCreationType;
  public loadingAvailableData = false;
  public cannotLoadAvailableData = false;
  public providerFolders: any[] = null;
  public selectedProviderFolder: string | null = null;

  constructor(
    private mailFolderClient: MailFolderClient,
    public matchError: MatchError,
    private remoteClient: RemoteClient,
    private errorsPipe: ErrorsPipe,
    private drawerService: DrawerService
  ) {}

  async ngOnInit(): Promise<any> {
    this.mailFolderName && (this.form = new UntypedFormGroup({
      name: new UntypedFormControl(this.mailFolderName, [Validators.required, Validators.maxLength(256)]),
    }));
    if ((this.parentMailFolderType === 2 || this.parentMailFolderType === 3) && this.isSettings) {
      await this.loadData();
      this.providerFolderName && (this.selectedProviderFolder = this.providerFolderName);
    }
  }
  async loadData(): Promise<any> {
    this.errorText = '';
    this.loadingAvailableData = true;
    this.cannotLoadAvailableData = false;
    try {
      const response = await this.remoteClient.fetchRemoteFolders(new BaseGetByIdRequest(this.mailAccountId));
      this.providerFolders = response.data.map((e) => e.name);
      this.mailFolderName && this.providerFolders.unshift({ name: '--Clear--' });
    } catch (e) {
      this.errorText = this.errorsPipe.transform(e.Code) || `Error code: ${e.Code}: ${e.Description || 'something went wrong'}`;
      this.cannotLoadAvailableData = true;
      this.matchError.logError(e);
    } finally {
      this.loadingAvailableData = false;
    }
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  }

  public async onSubmit(formValue: any): Promise<void> {
    if (this.form.valid) {
      this.isLoading = true;
      this.drawerService.disabledDrawer(true);
      try {
        const request = {
          name: formValue.name,
          folderType: MailFolderType.Custom,
          providerFolderName: this.selectedProviderFolder
        };
        this.mailAccountId && ((request as MailFolderCreateRequest).mailAccountId = this.mailAccountId);
        this.mailFolderId && ((request as MailFolderUpdateRequest).mailFolderId =  this.mailFolderId);
        this.parentMailFolderId && ((request as MailFolderUpdateRequest).parentId = this.parentMailFolderId);

        this.mailFolderId
          ? await this.mailFolderClient.update(request as MailFolderUpdateRequest)
          : await this.mailFolderClient.create(request as MailFolderCreateRequest);
        this.creationSubmit.emit();
        this.drawerService.disabledDrawer(false);
        this.drawerService.closeDrawer();
      } catch (e) {
        this.errorText = this.errorsPipe.transform(e.Code) || `Error code: ${e.Code}: ${e.Description || 'something went wrong'}`;
        this.matchError.errorHandler(e);
        this.matchError.logError(e);
        this.drawerService.disabledDrawer(false);
      } finally {
        this.isLoading = false;
      }
    }
  }
}
