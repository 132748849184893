import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiRequest } from '../../services/api/api.request';
import { UserMailAccountCreateRequest } from '../contracts/user-mail-account/user-mail-account.create.request';
import { UserMailAccountAssignRoleRequest } from '../contracts/user-mail-account/user-mail-account.assign-role.request';
import { UserMailAccountGetListResponse } from '../contracts/user-mail-account/user-mail-account.get-list.response';
import { UserMailAccountCreateResponse } from '../contracts/user-mail-account/user-mail-account.create.response';
import { UserMailAccountGetRolesResponse } from '../contracts/user-mail-account/user-mail-account.get-roles.response';
import { UserMailAccountContract } from '../contracts/user-mail-account/user-mail-account.contract';
import { ApiRoutes } from '../routes/api.routes';
import { BaseDeleteResponse } from '../contracts/common/base-delete.response';
import { BaseGetByIdResponse } from '../contracts/common/base-get-by-id.response';
import { BaseDeleteRequest } from '../contracts/common/base-delete.request';
import { BaseGetByIdRequest } from '../contracts/common/base-get-by-id.request';
import { BaseCollectionSearchByIdRequest } from '../contracts/common/base-collection-search-by-id.request';
import { BaseValueResponse } from '../contracts/common/base-value-response';
import { BaseGetSearchCountByIdRequest } from '../contracts/common/base-get-search-count-by-id.request';
import { BaseGetCollectionResponse } from '../contracts/common/base-get-collection.response';
import { UserContract } from '../contracts/user/user.contract';
import { UserMailAccountRequest } from '../contracts/user-mail-account/user-mail-account.request';
import { UserMailAccountGetListRequest } from '../contracts/user-mail-account/user-mail-account.get-list.request';
import { MailAccountUserRequest } from '../contracts/user-mail-account/mail-account-user.request';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class UserMailAccountClient {
  constructor(private apiRequest: ApiRequest) {}

  public async setUserMailAccounts(request: UserMailAccountRequest) {
    return this.apiRequest.post<UserMailAccountRequest, any>(request, `${API_URL}${ApiRoutes.userMailAccount.setUserMailAccounts}`);
  }

  public async setMailAccountUsers(request: MailAccountUserRequest) {
    return this.apiRequest.post<MailAccountUserRequest, any>(request, `${API_URL}${ApiRoutes.userMailAccount.setMailAccountUsers}`);
  }

  async addMailAccountUser(request: UserMailAccountCreateRequest): Promise<UserMailAccountCreateResponse> {
    return this.apiRequest.post<UserMailAccountCreateRequest, UserMailAccountCreateResponse>(
      request,
      `${API_URL}${ApiRoutes.userMailAccount.addMailAccountUser}`
    );
  }

  async deleteMailAccountUser(request: BaseDeleteRequest): Promise<BaseDeleteResponse> {
    return this.apiRequest.post<BaseDeleteRequest, BaseDeleteResponse>(
      request,
      `${API_URL}${ApiRoutes.userMailAccount.deleteMailAccountUser}`
    );
  }

  async assignRole(request: UserMailAccountAssignRoleRequest): Promise<void> {
    return this.apiRequest.post<UserMailAccountAssignRoleRequest, void>(request, `${API_URL}${ApiRoutes.userMailAccount.assignRole}`);
  }

  async getMailAccountUsers(request: BaseGetByIdRequest): Promise<UserMailAccountGetListResponse> {
    return this.apiRequest.get<UserMailAccountGetListResponse>(
      `${API_URL}${ApiRoutes.userMailAccount.getMailAccountUsers}?${request.format()}`
    );
  }

  async getUserAccountsById(request: UserMailAccountGetListRequest): Promise<UserMailAccountGetListResponse> {
    return this.apiRequest.get<UserMailAccountGetListResponse>(
      `${API_URL}${ApiRoutes.userMailAccount.getUserAccountsById}?${request.format()}`
    );
  }

  async getUserMailAccountRoles(): Promise<UserMailAccountGetRolesResponse> {
    return this.apiRequest.get<UserMailAccountGetRolesResponse>(`${API_URL}${ApiRoutes.userMailAccount.getUserMailAccountRoles}`);
  }

  async getAvailableUsers(request: BaseGetByIdRequest): Promise<BaseGetCollectionResponse<UserContract>> {
    return this.apiRequest.get<BaseGetCollectionResponse<UserContract>>(
      `${API_URL}${ApiRoutes.userOrganization.getAvailableUsers}?${request.format()}`
    );
  }

  async getById(request: BaseGetByIdRequest): Promise<BaseGetByIdResponse<UserMailAccountContract>> {
    return this.apiRequest.get<BaseGetByIdResponse<UserMailAccountContract>>(
      `${API_URL}${ApiRoutes.userMailAccount.get}?${request.format()}`
    );
  }

  async getLoggedUserAccounts(): Promise<UserMailAccountGetListResponse> {
    return this.apiRequest.get<UserMailAccountGetListResponse>(`${API_URL}${ApiRoutes.userMailAccount.getLoggedUserAccounts}`);
  }

  async searchMailAccountUsers(request: BaseCollectionSearchByIdRequest): Promise<BaseGetCollectionResponse<UserMailAccountContract>> {
    return this.apiRequest.get<BaseGetCollectionResponse<UserMailAccountContract>>(
      `${API_URL}${ApiRoutes.userMailAccount.searchMailAccountUsers}?${request.format()}`
    );
  }

  async getSearchMailAccountUsersCount(request: BaseGetSearchCountByIdRequest): Promise<BaseValueResponse<number>> {
    return this.apiRequest.get<BaseValueResponse<number>>(
      `${API_URL}${ApiRoutes.userMailAccount.getSearchMailAccountUsersCount}?${request.format()}`
    );
  }
}
