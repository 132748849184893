export enum EmailType {
  Email = 1,
  NonAcceptance = 2,
  ServerUserAcceptance = 3,
  DeliveryErrorAdvanceNotice = 4,
  ServerServerAcceptance = 5,
  VirusDetection = 6,
  MessageDelivered = 8,
  DeliveryError = 9,
}
