import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor() {}

  private get(key: string): any {
    const data = localStorage.getItem(key);
    try {
      return !!data && JSON.parse(data);
    } catch (e) {
      return !!data && data;
    }
  }

  private set(key: string, value: any): void {
    const stringValue = typeof value === 'string' ? value : JSON.stringify(value);
    localStorage.setItem(key, stringValue);
  }

  private remove(key: string): void {
    localStorage.removeItem(key);
  }

  getData(key: string): any {
    return this.get(key);
  }

  setData(key: string, value: any): any {
    this.set(key, value);
  }

  removeData(key: string): any {
    this.remove(key);
  }
}
