import { ObjectTypeString } from '../contracts/common';

export class ApiRoutes {
  public static authentication: ObjectTypeString = {
    signIn: 'authentication/signin',
    renew: 'authentication/renew',
    signUp: 'authentication/signup',
    signOut: 'authentication/signout',
    signOutAll: 'authentication/signoutall',
    addGoogleIdentity: 'authentication/addgoogleidentity',
    addAdfsIdentity: 'authentication/addadfsidentity',
    getAdfsRedirectUrl: 'authentication/getadfsredirecturl',
    getAuthenticationTypes: 'authentication/getauthenticationtypes',
  };

  public static contact: ObjectTypeString = {
    create: 'contact/create',
    update: 'contact/update',
    delete: 'contact/delete',
    get: 'contact/get',
    getForAccount: 'contact/getforaccount',
    deleteForAccount: 'contact/deleteforaccount',
    search: 'contact/search',
  };

  public static attachment: ObjectTypeString = {
    get: 'attachment/getlistbyemailid',
  };

  public static contactGroup: ObjectTypeString = {
    create: 'contactgroup/create',
    update: 'contactgroup/update',
    delete: 'contactgroup/delete',
    get: 'contactgroup/get',
    getForAccount: 'contactgroup/getforaccount',
    deleteForAccount: 'contactgroup/deleteforaccount',
    addContactsToGroup: 'contactgroup/addcontactstogroup',
    removeContactsFromGroup: 'contactgroup/removecontactsfromgroup',
    getGroupContacts: 'contactgroup/getgroupcontacts',
    removeContactsFromAllGroups: 'contactgroup/removecontactsfromallgroups',
  };

  public static claim: ObjectTypeString = {
    getForAccount: 'claim/getforaccount',
    createOrUpdate: 'claim/createorupdate',
    getRtaClaimByOrganizationId: 'claim/getrtaclaimbyorganizationid',
    createOrUpdateRtaClaimByOrganizationId: 'claim/createorupdatertaclaimfororganization',
  };

  public static email: ObjectTypeString = {
    search: 'email/search',
    searchByWidget: 'email/searchbywidget',
    get: 'email/getbyid',
    getNotifications: 'email/getnotifications',
    createDraft: 'email/createdraft',
    update: 'email/update',
    markForSend: 'email/markforsend',
    reply: 'email/reply',
    replyAll: 'email/replyall',
    forward: 'email/forward',
    forwardMultiple: 'email/forwardmultiple',
    delete: 'email/delete',
    finalDelete: 'email/finaldelete',
    restore: 'email/restore',
    setIsSpam: 'email/setisspam',
    setNotSpam: 'email/setnotspam',
    setSeen: 'email/setseen',
    move: 'email/move',
    reassign: 'email/reassign',
    setValidation: 'email/setvalidation',
    readyToValidate: 'email/readytovalidate',
    validationApprove: 'email/validationapprove',
    validationReject: 'email/validationreject',
    download: 'email/download',
    singleDownload: 'email/singledownload',
    archive: 'email/archive',
    restoreFromArchive: 'email/restoreFromArchive',
    retrySend: 'email/retrysend',
    moveToDraft: 'email/movetodraft',
  };

  public static emailTag: ObjectTypeString = {
    saveTags: 'emailtag/savetags',
    getEmailTags: 'emailtag/getemailtags',
  };

  public static ferreroPlugin: ObjectTypeString = {
    create: 'ferreroplugin/create',
  };

  public static group: ObjectTypeString = {
    getGroupsForOrganization: 'group/getfororganization',
    create: 'group/create',
    update: 'group/update',
    delete: 'group/delete',
  };

  public static emailGroup: ObjectTypeString = {
    addEmailsToGroups: 'emailgroup/addemailstogroups',
    removeEmailsFromGroups: 'emailgroup/removeemailsfromgroups',
    getGroupsForEmails: 'emailgroup/getforemails',
  };

  public static emailAudit: ObjectTypeString = {
    getByEmail: 'emailaudit/getbyemail',
  };

  public static emailExport: ObjectTypeString = {
    exportExcel: 'emailexport/exportexcel',
  };

  public static emailNote: ObjectTypeString = {
    get: 'emailnote/get',
    getForEmail: 'emailnote/getforemail',
    create: 'emailnote/create',
    update: 'emailnote/update',
    delete: 'emailnote/delete',
  };

  public static emailFile: ObjectTypeString = {
    create: 'emailfile/create',
    get: 'emailfile/get',
    getMeta: 'emailfile/getmeta',
    multipleCreate: 'emailfile/multiple-create',
  };

  public static tempFile: ObjectTypeString = {
    get: 'tempfile/get',
    getMeta: 'tempfile/getmeta',
  };

  public static groupSettings: ObjectTypeString = {
    getForOrganization: 'group/getfororganization',
    create: 'group/create',
    update: 'group/update',
    delete: 'group/delete',
    getSearchCounter: 'group/getsearchcounter',
  };

  public static importSession: ObjectTypeString = {
    create: 'importsession/create',
    getByOrganizationId: 'importsession/getbyorganizationid',
  };

  public static importSessionResource: ObjectTypeString = {
    getByImportSessionId: 'importsessionresource/getbyimportsessionid',
  };

  public static logging: ObjectTypeString = {
    create: 'log/create',
  };

  public static mailAccount: ObjectTypeString = {
    getAll: 'mailaccount/getall',
    getForReassign: 'mailaccount/getforreassign',
    get: 'mailaccount/get',
    getByOrganizationId: 'mailaccount/getbyorganizationid',
    create: 'mailaccount/create',
    update: 'mailaccount/update',
    updatePassword: 'mailaccount/updatepassword',
    delete: 'mailaccount/delete',
    getSearchCount: 'mailaccount/search/getcount',
    getSearch: 'mailaccount/search',
    getOrganizationMailAccounts: 'mailaccount/getorganizationmailaccounts',
    getMailAccountsBadges: 'mailaccount/getmailaccountsbadges',
  };

  public static mailAccountSettings: ObjectTypeString = {
    getByMailAccount: 'mailaccountsettings/getbymailaccount',
    update: 'mailaccountsettings/createorupdate',
  };

  public static mailAccountSimpleConfiguration: ObjectTypeString = {
    canSetup: 'mailaccountsimpleconfiguration/cansetup',
    create: 'mailaccountsimpleconfiguration/create',
  };

  public static dispatchingRule: ObjectTypeString = {
    getForAccount: 'dispatchingrule/getforaccount',
    searchForAccount: 'dispatchingrule/seatchforaccount',
    getSearchForAccountCount: 'dispatchingrule/getseatchforaccountcount',
    getAvailableDataForAccount: 'dispatchingrule/getavailabledataforaccount',
    get: 'dispatchingrule/get',
    create: 'dispatchingrule/create',
    update: 'dispatchingrule/update',
    delete: 'dispatchingrule/delete',
  };

  public static mailFolder: ObjectTypeString = {
    get: 'mailfolder/get',
    getForReassign: 'mailfolder/getforreassign',
    getAll: 'mailfolder/getall',
    create: 'mailfolder/create',
    update: 'mailfolder/update',
    delete: 'mailfolder/delete',
    getForAccount: 'mailfolder/getforaccount',
    getForMailAccountFlat: 'mailfolder/getforaccountflat',
    getDefault: 'mailfolder/getdefault',
    getUnreadCount: 'mailfolder/getunreadcount',
    getFolderUnreadCount: 'mailfolder/getfolderunreadcount',
    getUserFolders: 'mailfolder/getuserfolders',
  };

  public static mailFolderSettings: ObjectTypeString = {
    getByMailFolder: 'mailfoldersettings/getbymailfolder',
    createOrUpdate: 'mailfoldersettings/createorupdate',
  };

  public static massiveSend: ObjectTypeString = {
    getForMailAccount: 'massivesend/getformailaccount',
    status: 'massivesend/status',
    startXlsx: 'massivesend/startxlsx',
  };

  public static organization: ObjectTypeString = {
    get: 'organization/get',
    search: 'organization/search',
    getSearchCount: 'organization/search/getcount',
    create: 'organization/create',
    update: 'organization/update',
    delete: 'organization/delete',
    getOrganizationName: 'organization/getorganizationname',
    getAvailableParentOrganizations: 'organization/getavailableparentorganizations',
  };

  public static organizationSettings: ObjectTypeString = {
    getByOrganization: 'organizationsettings/getbyorganization',
    createOrUpdate: 'organizationsettings/createorupdate',
    getOrganizationsWithAdfs: 'organizationsettings/getOrganizationswithadfs',
  };

  public static password: ObjectTypeString = {
    update: 'password/update',
  };

  public static permission: ObjectTypeString = {
    get: 'permission/get',
  };

  public static provider: ObjectTypeString = {
    getAll: 'provider/getall',
    getByOrganization: 'provider/getbyorganization',
    search: 'provider/search',
    get: 'provider/get',
    create: 'provider/create',
    update: 'provider/update',
    delete: 'provider/delete',
  };

  public static remote: ObjectTypeString = {
    fetchRemoteFolders: 'remote/fetchremotefolders',
  };

  public static report: ObjectTypeString = {
    exportExcel: 'report/exportexcel',
  };

  public static searchWidget: ObjectTypeString = {
    createOrUpdate: 'searchwidget/createorupdate',
    get: 'searchwidget/get',
    getAll: 'searchwidget/getAll',
    delete: 'searchwidget/delete',
  };

  public static statistic: ObjectTypeString = {
    organization: 'statistic/organization',
  };

  public static tag: ObjectTypeString = {
    getForMailAccount: 'tag/getformailaccount',
    getForEmail: 'tag/getforemail',
    getList: 'tag/getlist',
    getAll: 'tag/getall',
    searchForMailAccount: 'tag/searchformailaccount',
    getSearchCountForMailAccount: 'tag/getsearchcountformailaccount',
    insert: 'tag/insert',
    delete: 'tag/delete',
  };

  public static template: ObjectTypeString = {
    getForMailAccount: 'template/getformailaccount',
    searchForMailAccount: 'template/searchformailaccount',
    getSearchCountForMailAccount: 'template/getsearchcountformailaccount',
    create: 'template/create',
    update: 'template/update',
    getById: 'template/getbyid',
    delete: 'template/delete',
  };

  public static userIdentity: ObjectTypeString = {
    getForUser: 'useridentity/getforuser',
  };

  public static userMailAccount: ObjectTypeString = {
    addMailAccountUser: 'usermailaccount/addmailaccountuser',
    deleteMailAccountUser: 'usermailaccount/deletemailaccountuser',
    assignRole: 'usermailaccount/assignrole',
    getUserAccountsById: 'usermailaccount/getuseraccountsbyid',
    getMailAccountUsers: 'usermailaccount/getmailaccountusers',
    getUserMailAccountRoles: 'usermailaccount/getusermailaccountroles',
    getUsers: 'usermailaccount/getusers',
    get: 'usermailaccount/get',
    getLoggedUserAccounts: 'usermailaccount/getloggeduseraccounts',
    searchMailAccountUsers: 'usermailaccount/searchmailaccountusers',
    getSearchMailAccountUsersCount: 'usermailaccount/getsearchmailaccountuserscount',
    setUserMailAccounts: 'usermailaccount/setusersmailaccounts',
    setMailAccountUsers: 'usermailaccount/setmailaccountsusers',
  };

  public static userOrganization: ObjectTypeString = {
    getOrganizationUsers: 'userorganization/getorganizationusers',
    getUserOrganizations: 'userorganization/getuserorganizations',
    deleteUserFromOrganization: 'userorganization/deleteuserfromorganization',
    search: 'userorganization/search',
    getSearchCount: 'userorganization/getsearchcount',
    addUserToOrganization: 'userorganization/addusertoorganization',
    updateUser: 'userorganization/update',
    changeRole: 'userorganization/changerole',
    changeState: 'userorganization/changestate',
    getAvailableUsers: 'usermailaccount/getavailableusers',
    getUserIdentityTypes: 'useridentity/getuseridentitybyuserid',
    getIdentityOidcByUserIdentityId: 'useridentity/getidentityoidcbyuseridentityid',
  };

  public static fe: ObjectTypeString = {
    getUserOrganizations: 'getuserorganizations',
  };

  public static user: ObjectTypeString = {
    getUser: 'user/getuser',
    update: 'user/update',
  };

  public static userGroup: ObjectTypeString = {
    setUsersGroups: 'usergroup/setusersgroups',
    setGroupsUsers: 'usergroup/setgroupsusers',
    getUserGroups: 'usergroup/getusergroups',
    getGroupUsers: 'usergroup/getgroupusers',
  };

  public static importEML: ObjectTypeString = {
    upload: 'importeml/upload',
    import: 'importeml/import',
  };
}
