export enum ErrorCode {
  ValidationError = 1,
  ArgumentError = 2,
  PasswordMismatch = 3,
  EntityDoesNotExists = 4,
  NullableRequestError = 5,
  AwsFilePutError = 6,
  AwsFileGetError = 7,
  AwsClientConfigError = 8,
  BadLoginError = 9,
  UserAlreadyExists = 10,
  UserIdentityAlreadyExists = 11,
  OrganizationAlreadyHasUser = 14,
  InvalidJwtToken = 16,
  AccessError = 17,
  ImapWrongPassword = 18,
  ImapCantConnectToServer = 19,
  DeleteError = 20,
  EmailSendingError = 21,
  WeakPassword = 22,
  UserDontHaveIdentity = 23,
  UserDoesNotExist = 24,
  ProviderEntityAlreadyUse = 25,
  OrganizationHasMailAccounts = 27,
  OrganizationHasUsers = 28,
  InvalidHierarchy = 29,
  CannotDeleteDefaultMailFolder = 30,
  MailFolderHasSubfolders = 31,
  CannotUpdateCommonMailFolder = 32,
  DispatchingActionNotExists = 33,
  UpdateError = 34,
  DispatchingRuleFoldersEmpty = 35,
  DispatchingRuleActionsEmpty = 36,
  DispatchingRuleActionNameEmpty = 37,
  UnknownDispatchingCondition = 38,
  MailAccountDontExist = 39,
  DispatchingRuleObjectIsNull = 40,
  DispatchingActionArgumentError = 41,
  EmlBuildError = 42,
  DispatchingActionNotProvided = 43,
  RemoteFolderNotProvided = 44,
  EmailFieldsNotFetched = 45,
  AttachmentFileNotExist = 46,
  SendEmailSmtpError = 47,
  MailAccountWithEmailExist = 48,
  SpecialMailFolderNotExist = 49,
  EmailWasReceivedByOtherProcess = 50,
  FilePathIsNull = 51,
  EmailAlreadyDeleted = 52,
  EmailNotDeleted = 53,
  EmailNotExist = 54,
  EmailNoteNotExist = 55,
  EmailNoteUsersNotEquals = 56,
  EmlForEmailNotProvided = 57,
  EmlForEmailNotFound = 58,
  MailAccountSettingsAlreadyExist = 59,
  MailAccountSettingsNotExist = 60,
  MailFolderBelongsToDifferentMailAccount = 61,
  MailFolderBelongsToDifferentOrganization = 62,
  EmailBelongsToDifferentOrganization = 63,
  MailFolderDontExist = 64,
  OrganizationNotEnabledForArchive = 65,
  OrganizationDontExist = 66,
  ArchivePathNotConfigured = 67,
  ArchivePathCreationError = 68,
  FileStreamEmpty = 69,
  TempPathNotConfigured = 70,
  TempPathCreationError = 71,
  ContactCreateError = 72,
  ContactUpdateError = 73,
  ContactGroupCreateError = 74,
  ContactGroupUpdateError = 75,
  ContactNotExist = 76,
  ContactGroupNotExist = 77,
  MissingCredentials = 78,
  MassiveSendSessionNotCreated = 79,
  MassiveSendFileNotValid = 80,
  MassiveSendSessionNotFound = 81,
  MailFolderNotEnabledForValidation = 82,
  MailFolderNotEnabledForReassign = 83,
  ClientIdIsNull = 84,
  TempFileNotFound = 85,
  OAuthOptionsNotProvided = 86,
  OAuthInvalidCode = 87,
  GoogleUserNotFound = 88,
  GoogleAccountNotVerified = 89,
  GoogleAccountAlreadyLinked = 90,
  UnknownIdentityType = 91,
  OAuthAccountLinkedToOtherUserIdentity = 92,
  PermissionDenied = 93,
  EmailBelongsToDifferentMailAccount = 94,
  PasswordRestoreCodeInvalid = 95,
  PasswordRestoreCodeAlreadyActivated = 96,
  PasswordRestoreCodeExpired = 97,
  OldPasswordIsIncorrect = 98,
  SearchWidgetNotExists = 99,
  SearchWidgetFilterIsNull = 100,
  EmailDownloadError = 101,
  ImailBuildError = 102,
  CaptchaIsInvalid = 103,
  MailAccountCantBeSimpleSetup = 104,
  RemoteFolderTypeNotAllowedForSimpleConfiguration = 105,
  PasswordExpired = 106,
  PasswordNotExpired = 107,
  ImportWrongJson = 108,
  ImportFileNotFoundInArchive = 109,
  ImportOrganizationNotFound = 110,
  ImportProviderNotFound = 111,
  ImportBlankUserEmailAddress = 112,
  ImportUserNotExist = 113,
  ImportRoleNotExists = 114,
  ImportEmptyUserName = 115,
  ImportInboxNotFound = 116,
  ImportFolderNotFound = 117,
  ImportCantParseJson = 118,
  ImportEmptyOrganizationCode = 119,
  ImportEmptyUserList = 120,
  ImportEmptyAccountCode = 121,
  ImportEmptyAccountEmail = 122,
  ImportEmptyProviderCode = 123,
  ImportAccountSettingsIsNull = 124,
  ImportFolderListIsNull = 125,
  ImportAccountNotExists = 126,
  ImportNoEmailsForImport = 127,
  ImportEmailTypeIsNull = 128,
  ImportEmailStateIsNull = 129,
  ImportEmailValidationStatusIsNull = 130,
  ImportEmailArchiveSessionNotFound = 131,
  ImportEmailReassignAccountIdNotFound = 132,
  ImportEmailHistoryUserNotExists = 133,
  ImportEmailNoteUserNotExists = 134,
  CannotUpdateEmailAlreadySending = 135,
  EmailQueueNotExist = 136,
  EmailQueueIterationNotExist = 137,
  UserAlreadyBeenAddedToMailAccount = 138,
  CannotChangeTypeForOrganizationWithChildren = 139,
  CannotSetParentForParentOrganization = 140,
  CannotDeleteOrganizationWithChildren = 141,
  TagAlreadyExists = 142,
  TagIsUsed = 143,
  WebCodeAlreadyUsed = 144,
  LicenseIdAndCodeSiaAlreadyUsed = 145,
  UserNotInOrganization = 146,
  OrganizationHasNotSettings = 147,
  CannotDeleteRtaFromOrganization = 148,
  OidcCreateUserFailed = 149,
  GroupCreateError = 150,
  GroupAlreadyExists = 151,
  UnknownFolderType = 152,
  EmailEmptyFolder = 153,
  EmailMoveOperationNotValid = 154,
  EmailAlreadyReassigned = 155,
  EmailsNotInOneFolder = 156,
  EmailSourceTypeIsUndefined = 157,
  ProviderFolderAlreadyBusy = 158,
  FailedToDeterminateEmailDestinationFolder = 159,
  InvalidFolder = 160,
  BodyCachePathNotConfigured = 161,
  InvalidLenghtSubject = 162,
  InvalidToCcCount = 163,
  EmailsNotInOutbox = 164,
  PasswordIsIncorrect = 165,
  UserDontHaveMailAccounts = 166,
  MailAccountIdsListIsNull = 167,
  OrganizationIdIsNull = 168,
  AntiEnumerationConvert = 169,
  InsecureExtention = 170,
  UnknownFileType = 171,
  InvalidRoleForAccount = 172,
  OidcUpdateUserFailed = 173,
  NicknameNullExceptions = 174,
  PasswordNullExceptions = 175,
  UserWithIdentityExist = 176,
  IdentityCantBeNullOrEmpty = 177,
  TemplateAlreadyExists = 179,
  TemplateNotExists = 180,
  ExtractFileError = 186,
  MailFolderHasEmails = 203,
  ReportEmailsExceedingLimit = 204,
  EmailExistsByHash = 206,
  EmailFromToEmpty = 207,
  EmailInvalidState = 209,
  FilePathNotConfigured = 212,
  MailAccountConfigurationNotCompleted = 214,
  MailAccountConnectionStatusSettingsNotExist = 215,
  ImportEmlTempPathNotConfigured = 217,
  UnhandledError = 218,
}
