import { Injectable, TemplateRef } from '@angular/core';
import { DrawerComponent } from '../components/common/drawer/drawer.component';

@Injectable({
  providedIn: 'root',
})
export class DrawerService {
  private drawerComponent!: DrawerComponent;

  setDrawerComponent(drawer: DrawerComponent): void {
    this.drawerComponent = drawer;
  }

  openDrawer(template: TemplateRef<any>): void {
    this.drawerComponent?.openDrawer(template);
  }

  closeDrawer(): void {
    this.drawerComponent?.closeDrawer();
  }

  disabledDrawer(isDisable: boolean): void {
    this.drawerComponent?.disableCloseDrawer(isDisable);
  }
}
