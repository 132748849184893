import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDrawer, MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { PermissionService } from '../../services/permission/permission.service';
import { PermissionType } from '../../sdk/contracts/permission/permission-type';
import { UserOrganizationClient } from '../../sdk/clients/user-organization.client';
import { UserClient } from '../../sdk/clients/user.client';
import { UserContract } from '../../sdk/contracts/user/user.contract';
import { environment } from '../../../environments/environment';
import { OrganizationContract } from '../../sdk/contracts/organization/organization.contract';
import { ApiRequest } from 'src/app/services/api/api.request';
import { MatchError } from 'src/app/services/errors/error-matcher';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ListOfPossibleLanguages } from 'src/app/shared/list-of-possible-languages';
import { SpaceUsedPaymentPlanDetailTypes } from 'src/app/sdk/contracts/organization/settings/spaceUsedPaymentPlanTypes';
import { SettingsService } from 'src/app/services/settings/settings.service';
import { MainHeaderComponent } from '../main-header/main-header.component';
import { LoaderComponent } from '../common/loader/loader.component';
import { EMAIL_QUERY_PARAM, STORAGE_NAMES, USER_ROLE, WEB_CODE } from '../../shared/constants/constants';
import { LocalStorageService } from '../../services/local-storage.service';
import { Subscription } from 'rxjs';
import { MobileObserverService } from '../../services/adaptive/mobile-observer.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { delay } from 'rxjs/operators';
import { NavSidenavComponent } from '../nav-sidenav/nav-sidenav.component';
import { SpaceInfo } from '../../sdk/contracts/common';
import { EmailsSideNavComponent } from '../emails-sidenav/emails-side-nav.component';
import { MailFolderClient } from '../../sdk/clients/mail-folder.client';
import { UserFolderContract } from '../../sdk/contracts/mail-folder/user-folder.contract';
import { UserMailAccountClient } from '../../sdk/clients/user-mail-account.client';
import { UserMailAccountContract } from '../../sdk/contracts/user-mail-account/user-mail-account.contract';
import { MailFolderType } from '../../sdk/contracts/mail-folder/mail-folder.type';
import { encodeInfoMailAccount } from '../../shared/utils/search-params';
import { getFolderByType } from '../../shared/utils/common';
import { DrawerComponent } from '../common/drawer/drawer.component';
import { DrawerService } from '../../services/drawer.service';

const { RTA, ROOT, USER } = USER_ROLE;
const { USER_LOCALE } = STORAGE_NAMES;

@Component({
  selector: 'app-main',
  standalone: true,
  imports: [
    RouterOutlet,
    MatSidenavModule,
    MainHeaderComponent,
    TranslateModule,
    LoaderComponent,
    NavSidenavComponent,
    EmailsSideNavComponent,
    DrawerComponent,
  ],
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('sidenav') public sideNav: MatSidenav;
  @ViewChild(MatDrawer) matDriver: MatDrawer;
  @ViewChild(DrawerComponent)
  set drawer(drawer: DrawerComponent) {
    this.drawerService.setDrawerComponent(drawer);
  }

  public isLoading = false;
  public isLoadingPage = true;
  public isError = false;

  public settingRoute: string | null = null;
  public webCode: string;
  public user: UserContract;
  public userRole: string;
  public organizationName: string;
  public userOrganisaions: OrganizationContract[] = [];
  public spaceInfo: SpaceInfo = {
    maxAvailableSpaceUsed: 0,
    spaceUsed: 0,
    percentage: 0,
    alertPercentage: 90,
  };
  public isOpenLeftMenu = false;
  public visibleFullScreen = false;

  private useMobileViewSubscription: Subscription;

  public userFolders: UserFolderContract[] = [];

  public getMailAccountsError: string;
  public mailAccounts: UserMailAccountContract[] = [];

  constructor(
    public matchError: MatchError,
    private router: Router,
    private permissionService: PermissionService,
    private userOrganizationClient: UserOrganizationClient,
    private route: ActivatedRoute,
    private userClient: UserClient,
    private apiRequest: ApiRequest,
    public translate: TranslateService,
    private settings: SettingsService,
    private localStorageService: LocalStorageService,
    private mobileObserverService: MobileObserverService,
    private observer: BreakpointObserver,
    private mailFolderClient: MailFolderClient,
    private userMailAccountClient: UserMailAccountClient,
    private drawerService: DrawerService
  ) {}

  async ngOnInit(): Promise<void> {
    this.webCode = this.route.parent.snapshot.paramMap.get(WEB_CODE);

    const lastEndpoints = window.location.pathname
      .split('/').slice(-2);

    const isEmailUrl = window.location.pathname
      .split('/')
      .filter((e, i, arr) => i === arr.length - 1);

    /* TODO: check.*/
    this.settings.settingsAlertLevel$.subscribe((value) => {
      this.spaceInfo.alertPercentage = value;
    });

    this.useMobileViewSubscription = this.mobileObserverService.mobileObserver().subscribe(
      (isMobile) => (this.isOpenLeftMenu = isMobile)
    );
    await this.loadFolders();
    await this.getMailAccounts();

    await this.loadData();

    if (isEmailUrl[0] === this.webCode) {
      if (this.userRole === RTA || this.userRole === ROOT) {
        this.router.navigateByUrl(this.settingRoute);
        this.isOpenLeftMenu = false;
      } else {
        const infoMailAcc = this.getInfoMailAccount();
        this.router.navigate([this.webCode,  'emails'], { queryParams: infoMailAcc ? {
          [EMAIL_QUERY_PARAM]: infoMailAcc
        } : {} });
        this.isOpenLeftMenu = true;
      }
    }

    if (lastEndpoints[0] === this.webCode && lastEndpoints[1] === 'emails') {
      if (!window.location.search) {
        const infoMailAcc = this.getInfoMailAccount();
        this.router.navigate([this.webCode,  'emails'], { queryParams: infoMailAcc ? {
            [EMAIL_QUERY_PARAM]: infoMailAcc
          } : {} });
      }
      this.isOpenLeftMenu = true;
    }

    /* TODO: check.*/
    this.settings.settingsMaxSpace$.subscribe((value) => {
      this.setSpaceInfo(value, this.spaceInfo.spaceUsed);
    });

    this.isLoadingPage = false;
  }

  public onEmailsClick(): void {
    this.sideNav?.toggle();
    this.matDriver?.open();
  }

  getInfoMailAccount(): string {
    const folder = getFolderByType(
      this.userFolders,
      this.mailAccounts[0].mailAccountId,
      MailFolderType.Inbox
    );
    return encodeInfoMailAccount(this.mailAccounts[0].mailAccountId, folder);
  }

  public async loadData(): Promise<void> {
    !this.isLoadingPage && (this.isLoading = true);
    this.isError = false;
    try {
      const response = await this.userClient.getUser();
      this.user = response.result;
      this.setCorrectLanguage(this.user.localizationCode);
      this.translate.use(this.localStorageService.getData(USER_LOCALE));

      const hasPermissionOver = await this.permissionService.hasPermissionOver(
        null,
        null,
        PermissionType.CanEverything
      );

      if (hasPermissionOver) {
        this.userRole = ROOT;
        this.settingRoute = `/${this.webCode}/settings/organizations`;
        this.organizationName = null;
        return;
      }

      const currentOrganization = await this.getCurrentOrganisation();
      const organizationId = currentOrganization.organizationId;
      const space = currentOrganization.spaceUsedPaymentPlanType
        ? SpaceUsedPaymentPlanDetailTypes[currentOrganization.spaceUsedPaymentPlanType].value
        : 0;
      this.setSpaceInfo(space, currentOrganization.spaceUsed);
      this.settings.setSettingsMaxSpace(this.spaceInfo.maxAvailableSpaceUsed);
      this.spaceInfo.alertPercentage = currentOrganization.alertLevelSpaceUsed;

      const canManageOrganization = await this.permissionService.hasPermissionOver(
        organizationId,
        null,
        PermissionType.CanManageOrganization
      );

      if (canManageOrganization) {
        this.userRole = RTA;
        this.settingRoute = `/${this.webCode}/settings/organization/${organizationId}/general`;
        return;
      }

      const canManageMailAccount = await this.permissionService.hasPermissionOverAtLeastOneMailAccount(
        PermissionType.CanManageMailAccount
      );

      if (canManageMailAccount) {
        this.userRole = USER;
        this.settingRoute = `/${this.webCode}/settings/organization/${organizationId}/mail-accounts`;
        return;
      }

      this.userRole = USER;
      this.settingRoute = null;
    } catch (e) {
      this.isError = true;
      this.matchError.logError(e);
    } finally {
      this.isLoading = false;
    }
  }

  async loadFolders(): Promise<void> {
    try {
      const response = await this.mailFolderClient.getUserFolders();
      this.userFolders = response.data;
    } catch (error) {
      console.log(error);
      this.userFolders = [];
    }
  }

  async getMailAccounts(): Promise<void> {
    try {
      const hasPermissionOver = await this.permissionService.hasPermissionOver(
        null,
        null,
        PermissionType.CanEverything
      );

      if (hasPermissionOver) {
        return;
      }
      const response = await this.userMailAccountClient.getLoggedUserAccounts();
      this.mailAccounts = response.userMailAccounts;
    } catch (e) {
      this.getMailAccountsError = `Code:${e.error.Code}, description:${e.error.description}`;
    }
  }

  async getCurrentOrganisation(): Promise<OrganizationContract> {
    const organizations = (await this.userOrganizationClient.getUserOrganizations()).organizations;

    if (!organizations || !organizations?.length) {
      this.userRole = null;
      this.settingRoute = null;
    }

    const currentOrganization = organizations.find((e) => e.webCode === this.webCode);
    this.userOrganisaions = organizations;
    this.organizationName = currentOrganization.name;
    return currentOrganization;
  }

  private setSpaceInfo(space, spaceUsed): void {
    this.spaceInfo.maxAvailableSpaceUsed = space;
    this.spaceInfo.spaceUsed = spaceUsed ? spaceUsed : 0;
    this.spaceInfo.percentage =
      this.spaceInfo.maxAvailableSpaceUsed > 0
        ? +((this.spaceInfo.spaceUsed / this.spaceInfo.maxAvailableSpaceUsed) * 100).toFixed(2)
        : 0;
  }

  private setCorrectLanguage(code): void {
    if (code) {
      this.localStorageService.setData(USER_LOCALE, code);
    } else {
      const elem = navigator.language.split('-')[0];
      if (ListOfPossibleLanguages.indexOf(elem) !== -1) {
        this.localStorageService.setData(USER_LOCALE, elem);
      } else {
        this.localStorageService.setData(USER_LOCALE, environment.language);
      }
    }
  }

  rightMenuOpen(): void {
    this.sideNav?.toggle();
  }

  leftMenuOpen(): void {
    this.matDriver?.toggle();
  }

  ngAfterViewInit(): void {
    this.observer
      .observe(['(max-width: 800px)'])
      .pipe(delay(1))
      .subscribe((res) => {
        this.visibleFullScreen = !res.matches;
      });
  }

  async ngOnDestroy(): Promise<any> {
    this.useMobileViewSubscription?.unsubscribe();
    this.settings.settingsMaxSpace$?.unsubscribe();
  }
}
