import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProviderClient } from '../../../../sdk/clients/provider.client';
import { ApiException } from '../../../../services/errors/api.exception';
import { ErrorCode } from '../../../../sdk/api-errors/error-code';
import { ErrorDialogComponent } from '../../../error/error-dialog/error-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatchError } from 'src/app/services/errors/error-matcher';

@Component({
  selector: 'app-provider-delete-dialog',
  templateUrl: './provider-delete-dialog.component.html',
  styleUrls: ['./provider-delete-dialog.component.scss'],
})
export class ProviderDeleteDialogComponent implements OnInit {
  public isLoading = false;
  public hasProviderIsUseError = false;
  public hasUnhandingError = false;

  constructor(
    public matchError: MatchError,
    private providerClient: ProviderClient,
    public dialogRef: MatDialogRef<ProviderDeleteDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: { providerId: number; providerName: string }
  ) {}

  ngOnInit(): void {}
  openErrorDialog(title: string, description: string): void {
    this.dialog.open(ErrorDialogComponent, {
      width: '300px',
      data: { title: title, description: description },
    });
  }
  onCancelBtnClicked(): void {
    this.dialogRef.close({ isDeleted: false });
  }

  async onDeleteBtnClicked(): Promise<void> {
    this.isLoading = true;
    this.hasProviderIsUseError = false;
    this.hasUnhandingError = false;
    try {
      await this.providerClient.delete({ id: this.data.providerId });
      this.dialogRef.close({ isDeleted: true });
    } catch (e) {
      if (e instanceof ApiException && e.Code === ErrorCode.ProviderEntityAlreadyUse) {
        // this.hasProviderIsUseError = true;
        this.openErrorDialog('error', 'cannotDeleteProviderAlreadyUsedErrorPopupSubtitle');
      } else {
        // this.hasUnhandingError = true;
        this.openErrorDialog('error', 'cannotDeleteErrorPopupSubtitle');
      }
      this.matchError.logError(e);
    } finally {
      this.isLoading = false;
    }
  }
}
