<div class="breadcrumbs">
  <div *ngFor="let item of sortedItems" class="flex flex-wrap content-center h-5">
    <a
      *ngIf="item.route; else inaccessible"
      class="link"
      [class.accessible-link]="!isLastItem(item.index)"
      [routerLink]="isLastItem(item.index) ? [] : [item.route]">
      {{ item.label | translate }}
    </a>
    <ng-template #inaccessible>
      <span class="link">
        {{ item.label | translate }}
      </span>
    </ng-template>

    <mat-icon *ngIf="!isLastItem(item.index)" class="nav-icon" [inline]="true">navigate_next</mat-icon>
  </div>
</div>
