import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-close-upload-dialog',
  templateUrl: './close-upload-dialog.component.html',
  styleUrls: ['./close-upload-dialog.component.scss'],
})
export class CloseUploadDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<CloseUploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { },
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {}

  onCancelBtnClicked(isClose: boolean): void {
    this.dialogRef.close({ isClose });
  }
}
