import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { WEB_CODE } from '../../shared/constants/constants';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-error-page',
  standalone: true,
  imports: [
    MatButtonModule,
    TranslateModule,
    RouterLink
  ],
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent implements OnInit {
  error = {
    403: {
      title: 'noPermission',
      subTitle: 'youDontHavePermission',
      src: '../../../assets/403.svg'
    },
    401: {
      title: 'unauthorized',
      subTitle: 'notLoggedIn',
      src: '../../../assets/401.svg'
    },
    500: {
      title: 'internalServerError',
      subTitle: '',
      src: '../../../assets/500.svg'
    },
    404: {
      title: 'noSuchPageExists',
      subTitle: '',
      src: '../../../assets/404.svg'
    },
    requesterror: {
      title: 'pageCannotBeShown',
      subTitle: '',
      src: '../../../assets/500.svg'
    }
  };
  errorCode = '';
  public returnUrl: string;
  public canRedirectToHomePage: boolean;
  public homeUrl: string | null;

  constructor(
    private router: Router,
    private cookieService: CookieService,
    private authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    const webCode = this.activatedRoute.snapshot.paramMap.get(WEB_CODE);
    const urlArr = window.location.pathname.split('/');
    this.errorCode = this.error?.[urlArr[urlArr.length - 1]]?.title ? urlArr[urlArr.length - 1] : '404';

    if (webCode) {
      this.homeUrl = `/${webCode}`;
      this.canRedirectToHomePage = true;
    } else {
      this.canRedirectToHomePage = false;
    }
    this.returnUrl = this.router.getCurrentNavigation()?.extras?.state?.returnUrl;
  }

  async signout(): Promise<void> {
    const webCode = this.activatedRoute.snapshot.paramMap.get(WEB_CODE);

    if (webCode) {
      await this.authenticationService.logout(webCode);
    }
  }
}
