<h1 mat-dialog-title>{{ "confirmMailAccountDeletion" | translate }}</h1>
<mat-dialog-content class="mat-mdc-dialog-content mdc-dialog__content mat-container">
  <p>
    {{ "wantToDelete" | translate }} <span class="font-bold italic">{{ data.mailAccountName }}</span> {{ "mailAccount" | translate }}?
  </p>
</mat-dialog-content>
<mat-dialog-actions class="mat-mdc-dialog-actions mdc-dialog__actions">
  <div class="w-full flex justify-between">
    <button mat-button (click)="onCancelBtnClicked()">{{ "cancel" | translate }}</button>
    <button mat-flat-button color="primary" [disabled]="isLoading" (click)="onDeleteBtnClicked()">
<!--      <app-loading-button text="{{ 'delete' | translate }}" [loading]="isLoading"></app-loading-button>-->
    </button>
  </div>
</mat-dialog-actions>
