import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { LoggingCreateRequest } from '../contracts/logging/logging.create.request';
import { ApiRoutes } from '../routes/api.routes';

const API_URL = environment.apiUrl;

@Injectable()
export class LoggingClient {
  constructor(private http: HttpClient) {}

  async create(request: LoggingCreateRequest): Promise<void> {
    this.http.post(`${API_URL}${ApiRoutes.logging.create}`, request).subscribe(() => {});
  }
}
