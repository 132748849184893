import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatAccountLanguage',
})
export class FormatAccountLanguage implements PipeTransform {
  transform(lang: string): string {
    switch (lang) {
      case 'it':
        return 'Italiano';
      case 'en':
        return 'English';
      case 'de':
        return 'Deutsch';
      case 'fr':
        return 'Français';
    }
  }
}
