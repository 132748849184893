<div class="breadcrumbs-container">
  <app-breadcrumb [items]="breadcrumbItems"></app-breadcrumb>
</div>

<div>
  <app-navigation-tabs [tabItems]="tabItems"></app-navigation-tabs>
</div>

<!--<app-drawer [isOpen]="addDrawerIsOpen" (closeEventEmitter)="onCloseAddModalForm()">-->
<!--  <app-mail-account-users-add-->
<!--    *ngIf="addDrawerIsOpen"-->
<!--    (closeEventEmitter)="onCloseAddModalForm()"-->
<!--    (creationSubmit)="onAddUserToMailAccount($event)"-->
<!--    [organizationId]="organizationId"-->
<!--    [mailAccountId]="mailAccountId">-->
<!--  </app-mail-account-users-add>-->
<!--</app-drawer>-->

<!--<app-drawer [isOpen]="editDrawerIsOpen" (closeEventEmitter)="onCloseEditModalForm()">-->
<!--  <app-mail-account-users-edit-->
<!--    *ngIf="editDrawerIsOpen"-->
<!--    (closeEventEmitter)="onCloseEditModalForm()"-->
<!--    (submitEventEmitter)="onEditMailAccountUser()"-->
<!--    [userMailAccount]="editingUserMailAccount">-->
<!--  </app-mail-account-users-edit>-->
<!--</app-drawer>-->

<ng-container>
  <div class="page-content">
    <div class="search-panel flex-wrap">
      <div class="button-group mr-5">
        <button
          *ngIf="!iscredemISAMSupport"
          mat-raised-button
          color="primary"
          class="page-block-button"
          aria-label="Add user to mail account"
          (click)="onCreateMailAccountUserBtnClicked()">
          <mat-icon>add</mat-icon>{{ "assignUser" | translate }}
        </button>
      </div>

      <div class="mobile-view search-bar">
        <app-search-bar
          [fullWidth]="true"
          (searchEventEmitter)="loadMailAccountUsers(true)"
          (valueChangedEventEmitter)="onSearchValueChanged($event)"
          [search]="searchValue"></app-search-bar>
      </div>
      <div class="not-mobile-view search-bar">
        <app-search-bar
          [fullWidth]="false"
          (searchEventEmitter)="loadMailAccountUsers(true)"
          (valueChangedEventEmitter)="onSearchValueChanged($event)"
          [search]="searchValue"></app-search-bar>
      </div>
    </div>
    <div *ngIf="!navigationIsLoading && !hasError">
      <div *ngIf="useMobileView">
        <div class="page-block">
          <mat-paginator
            [pageSize]="pageSize"
            [pageSizeOptions]="[25, 50, 100]"
            [length]="totalSize"
            [pageIndex]="pageIndex"
            (page)="handlePage($event)">
          </mat-paginator>
        </div>
        <div *ngIf="!isLoading && !hasError">
          <div class="page-block" *ngFor="let userMailAccount of userMailAccounts">
            <div class="card">
              <mat-card class="mobile-row">
                <mat-card-content (click)="onEditMailAccountUserBtnClicked(userMailAccount)" class="cursor-default flex flex-1">
                  <div class="card-table-row">
                    <div class="card-table-row-header">{{ "firstName" | translate }}:</div>
                    <div class="card-table-row-value">{{ userMailAccount.userFirstName }}</div>
                  </div>
                  <div class="card-table-row">
                    <div class="card-table-row-header">{{ "lastName" | translate }}:</div>
                    <div class="card-table-row-value">{{ userMailAccount.userLastName }}</div>
                  </div>
                  <div class="card-table-row">
                    <div class="card-table-row-header">Adfs identity:</div>
                    <div class="card-table-row-value">{{ userMailAccount.adfsIdentity }}</div>
                  </div>
                  <div class="card-table-row">
                    <div class="card-table-row-header">Google identity:</div>
                    <div class="card-table-row-value">{{ userMailAccount.googleIdentity }}</div>
                  </div>
                  <div class="card-table-row">
                    <div class="card-table-row-header">Oidc identity:</div>
                    <div class="card-table-row-value">{{ userMailAccount.oidcIdentity }}</div>
                  </div>
                  <div class="card-table-row">
                    <div class="card-table-row-header">{{ "email" | translate }}:</div>
                    <div class="card-table-row-value">{{ userMailAccount.userEmail }}</div>
                  </div>
                  <div class="card-table-row">
                    <div class="card-table-row-header">{{ "role" | translate }}:</div>
                    <div class="card-table-row-value">{{ roleTypes[userMailAccount.roleId] | translate }}</div>
                  </div>
                </mat-card-content>
                <div style="width: 40px; margin-left: 10px">
                  <button
                    *ngIf="!iscredemISAMSupport"
                    matTooltip="{{ 'changeRole' | translate }}"
                    mat-icon-button
                    color="primary"
                    aria-label="Change role"
                    (click)="onEditMailAccountUserBtnClicked(userMailAccount)">
                    <mat-icon>manage_accounts</mat-icon>
                  </button>
                  <span class="mr-2"></span>
                  <button
                    *ngIf="!iscredemISAMSupport"
                    matTooltip="{{ 'delete' | translate }}"
                    mat-icon-button
                    color="primary"
                    aria-label="Delete"
                    (click)="
                      deleteUserMailAccount(
                        userMailAccount.userMailAccountId,
                        userMailAccount.mailAccountName,
                        userMailAccount.userFirstName,
                        userMailAccount.userLastName
                      )
                    ">
                    <mat-icon>delete_outline</mat-icon>
                  </button>
                </div>
              </mat-card>
            </div>
          </div>
        </div>
        <app-no-data *ngIf="userMailAccounts.length === 0 && !isLoading && !navigationIsLoading && !hasError"></app-no-data>
      </div>

      <div class="page-block table-container" *ngIf="!useMobileView">
        <mat-paginator
          [pageSize]="pageSize"
          [pageSizeOptions]="[25, 50, 100]"
          [length]="totalSize"
          [pageIndex]="pageIndex"
          (page)="handlePage($event)">
        </mat-paginator>

        <table *ngIf="!isLoading && !hasError" mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="first-name">
            <th mat-header-cell *matHeaderCellDef>{{ "firstName" | translate }}</th>
            <td mat-cell *matCellDef="let userMailAccount">{{ userMailAccount.userFirstName }}</td>
          </ng-container>
          <ng-container matColumnDef="last-name">
            <th mat-header-cell *matHeaderCellDef>{{ "lastName" | translate }}</th>
            <td mat-cell *matCellDef="let userMailAccount">{{ userMailAccount.userLastName }}</td>
          </ng-container>
          <ng-container matColumnDef="oidcIdentity">
            <th mat-header-cell *matHeaderCellDef>{{ "GAW" | translate }}</th>
            <td mat-cell *matCellDef="let organizationUser">{{ organizationUser.oidcIdentity }}</td>
          </ng-container>
          <ng-container matColumnDef="googleIdentity">
            <th mat-header-cell *matHeaderCellDef>{{ "google" | translate }}</th>
            <td mat-cell *matCellDef="let organizationUser">{{ organizationUser.googleIdentity }}</td>
          </ng-container>
          <ng-container matColumnDef="adfsIdentity">
            <th mat-header-cell *matHeaderCellDef>{{ "adfs" | translate }}</th>
            <td mat-cell *matCellDef="let organizationUser">{{ organizationUser.adfsIdentity }}</td>
          </ng-container>
          <ng-container matColumnDef="user-email">
            <th mat-header-cell *matHeaderCellDef>{{ "email" | translate }}</th>
            <td mat-cell *matCellDef="let userMailAccount">{{ userMailAccount.userEmail }}</td>
          </ng-container>
          <ng-container matColumnDef="user-mail-account-role-name">
            <th mat-header-cell *matHeaderCellDef>{{ "role" | translate }}</th>
            <td mat-cell *matCellDef="let userMailAccount">{{ roleTypes[userMailAccount.roleId] | translate }}</td>
          </ng-container>
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>{{ "actions" | translate }}</th>
            <td mat-cell *matCellDef="let userMailAccount">
              <button
                *ngIf="!iscredemISAMSupport"
                mat-icon-button
                aria-label="{{ 'changeRole' | translate }}"
                matTooltip="{{ 'changeRole' | translate }}"
                (click)="onEditMailAccountUserBtnClicked(userMailAccount)">
                <mat-icon>manage_accounts</mat-icon>
              </button>
              <span class="mr-2"></span>
              <button
                *ngIf="!iscredemISAMSupport"
                mat-icon-button
                aria-label="{{ 'delete' | translate }}"
                matTooltip="{{ 'delete' | translate }}"
                (click)="
                  deleteUserMailAccount(
                    userMailAccount.userMailAccountId,
                    userMailAccount.mailAccountName,
                    userMailAccount.userFirstName,
                    userMailAccount.userLastName
                  )
                ">
                <mat-icon>delete_outline</mat-icon>
              </button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns" class="cursor-default"></tr>
        </table>
        <app-no-data *ngIf="userMailAccounts.length === 0 && !isLoading && !navigationIsLoading && !hasError"></app-no-data>
      </div>
    </div>
    <app-loader *ngIf="isLoading || navigationIsLoading"></app-loader>

    <div *ngIf="hasError" class="half-of-screen">
      <app-something-went-wrong
        description="{{ 'cannotLoadMailAccount' | translate }}"
        [showPicture]="false"
        (retryEmitter)="loadMailAccountUsers(true) && loadNavigation()"></app-something-went-wrong>
    </div>
  </div>
</ng-container>
