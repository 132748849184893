import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { ApiRequest } from '../../services/api/api.request';
import { EmailSearchRequest } from '../contracts/Email/email.search.request';
import { EmailSearchResponse } from '../contracts/Email/email.search.response';
import { ApiRoutes } from '../routes/api.routes';
import { BaseGetByIdRequest } from '../contracts/common/base-get-by-id.request';
import { EmailGetByIdResponse } from '../contracts/Email/email.get-by-id.response';
import { EmailCreateRequest } from '../contracts/Email/email.create.request';
import { BaseCreateResponse } from '../contracts/common/base-create.response';
import { BaseUpdateResponse } from '../contracts/common/base-update.response';
import { EmailUpdateRequest } from '../contracts/Email/email.update.request';
import { EmailReplyRequest } from '../contracts/Email/email.reply.request';
import { EmailForwardRequest } from '../contracts/Email/email.forward.request';
import { EmailMultipleForwardRequest } from '../contracts/Email/email.forward-multiple.request';
import { EmailMoveRequest } from '../contracts/Email/email.move.request';
import { EmailReassignRequest } from '../contracts/Email/email.reassign.request';
import { EmailSetValidatedRequest } from '../contracts/Email/email.set-validated.request';
import { BasePostCommentRequest } from '../contracts/common/base-post-comment.request';
import { EmailSearchByWidgetRequest } from '../contracts/Email/email.search-by-widget.request';
import { BaseGetCollectionResponse } from '../contracts/common/base-get-collection.response';
import { EmailListItemNotificationsContract } from '../contracts/Email/email.list-item-notifications.contract';
import { BaseDeleteListResponse } from '../contracts/common/base-delete-list-response';
import { EmailDeleteRequest } from '../contracts/Email/email-delete-request';
import { EmailRestoreRequest } from '../contracts/Email/email-restore-request';
import { EmailRestoreResponse } from '../contracts/Email/email-restore-response';
import { EmailSetIsSpamRequest } from '../contracts/Email/email-set-is-spam-request';
import { EmailSetIsSpamResponse } from '../contracts/Email/email-set-is-spam-response';
import { EmailSetSeenResponse } from '../contracts/Email/email-set-seen-response';
import { EmailSetSeenRequest } from '../contracts/Email/email-set-seen-request';
import { EmailSetNotSpamRequest } from '../contracts/Email/email-set-not-spam-request';
import { EmailSetNotSpamResponse } from '../contracts/Email/email-set-not-spam-response';
import { EmailBaseArrayIdsResponse } from '../contracts/Email/email-base-array-ids-response';
import { EmailBaseRestoreRequest } from '../contracts/Email/email-base-restore-request';
import { EmailRetrySendRequest } from '../contracts/Email/email.retry-send.request';
import { EmailMoveToDraftRequest } from '../contracts/Email/email.move-to-draft.request';
import { BaseFinalDeleteListResponse } from '../contracts/common/base-final-delete-list-response';

const API_URL = environment.apiUrl;

@Injectable()
export class EmailClient {
  constructor(private apiRequest: ApiRequest) {}

  async search(request: EmailSearchRequest): Promise<EmailSearchResponse> {
    return this.apiRequest.post(request, `${API_URL}${ApiRoutes.email.search}`);
  }

  async searchByWidget(request: EmailSearchByWidgetRequest): Promise<EmailSearchResponse> {
    return this.apiRequest.post(request, `${API_URL}${ApiRoutes.email.searchByWidget}`);
  }

  async getById(request: BaseGetByIdRequest): Promise<EmailGetByIdResponse> {
    return this.apiRequest.get(`${API_URL}${ApiRoutes.email.get}?${request.format()}`);
  }

  async getNotifications(request: BaseGetByIdRequest): Promise<BaseGetCollectionResponse<EmailListItemNotificationsContract>> {
    return this.apiRequest.get(`${API_URL}${ApiRoutes.email.getNotifications}?${request.format()}`);
  }

  async download(request: { emailIds: number[] }): Promise<any> {
    return this.apiRequest.post(request, `${API_URL}${ApiRoutes.email.download}`);
  }

  async singleDownload(request: { emailIds: number[] }): Promise<any> {
    return this.apiRequest.post(request, `${API_URL}${ApiRoutes.email.singleDownload}`);
  }

  async createDraft(request: EmailCreateRequest): Promise<BaseCreateResponse> {
    return this.apiRequest.post<EmailCreateRequest, BaseCreateResponse>(request, `${API_URL}${ApiRoutes.email.createDraft}`);
  }

  async update(request: EmailUpdateRequest): Promise<BaseUpdateResponse> {
    return this.apiRequest.post<EmailUpdateRequest, BaseUpdateResponse>(request, `${API_URL}${ApiRoutes.email.update}`);
  }

  async markForSend(request: BaseGetByIdRequest): Promise<void> {
    await this.apiRequest.get(`${API_URL}${ApiRoutes.email.markForSend}?${request.format()}`);
  }

  async reply(request: EmailReplyRequest): Promise<BaseCreateResponse> {
    return this.apiRequest.post<EmailReplyRequest, BaseCreateResponse>(request, `${API_URL}${ApiRoutes.email.reply}`);
  }

  async replyAll(request: EmailReplyRequest): Promise<BaseCreateResponse> {
    return this.apiRequest.post<EmailReplyRequest, BaseCreateResponse>(request, `${API_URL}${ApiRoutes.email.replyAll}`);
  }

  async forward(request: EmailForwardRequest): Promise<BaseCreateResponse> {
    return this.apiRequest.post<EmailForwardRequest, BaseCreateResponse>(request, `${API_URL}${ApiRoutes.email.forward}`);
  }

  async forwardMultiple(request: EmailMultipleForwardRequest): Promise<BaseCreateResponse> {
    return this.apiRequest.post<EmailMultipleForwardRequest, BaseCreateResponse>(request, `${API_URL}${ApiRoutes.email.forwardMultiple}`);
  }

  async delete(request: EmailDeleteRequest): Promise<BaseDeleteListResponse> {
    return this.apiRequest.post<EmailDeleteRequest, BaseDeleteListResponse>(request, `${API_URL}${ApiRoutes.email.delete}`);
  }

  async finalDelete(request: EmailDeleteRequest): Promise<BaseFinalDeleteListResponse> {
    return this.apiRequest.post<EmailDeleteRequest, BaseFinalDeleteListResponse>(request, `${API_URL}${ApiRoutes.email.finalDelete}`);
  }

  async restore(request: EmailRestoreRequest): Promise<EmailRestoreResponse> {
    return this.apiRequest.post<EmailRestoreRequest, EmailRestoreResponse>(request, `${API_URL}${ApiRoutes.email.restore}`);
  }

  async setIsSpam(request: EmailSetIsSpamRequest): Promise<EmailSetIsSpamResponse> {
    return this.apiRequest.post<EmailSetIsSpamRequest, EmailSetIsSpamResponse>(request, `${API_URL}${ApiRoutes.email.setIsSpam}`);
  }

  async archive(request: EmailRestoreRequest): Promise<EmailBaseArrayIdsResponse> {
    return this.apiRequest.post<EmailRestoreRequest, EmailBaseArrayIdsResponse>(request, `${API_URL}${ApiRoutes.email.archive}`);
  }

  async restoreFromArchive(request: EmailBaseRestoreRequest): Promise<EmailBaseArrayIdsResponse> {
    return this.apiRequest.post<EmailBaseRestoreRequest, EmailBaseArrayIdsResponse>(
      request,
      `${API_URL}${ApiRoutes.email.restoreFromArchive}`
    );
  }

  async setNotSpam(request: EmailSetNotSpamRequest): Promise<EmailSetNotSpamResponse> {
    return this.apiRequest.post<EmailSetNotSpamRequest, EmailSetNotSpamResponse>(request, `${API_URL}${ApiRoutes.email.setNotSpam}`);
  }

  async setSeen(request: EmailSetSeenRequest): Promise<EmailSetSeenResponse> {
    return this.apiRequest.post<EmailSetSeenRequest, EmailSetSeenResponse>(request, `${API_URL}${ApiRoutes.email.setSeen}`);
  }

  async setValidated(request: EmailSetValidatedRequest): Promise<void> {
    return this.apiRequest.post<EmailSetValidatedRequest, any>(request, `${API_URL}${ApiRoutes.email.setValidation}`);
  }

  async move(request: EmailMoveRequest): Promise<void> {
    return this.apiRequest.post<EmailMoveRequest, any>(request, `${API_URL}${ApiRoutes.email.move}`);
  }

  async reassign(request: EmailReassignRequest): Promise<void> {
    return this.apiRequest.post<EmailReassignRequest, any>(request, `${API_URL}${ApiRoutes.email.reassign}`);
  }

  async readyToValidate(request: BaseGetByIdRequest): Promise<void> {
    await this.apiRequest.get(`${API_URL}${ApiRoutes.email.readyToValidate}?${request.format()}`);
  }

  async validationApprove(request: BaseGetByIdRequest): Promise<void> {
    await this.apiRequest.get(`${API_URL}${ApiRoutes.email.validationApprove}?${request.format()}`);
  }

  async validationReject(request: BasePostCommentRequest): Promise<void> {
    await this.apiRequest.post(request, `${API_URL}${ApiRoutes.email.validationReject}`);
  }

  async retrySend(request: EmailRetrySendRequest): Promise<void> {
    await this.apiRequest.post(request, `${API_URL}${ApiRoutes.email.retrySend}`);
  }

  async moveToDraft(request: EmailMoveToDraftRequest): Promise<void> {
    await this.apiRequest.post(request, `${API_URL}${ApiRoutes.email.moveToDraft}`);
  }
}
