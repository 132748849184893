import { BaseGetRequest } from './base-get.request';

export class BaseGetCollectionByIdRequest extends BaseGetRequest {
  id: number;
  page: number;
  pageSize: number;

  constructor(id: number, page: number, pageSize: number) {
    super();
    this.id = id;
    this.page = page;
    this.pageSize = pageSize;
  }
}
