import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { BreadcrumbItem } from '../../../../../shared/breadcrumb-item';
import { TabItem } from '../../../../../shared/tab-item';
import { BaseGetByIdRequest } from '../../../../../sdk/contracts/common/base-get-by-id.request';
import { PermissionType } from '../../../../../sdk/contracts/permission/permission-type';
import { PermissionNavTabHelper } from '../../../../../services/permission/permission-nav-tab-helper';
import { OrganizationClient } from '../../../../../sdk/clients/organization.client';
import { MailAccountClient } from '../../../../../sdk/clients/mail-account.client';
import { PermissionService } from '../../../../../services/permission/permission.service';
import { ActivatedRoute } from '@angular/router';
import { MailFolderClient } from '../../../../../sdk/clients/mail-folder.client';
import { MatTableDataSource } from '@angular/material/table';
import { MailFolderType } from '../../../../../sdk/contracts/mail-folder/mail-folder.type';
import { Subscription } from 'rxjs';
import { MobileObserverService } from '../../../../../services/adaptive/mobile-observer.service';
import { MailFolderContractWithFullNameForResponse } from '../../../../../sdk/contracts/mail-folder/mail-folder-contract-with-full-name.response';
import { MatDialog } from '@angular/material/dialog';
//import { MailFolderDeleteDialogComponent } from '../../../../mail-account-folders/mail-folder-delete-dialog/mail-folder-delete-dialog.component';
import { MailFolderService } from '../../../../../services/mail-folder/mail.folder.service';
import { MailFolderContract } from '../../../../../sdk/contracts/mail-folder/mail-folder.contract';
import { MailCreationType } from 'src/app/sdk/contracts/mail-folder/mail-folder-creation.type';
import { MatchError } from 'src/app/services/errors/error-matcher';

@Component({
  selector: 'app-mail-account-folders-settings',
  templateUrl: './mail-account-folders-settings.component.html',
  styleUrls: ['./mail-account-folders-settings.component.scss'],
})
export class MailAccountFoldersSettingsComponent implements OnInit, AfterViewInit, OnDestroy {
  public webCode: string;
  public organizationId: number;
  public mailAccountId: number;
  public navigationIsLoading = false;
  public cannotLoadNavigation = false;
  public isLoading = false;
  public cannotLoadData = false;
  public breadcrumbItems: BreadcrumbItem[] = [];
  public tabItems: TabItem[] = [];
  public folders: MailFolderContract[] = [];
  public dataSource = new MatTableDataSource<MailFolderContract>(this.folders);
  public displayedColumns: string[] = ['name', 'providerName', 'archive', 'actions'];
  public mailFolderType = MailFolderType;
  public mailCreationType = MailCreationType;
  public useMobileView = false;
  public useMobileViewSubscription: Subscription;
  public parentMailFolderIdForCreation: number | null;
  public createDrawerIsOpen = false;
  public renameDrawerIsOpen = false;
  public editingFolder: MailFolderContractWithFullNameForResponse | null;
  public settingsDrawerIsOpen = false;
  public parentMailFolderTypeForCreation: number;

  constructor(
    public matchError: MatchError,
    private permissionNavTabHelper: PermissionNavTabHelper,
    private organizationClient: OrganizationClient,
    private mailAccountClient: MailAccountClient,
    private mailFolderService: MailFolderService,
    private permissionService: PermissionService,
    private route: ActivatedRoute,
    private mailFolderClient: MailFolderClient,
    private mobileObserverService: MobileObserverService,
    public dialog: MatDialog
  ) {}

  async ngOnInit(): Promise<void> {
    this.useMobileViewSubscription = this.mobileObserverService.mobileObserver().subscribe((isMobile) => (this.useMobileView = isMobile));

    this.webCode = this.route.parent.snapshot.paramMap.get('webCode');
    this.organizationId = parseInt(this.route.snapshot.paramMap.get('organizationId'), 10);
    this.mailAccountId = parseInt(this.route.snapshot.paramMap.get('mailAccountId'), 10);
    await this.loadNavigation();
    await this.loadData();
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    this.useMobileViewSubscription?.unsubscribe();
  }

  public async loadNavigation(): Promise<void> {
    this.isLoading = true;
    this.cannotLoadData = false;
    try {
      this.loadTabs();
      await this.loadBreadcrumbItems();
    } catch (e) {
      this.cannotLoadNavigation = true;
      this.cannotLoadData = true;
      this.matchError.logError(e);
    } finally {
      this.isLoading = false;
    }
  }

  public inInboxSentOrArchiveFolder(folder: MailFolderContract) {
    return (
      folder.folderType === MailFolderType.Inbox ||
      folder.folderType === MailFolderType.Sent ||
      folder.folderType === MailFolderType.Archive
    );
  }

  public async loadData(): Promise<void> {
    this.isLoading = true;
    this.cannotLoadData = false;
    try {
      const response = await this.mailFolderService.getFoldersForAccountFlatForSettings(this.mailAccountId);
      const folderTypeOrder = [
        MailFolderType.Inbox,
        MailFolderType.Sent,
        MailFolderType.Archive,
        MailFolderType.Outbox,
        MailFolderType.Validation,
        MailFolderType.Draft,
        MailFolderType.Trash,
        MailFolderType.Spam,
      ];
      response.sort((a, b) => folderTypeOrder.indexOf(a.folderType) - folderTypeOrder.indexOf(b.folderType));
      this.folders = response;
      this.dataSource.data = response;
    } catch (e) {
      this.cannotLoadData = true;
      this.matchError.logError(e);
    } finally {
      this.isLoading = false;
    }
  }

  private loadTabs(): void {
    this.tabItems = this.permissionNavTabHelper.getTabsForMailAccountPage(this.organizationId, this.mailAccountId, this.webCode);
  }

  private async loadBreadcrumbItems(): Promise<void> {
    const mailAccountName = (await this.mailAccountClient.getById(new BaseGetByIdRequest(this.mailAccountId))).mailAccount.name;
    if (await this.permissionService.hasPermissionOver(null, null, PermissionType.CanEverything)) {
      this.breadcrumbItems = [
        { index: 0, label: 'settingsBreadCrumbs', route: `/${this.webCode}/settings/organization/${this.organizationId}/general` },
        {
          index: 1,
          label: 'mailAccountsBreadCrumbs',
          route: `/${this.webCode}/settings/organization/${this.organizationId}/mail-accounts`,
        },
        { index: 2, label: mailAccountName, route: null },
        { index: 3, label: 'foldersBreadCrumbs', route: null },
      ];
    } else if (await this.permissionService.hasPermissionOver(this.organizationId, null, PermissionType.CanManageOrganization)) {
      this.breadcrumbItems = [
        { index: 0, label: 'settingsBreadCrumbs', route: `/${this.webCode}/settings/organization/${this.organizationId}/general` },
        {
          index: 1,
          label: 'mailAccountsBreadCrumbs',
          route: `/${this.webCode}/settings/organization/${this.organizationId}/mail-accounts`,
        },
        { index: 2, label: mailAccountName, route: null },
        { index: 3, label: 'foldersBreadCrumbs', route: null },
      ];
    } else {
      this.breadcrumbItems = [
        { index: 0, label: 'settingsBreadCrumbs', route: `/${this.webCode}/settings/organization/${this.organizationId}/general` },
        {
          index: 1,
          label: 'mailAccountsBreadCrumbs',
          route: `/${this.webCode}/settings/organization/${this.organizationId}/mail-accounts`,
        },
        { index: 2, label: mailAccountName, route: null },
        { index: 3, label: 'foldersBreadCrumbs', route: null },
      ];
    }
  }
  public createFolder(parentMailFolder: MailFolderContractWithFullNameForResponse): void {
    this.parentMailFolderTypeForCreation = parentMailFolder.folderType;
    this.parentMailFolderIdForCreation = parentMailFolder.mailFolderId;
    this.createDrawerIsOpen = true;
  }

  public onCloseCreateModalForm(): void {
    this.createDrawerIsOpen = false;
    this.parentMailFolderIdForCreation = null;
  }

  public async onSubmitCreation(): Promise<void> {
    this.onCloseCreateModalForm();
    await this.loadData();
  }

  public renameFolder(folder: MailFolderContractWithFullNameForResponse): void {
    this.parentMailFolderTypeForCreation = folder.folderType;
    this.editingFolder = folder;
    this.renameDrawerIsOpen = true;
  }

  public onCloseRenameModalForm(): void {
    this.renameDrawerIsOpen = false;
    this.editingFolder = null;
  }

  public async onSubmitRename(): Promise<void> {
    this.onCloseRenameModalForm();
    await this.loadData();
  }

  public deleteFolder(folder: MailFolderContractWithFullNameForResponse): void {
    // const dialogRef = this.dialog.open(MailFolderDeleteDialogComponent, {
    //   width: '450px',
    //   data: { mailFolderId: folder.mailFolderId, folderName: folder.fullName },
    // });
    //
    // dialogRef.afterClosed().subscribe(async (x) => {
    //   if (x.isDeleted) {
    //     this.folders = this.folders.filter((f) => f.mailFolderId !== folder.mailFolderId);
    //     this.dataSource.data = this.folders;
    //   }
    // });
  }

  public openFolderSettings(folder: MailFolderContractWithFullNameForResponse): void {
    this.editingFolder = folder;
    this.settingsDrawerIsOpen = true;
  }

  public onCloseSettingsModalForm(): void {
    this.settingsDrawerIsOpen = false;
    this.editingFolder = null;
  }

  public async onSubmitSettings(): Promise<void> {
    this.onCloseSettingsModalForm();
    await this.loadData();
  }
}
