@if(isLoading) {
  <div class="h-screen w-screen">
    <app-loader height="100vh"></app-loader>
  </div>
}

@if(!isLoading) {
  <div class="h-screen w-screen flex flex-wrap content-center justify-center relative">
    <div class="sign-in-form -mt-20">
      <div class="text-2xl mb-5 font-medium text-center">
        {{ "login" | translate }}
      </div>
      <mat-form-field>
        <mat-label>{{ "authenticationType" | translate }}</mat-label>
        <mat-select [(value)]="selectedAuthType">
          @for(authType of authTypes; track authType) {
            <mat-option [value]="authType">
              {{ getAuthTypeDescription(authType) | translate }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
      <div class="flex justify-center mt-8">
        <button mat-flat-button color="primary" (click)="onLogin()">{{ "login" | translate }}</button>
      </div>
    </div>
  </div>
}
