import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { UserGetIdentitiesRequest } from '../contracts/user/user.get-identities.request';
import { UserGetIdentitiesResponse } from '../contracts/user/user.get-identities.response';
import { UserLoginResponse } from '../contracts/user/user.login.response';
import { UserRegisterRequest } from '../contracts/user/user.register.request';
import { ApiRequest } from '../../services/api/api.request';
import { FeRoutes } from '../routes/fe.routes';
import { PasswordRestoreRequest } from '../contracts/user/password.restore.request';
import { PasswordResetRequest } from '../contracts/user/password.reset.request';
import { PasswordCanResetResponse } from '../contracts/user/password.can.reset.response';
import { PasswordChangeExpiredRequest } from '../contracts/user/password.change.expired.request';
import { BaseValueResponse } from '../contracts/common/base-value-response';
import { UserContract } from '../contracts/user/user.contract';
import { ApiRoutes } from '../routes/api.routes';
import { UserUpdateRequest } from '../contracts/user/user-update.request';

const FE_URL = environment.feUrl;
const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class UserClient {
  constructor(private apiRequest: ApiRequest) {}

  getIdentities(request: UserGetIdentitiesRequest, captcha: string): Promise<UserGetIdentitiesResponse> {
    return this.apiRequest.post<UserGetIdentitiesRequest, UserGetIdentitiesResponse>(request, `${FE_URL}${FeRoutes.user.getIdentities}`, {
      captcha,
    });
  }

  register(request: UserRegisterRequest, captcha: string): Promise<UserLoginResponse> {
    return this.apiRequest.post<UserRegisterRequest, UserLoginResponse>(request, `${FE_URL}${FeRoutes.user.registration}`, { captcha });
  }

  restore(request: PasswordRestoreRequest, captcha: string): Promise<void> {
    return this.apiRequest.post<PasswordRestoreRequest, void>(request, `${FE_URL}${FeRoutes.user.restorePassword}`, { captcha });
  }

  reset(request: PasswordResetRequest, captcha: string): Promise<void> {
    return this.apiRequest.post<PasswordResetRequest, void>(request, `${FE_URL}${FeRoutes.user.resetPassword}`, { captcha });
  }

  resetExpiredPassword(request: PasswordChangeExpiredRequest, captcha: string): Promise<void> {
    return this.apiRequest.post<PasswordChangeExpiredRequest, void>(request, `${FE_URL}${FeRoutes.user.resetExpiredPassword}`, { captcha });
  }

  canReset(request: PasswordResetRequest, captcha: string): Promise<PasswordCanResetResponse> {
    return this.apiRequest.post<PasswordResetRequest, PasswordCanResetResponse>(request, `${FE_URL}${FeRoutes.user.canResetPassword}`, {
      captcha,
    });
  }

  getUser(): Promise<BaseValueResponse<UserContract>> {
    return this.apiRequest.get(`${API_URL}${ApiRoutes.user.getUser}`);
  }

  async update(request: UserUpdateRequest): Promise<void> {
    await this.apiRequest.post<UserUpdateRequest, void>(request, `${API_URL}${ApiRoutes.user.update}`);
  }
}
