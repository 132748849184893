import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-errorNotExisted-page',
  standalone: true,
  imports: [],
  templateUrl: './errorNotExisted-page.component.html',
  styleUrls: ['./errorNotExisted-page.component.scss'],
})
export class ErrorNotExistedPageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
