<div class="close-button">
  <button tabIndex="-1" class="" mat-icon-button (click)="cancel()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<h1 mat-dialog-title>{{ "reassignEmails" | translate }}</h1>
<mat-dialog-content class="mat-mdc-dialog-content mdc-dialog__content mat-container">
  <div *ngIf="isLoading">
    <app-loader diameter="32" height="100%"></app-loader>
  </div>
  <div *ngIf="cannotLoad">
    <app-something-went-wrong
      [showPicture]="false"
      (retryEmitter)="loadData()"></app-something-went-wrong>
  </div>

  <div *ngIf="!isLoading && !cannotLoad">
    <mat-form-field *ngIf="selectedMailAccountId">
      <mat-label>{{ "accountForReassign" | translate }}</mat-label>
      <mat-select [(value)]="selectedMailAccountId" (selectionChange)="onAccountChange()">
        <mat-option *ngFor="let account of accounts" [value]="account.mailAccountId">
          {{ account.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <app-error-hint
      *ngIf="cannotSave"
      errorText="{{ 'cannotReassignErrorPopupSubtitle' | translate }}"
      [centerAlign]="false"></app-error-hint>
  </div>
</mat-dialog-content>
<div mat-dialog-actions align="end" class="mat-mdc-dialog-actions mdc-dialog__actions">
  <button mat-button mat-flat-button color="primary" (click)="confirm()" [disabled]="saving || isLoading || cannotLoad || isLoadingFolders">
<!--    <app-loading-button text="{{ 'moveReassign' | translate }}" [loading]="saving"></app-loading-button>-->
  </button>
</div>
