import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatchError } from 'src/app/services/errors/error-matcher';
import { DispatchingRuleClient } from '../../../../../../sdk/clients/dispatchingrule.client';

@Component({
  selector: 'app-dispatching-rule-delete-dialog',
  templateUrl: './dispatching-rule-delete-dialog.component.html',
  styleUrls: ['./dispatching-rule-delete-dialog.component.scss'],
})
export class DispatchingRuleDeleteDialogComponent implements OnInit {
  public isLoading = false;
  public errorText: string;

  public handlingErrorCodes = new Map<number, string>([]);

  constructor(
    public dialogRef: MatDialogRef<DispatchingRuleDeleteDialogComponent>,
    public matchError: MatchError,
    @Inject(MAT_DIALOG_DATA) public data: { dispatchingRuleId: number; dispatchingRuleName: string },
    private dispatchingRuleClient: DispatchingRuleClient,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {}

  onCancelBtnClicked(): void {
    this.dialogRef.close({ isDeleted: false });
  }

  async onDeleteBtnClicked(): Promise<void> {
    this.isLoading = true;
    try {
      await this.dispatchingRuleClient.delete({ id: this.data.dispatchingRuleId });
      this.dialogRef.close({ isDeleted: true });
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.isLoading = false;
    }
  }
}
