import { Injectable } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { delay, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MobileObserverService {
  constructor(private observer: BreakpointObserver) {}

  public mobileObserver(): Observable<boolean> {
    return this.observer.observe(['(max-width: 800px)']).pipe(
      map((res) => {
        return res.matches;
      })
    );
  }

  public tabletObserver(): Observable<boolean> {
    return this.observer.observe(['(max-width: 960px)']).pipe(
      map((res) => {
        return res.matches;
      })
    );
  }
}
