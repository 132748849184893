<div class="breadcrumbs-container">
  <app-breadcrumb [items]="breadcrumbItems"></app-breadcrumb>
</div>
<div>
  <app-navigation-tabs [tabItems]="tabItems"></app-navigation-tabs>
</div>
<div>
<!--  <app-drawer [isOpen]="createDrawerIsOpen" (closeEventEmitter)="closeDrawer()">-->
<!--    <app-mail-account-create-dialog-->
<!--      *ngIf="createDrawerIsOpen"-->
<!--      [organizationId]="organizationId"-->
<!--      (closeEventEmitter)="onCloseCreateModalForm()"-->
<!--      (creationSubmit)="onCreateMailAccount()">-->
<!--    </app-mail-account-create-dialog>-->
<!--  </app-drawer>-->

<!--  <app-drawer [isOpen]="showAddMailAccountsToUsersForm" (closeEventEmitter)="onDrawerClose()">-->
<!--    <app-add-mail-accounts-to-users-form-->
<!--      *ngIf="showAddMailAccountsToUsersForm"-->
<!--      (close)="onHideAddMailAccountsToUsersForm()"-->
<!--      (submit)="onAddMailAccountsToUsers()"-->
<!--      [mailAccounts]="selection.selected"-->
<!--      [organizationID]="organizationId">-->
<!--    </app-add-mail-accounts-to-users-form>-->
<!--  </app-drawer>-->

  <app-loader *ngIf="navigationIsLoading"></app-loader>
  <div *ngIf="cannotLoadNavigation" class="navigation-error-container">
    <app-something-went-wrong description="Cannot load navigation" [showPicture]="false" (retryEmitter)="retry()">
    </app-something-went-wrong>
  </div>

  <div class="page-content">
    <div class="search-panel flex optional-wrap">
      <div class="button-group">
        <button
          *ngIf="canManageMailAccounts"
          mat-raised-button
          color="primary"
          class="page-block-button w-full"
          aria-label="New mail account"
          (click)="onCreateMailAccountBtnClicked()">
          <mat-icon>add</mat-icon>{{ "newMailAccount" | translate }}
        </button>
        <!-- <button
          color="white"
          *ngIf="!selection.isEmpty()"
          mat-mini-fab
          class="page-block-button"
          mat-icon-button
          aria-label="{{ 'addMailAccountsToUsers' | translate }}"
          matTooltip="{{ 'addMailAccountsToUsers' | translate }}"
          (click)="onAddMailAccountsToUsersBtnClicked()">
          <mat-icon>person_add</mat-icon>
        </button> -->
      </div>
      <div class="flex w-full items-center pl-1" style="min-height: 40px;">
        <div class="w-fit">{{"totalSpaceOccupies" | translate}}</div>
        <div *ngIf="!isLoading" class="blue ml-4 font-bold">
          {{spaceUsed}} GB
        </div>
      </div>
      <div class="mobile-view search-bar">
        <app-search-bar
          [fullWidth]="true"
          (searchEventEmitter)="loadMailAccounts(true)"
          (valueChangedEventEmitter)="onSearchValueChanged($event)"
          [search]="searchValue"></app-search-bar>
      </div>
      <div class="not-mobile-view search-bar">
        <app-search-bar
          [fullWidth]="false"
          (searchEventEmitter)="loadMailAccounts(true)"
          (valueChangedEventEmitter)="onSearchValueChanged($event)"
          [search]="searchValue"></app-search-bar>
      </div>
      <div
        *ngIf="useMobileView"
        class="per-page-block flex items-center"
        style="padding-bottom: -16px;  height: 40px;">
        <button mat-icon-button (click)="reload()" matTooltip="{{ 'reload' | translate }}">
          <mat-icon>refresh</mat-icon>
        </button>
        <mat-form-field style="width: 112px;">
          <mat-select class="text-center" [(value)]="searchOrder" (valueChange)="searchOrderChange()">
            <mat-option *ngFor="let searchOrder of searchOrders" [value]="searchOrder.id">
              {{ searchOrder.name | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-icon-button (click)="changeOrder()" matTooltip="{{ 'order' | translate }}">
          <mat-icon [class.reverse]="filter.orderDirection===2">keyboard_arrow_up</mat-icon>
        </button>
      </div>
    </div>

    <div>
      <div class="mobile-view">
        <div class="buttons-mobile-container nav-panel">
          <mat-checkbox
            color="primary"
            class="mb-2 mt-4"
            [checked]="selection.hasValue() && isAllSelected()"
            (change)="$event ? masterToggle() : null"
            [indeterminate]="selection.hasValue() && !isAllSelected()">
            {{ "selectAll" | translate }}
          </mat-checkbox>
          <mat-paginator
            [pageSize]="pageSize"
            [pageSizeOptions]="[25, 50, 100]"
            [length]="totalSize"
            [pageIndex]="pageIndex"
            (page)="handlePage($event)">
          </mat-paginator>
        </div>
        <div *ngIf="!isLoading && !hasError">
          <div class="page-block" *ngFor="let mailAccount of mailAccounts">
            <div class="card">
              <div class="checkbox-col">
                <mat-checkbox
                  color="primary"
                  class="mobile-card-title-checkbox"
                  (click)="$event.stopPropagation()"
                  (change)="$event ? selection.toggle(mailAccount) : null"
                  [checked]="selection.isSelected(mailAccount)">
                </mat-checkbox>
              </div>
              <mat-card class="mobile-row">
                <mat-card-content class="cursor-pointer flex flex-1" (click)="redirectToDetails(mailAccount.mailAccountId)">
                  <div class="card-table-row">
                    <div class="card-table-row-header">{{ "name" | translate }}:</div>
                    <div class="card-table-row-value">{{ mailAccount.name }}</div>
                  </div>
                  <div class="card-table-row">
                    <div class="card-table-row-header">{{ "license" | translate }}:</div>
                    <div class="card-table-row-value">{{ mailAccount.license }}</div>
                  </div>
                  <div class="card-table-row">
                    <div class="card-table-row-header">{{ "codeSia" | translate }}:</div>
                    <div class="card-table-row-value">{{ mailAccount.codeSia }}</div>
                  </div>
                  <div class="card-table-row">
                    <div class="card-table-row-header">{{ "anagId" | translate }}:</div>
                    <div class="card-table-row-value">{{ mailAccount.anagId }}</div>
                  </div>
                  <div class="card-table-row">
                    <div class="card-table-row-header">{{ "email" | translate }}:</div>
                    <div class="card-table-row-value">{{ mailAccount.email }}</div>
                  </div>
                  <div class="card-table-row">
                    <div class="card-table-row-header">{{ "providerName" | translate }}:</div>
                    <div class="card-table-row-value">{{ mailAccount.providerName }}</div>
                  </div>
                  <div class="card-table-row">
                    <div class="card-table-row-header">{{ "emailState" | translate }}:</div>
                    <div class="card-table-row-value">{{ mailAccount.isActive ? ("active" | translate) : ("notActive" | translate) }}</div>
                  </div>
                  <div class="card-table-row">
                    <div class="card-table-row-header">{{ "archive" | translate }}:</div>
                    <div class="card-table-row-value">{{ mailAccount.archive ? ("active" | translate) : ("notActive" | translate) }}</div>
                  </div>
                </mat-card-content>

                <div style="width: 40px; margin-left: 10px">
                  <button
                    *ngIf="!iscredemISAMSupport"
                    mat-icon-button
                    color="primary"
                    aria-label="{{ 'addMailAccountToUser' | translate }}"
                    matTooltip="{{ 'addMailAccountToUser' | translate }}"
                    (click)="onAddMailAccountToUsersBtnClicked(mailAccount)">
                    <mat-icon>person_add</mat-icon>
                  </button>
                  <a
                    mat-icon-button
                    color="primary"
                    aria-label="Details"
                    [routerLink]="getDetailsLink(mailAccount.mailAccountId)"
                    matTooltip="{{ 'info' | translate }}">
                    <mat-icon>info_outline</mat-icon>
                  </a>
                  <!-- <button mat-icon-button *ngIf="canManageMailAccounts" color="primary" aria-label="Details"
                  (click)="deleteMailAccount(mailAccount.mailAccountId, mailAccount.name)">
                  <mat-icon>delete_outline</mat-icon>
                </button> -->
                </div>
              </mat-card>
            </div>
          </div>
        </div>
        <app-no-data *ngIf="mailAccounts.length === 0 && !isLoading && !hasError"></app-no-data>
      </div>

      <div class="page-block" *ngIf="!useMobileView">
        <div class="table-container">
          <div class="flex justify-end items-center">
            <div
              class="per-page-block flex items-center"
              style="padding-bottom: -18px;  height: 40px;">
              <button mat-icon-button (click)="reload()" matTooltip="{{ 'reload' | translate }}">
                <mat-icon>refresh</mat-icon>
              </button>
              <mat-form-field style="width: 112px;">
                <mat-select class="text-center" [(value)]="searchOrder" (valueChange)="searchOrderChange()">
                  <mat-option *ngFor="let searchOrder of searchOrders" [value]="searchOrder.id">
                    {{ searchOrder.name | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <button mat-icon-button (click)="changeOrder()" matTooltip="{{ 'order' | translate }}">
                <mat-icon [class.reverse]="filter.orderDirection===2">keyboard_arrow_up</mat-icon>
              </button>
            </div>
            <mat-paginator
              [pageSize]="pageSize"
              [pageSizeOptions]="[25, 50, 100]"
              [length]="totalSize"
              [pageIndex]="pageIndex"
              (page)="handlePage($event)"
            >
            </mat-paginator>
          </div>
          <table *ngIf="!isLoading && !hasError" mat-table [dataSource]="dataSource" class="w-full overflow-auto">
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox
                  color="primary"
                  (change)="$event ? masterToggle() : null"
                  [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox
                  color="primary"
                  (click)="$event.stopPropagation()"
                  (change)="$event ? selection.toggle(row) : null"
                  [checked]="selection.isSelected(row)">
                </mat-checkbox>
              </td>
            </ng-container>
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>{{ "name" | translate }}
              </th>
              <td mat-cell *matCellDef="let mailAccount" (click)="redirectToDetails(mailAccount.mailAccountId)">{{ mailAccount.name }}
              </td>
            </ng-container>
            <ng-container matColumnDef="license">
              <th
                [class.display-none]="useTabletView"
                mat-header-cell
                *matHeaderCellDef>{{ "license" | translate }}</th>
              <td
                [class.display-none]="useTabletView"
                mat-cell
                *matCellDef="let mailAccount"
                (click)="redirectToDetails(mailAccount.mailAccountId)">
                  {{ mailAccount.license }}
              </td>
            </ng-container>
            <ng-container matColumnDef="codeSia">
              <th
                [class.display-none]="useTabletView"
                mat-header-cell
                *matHeaderCellDef>{{ "codeSia" | translate }}</th>
              <td
                [class.display-none]="useTabletView"
                mat-cell
                *matCellDef="let mailAccount"
                (click)="redirectToDetails(mailAccount.mailAccountId)">
                  {{ mailAccount.codeSia }}
              </td>
            </ng-container>
            <ng-container matColumnDef="anagId">
              <th
                [class.display-none]="useTabletView"
                mat-header-cell
                *matHeaderCellDef>{{ "anagId" | translate }}</th>
              <td
                [class.display-none]="useTabletView"
                mat-cell
                *matCellDef="let mailAccount"
                (click)="redirectToDetails(mailAccount.mailAccountId)">
                  {{ mailAccount.anagId }}
              </td>
            </ng-container>
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef>{{ "email" | translate }}</th>
              <td mat-cell *matCellDef="let mailAccount" (click)="redirectToDetails(mailAccount.mailAccountId)">{{ mailAccount.email }}</td>
            </ng-container>
            <ng-container matColumnDef="provider-name">
              <th mat-header-cell *matHeaderCellDef>
                <div class="flex  items-center">{{ "providerName" | translate }}
                  <button mat-icon-button [matMenuTriggerFor]="menuName">
                    <mat-icon class="icon" [class.blue]="filter.ProviderId">filter_list</mat-icon>
                  </button>
                </div>
              </th>
              <td mat-cell *matCellDef="let mailAccount" (click)="redirectToDetails(mailAccount.mailAccountId)">
                {{ mailAccount.providerName }}
              </td>
            </ng-container>
            <ng-container matColumnDef="is-active">
              <th mat-header-cell *matHeaderCellDef>
                <div class="flex items-center">{{ "emailState" | translate }}
                  <button mat-icon-button [matMenuTriggerFor]="menuState">
                    <mat-icon
                      class="icon"
                      [class.blue]="filter.isActive!==null || filter.isConfigurationCompleted===false"
                    >
                      filter_list
                    </mat-icon>
                  </button>
                </div>
              </th>
              <td mat-cell *matCellDef="let mailAccount" (click)="redirectToDetails(mailAccount.mailAccountId)">
                <span
                  class="flex gap-[0.5rem] {{ mailAccount.configurationCompleted===false ? 'text-red-600' : ''}}"
                >
                  {{ mailAccount.configurationCompleted===false ? ("notConfigured" | translate) : mailAccount.isActive ? ("active" | translate) : ("notActive" | translate) }}
                  <mat-icon
                    *ngIf="mailAccount.configurationCompleted===false"
                    matTooltip="{{ 'emailAccountIsNotCompleted' | translate }}"
                  >
                    warning
                  </mat-icon>
                </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="archive">
              <th mat-header-cell *matHeaderCellDef>
                <div class="flex  items-center">{{ "archive" | translate }}
                  <button mat-icon-button [matMenuTriggerFor]="menuArchive">
                    <mat-icon class="icon" [class.blue]="filter.isArchive!==null">filter_list</mat-icon>
                  </button>
                </div>
              </th>
              <td mat-cell *matCellDef="let mailAccount" (click)="redirectToDetails(mailAccount.mailAccountId)">
                {{ mailAccount.archive ? ("active" | translate) : ("notActive" | translate) }}
              </td>
            </ng-container>
            <ng-container matColumnDef="space">
              <th mat-header-cell *matHeaderCellDef>{{ "spaceUsedGB" | translate }}
              </th>
              <td mat-cell *matCellDef="let mailAccount" (click)="redirectToDetails(mailAccount.mailAccountId)">
                {{ mailAccount.spaceUsed }}
              </td>
            </ng-container>
            <ng-container matColumnDef="connect">
              <th mat-header-cell *matHeaderCellDef>
                <div class="flex items-center">{{ "connect" | translate }}
                  <button mat-icon-button [matMenuTriggerFor]="menuConnect">
                    <mat-icon class="icon" [class.blue]="!!filter.connectionStatus">filter_list</mat-icon>
                  </button>
                </div>
              </th>
              <td mat-cell *matCellDef="let mailAccount">
                <span
                  class="flex gap-[0.5rem] {{ connectionStatusName[mailAccount.connectionStatus].color }}"
                >
                  {{ connectionStatusName[mailAccount.connectionStatus].title | translate }}
                  <mat-icon
                    *ngIf="mailAccount.connectionStatusInfo || mailAccount.connectionStatus === 3"
                    matTooltip="{{ mailAccount.connectionStatus === 3
                       ? (connectionStatusName[mailAccount.connectionStatus].info | translate)
                       : (connectionStatusInfo[mailAccount.connectionStatusInfo] | translate) }}"
                  >
                    warning
                  </mat-icon>
                </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef>{{ "actions" | translate }}</th>
              <td mat-cell *matCellDef="let mailAccount">
                <button
                  *ngIf="!iscredemISAMSupport"
                  mat-icon-button
                  color="primary"
                  aria-label="{{ 'addMailAccountToUser' | translate }}"
                  matTooltip="{{ 'addMailAccountToUser' | translate }}"
                  (click)="onAddMailAccountToUsersBtnClicked(mailAccount)">
                  <mat-icon>person_add</mat-icon>
                </button>
                <a
                  mat-icon-button
                  aria-label="Details"
                  [routerLink]="getDetailsLink(mailAccount.mailAccountId)"
                  matTooltip="{{ 'info' | translate }}">
                  <mat-icon>info_outline</mat-icon>
                </a>
                <!-- <button  *ngIf="canManageMailAccounts" mat-icon-button aria-label="Details"
                  (click)="deleteMailAccount(mailAccount.mailAccountId, mailAccount.name)">
                  <mat-icon>delete_outline</mat-icon>
                </button> -->
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" class="cursor-pointer"></tr>
          </table>
          <app-no-data *ngIf="mailAccounts.length === 0 && !isLoading && !hasError"></app-no-data>
        </div>
      </div>
    </div>
  </div>

  <app-loader *ngIf="isLoading"></app-loader>
  <div *ngIf="hasError" class="half-of-screen">
    <app-something-went-wrong
      description="{{ 'cannotLoadMailAccounts' | translate }}"
      [showPicture]="false"
      (retryEmitter)="loadMailAccounts(true)"></app-something-went-wrong>
  </div>
</div>

<mat-menu #menuName>
  <button mat-menu-item *ngFor="let provider of providers" (click)="searchProviderChange(provider);">
    <span [class.blue]="filter.ProviderId===provider.val">{{provider.name}}</span>
  </button>
</mat-menu>

<mat-menu #menuState (click)="$event.stopPropagation();">
  <button
    *ngFor="let status of menuStateList; index as indexStatus"
    mat-menu-item
    (click)="setFilterChange(status.field, status.value, status.fieldRemove)"
  >
    <span [class.blue]="filter[status.field] === status.value">{{ status.title | translate}}</span>
  </button>
</mat-menu>

<mat-menu #menuArchive (click)="$event.stopPropagation();">
  <button mat-menu-item (click)="setFilterChange('isArchive',true)">
    <span [class.blue]="filter.isArchive">{{"active" | translate}}</span>
  </button>
  <button mat-menu-item (click)="setFilterChange('isArchive',false);">
    <span [class.blue]="filter.isArchive===false">{{"notActive" | translate}}</span>
  </button>
</mat-menu>

<mat-menu #menuConnect (click)="$event.stopPropagation();">
  <button
    *ngFor="let connectStatus of menuConnectionStatus; index as indexConnect"
    mat-menu-item
    (click)="setFilterChange('connectionStatus',indexConnect + 1)"
  >
    <span [class.blue]="filter.connectionStatus === indexConnect + 1">{{ connectStatus.title | translate}}</span>
  </button>
</mat-menu>
