export enum RoleType {
  // User roles
  Default = 1,
  Root = 2,

  // Organization roles
  Rta = 3,

  // MailAccount roles
  SuperUser = 4,
  User = 5,
  Validator = 6,
  Creator = 7,

  LimitedUser = 8,
  LimitedCreator = 9,
}
