import { Injectable, OnDestroy } from '@angular/core';
import { AuthenticationService } from './auth/authentication.service';
import { WEB_CODE } from '../shared/constants/constants';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export abstract class IntervalService implements OnDestroy {
  private intervalIds: { [key: string]: any } = {};

  protected constructor(
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
  ) {
  }

  startInterval(key: string, callback: () => void, interval: number): void {
    this.clearInterval(key);
    this.intervalIds[key] = setInterval(() => {
      if (this.authenticationService.isLoggedIn()) {
        callback();
      } else {
        const webCode = this.route.parent.snapshot.paramMap.get(WEB_CODE);
        console.log(webCode);
        this.authenticationService.logout(webCode);
      }
    }, interval);
  }

  clearInterval(key: string): void {
    if (this.intervalIds[key]) {
      clearInterval(this.intervalIds[key]);
      delete this.intervalIds[key];
    }
  }

  clearAllIntervals(): void {
    for (const key in this.intervalIds) {
      this.clearInterval(key);
    }
  }

  ngOnDestroy(): void {
    this.clearAllIntervals();
  }
}
