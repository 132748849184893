(function (e) {
  const i = (e["it"] = e["it"] || {});
  i.dictionary = Object.assign(i.dictionary || {}, {
    "%0 of %1": "%0 di %1",
    Aquamarine: "Aquamarina",
    Black: "Nero",
    "Block quote": "Blocco citazione",
    Blue: "Blu",
    Bold: "Grassetto",
    "Break text": "Interrompi testo",
    "Bulleted List": "Elenco puntato",
    Cancel: "Annulla",
    "Cannot upload file:": "Impossibile caricare il file:",
    "Centered image": "Immagine centrata",
    "Change image text alternative": "Cambia testo alternativo dell'immagine",
    "Choose heading": "Seleziona intestazione",
    Column: "Colonna",
    "Could not insert image at the current position.": "Non è stato possibile inserire l'immagine nella posizione corrente.",
    "Could not obtain resized image URL.": "Non è stato possibile ottenere l'URL dell'immagine ridimensionata.",
    "Decrease indent": "Riduci rientro",
    "Delete column": "Elimina colonna",
    "Delete row": "Elimina riga",
    "Dim grey": "Grigio tenue",
    Downloadable: "Scaricabile",
    "Dropdown toolbar": "Barra degli strumenti del menu a discesa",
    "Edit block": "Modifica blocco",
    "Edit link": "Modifica collegamento",
    "Editor toolbar": "Barra degli strumenti dell'editor",
    "Enter image caption": "inserire didascalia dell'immagine",
    "Full size image": "Immagine a dimensione intera",
    Green: "Verde",
    Grey: "Grigio",
    "Header column": "Intestazione colonna",
    "Header row": "Riga d'intestazione",
    Heading: "Intestazione",
    "Heading 1": "Intestazione 1",
    "Heading 2": "Intestazione 2",
    "Heading 3": "Intestazione 3",
    "Heading 4": "Intestazione 4",
    "Heading 5": "Intestazione 5",
    "Heading 6": "Intestazione 6",
    "Image toolbar": "Barra degli strumenti dell'immagine",
    "image widget": "Widget immagine",
    "In line": "In linea",
    "Increase indent": "Aumenta rientro",
    "Insert column left": "Inserisci colonna a sinistra",
    "Insert column right": "Inserisci colonna a destra",
    "Insert image": "Inserisci immagine",
    "Insert image or file": "Inserisci immagine o file",
    "Insert media": "Inserisci media",
    "Insert paragraph after block": "Inserisci paragrafo dopo blocco",
    "Insert paragraph before block": "Inserisci paragrafo prima di blocco",
    "Insert row above": "Inserisci riga sopra",
    "Insert row below": "Inserisci riga sotto",
    "Insert table": "Inserisci tabella",
    "Inserting image failed": "L'inserimento dell'immagine è fallito",
    Italic: "Corsivo",
    "Left aligned image": "Immagine allineata a sinistra",
    "Light blue": "Azzurro",
    "Light green": "Verde chiaro",
    "Light grey": "Grigio chiaro",
    Link: "Collegamento",
    "Link URL": "URL del collegamento",
    "Media URL": "URL media",
    "media widget": "widget media",
    "Merge cell down": "Unisci cella sotto",
    "Merge cell left": "Unisci cella a sinistra",
    "Merge cell right": "Unisci cella a destra",
    "Merge cell up": "Unisci cella sopra",
    "Merge cells": "Unisci celle",
    Next: "Avanti",
    "Numbered List": "Elenco numerato",
    "Open in a new tab": "Apri in una nuova scheda",
    "Open link in new tab": "Apri collegamento in nuova scheda",
    Orange: "Arancio",
    Paragraph: "Paragrafo",
    "Paste the media URL in the input.": "Incolla l'URL del file multimediale nell'input.",
    Previous: "Indietro",
    Purple: "Porpora",
    Red: "Rosso",
    Redo: "Ripristina",
    "Rich Text Editor": "Editor di testo formattato",
    "Rich Text Editor, %0": "Editor di testo formattato, %0",
    "Right aligned image": "Immagine allineata a destra",
    Row: "Riga",
    Save: "Salva",
    "Select all": "Seleziona tutto",
    "Select column": "Seleziona colonna",
    "Select row": "Seleziona riga",
    "Selecting resized image failed": "La selezione dell'immagine ridimensionata è fallita",
    "Show more items": "Mostra più elementi",
    "Side image": "Immagine laterale",
    "Split cell horizontally": "Dividi cella orizzontalmente",
    "Split cell vertically": "Dividi cella verticalmente",
    "Table toolbar": "Barra degli strumenti della tabella",
    "Text alternative": "Testo alternativo",
    "The URL must not be empty.": "L'URL non può essere vuoto.",
    "This link has no URL": "Questo collegamento non ha un URL",
    "This media URL is not supported.": "Questo URL di file multimediali non è supportato.",
    "Tip: Paste the URL into the content to embed faster.": "Consiglio: incolla l'URL nel contenuto per un'incorporazione più veloce.",
    "Toggle caption off": "Attiva didascalia",
    "Toggle caption on": "Disattiva didascalia",
    Turquoise: "Turchese",
    Undo: "Annulla",
    Unlink: "Elimina collegamento",
    "Upload failed": "Caricamento fallito",
    "Upload in progress": "Caricamento in corso",
    White: "Bianco",
    "Widget toolbar": "Barra degli strumenti del widget",
    "Wrap text": "Testo a capo",
    "Text alignment": "Allineamento testo",
    "Align left": "Allineamento a sinistra",
    "Align right": "Allineamento a destra",
    "Align center": "Allineamento centrale",
    Decimal: "Decimale",
    "Decimal with leading zero": "Decimale con zero davanti",
    "Lower–roman": "Romano minuscolo",
    "Upper-roman": "Romano maiuscolo",
    "Lower-latin": "Latino minuscolo",
    "Upper-latin": "Latino maiuscolo",
    "Font Family": "Tipo di carattere",
    "Font Size": "Dimensione carattere",
    Tiny: "Molto piccolo",
    Small: "Piccolo",
    Default: "Normale",
    Big: "Grande",
    Huge: "Molto grande",
    "Font Color": "Colore carattere",
    "Font Background Color": "Colore sfondo carattere",
    "Remove color": "Rimuovi colore",
    Disc: "Disco",
    "Horizontal line": "Linea orizzontale",
    "Remove Format": "Rimuovi formattazione",
    Code: "Codice",
    "Insert paragraph before block": "Inserisci paragrafo prima della linea",
    "Insert paragraph after block": "Inserisci paragrafo dopo la linea",
    Circle: "Cerchio",
    Square: "Quadrato",
    Justify: "Giustificato",
    Underline: "Sottolineato",
    Strikethrough: "Barrato",
    Subscript: "Pedice",
    Superscript: "Apice",
    Yellow: "Giallo",
  });
  i.getPluralForm = function (e) {
    return e == 1 ? 0 : e != 0 && e % 1e6 == 0 ? 1 : 2;
  };
})(window.CKEDITOR_TRANSLATIONS || (window.CKEDITOR_TRANSLATIONS = {}));
