import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiException } from '../../../../services/errors/api.exception';
import { ErrorCode } from '../../../../sdk/api-errors/error-code';
import { OrganizationClient } from '../../../../sdk/clients/organization.client';
import { ErrorDialogComponent } from '../../../error/error-dialog/error-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatchError } from 'src/app/services/errors/error-matcher';

@Component({
  selector: 'app-organization-delete-dialog',
  templateUrl: './organization-delete-dialog.component.html',
  styleUrls: ['./organization-delete-dialog.component.scss'],
})
export class OrganizationDeleteDialogComponent implements OnInit {
  public isLoading = false;
  public hasError = false;
  public errorCode: ErrorCode | null = null;
  public handlingErrors = {
    [ErrorCode.OrganizationHasMailAccounts]: 'Cannot delete company: company has mail accounts',
    [ErrorCode.OrganizationHasUsers]: 'Cannot delete company: company has users',
    [ErrorCode.CannotDeleteOrganizationWithChildren]: 'Cannot delete company: company has children organizations',
  };

  constructor(
    public matchError: MatchError,
    private organizationClient: OrganizationClient,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<OrganizationDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { organizationId: number; organizationName: string }
  ) {}

  ngOnInit(): void {}

  onCancelBtnClicked(): void {
    this.dialogRef.close({ isDeleted: false });
  }
  public openErrorDialog(title: string, description: string): void {
    this.dialog.open(ErrorDialogComponent, {
      width: '300px',
      data: { title: title, description: description },
    });
  }
  async onDeleteBtnClicked(): Promise<void> {
    this.isLoading = true;
    this.hasError = false;
    this.errorCode = null;
    try {
      await this.organizationClient.delete({ id: this.data.organizationId });
      this.dialogRef.close({ isDeleted: true });
    } catch (e) {
      this.hasError = true;
      if (e instanceof ApiException) {
        this.errorCode = e.Code;
      }
      this.openErrorDialog('error', this.errorText);
      this.matchError.logError(e);
    } finally {
      this.isLoading = false;
    }
  }
  public get errorText(): string | null {
    if (!this.hasError) {
      return null;
    }

    const defaultErrorText = 'cannotDeleteCompanyErrorPopupSubtitle';

    if (this.errorCode) {
      const textForHandingError = this.handlingErrors[this.errorCode];
      return textForHandingError ?? defaultErrorText;
    }

    return defaultErrorText;
  }
}
