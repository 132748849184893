<div *ngIf="isLoading" class="w-full h-full">
  <app-loader height="100%"></app-loader>
</div>

<div *ngIf="cannotLoadData">
  <app-something-went-wrong
    [showPicture]="false"
    (retryEmitter)="loadData()"></app-something-went-wrong>
</div>

<div *ngIf="!isLoading && !cannotLoadData" class="block p-[15px] w-full">
  <form [formGroup]="form" autocomplete="off" class="xl:w-[50%] w-full mt-6 relative" novalidate>
    <h1>{{ "reports" | translate }}</h1>
    <div class="mt-8">
      <mat-form-field  class="w-full">
        <mat-label>{{ "reportType" | translate }}</mat-label>
        <mat-select formControlName="reportType" required>
          <mat-option *ngFor="let report of reportTypes" [value]="report.value">
            {{ report.name | translate }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="form.controls['reportType'].hasError('required')">{{ "chooseAnReport" | translate }}</mat-error>
      </mat-form-field>
    </div>

    <div class="mt-4">
      <mat-form-field  class="w-full">
        <mat-label>{{ "accountReports" | translate }}</mat-label>
        <mat-select formControlName="mailsControl" #select multiple>
          <mat-option *ngIf="accounts.length" #allSelected (click)="toggleAllSelection()" [value]="0">{{ "all" | translate }}</mat-option>
          <mat-option (click)="togglePerOne(allSelected.viewValue)" *ngFor="let account of accounts" [value]="account.mailAccountEmail">
            {{ account.mailAccountEmail }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="form.controls.mailsControl.hasError('required')">{{ "chooseAnAccount" | translate }}</mat-error>
      </mat-form-field>
    </div>

    <div class="mt-4">
      <mat-form-field  class="w-full">
        <mat-label>{{ "enterADateRange" | translate }}</mat-label>
        <mat-date-range-input formGroupName="range" [rangePicker]="picker">
          <input matStartDate formControlName="start" placeholder="Start date" />
          <input matEndDate formControlName="end" placeholder="End date" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
        <mat-error *ngIf="form.controls.range.controls.start.hasError('matStartDateInvalid')"
          >{{ "invalidStartDate" | translate }}
        </mat-error>
        <mat-error *ngIf="form.controls.range.controls.end.hasError('matEndDateInvalid')">{{ "invalidEndDate" | translate }}</mat-error>
      </mat-form-field>
    </div>
    <button
      mat-flat-button
      color="primary"
      class="custom-button"
      [disabled]="isSendingRequest || isLoading || !form.valid"
      (click)="onSubmit(form.value)">
<!--      <app-loading-button text="{{ 'generate' | translate }}" [loading]="isSendingRequest"></app-loading-button>-->
    </button>
  </form>
</div>
