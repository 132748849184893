import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiRequest } from '../../services/api/api.request';
import { ApiRoutes } from '../routes/api.routes';
import { FileCreateRequest } from '../contracts/file/file.create.request';
import { FileCreateResponse } from '../contracts/file/file.create.response';
import { FileRefContract } from '../contracts/file/file-ref.contract';
import { BaseGetByEmailFileIdRequest } from '../contracts/common/base-get-by-email-file-id.request';
import { Observable } from 'rxjs';
import { MultipleFileCreateRequest } from '../contracts/file/multiple-file-create.request';
import { BaseGetCollectionResponse } from '../contracts/common/base-get-collection.response';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class FileClient {
  constructor(private apiRequest: ApiRequest) {}

  async create(request: FileCreateRequest): Promise<FileCreateResponse> {
    return this.apiRequest.post<FileCreateRequest, FileCreateResponse>(request, `${API_URL}${ApiRoutes.emailFile.create}`);
  }

  async getMeta(request: BaseGetByEmailFileIdRequest): Promise<any> {
    return this.apiRequest.get<FileRefContract>(`${API_URL}${ApiRoutes.emailFile.getMeta}?${request.format()}`);
  }

  async download(request: BaseGetByEmailFileIdRequest): Promise<void> {
    // const result = await this.apiRequest.download(`${API_URL}${ApiRoutes.emailFile.get}?${request.format()}`);
    // const data = new Blob([new Uint8Array(result)], { type: mimeType });
    // const objectUrl = window.URL.createObjectURL(data);
    // const anchor: HTMLAnchorElement = document.createElement('a');
    // anchor.href = objectUrl;
    // anchor.target = '_blank';
    // anchor.download = fileName;
    // anchor.click();
    // window.URL.revokeObjectURL(objectUrl);

    const anchor: HTMLAnchorElement = document.createElement('a');
    anchor.href = `${API_URL}${ApiRoutes.emailFile.get}?${request.format()}`;
    anchor.target = '_blank';
    anchor.click();
  }

  upload(request: FileCreateRequest): Observable<any> {
    return this.apiRequest.upload<FileCreateRequest, Observable<any>>(request, `${API_URL}${ApiRoutes.emailFile.create}`);
  }

  async multipleCreate(request: MultipleFileCreateRequest): Promise<BaseGetCollectionResponse<number>> {
    const formData = new FormData();
    const name = Object.keys(request)[0];
    request.file.forEach((x) => formData.append(name, x));

    return this.apiRequest.post<FormData, BaseGetCollectionResponse<number>>(formData, `${API_URL}${ApiRoutes.emailFile.multipleCreate}`);
  }
}
