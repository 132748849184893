import { Component, ElementRef, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { UntypedFormControl } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

import { EmailTagSaveRequest } from '../../sdk/contracts/email-tags/email-tag-save.request';
import { EmailTagClient } from '../../sdk/clients/email-tag.client';
import { MatchError } from 'src/app/services/errors/error-matcher';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss'],
})
export class TagsComponent implements OnInit {
  @Input() existedTags: string[];
  @Input() thisTags: string[];
  @Input() emailId: number[];
  @Input() needSaveTags = true;
  @Output() private setIsSave = new EventEmitter();
  @Output() private setTags = new EventEmitter();
  tagCtrl = new UntypedFormControl();
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  public filteredTags: string[];
  filteredTagsObs: Observable<string[]>;
  public isSavingTags = false;
  public isError = false;
  public errorText: any;
  public newTags = [];

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(public matchError: MatchError, private emailTagClient: EmailTagClient) {
  }

  ngOnInit(): void {
    this.filteredTags = this.existedTags.filter((e) => !this.thisTags.includes(e));

    this.filteredTagsObs = this.tagCtrl.valueChanges.pipe(
      startWith(null),
      map((tag: string | null) => (tag ? this._filter(tag) : this.filteredTags.slice()))
    );
  }

  async saveTags(): Promise<any> {
    this.errorText = '';
    this.isSavingTags = true;
    try {
      const uniqueTags = this.thisTags.filter((e, i, arr) => arr.indexOf(e) === i);
      if (uniqueTags.length !== this.thisTags.length) {
        this.thisTags = uniqueTags;
        throw new Error();
      }
      if (this.needSaveTags) {
        await this.emailTagClient.saveTags(new EmailTagSaveRequest(this.emailId, this.thisTags));
      }
      this.setTags.emit(this.thisTags);
      this.filteredTags = this.existedTags.filter((e) => !this.thisTags.includes(e));

      this.setIsSave.emit();
    } catch (e) {
      this.errorText = 'Something went wrong';
      this.matchError.logError(e);
    } finally {
      this.setTags.emit(this.thisTags);
      this.isSavingTags = false;
    }
  }

  public onItemAdded($event): void {
    this.newTags = this.thisTags.filter((e) => e !== $event.value);
    this.saveTags();
  }

  public onItemRemoved($event): void {
    this.newTags = this.thisTags.concat($event);

    this.saveTags();
  }

  removeTag(tag: string): void {
    const index = this.thisTags.indexOf(tag);
    if (index >= 0) {
      this.thisTags.splice(index, 1);
    }
    this.saveTags();
    this.tagInput.nativeElement.focus();
  }

  addTag(event: MatChipInputEvent): void {
      const input = event.input;

      const value = event.value;
      if ((value || '').trim()) {
        this.thisTags.push(value);
      }
      if (input) {
        input.value = '';
      }
      this.tagCtrl.setValue(null);
      this.tagInput.nativeElement.focus();
      this.saveTags();
  }

  selectedTag(event: MatAutocompleteSelectedEvent): void {
    this.thisTags.push(event.option.value);
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
    this.saveTags();
  }

  private _filter(value: string): string[] {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.filteredTags.filter((tag) => tag.toLowerCase().indexOf(filterValue) === 0);
    } else {
      const filterValue = value.toLowerCase();
      return this.filteredTags.filter((tag) => tag.toLowerCase().indexOf(filterValue) === 0);
    }
  }
}
