<div class="w-full h-full flex flex-wrap justify-center content-center rounded-lg p-5">
  <div class="text-center">
    <div class="font-bold text-2xl">{{ title || "wentWrong" | translate }}</div>
    @if(description) {
      <p class="text-gray-700 text-md mt-5">
        {{ description }}
      </p>
    }
    <div class="mt-2">
      @if (showPicture) {
        <img src="../../../../assets/something-went-wrong.svg" alt="something-went-wrong" />
      }
    </div>
    @if(showRetryButton) {
      <button mat-flat-button color="primary" (click)="onRetryBtnClicked()">{{ "retry" | translate }}</button>
    }
  </div>
</div>
