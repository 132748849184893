import { FerreroAttachmentContract } from './ferrero-attachment.contract';
export class EmailFerreroCreateRequest {
  from: string;
  subject: string;
  body: string;
  attachments: FerreroAttachmentContract[];

  constructor(from: string, subject: string, body: string, attachments: FerreroAttachmentContract[]) {
    this.from = from;
    this.subject = subject;
    this.body = body;
    this.attachments = attachments;
  }
}
