export enum PermissionType {
  // User
  CanEverything = 1,

  // Organization
  CanManageOrganization = 2,
  CanManageMailAccounts = 3,

  // MailAccount
  CanManageMailAccount = 4,
  CanCreateEmail = 5,
  CanSendEmail = 6,
  CanValidateEmail = 7,
  CanManageDispatchingRules = 8,
  CanManageTags = 9,
  CanManageFolders = 10,
  CanRead = 11,
  CanDeleteCustomFolder = 12,
  CanReassign = 13,
  CanFinalDelete = 14,
}
