import { Component, OnInit } from '@angular/core';
import { OrganizationClient } from '../../../../sdk/clients/organization.client';
import { ActivatedRoute } from '@angular/router';
import { BaseGetByIdRequest } from '../../../../sdk/contracts/common/base-get-by-id.request';
import { OrganizationContract } from '../../../../sdk/contracts/organization/organization.contract';
import { BreadcrumbItem } from '../../../../shared/breadcrumb-item';
import { PermissionService } from '../../../../services/permission/permission.service';
import { TabItem } from '../../../../shared/tab-item';
import { PermissionNavTabHelper } from '../../../../services/permission/permission-nav-tab-helper';
import { OrganizationStatisticContract } from '../../../../sdk/contracts/statistic/organization-statistic-contract';
import { StatisticClient } from '../../../../sdk/clients/statistic.client';
import { OrganizationType } from '../../../../sdk/contracts/organization/organization-type';
import { MatchError } from 'src/app/services/errors/error-matcher';

@Component({
  selector: 'app-organization-general-settings',
  templateUrl: './organization-general-settings.component.html',
  styleUrls: ['./organization-general-settings.component.scss'],
})
export class OrganizationGeneralSettingsComponent implements OnInit {
  public isLoading = false;
  public hasError = false;
  public statisticIsLoading = false;
  public statisticHasError = false;
  public organizationId: number;
  public organization: OrganizationContract;
  public statistic: OrganizationStatisticContract;
  public breadcrumbItems: BreadcrumbItem[] = [];
  public tabItems: TabItem[] = [];
  public settingsDrawerIsOpen = false;
  public organizationTypes = OrganizationType;
  public webCode: string;

  constructor(
    public matchError: MatchError,
    private organizationClient: OrganizationClient,
    private route: ActivatedRoute,
    private permissionService: PermissionService,
    private permissionNavTabHelper: PermissionNavTabHelper,
    private statisticClient: StatisticClient
  ) {}

  async ngOnInit(): Promise<void> {
    this.webCode = this.route.parent.snapshot.paramMap.get('webCode');
    this.organizationId = parseInt(this.route.snapshot.paramMap.get('organizationId'), 10);
    this.tabItems = this.permissionNavTabHelper.getOrganizationPageTabs(this.organizationId, this.webCode);
    this.breadcrumbItems = [
      { index: 0, label: 'settingsBreadCrumbs', route: `/${this.webCode}/settings/organization/${this.organizationId}/general` },
      { index: 1, label: 'generalBreadCrumbs', route: null },
    ];
    await this.loadOrganization();
    await Promise.all([this.loadStatistic()]);
  }

  public async retry(): Promise<void> {
    await this.loadOrganization();
    await Promise.all([this.loadStatistic()]);
  }

  public async loadOrganization(): Promise<void> {
    this.isLoading = true;
    this.hasError = false;
    try {
      const response = await this.organizationClient.getById(new BaseGetByIdRequest(this.organizationId));
      this.organization = response.organization;
    } catch (e) {
      this.hasError = true;
      this.matchError.logError(e);
    } finally {
      this.isLoading = false;
    }
  }

  public async loadStatistic(): Promise<void> {
    this.statisticIsLoading = true;
    this.statisticHasError = false;
    try {
      const response = await this.statisticClient.organization(new BaseGetByIdRequest(this.organizationId));
      this.statistic = response.result;
    } catch (e) {
      this.statisticHasError = true;
      this.matchError.logError(e);
    } finally {
      this.statisticIsLoading = false;
    }
  }

  openOrganizationSettingsDialog(): void {
    this.settingsDrawerIsOpen = true;
  }

  onUpdateOrganizationSettings(): void {
    this.settingsDrawerIsOpen = false;
  }

  onCloseSettingsModalForm(): void {
    this.settingsDrawerIsOpen = false;
  }

  public hasParentOrganization(): boolean {
    return !!this.organization.parentOrganizationId;
  }
}
