export enum EmailIcons {
  Seen = 1,
  Validate = 2,
  Reply = 3,
  ReplyAll = 4,
  Forward = 5,
  Groups = 6,
  Tags = 7,
  Delete = 8,
  Reassign = 9,
  Restore = 10,
  DownloadEML = 11,
  Spam = 12,
  NotSpam = 13,
  Move = 14,
  Archive = 15,
  RestoreFromArchive = 16,
  RetrySending = 17,
}
