import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MailAccountGetBadgeRequest } from 'src/app/sdk/contracts/mail-account/mail-account.get-badges.request';
import { MailAccountGetBadgeResponse } from 'src/app/sdk/contracts/mail-account/mail-account.get-badges.response';
import { PermissionService } from 'src/app/services/permission/permission.service';
import { MailAccountClient } from '../../sdk/clients/mail-account.client';
import { UserMailAccountContract } from '../../sdk/contracts/user-mail-account/user-mail-account.contract';
import { EventsService } from '../../services/events/events.service';
import { FoldersTypeCheck } from '../../services/folders-utility/folders-type-check.service';
import { SearchContextStorageService } from '../../services/search/search-context-storage.service';
import { CONNECTION_STATUS, CONNECTION_STATUS_INFO } from '../../shared/connection-status';
import { TranslateModule } from '@ngx-translate/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MailAccountFoldersComponent } from '../mail-account-folders/mail-account-folders.component';
import { EMAIL_QUERY_PARAM, WEB_CODE } from '../../shared/constants/constants';
import { UserFolderContract } from '../../sdk/contracts/mail-folder/user-folder.contract';
import { GenerateMailAccountTreeService } from '../../services/generate-mail-account-tree.service';
import { TreeItem } from '../../sdk/contracts/tree.item';
import { MailFolderContract } from '../../sdk/contracts/mail-folder/mail-folder.contract';
import { MailAccountGetUnreadCountRequest } from '../../sdk/contracts/mail-folder/mail-folder.get-unreadcount.request';
import { MailFolderClient } from '../../sdk/clients/mail-folder.client';
import { MailFolderUnreadCount } from '../../sdk/contracts/mail-folder/mail-folder.unread.count';
import { encodeInfoMailAccount, decodeInfoMailAccount } from '../../shared/utils/search-params';
import { MailFolderType } from '../../sdk/contracts/mail-folder/mail-folder.type';
import { getFolderByType } from '../../shared/utils/common';
import { filter } from 'rxjs/operators';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { IntervalService } from '../../services/interval.service';

@Component({
  selector: 'app-emails-sidenav',
  standalone: true,
  imports: [
    TranslateModule,
    MatExpansionModule,
    MatTooltipModule,
    MatIconModule,
    MailAccountFoldersComponent,
  ],
  templateUrl: './emails-side-nav.component.html',
  styleUrls: ['./emails-side-nav.component.scss'],
})
export class EmailsSideNavComponent implements OnInit, OnDestroy {
  @Input() userFolders: UserFolderContract[] = [];
  @Input() getMailAccountsError: string;
  @Input() mailAccounts: UserMailAccountContract[] = [];

  @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger: MatMenuTrigger;

  public mailAccountId: number;
  public mailAccountIds: number[] = [];
  public mailAccountBadges: MailAccountGetBadgeResponse[] = [];
  public webCode: string;
  public isTreeLoaded = false; // ??
  public hideNotification = true;
  public badgesRefreshInterval;
  // public defaultMailAccountId: number;
  menuTopLeftPosition = { x: '0', y: '0' };

  reassignedAccountIds;
  connectionStatusName = CONNECTION_STATUS;
  connectionStatusInfo = CONNECTION_STATUS_INFO;
  public openMailAccountId: number;
  tree: TreeItem<number, MailFolderContract>[] = [];
  folderCounts: { [key: string]: MailFolderUnreadCount[] } = {};
  infoOpenedMailAccount;
  // public displayedColumns: string[] = ['name', 'email', 'action'];
  // public createDrawerIsOpen = false;
  // public mailAccountIdRightClick: number | null;

  constructor(
    private mailAccountClient: MailAccountClient,
    protected route: ActivatedRoute,
    private router: Router,
    private eventsService: EventsService,
    private permissionService: PermissionService,
    private searchContextStorageService: SearchContextStorageService,
    private readonly foldersTypeCheck: FoldersTypeCheck,
    private generateMailAccountTreeService: GenerateMailAccountTreeService,
    private mailFolderClient: MailFolderClient,
    private authenticationService: AuthenticationService,
    private intervalService: IntervalService
  ) {}

  async ngOnInit(): Promise<void> {
    this.webCode = this.route.parent.snapshot.paramMap.get(WEB_CODE);
    this.setInfoOpenMAilAccount(window.location.href);

    this.mailAccountIds = this.mailAccounts.map((e) => e.mailAccountId);
    this.tree = this.generateMailAccountTreeService.buildFolderTree(this.userFolders);
    this.isTreeLoaded = true;
    // this.mailAccounts.length ? this.foldersTypeCheck.setIsAnyFolder(true) : this.foldersTypeCheck.setIsAnyFolder(false);
    this.getBadgesForMailAccounts(this.mailAccountIds);

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(event => {
        this.setInfoOpenMAilAccount(window.location.href);
      });

    // const defaultMailAccount = this.searchContextStorageService.getDefaultMailAccount();
    // if (defaultMailAccount) {
    //   this.searchContextStorageService.selectMailAccount(defaultMailAccount.mailAccountId);
    // }
    // this.eventsService.unreadCountEvent.subscribe(() => {
    //   if (this.hideNotification !== !this.eventsService.unreadCountValue) {
    //     this.hideNotification = !this.eventsService.unreadCountValue;
    //     this.getBadgesForMailAccounts(this.mailAccountIds);
    //   } else {
    //     this.hideNotification = !this.eventsService.unreadCountValue;
    //   }
    // });
    //
    // this.reassignedAccountIds = this.eventsService.reassignedAccountIds$.subscribe((data) => {
    //   if (!!data?.length) {
    //     this.getBadgesForMailAccounts(this.mailAccountIds);
    //   }
    // });
    this.intervalService.startInterval('badgesRefreshInterval', () => {
      this.getBadgesForMailAccounts(this.mailAccountIds);
      this.refreshUnread({
        mailAccountId: this.openMailAccountId,
        updateBadges: false,
      });
    }, 120000);
  }

  setInfoOpenMAilAccount(url: string): void {
    const newUrl = new URL(url);
    if (newUrl.pathname.includes('emails')) {
      const search = newUrl.searchParams.get(EMAIL_QUERY_PARAM);
      this.infoOpenedMailAccount = decodeInfoMailAccount(search);
    }
    this.openMailAccountId = this.infoOpenedMailAccount?.acc ?? this.mailAccounts?.[0]?.mailAccountId ?? null;
  }

  getMailAccountTree(mailAccountId: number): TreeItem<number, MailFolderContract>  {
    return this.tree.find(({ key }) => key === mailAccountId);
  }

  public async getBadgesForMailAccounts(mailAccountIds: number[]): Promise<void> {
    if (mailAccountIds.length) {
      const badgesForMailAccount = await this.mailAccountClient.getMailAccountsBadges(
        new MailAccountGetBadgeRequest(mailAccountIds, this.hideNotification)
      );

      badgesForMailAccount.data.forEach((elem) => {
        this.mailAccounts.map((x) => {
          if (x.mailAccountId === elem.mailAccountId) { x.spaceUsed = elem.spaceUsed ? elem.spaceUsed : 0; }
        });
      });

      badgesForMailAccount.data.forEach((e) =>
        this.mailAccountBadges.some((x) => x.mailAccountId === e.mailAccountId)
          ? (this.mailAccountBadges = this.mailAccountBadges.map(
            (x) => (x.mailAccountId === e.mailAccountId ? e : x))
          )
          : this.mailAccountBadges.push(e)
      );
      const mailAccountBadgesNeedsToBeRemoved = mailAccountIds.filter((e) => !badgesForMailAccount.data
        .some((x) => x.mailAccountId === e));
      if (mailAccountBadgesNeedsToBeRemoved.length) {
        this.mailAccountBadges = this.mailAccountBadges
          .filter((e) => !mailAccountBadgesNeedsToBeRemoved.includes(e.mailAccountId));
      }
    }
  }

  private async refreshUnread(
    { mailAccountId, updateBadges = true }: { mailAccountId: number, updateBadges?: boolean }
  ): Promise<void> {
    if (updateBadges) {
      await this.getBadgesForMailAccounts([mailAccountId]);
    }
    const counts = await this.mailFolderClient.getUnreadCount(
      new MailAccountGetUnreadCountRequest(mailAccountId, this.hideNotification)
    );
    this.folderCounts = { ...this.folderCounts, [mailAccountId]: counts?.data };
  }

  public getBadge(mailAccount: UserMailAccountContract): number {
    return this.mailAccountBadges
      .find(
        (e) => e.mailAccountId === mailAccount.mailAccountId
      )?.badge || null;
  }

  public async opened(event: any, mailAccount: UserMailAccountContract): Promise<void> {
    this.openMailAccountId = mailAccount.mailAccountId;
    await this.refreshUnread({
      mailAccountId: mailAccount.mailAccountId
    });
    const folder = getFolderByType(
      this.userFolders,
      this.openMailAccountId,
      MailFolderType.Inbox
    );

    const infoMailAcc = encodeInfoMailAccount(this.openMailAccountId, folder);
    this.router.navigate([this.webCode,  'emails'], { queryParams: infoMailAcc ? {
      [EMAIL_QUERY_PARAM]: infoMailAcc
    } : {} });
    // this.eventsService.UserMailAccountSelected.emit({
    //   MailAccountId: mailAccount.mailAccountId,
    //   MailAccountRole: mailAccount.roleId,
    //   MailAccountName: mailAccount.mailAccountName,
    // } as UserMailAccountSelected);
    // this.searchContextStorageService.selectMailAccount(mailAccount.mailAccountId);
    // this.foldersTypeCheck.setIsInboxTrue();
  }

  async loadFolders(): Promise<void> {
    try {
      const response = await this.mailFolderClient.getUserFolders();
      this.userFolders = response.data;
    } catch (error) {
      console.log(error);
      this.userFolders = [];
    }
  }

  async updateDataSideNav(): Promise<void> {
    await this.loadFolders();
    this.tree = this.generateMailAccountTreeService.buildFolderTree(this.userFolders);
    await this.refreshUnread({
      mailAccountId: this.openMailAccountId
    });
  }

  onRightClick(event: MouseEvent, item): void {
    // preventDefault avoids to show the visualization of the right-click menu of the browser
    event.preventDefault();

    // we record the mouse position in our object
    this.menuTopLeftPosition.x = event.clientX + 'px';
    this.menuTopLeftPosition.y = event.clientY + 'px';

    // we open the menu
    // we pass to the menu the information about our object
    this.matMenuTrigger.menuData = { item };

    // we open the menu
    this.matMenuTrigger.openMenu();
  }

  public getTitle(mailAccount: UserMailAccountContract): string {
    return mailAccount.mailAccountName !== '' ? mailAccount.mailAccountName : mailAccount.mailAccountEmail;
  }

  getColorText(mailAccountId: number): string {
    const dataBadges = this.mailAccountBadges.find(el => el.mailAccountId === mailAccountId);
    return dataBadges?.connectionStatus ? this.connectionStatusName[dataBadges.connectionStatus].color : '';
  }

  getConnectionStatusTitle(mailAccountId: number): string {
    const dataBadges = this.mailAccountBadges.find(el => el.mailAccountId === mailAccountId);
    return dataBadges?.connectionStatus ? this.connectionStatusName[dataBadges.connectionStatus].title : '';
  }

  isConnectionStatusInfo(mailAccountId: number): boolean {
    const dataBadges = this.mailAccountBadges.find(el => el.mailAccountId === mailAccountId);
    return !!dataBadges?.connectionStatusInfo;
  }

  getConnectionStatusInfo(mailAccountId: number): string {
    const dataBadges = this.mailAccountBadges.find(el => el.mailAccountId === mailAccountId);
    return dataBadges?.connectionStatus ? this.connectionStatusInfo[dataBadges.connectionStatusInfo] : '';
  }

  getConnectStatusInfo(mailAccountId: number): string {
    const dataBadges = this.mailAccountBadges.find(el => el.mailAccountId === mailAccountId);
    return dataBadges?.connectionStatus ? this.connectionStatusName[dataBadges.connectionStatus].info : '';
  }

  ngOnDestroy(): void {
    this.reassignedAccountIds?.unsubscribe();
    this.intervalService.clearInterval(this.badgesRefreshInterval);
  }
}
