import { AfterViewInit, Component, OnInit } from '@angular/core';
import { BreadcrumbItem } from '../../../../../shared/breadcrumb-item';
import { TabItem } from '../../../../../shared/tab-item';
import { BaseGetByIdRequest } from '../../../../../sdk/contracts/common/base-get-by-id.request';
import { PermissionType } from '../../../../../sdk/contracts/permission/permission-type';
import { ActivatedRoute } from '@angular/router';
import { PermissionNavTabHelper } from '../../../../../services/permission/permission-nav-tab-helper';
import { OrganizationClient } from '../../../../../sdk/clients/organization.client';
import { MailAccountClient } from '../../../../../sdk/clients/mail-account.client';
import { PermissionService } from '../../../../../services/permission/permission.service';
import { MobileObserverService } from '../../../../../services/adaptive/mobile-observer.service';
import { TemplateClient } from '../../../../../sdk/clients/template.client';
import { BaseCollectionSearchByIdRequest } from '../../../../../sdk/contracts/common/base-collection-search-by-id.request';
import { BaseGetSearchCountByIdRequest } from '../../../../../sdk/contracts/common/base-get-search-count-by-id.request';
import { TemplateContract } from '../../../../../sdk/contracts/template/template.contract';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../../confirm-dialog/confirm-dialog.component';
import { MatchError } from 'src/app/services/errors/error-matcher';

@Component({
  selector: 'app-mail-account-templates',
  templateUrl: './mail-account-templates.component.html',
  styleUrls: ['./mail-account-templates.component.scss'],
})
export class MailAccountTemplatesComponent implements OnInit, AfterViewInit {
  public navigationIsLoading = false;
  public cannotLoadNavigation = false;
  public breadcrumbItems: BreadcrumbItem[] = [];
  public tabItems: TabItem[] = [];
  public webCode: string;

  public organizationId: number;
  public mailAccountId: number;

  public isLoading = false;
  public isError = false;

  public searchValue = '';
  public pageSize = 25;
  public pageIndex = 0;
  public totalSize = 0;

  public templates: TemplateContract[] = [];
  public editingTemplateId: number;

  public dataSource = new MatTableDataSource<TemplateContract>(this.templates);
  public displayedColumns: string[] = ['name', 'desc', 'date', 'actions'];

  public useMobileView = false;

  public createDrawerIsOpen = false;
  public editDrawerIsOpen = false;

  constructor(
    private route: ActivatedRoute,
    private permissionService: PermissionService,
    private organizationClient: OrganizationClient,
    private permissionNavTabHelper: PermissionNavTabHelper,
    private mailAccountClient: MailAccountClient,
    private mobileObserverService: MobileObserverService,
    public matchError: MatchError,
    private templateClient: TemplateClient,
    public dialog: MatDialog
  ) {}

  async ngOnInit(): Promise<void> {
    this.mobileObserverService.mobileObserver().subscribe((isMobile) => (this.useMobileView = isMobile));

    this.pageSize = JSON.parse(localStorage.getItem('pageSizeSettings')) || 25;
    this.webCode = this.route.parent.snapshot.paramMap.get('webCode');
    this.organizationId = parseInt(this.route.snapshot.paramMap.get('organizationId'), 10);
    this.mailAccountId = parseInt(this.route.snapshot.paramMap.get('mailAccountId'), 10);
    await this.loadNavigation();
    await this.loadTemplates(true);
  }

  ngAfterViewInit(): void {}

  public async loadTemplates(fetchCounter: boolean): Promise<void> {
    this.isLoading = true;
    this.isError = false;
    if (fetchCounter) {
      this.pageIndex = 0;
    }
    const nullableSearchValue = this.searchValue.length > 0 ? this.searchValue : null;
    const request = new BaseCollectionSearchByIdRequest(this.mailAccountId, this.pageIndex + 1, this.pageSize, nullableSearchValue);
    try {
      const response = await this.templateClient.searchForMailAccount(request);
      this.templates = response.data;
      this.dataSource.data = this.templates;
      if (fetchCounter) {
        const countRequest = new BaseGetSearchCountByIdRequest(this.mailAccountId, nullableSearchValue);
        const counterResponse = await this.templateClient.getSearchCountForMailAccount(countRequest);
        this.totalSize = counterResponse.result;
      }
    } catch (e) {
      this.isError = true;
      this.matchError.logError(e);
    } finally {
      this.isLoading = false;
    }
  }

  public async loadNavigation(): Promise<void> {
    this.navigationIsLoading = true;
    try {
      this.loadTabs();
      await this.loadBreadcrumbItems();
    } catch (e) {
      this.cannotLoadNavigation = true;
      this.matchError.logError(e);
    } finally {
      this.navigationIsLoading = false;
    }
  }

  private loadTabs(): void {
    this.tabItems = this.permissionNavTabHelper.getTabsForMailAccountPage(this.organizationId, this.mailAccountId, this.webCode);
  }

  private async loadBreadcrumbItems(): Promise<void> {
    const mailAccountName = (await this.mailAccountClient.getById(new BaseGetByIdRequest(this.mailAccountId))).mailAccount.name;
    if (await this.permissionService.hasPermissionOver(null, null, PermissionType.CanEverything)) {
      this.breadcrumbItems = [
        { index: 0, label: 'settingsBreadCrumbs', route: `/${this.webCode}/settings/organization/${this.organizationId}/general` },
        {
          index: 1,
          label: 'mailAccountsBreadCrumbs',
          route: `/${this.webCode}/settings/organization/${this.organizationId}/mail-accounts`,
        },
        { index: 2, label: mailAccountName, route: null },
        { index: 3, label: 'templates', route: null },
      ];
    } else if (await this.permissionService.hasPermissionOver(this.organizationId, null, PermissionType.CanManageOrganization)) {
      this.breadcrumbItems = [
        { index: 0, label: 'settingsBreadCrumbs', route: `/${this.webCode}/settings/organization/${this.organizationId}/general` },
        {
          index: 1,
          label: 'mailAccountsBreadCrumbs',
          route: `/${this.webCode}/settings/organization/${this.organizationId}/mail-accounts`,
        },
        { index: 2, label: mailAccountName, route: null },
        { index: 3, label: 'templates', route: null },
      ];
    } else {
      this.breadcrumbItems = [
        { index: 0, label: 'settingsBreadCrumbs', route: `/${this.webCode}/settings/organization/${this.organizationId}/general` },
        {
          index: 1,
          label: 'mailAccountsBreadCrumbs',
          route: `/${this.webCode}/settings/organization/${this.organizationId}/mail-accounts`,
        },
        { index: 2, label: mailAccountName, route: null },
        { index: 3, label: 'templates', route: null },
      ];
    }
  }

  public onSearchValueChanged(newSearchValue: string): void {
    this.searchValue = newSearchValue;
  }

  public async handlePage(e: any): Promise<void> {
    localStorage.setItem('pageSizeSettings', JSON.stringify(e.pageSize));
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    await this.loadTemplates(false);
  }

  public openCreateModelForm(): void {
    this.createDrawerIsOpen = true;
  }

  public openEditModelForm(template: TemplateContract): void {
    this.editingTemplateId = template.templateId;
    this.editDrawerIsOpen = true;
  }

  public onCloseCreateModalForm(): void {
    this.createDrawerIsOpen = false;
  }

  public onCloseEditModalForm(): void {
    this.editDrawerIsOpen = false;
  }

  public async onCreateTemplate(): Promise<void> {
    this.totalSize += 1;
    this.createDrawerIsOpen = false;
    await this.loadTemplates(false);
  }

  public async onEditTemplate(): Promise<void> {
    this.editDrawerIsOpen = false;
    await this.loadTemplates(false);
  }

  public openConfirmDialog(templateId: number, name: string): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '450px',
      data: { name: 'templateDelete', title: 'templateDeleteTitle', isDontNeedConfirmWord: true, itemName: name },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteTemplate(templateId);
      }
    });
  }

  async deleteTemplate(templateId: number): Promise<void> {
    try {
      await this.templateClient.delete(new BaseGetByIdRequest(templateId));
      this.loadTemplates(false);
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    }
  }
}
