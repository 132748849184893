import { BaseGetSearchCountRequest } from './base-get-search-count.request';

export class BaseGetSearchCountByIdRequest extends BaseGetSearchCountRequest {
  id: number;

  constructor(id: number, search: string | null) {
    super(search);
    this.id = id;
  }
}
