export const CONNECTION_STATUS = {
  1: {
    title: 'online',
    color: 'text-primary',
    info: ''
  },
  2: {
    title: 'offline',
    color: 'text-red-600',
    info: ''
  },
  3: {
    title: 'unknown',
    color: 'text-red-600',
    info: 'failedToCheckStatus'
  },
};

export const CONNECTION_STATUS_INFO = {
  1: 'failedConnect',
  2: 'failedImapConnect',
  3: 'failedSmtpConnect',
  4: 'failedAuthentication',
};
