import { Component, Input, OnInit } from '@angular/core';
import { BreadcrumbItem } from '../../shared/breadcrumb-item';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  @Input() items: BreadcrumbItem[] = [];

  constructor() {}

  ngOnInit(): void {}

  public get sortedItems(): BreadcrumbItem[] {
    return this.items.sort((a, b) => a.index - b.index);
  }

  public isLastItem(index): boolean {
    return index === this.items.length - 1;
  }
}
