@if(loadError) {
  <ng-template>
    {{ apiError }}
  </ng-template>
}

@if(!loadError) {
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="mail-folder-tree -ml-4">
    <mat-tree-node
      [class.btn-dark]="item?.data?.mailFolderId === infoOpenedMailAccount?.folder"
      *matTreeNodeDef="let item"
    >
      <div class="mat-tree-node">
        <button class="h-[1.25rem]" mat-icon-button disabled></button>
        <button
          [class.btn-dark]="item?.data?.mailFolderId === infoOpenedMailAccount?.folder"
          [disableRipple]="true"
          (contextmenu)="onRightClick($event, item.data)"
          (click)="selectFolder(item)"
        >
          {{ item.data.creationType === 1 ? ("folderType" + item.data.folderType | translate) : item.data.name }}
        </button>
        @if (getCountForFolder(item.data.mailFolderId)) {
          <span
            [class.btn-dark]="item?.data?.mailFolderId === infoOpenedMailAccount?.folder"
          >
            {{ getCountForFolder(item.data.mailFolderId) }}
          </span>
        }

      </div>
    </mat-tree-node>
    <mat-nested-tree-node *matTreeNodeDef="let item; when: hasChild">
      <div>
        <div
          class="mat-tree-node overflow-hidden"
          [class.btn-dark]="item?.data?.mailFolderId === infoOpenedMailAccount?.folder">
          <button
            (click)="expandCollapseTree(item)"
            mat-icon-button
            [disableRipple]="true"
            [attr.aria-label]="'toggle ' + item.data.name"
          >
            <mat-icon class="mat-icon-rtl-mirror">
              {{ isOpenedFolder(item.data.mailFolderId) ? "add" : "remove" }}
            </mat-icon>
          </button>
          <button
            [class.btn-dark]="item?.data?.mailFolderId === infoOpenedMailAccount?.folder"
            [disableRipple]="true"
            (click)="selectFolder(item)"
            (contextmenu)="onRightClick($event, item.data)"
          >
            {{ item.data.creationType === 1 ? ("folderType" + item.data.folderType | translate) : item.data.name }}
          </button>
          <span>{{ getCountForFolder(item.data.mailFolderId) }}</span>
        </div>
        <div [class.mail-folder-tree-invisible]="isOpenedFolder(item.data.mailFolderId)" role="group">
          <ng-container matTreeNodeOutlet></ng-container>
        </div>
      </div>
    </mat-nested-tree-node>
  </mat-tree>
}

<div style="visibility: hidden; position: fixed"
     [style.left]="menuTopLeftPosition.x"
     [style.top]="menuTopLeftPosition.y"
     [matMenuTriggerFor]="rightMenu"
></div>

<mat-menu #rightMenu="matMenu">
  <ng-template matMenuContent let-item="item">
    <button mat-menu-item (click)="createFolder(item)">{{ "createSubfolder" | translate }}</button>
    @if(item && item.parentId !== null) {
    <button mat-menu-item (click)="renameFolder(item)">{{ "modify" | translate }}</button>
      <button mat-menu-item (click)="deleteFolder(item)">{{ "delete" | translate }}</button>
    }
  </ng-template>
</mat-menu>

<ng-template #createFolderTemplate>
  <app-mail-folder-create-dialog
    (creationSubmit)="onSubmitCreation($event)"
    [parentMailFolderId]="parentMailFolderIdForCreation"
    [mailAccountId]="mailAccountId"
    [parentMailFolderType]="parentMailFolderTypeForCreation"
    [isSettings]="false">
  </app-mail-folder-create-dialog>
</ng-template>


<ng-template #renameFolderTemplate>
  <app-mail-folder-create-dialog
    [mailFolderId]="editingFolder?.mailFolderId"
    [mailFolderName]="editingFolder?.name"
    [providerFolderName]="editingFolder?.providerFolderName"
    (creationSubmit)="onSubmitRename()"
    [mailAccountId]="mailAccountId"
    [parentMailFolderType]="parentMailFolderTypeForCreation"
    [isSettings]="false">
  </app-mail-folder-create-dialog>
</ng-template>
