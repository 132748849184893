import { Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { EventsService } from 'src/app/services/events/events.service';
import { SearchContextStorageService } from 'src/app/services/search/search-context-storage.service';
import { FoldersUtilityService } from '../../services/folders-utility/folders-utility.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatIconModule } from '@angular/material/icon';
import { DrawerComponent } from '../common/drawer/drawer.component';
import { MatInputModule } from '@angular/material/input';
import { DrawerService } from '../../services/drawer.service';
import { EmailSearchAdvancedDialogComponent } from '../email-search-advanced-dialog/email-search-advanced-dialog.component';

@Component({
  selector: 'app-email-search-input',
  standalone: true,
  imports: [
    TranslateModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatIconModule,
    DrawerComponent,
    MatInputModule,
    EmailSearchAdvancedDialogComponent
  ],
  templateUrl: './email-search-input.component.html',
  styleUrls: ['./email-search-input.component.scss'],
})
export class EmailSearchInputComponent implements OnInit {
  @ViewChild('topInput', { static: false }) topInput: ElementRef;
  @Input() public fullWidth = false;
  @Input() public search = '';
  @Input() public hideNotifications;
  @Input() public isDefaultSearchParams = true;
  @Input() public isAnyFolder = false;
  @Output() public valueChangedEventEmitter = new EventEmitter<string>();
  @Output() public hideNotificationsEmitter = new EventEmitter<boolean>();
  @Output() public searchEventEmitter = new EventEmitter<string>();

  @ViewChild('advancedSearch') advancedSearch!: TemplateRef<any>;
  public advancedSearchIsOpen = false;

  constructor(
    private eventsService: EventsService,
    private searchContextStorageService: SearchContextStorageService,
    public foldersUtilityService: FoldersUtilityService,
    private drawerService: DrawerService
  ) {}

  ngOnInit(): void {
    this.eventsService.onEscCloseDetailDialogEvent.subscribe(() => {
      this.topInput.nativeElement.focus();
    });
  }

  onValueChanged($event): void {
    this.valueChangedEventEmitter.emit($event.target.value);
  }

  onHideNotificationsChanged($event): void {
    this.hideNotificationsEmitter.emit($event);
    if (this.search === null) {
      this.search = '';
      this.valueChangedEventEmitter.emit('');
    }
    this.searchEventEmitter.emit();
  }

  onSearchBtnClicked(): void {
    if (this.search === null) {
      this.search = '';
      this.valueChangedEventEmitter.emit('');
    }
    this.searchEventEmitter.emit();
  }

  onXClicked(): void {
    this.search = '';
    this.searchContextStorageService.resetParams();
    this.valueChangedEventEmitter.emit('');
    this.searchEventEmitter.emit();
  }

  public get widthClass(): string {
    return this.fullWidth ? ' w-full' : ' w-40';
  }

  public openAdvancedSearch(): void {
    this.drawerService.openDrawer(this.advancedSearch);
  }

  public closeAdvancedSearch(): void {
    this.drawerService.closeDrawer();
    // this.advancedSearchIsOpen = false;
    // this.topInput.nativeElement.focus();
  }
}
