export const getObjectFromQueryString = (search: string) => {
  const paramsEntries = new URLSearchParams(search).entries();
  return Object.fromEntries(paramsEntries);
};

export const getQueryString = (params: any) =>
  new URLSearchParams(params).toString();

export const encodeInfoMailAccount = (mailAccountId: number, folderId: number): string => {
  const params = {
    acc: String(mailAccountId),
    folder: String(folderId)
  };
  if (!mailAccountId || !folderId) { return ''; }
  const url = getQueryString(params);
  return window.btoa(url);
};

type ObjectNumber = { [key: string]: number };

export const decodeInfoMailAccount = (search: string): ObjectNumber => {
  const infoAtob = window.atob(decodeURIComponent(search));
  const infoMailAccount = getObjectFromQueryString(infoAtob);
  const newData: ObjectNumber = {};
  for (const key in infoMailAccount) {
    if (infoMailAccount.hasOwnProperty(key)) {
      newData[key] = +infoMailAccount[key];
    }
  }
  return newData;
};
