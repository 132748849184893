import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { BaseGetByIdRequest } from '../../../sdk/contracts/common/base-get-by-id.request';
import { BaseIdListRequest } from '../../../sdk/contracts/common/base.id-list.request';
import { BaseGetCollectionByIdRequest } from '../../../sdk/contracts/common/base-get-collection-by-id.request';
import { MailAccountClient } from '../../../sdk/clients/mail-account.client';
import { MailAccountContract } from '../../../sdk/contracts/mail-account/mail-account.contract';
import { MatchError } from 'src/app/services/errors/error-matcher';

import { EmailGroupClient } from '../../../sdk/clients/email-group.client';
import { GroupClient } from '../../../sdk/clients/group.client';
import { GroupRequest } from '../../../sdk/contracts/group/group.request';
import { EmailGroupsRequest } from 'src/app/sdk/contracts/email-groups/email-groups.request';

@Component({
  selector: 'email-add-groups-dialog',
  templateUrl: './email-add-groups-dialog.component.html',
  styleUrls: ['./email-add-groups-dialog.component.scss'],
})
export class EmailAddGroupsDialogComponent {
  public thisGroups: any[] = [];
  public existedGroups: any[] = [];
  public isLoading: boolean = true;
  public existedGroupsResponse: any;
  public isSave: boolean = false;
  public tags: string[] = [];
  private mailAccount: MailAccountContract;
  public thisGroupsIds: number[] = [];
  public resultGroupsIds: number[] = [];
  public isError: boolean = false;
  public isSavingGroups: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<EmailAddGroupsDialogComponent>,
    public emailGroupClient: EmailGroupClient,
    public groupClient: GroupClient,
    public matchError: MatchError,
    private mailAccountClient: MailAccountClient,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  async ngOnInit() {
    this.dialogRef.keydownEvents().subscribe((event) => {
      if (event.key === 'Escape') {
        this.ok();
      }
    });
    await this.loadData();
  }

  async loadData(): Promise<void> {
    this.isError = false;
    this.isLoading = true;
    try {
      const response = await this.mailAccountClient.getById(new BaseGetByIdRequest(this.data.mailAccountId));
      this.mailAccount = response.mailAccount;
      this.existedGroupsResponse = await this.groupClient.getGroupsForOrganization(
        new BaseGetCollectionByIdRequest(this.mailAccount.organizationId, 1, 25)
      );
      this.existedGroups = this.existedGroupsResponse.data;
      if (this.data.ids.length === 1) {
        const request = new BaseIdListRequest();

        request.list = this.data.ids;
        this.thisGroups = await this.emailGroupClient.getGroupsForEmails(request);
        this.thisGroupsIds = this.thisGroups.map((e) => e.groupId).filter((e, i, arr) => arr.indexOf(e) === i);
      } else {
        const allExistedGroupsIds = this.existedGroups.map((e) => e.groupId);
        await this.emailGroupClient.removeGroupsFromEmails(new EmailGroupsRequest(this.data.ids, allExistedGroupsIds));
        this.isSave = true;
      }
    } catch (e) {
      this.isError = true;
      this.matchError.logError(e);
    } finally {
      this.isLoading = false;
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }

  public isChecked(groupId: number) {
    return this.thisGroupsIds.includes(groupId) ? true : false;
  }

  async addGroupToEmails(isCheck, groupId: number) {
    if (isCheck) {
      this.thisGroupsIds.push(groupId);
    } else {
      this.thisGroupsIds = this.thisGroupsIds.filter((e) => e !== groupId);
      // await this.removeGroups(groupId)
    }
  }

  async createNewGroup() {
    await this.groupClient.createGroupForOrganization(new GroupRequest(this.mailAccount.organizationId, 'test3'));
  }

  async ok(): Promise<any> {
    if (this.existedGroups.length === 0) {
      this.dialogRef.close();
      return;
    }
    this.isSavingGroups = true;
    try {
      if (this.thisGroupsIds.length) {
        await this.emailGroupClient.addGroupsToEmails(new EmailGroupsRequest(this.data.ids, this.thisGroupsIds));
      } else {
        const allGroupIds = this.existedGroups?.map((e) => e?.groupId);
        await this.emailGroupClient.removeGroupsFromEmails(new EmailGroupsRequest(this.data.ids, allGroupIds));
      }
      this.isSave = true;
      this.resultGroupsIds = this.thisGroupsIds;
      if (this.isSave) {
        const res = this.existedGroups.filter((e) => this.resultGroupsIds.includes(e.groupId));
        this.dialogRef.close(res);
      } else {
        this.dialogRef.close();
      }
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.isSavingGroups = false;
    }
  }
}
