import { Injectable } from '@angular/core';
import { BaseGetByIdRequest } from '../../sdk/contracts/common/base-get-by-id.request';
import { MailAccountClient } from '../../sdk/clients/mail-account.client';
import { MailAccountGetByOrganizationIdContract } from '../../sdk/contracts/mail-account/mail-account.get-by-organization-id.contract';
import { BaseCollectionSearchByIdRequest } from '../../sdk/contracts/common/base-collection-search-by-id.request';
import { BaseCollectionFilteredSearchByIdRequest } from 'src/app/sdk/contracts/common/base-collection-filtered-search-by-id.request';

@Injectable()
export class MailAccountService {
  constructor(private mailAccountClient: MailAccountClient) {}

  async getAccountsForAccountOrganization(mailAccountId: number): Promise<MailAccountGetByOrganizationIdContract[]> {
    const accountResponse = await this.mailAccountClient.getById(new BaseGetByIdRequest(mailAccountId));
    if (accountResponse && accountResponse.mailAccount && accountResponse.mailAccount.organizationId) {
      const response = await this.mailAccountClient.getForReassign(
        new BaseCollectionSearchByIdRequest(accountResponse.mailAccount.organizationId, 1, 2147483647, null)
      );
      return response.data.filter((value) => {
        return value.mailAccountId !== mailAccountId;
      });
    }
  }

  async getAccountsForAccountOrganizationByOrganizationId(mailAccountId: number): Promise<MailAccountGetByOrganizationIdContract[]> {
    const accountResponse = await this.mailAccountClient.getById(new BaseGetByIdRequest(mailAccountId));
    if (accountResponse && accountResponse.mailAccount && accountResponse.mailAccount.organizationId) {
      let request = new BaseCollectionFilteredSearchByIdRequest(accountResponse.mailAccount.organizationId, 1, 2147483647, null);

      const response = await this.mailAccountClient.getSearch(request);
      return response.data.filter((value) => {
        return value.mailAccountId !== mailAccountId;
      });
    }
  }
}
