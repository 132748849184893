import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements OnInit {
  @Input() hasPreviousPage: boolean;
  @Input() countFrom: string;
  @Input() countTotal: string;
  @Input() countTo: string;
  @Input() hasNextPage: boolean;
  @Input() pageSize: number;
  @Input() pageSizeOptions: number[];
  @Output() pageSizeChange = new EventEmitter<number>();
  @Output() goToPreviousPage = new EventEmitter<void>();
  @Output() goToNextPage = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  public onChangePageSize(pageSize: number): void {
    this.pageSize = pageSize;
    this.pageSizeChange.emit(this.pageSize);
  }

  public previous(): void {
    this.goToPreviousPage.emit();
  }

  public next(): void {
    this.goToNextPage.emit();
  }
}
