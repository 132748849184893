import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { UserMailAccountContract } from '../../sdk/contracts/user-mail-account/user-mail-account.contract';
import { ActivatedRoute } from '@angular/router';
import { EmailFerreroCreateRequest } from '../../sdk/contracts/plugins/email-ferrero.create-request';
import { PluginsClient } from '../../sdk/clients/plugins.client';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from '../error/error-dialog/error-dialog.component';
import { UserMailAccountClient } from '../../sdk/clients/user-mail-account.client';
import { MatchError } from 'src/app/services/errors/error-matcher';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import ClassicEditor from '../../../ckeditor';
import { getFileExtension } from '../../shared/helpers/file-helper';
import { FerreroAttachmentContract } from 'src/app/sdk/contracts/plugins/ferrero-attachment.contract';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { FerreroWebCodes } from 'src/app/sdk/contracts/plugins/ferrero-webcodes';
import 'src/app/translation/ckeditor-translation/it';
import 'src/app/translation/ckeditor-translation/en-gb';

@Component({
  selector: 'app-plugins',
  templateUrl: './plugins.component.html',
  styleUrls: ['./plugins.component.scss'],
})
export class PluginsComponent implements OnInit {
  public form: UntypedFormGroup = new UntypedFormGroup({});
  @ViewChild('select') select: MatSelect;
  @ViewChild('bodyText') bodyText: CKEditorComponent;
  @ViewChild('uploader') uploader: ElementRef;
  public config = {
    language: localStorage.getItem('userLocale'),
  };
  public isSendingRequest = false;
  public isLoading = true;
  public cannotLoadData = false;
  public accounts: UserMailAccountContract[] = [];
  public webCode: string;
  public getFileExtension = getFileExtension;
  private allSelected = false;
  public attachmentFiles: FerreroAttachmentContract[] = [];
  public editor = ClassicEditor;
  public formDataArray = [];

  constructor(
    private route: ActivatedRoute,
    private userMailAccountClient: UserMailAccountClient,
    private pluginsClient: PluginsClient,
    private matchError: MatchError,
    public dialog: MatDialog
  ) {}

  async ngOnInit(): Promise<void> {
    const ferreroWebCodes = new FerreroWebCodes();
    console.log(ferreroWebCodes);
    this.webCode = this.route.parent.snapshot.paramMap.get('webCode');
    if (!ferreroWebCodes.codes.includes(this.webCode)) {
      window.location.href = `/${this.webCode}/404`;
    }
    this.form = new UntypedFormGroup({
      mailsControl: new UntypedFormControl('', Validators.required),
      subject: new UntypedFormControl('', [Validators.required, Validators.maxLength(2048)]),
    });
    await this.loadData();
  }

  addAttachmentFileFromButton(event: any): void {
    this.addAttachmentFile(event.target.files);
    this.uploader.nativeElement.value = '';
  }

  public openConfirmDialog(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '450px',
      data: { name: 'reset' },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.clearForm();
      }
    });
  }

  public deleteAttachment(file: FerreroAttachmentContract): void {
    const index = this.attachmentFiles.indexOf(file);
    this.attachmentFiles.splice(index, 1);
  }

  openSuccessDialog(title: string, description: string): void {
    this.dialog.open(ErrorDialogComponent, {
      width: '300px',
      data: { title: title, description: description },
    });
  }

  addAttachmentFile(files: File[]): void {
    for (const selectedFile of files) {
      const uploadFile = new FerreroAttachmentContract();
      uploadFile.fileName = selectedFile.name;
      uploadFile.mimeType = selectedFile.type;
      if (!uploadFile.mimeType) {
        uploadFile.mimeType = 'application/octet-stream';
      }

      if (this.attachmentFiles.length > 0) {
        const existAttachment = this.attachmentFiles.filter((x) => x.fileName === selectedFile.name);
        if (existAttachment.length === 0) {
          const newAttachments = this.attachmentFiles.filter((x) => x.fileName !== selectedFile.name);
          newAttachments.push(uploadFile);
          this.attachmentFiles = newAttachments;
        }
      } else {
        const newAttachments = this.attachmentFiles.filter((x) => x.fileName !== selectedFile.name);
        newAttachments.push(uploadFile);
        this.attachmentFiles = newAttachments;
      }

      const notUploadFiles = this.attachmentFiles.filter((x) => x.fileName === selectedFile.name);
      if (notUploadFiles.length !== 0) {
        const reader = new FileReader();
        reader.onload = () => {
          const fileContent = (reader.result as string).split(',')[1];
          this.attachmentFiles = this.attachmentFiles.map((e) => (e.fileName === selectedFile.name ? { ...e, fileContent } : e));
        };

        reader.readAsDataURL(selectedFile);
      }
    }
  }

  async clearForm() {
    this.form.get('subject')?.setValue('');
    this.form.get('mailsControl')?.setValue('');
    this.attachmentFiles = [];
    this.bodyText.editorInstance.setData('');
  }

  async loadData(): Promise<void> {
    this.isLoading = true;
    this.cannotLoadData = false;
    try {
      const response = await this.userMailAccountClient.getLoggedUserAccounts();

      this.accounts = response.userMailAccounts;
    } catch (e) {
      this.cannotLoadData = true;
      this.matchError.logError(e);
    } finally {
      this.isLoading = false;
    }
  }

  public isBodyValid() {
    return !!this.bodyText?.editorInstance?.getData();
  }

  public async onSubmit() {
    try {
      this.isSendingRequest = true;
      const request = new EmailFerreroCreateRequest(
        this.form.get('mailsControl').value,
        this.form.get('subject').value,
        this.bodyText.editorInstance.getData(),
        this.attachmentFiles
      );
      await this.pluginsClient.create(request);
      this.openSuccessDialog('information', `theMessageWasSuccessfullySent`);
      this.clearForm();
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.isSendingRequest = false;
    }
  }

  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }

  optionClick() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }
}
