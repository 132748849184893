import { BaseGetRequest } from './base-get.request';

export class BaseGetByIdRequest extends BaseGetRequest {
  id: number;

  constructor(id: number) {
    super();
    this.id = id;
  }
}
