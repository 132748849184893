import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { OrganizationCreateRequest } from '../contracts/organization/organization.create.request';
import { OrganizationCreateResponse } from '../contracts/organization/organization.create.response';
import { OrganizationUpdateRequest } from '../contracts/organization/organization.update.request';
import { OrganizationGetByIdResponse } from '../contracts/organization/organization.get-by-id.response';
import { ApiRoutes } from '../routes/api.routes';
import { ApiRequest } from '../../services/api/api.request';
import { BaseUpdateResponse } from '../contracts/common/base-update.response';
import { BaseDeleteRequest } from '../contracts/common/base-delete.request';
import { BaseGetByIdRequest } from '../contracts/common/base-get-by-id.request';
import { BaseCollectionSearchRequest } from '../contracts/common/base-collection-search.request';
import { BaseGetCollectionResponse } from '../contracts/common/base-get-collection.response';
import { OrganizationContract } from '../contracts/organization/organization.contract';
import { BaseValueResponse } from '../contracts/common/base-value-response';
import { BaseGetSearchCountRequest } from '../contracts/common/base-get-search-count.request';

const API_URL = environment.apiUrl;
@Injectable()
export class OrganizationClient {
  constructor(private apiRequest: ApiRequest) {}

  async search(request: BaseCollectionSearchRequest): Promise<BaseGetCollectionResponse<OrganizationContract>> {
    return this.apiRequest.get(`${API_URL}${ApiRoutes.organization.search}?${request.format()}`);
  }

  async getSearchCount(request: BaseGetSearchCountRequest): Promise<BaseValueResponse<number>> {
    return this.apiRequest.get(`${API_URL}${ApiRoutes.organization.getSearchCount}?${request.format()}`);
  }

  async create(request: OrganizationCreateRequest): Promise<OrganizationCreateResponse> {
    return this.apiRequest.post<OrganizationCreateRequest, OrganizationCreateResponse>(
      request,
      `${API_URL}${ApiRoutes.organization.create}`
    );
  }

  async update(request: OrganizationUpdateRequest): Promise<BaseUpdateResponse> {
    return this.apiRequest.post<OrganizationUpdateRequest, BaseUpdateResponse>(request, `${API_URL}${ApiRoutes.organization.update}`);
  }

  async delete(request: BaseDeleteRequest): Promise<BaseUpdateResponse> {
    return this.apiRequest.post<BaseDeleteRequest, BaseUpdateResponse>(request, `${API_URL}${ApiRoutes.organization.delete}`);
  }

  async getById(request: BaseGetByIdRequest): Promise<OrganizationGetByIdResponse> {
    return this.apiRequest.get(`${API_URL}${ApiRoutes.organization.get}?${request.format()}`);
  }

  async getOrganizationName(request: BaseGetByIdRequest): Promise<BaseValueResponse<string>> {
    return this.apiRequest.get(`${API_URL}${ApiRoutes.organization.getOrganizationName}?${request.format()}`);
  }

  async getAvailableParentOrganizations(): Promise<BaseGetCollectionResponse<OrganizationContract>> {
    return this.apiRequest.get(`${API_URL}${ApiRoutes.organization.getAvailableParentOrganizations}`);
  }
}
