<div class="breadcrumbs-container">
  <app-breadcrumb [items]="breadcrumbItems"></app-breadcrumb>
</div>

<div>
  <app-navigation-tabs [tabItems]="tabItems"></app-navigation-tabs>
</div>

<!--<app-drawer [isOpen]="showAddUsersToGroupsForm" (closeEventEmitter)="onDrawerClose()">-->
<!--  <app-add-users-to-groups-form-->
<!--    *ngIf="showAddUsersToGroupsForm"-->
<!--    (close)="onHideAddUsersToGroupForm()"-->
<!--    (submit)="onAddUsersToGroups()"-->
<!--    [users]="selection.selected"-->
<!--    [organizationID]="organizationId">-->
<!--  </app-add-users-to-groups-form>-->
<!--</app-drawer>-->

<!--<app-drawer [isOpen]="updateDrawerIsOpen" (closeEventEmitter)="onDrawerClose()">-->
<!--  <app-organization-user-update-dialog-->
<!--    [user]="selection.selected[0]"-->
<!--    *ngIf="updateDrawerIsOpen"-->
<!--    (closeEventEmitter)="onCloseUpdateModalForm()"-->
<!--    (onUpdateOrganizationUser)="onUpdateOrganizationUser()"-->
<!--    [organizationId]="organizationId">-->
<!--  </app-organization-user-update-dialog>-->
<!--</app-drawer>-->

<!--<app-drawer [isOpen]="createDrawerIsOpen" (closeEventEmitter)="onDrawerClose()">-->
<!--  <app-organization-user-create-dialog-->
<!--    *ngIf="createDrawerIsOpen"-->
<!--    (closeEventEmitter)="onCloseCreateModalForm()"-->
<!--    (creationSubmit)="onCreateOrganizationUser()"-->
<!--    [organizationId]="organizationId">-->
<!--  </app-organization-user-create-dialog>-->
<!--</app-drawer>-->

<!--<app-drawer [isOpen]="showAddUsersToMailAccountsForm" (closeEventEmitter)="onDrawerClose()">-->
<!--  <app-add-users-to-mail-accounts-form-->
<!--    *ngIf="showAddUsersToMailAccountsForm"-->
<!--    (close)="onHideAddUsersToMailAccountsForm()"-->
<!--    (submit)="onAddUsersToMailAccounts()"-->
<!--    [users]="selection.selected"-->
<!--    [organizationID]="organizationId">-->
<!--  </app-add-users-to-mail-accounts-form>-->
<!--</app-drawer>-->

<ng-container>
  <div class="page-content">
    <div class="search-panel flex-wrap">
      <div class="button-group">
        <div *ngIf="!iscredemISAMSupport" class="button-group mr-5">
          <button mat-raised-button color="primary" class="page-block-button" (click)="onCreateOrganizationUserBtnClicked()">
            <mat-icon>add</mat-icon>{{ "newUser" | translate }}
          </button>
        </div>

        <div class="button-group button-add" *ngIf="!selection.isEmpty()">
          <button
            color="white"
            class="page-block-button"
            mat-icon-button
            aria-label="{{ 'addGroupsToUsers' | translate }}"
            matTooltip="{{ 'addGroupsToUsers' | translate }}"
            (click)="onAddUsersToGroupsBtnClicked()">
            <mat-icon>add</mat-icon>
          </button>

          <!-- <button
            color="white"
            mat-mini-fab
            class="page-block-button"
            mat-icon-button
            aria-label="{{ 'addUsersToMailAccounts' | translate }}"
            matTooltip="{{ 'addUsersToMailAccounts' | translate }}"
            (click)="onAddUsersToMailAccountsBtnClicked()">
            <mat-icon>person_add</mat-icon>
          </button> -->
        </div>
      </div>
      <div class="mobile-view search-bar">
        <app-search-bar
          [fullWidth]="true"
          (searchEventEmitter)="loadOrganizationUsers(true)"
          (valueChangedEventEmitter)="onSearchValueChanged($event)"
          [search]="searchValue"></app-search-bar>
      </div>
      <div class="not-mobile-view search-bar">
        <app-search-bar
          [fullWidth]="false"
          (searchEventEmitter)="loadOrganizationUsers(true)"
          (valueChangedEventEmitter)="onSearchValueChanged($event)"
          [search]="searchValue"></app-search-bar>
      </div>
    </div>
    <div class="mobile-view">
      <div class="mobile">
        <div class="buttons-mobile-container">
          <mat-checkbox
            color="primary"
            class="mb-2 mt-4"
            [checked]="selection.hasValue() && isAllSelected()"
            (change)="$event ? masterToggle() : null"
            [indeterminate]="selection.hasValue() && !isAllSelected()">
            {{ "selectAll" | translate }}
          </mat-checkbox>
          <mat-paginator
            [pageSize]="pageSize"
            [pageSizeOptions]="[25, 50, 100]"
            [length]="totalSize"
            [pageIndex]="pageIndex"
            (page)="handlePage($event)">
          </mat-paginator>
        </div>

        <div *ngIf="!isLoading && !hasError">
          <div class="page-block" *ngFor="let organizationUser of organizationUsers">
            <div class="card">
              <div class="checkbox-col">
                <mat-checkbox
                  color="primary"
                  class="mobile-card-title-checkbox"
                  (click)="$event.stopPropagation()"
                  (change)="$event ? selection.toggle(organizationUser) : null"
                  [checked]="selection.isSelected(organizationUser)">
                </mat-checkbox>
              </div>
              <mat-card class="mobile-row">
                <mat-card-content (click)="onUpdateOrganizationUserBtnClicked(organizationUser)" class="cursor-pointer flex flex-1">
                  <div class="card-table-row">
                    <div class="card-table-row-header">First name:</div>
                    <div class="card-table-row-value">{{ organizationUser.firstName }}</div>
                  </div>
                  <div class="card-table-row">
                    <div class="card-table-row-header">Last name:</div>
                    <div class="card-table-row-value">{{ organizationUser.lastName }}</div>
                  </div>
                  <div class="card-table-row">
                    <div class="card-table-row-header">Adfs identity:</div>
                    <div class="card-table-row-value">{{ organizationUser.adfsIdentity }}</div>
                  </div>
                  <div class="card-table-row">
                    <div class="card-table-row-header">Google identity:</div>
                    <div class="card-table-row-value">{{ organizationUser.googleIdentity }}</div>
                  </div>
                  <div class="card-table-row">
                    <div class="card-table-row-header">Oidc identity:</div>
                    <div class="card-table-row-value">{{ organizationUser.oidcIdentity }}</div>
                  </div>
                  <div class="card-table-row">
                    <div class="card-table-row-header">Email:</div>
                    <div class="card-table-row-value">{{ organizationUser.email }}</div>
                  </div>
                  <div class="card-table-row">
                    <div class="card-table-row-header">Role:</div>
                    <div class="card-table-row-value">{{ roleTypes[organizationUser.roleId] | translate }}</div>
                  </div>
                </mat-card-content>

                <div style="width: 40px; margin-left: 10px">
                  <button
                    *ngIf="!iscredemISAMSupport"
                    mat-icon-button
                    color="primary"
                    aria-label="Update User"
                    matTooltip="Update User"
                    (click)="onUpdateOrganizationUserBtnClicked(organizationUser)">
                    <mat-icon>edit</mat-icon>
                  </button>
                  <button
                    *ngIf="!iscredemISAMSupport"
                    mat-icon-button
                    color="primary"
                    aria-label="Add User To Mail accounts"
                    matTooltip="Add Users To Mail accounts"
                    (click)="onAddUserToMailAccountsBtnClicked(organizationUser)">
                    <mat-icon>person_add</mat-icon>
                  </button>

                  <button
                    mat-icon-button
                    color="primary"
                    aria-label="Add User To Groups"
                    matTooltip="Add User To Groups"
                    (click)="onAddUserToGroupsBtnClicked(organizationUser)">
                    <mat-icon>groups</mat-icon>
                  </button>

                  <button
                    *ngIf="!isRta(organizationUser.roleId) && organizationUser.state === 1 && !iscredemISAMSupport"
                    mat-icon-button
                    color="primary"
                    aria-label="{{ 'addUserToMailAccounts' | translate }}"
                    matTooltip="{{ 'diactivateUser' | translate }}"
                    (click)="openConfirmDialog(organizationUser.userOrganizationId, organizationUser.state, organizationUser)">
                    <mat-icon>dangerous</mat-icon>
                  </button>
                  <button
                    *ngIf="!isRta(organizationUser.roleId) && organizationUser.state === 2 && !iscredemISAMSupport"
                    mat-icon-button
                    color="primary"
                    aria-label="{{ 'addUserToMailAccounts' | translate }}"
                    matTooltip="{{ 'activateUser' | translate }}"
                    (click)="openConfirmDialog(organizationUser.userOrganizationId, organizationUser.state, organizationUser)">
                    <mat-icon>power_settings_new</mat-icon>
                  </button>

                  <!-- <button
                    mat-icon-button
                    color="primary"
                    aria-label="Delete"
                    matTooltip="Delete"
                    [disabled]="isRta(organizationUser.roleId)"
                    (click)="onDeleteUserFromOrganizationBtnClicked(organizationUser.userOrganizationId, organizationUser.firstName)">
                    <mat-icon>delete_outline</mat-icon>
                  </button> -->
                </div>
              </mat-card>
            </div>
          </div>
        </div>
        <app-no-data *ngIf="organizationUsers.length === 0 && !isLoading && !hasError"></app-no-data>
      </div>
    </div>

    <div class="page-block">
      <div class="table-container">
        <mat-paginator
          *ngIf="!useMobileView"
          [pageSize]="pageSize"
          [pageSizeOptions]="[25, 50, 100]"
          [length]="totalSize"
          [pageIndex]="pageIndex"
          (page)="handlePage($event)">
        </mat-paginator>
        <table *ngIf="!useMobileView && !isLoading && !hasError" mat-table [dataSource]="dataSource">
          <!-- Select Column -->
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox
                color="primary"
                (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox
                color="primary"
                (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)">
              </mat-checkbox>
            </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="firstName">
            <th mat-header-cell *matHeaderCellDef>{{ "firstName" | translate }}</th>
            <td mat-cell *matCellDef="let organizationUser">{{ organizationUser.firstName }}</td>
          </ng-container>

          <ng-container matColumnDef="lastName">
            <th mat-header-cell *matHeaderCellDef>{{ "lastName" | translate }}</th>
            <td mat-cell *matCellDef="let organizationUser">{{ organizationUser.lastName }}</td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="oidcIdentity">
            <th mat-header-cell *matHeaderCellDef>{{ "GAW" | translate }}</th>
            <td mat-cell *matCellDef="let organizationUser">{{ organizationUser.oidcIdentity }}</td>
          </ng-container>
          <ng-container matColumnDef="googleIdentity">
            <th mat-header-cell *matHeaderCellDef>{{ "google" | translate }}</th>
            <td mat-cell *matCellDef="let organizationUser">{{ organizationUser.googleIdentity }}</td>
          </ng-container>
          <ng-container matColumnDef="adfsIdentity">
            <th mat-header-cell *matHeaderCellDef>{{ "adfs" | translate }}</th>
            <td mat-cell *matCellDef="let organizationUser">{{ organizationUser.adfsIdentity }}</td>
          </ng-container>

          <!-- Email Column -->
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef>{{ "email" | translate }}</th>
            <td mat-cell *matCellDef="let organizationUser">{{ organizationUser.email }}</td>
          </ng-container>

          <!-- Role Column -->
          <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef>{{ "role" | translate }}</th>
            <td mat-cell *matCellDef="let organizationUser">{{ roleTypes[organizationUser.roleId] | translate }}</td>
          </ng-container>

          <ng-container matColumnDef="state">
            <th mat-header-cell *matHeaderCellDef>{{ "state" | translate }}</th>
            <td mat-cell *matCellDef="let organizationUser">{{ formatUserStateEnum(organizationUser.state) | translate }}</td>
          </ng-container>

          <!-- Actionc Column -->
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>{{ "actions" | translate }}</th>
            <td mat-cell *matCellDef="let organizationUser" class="action-link">
              <button
                *ngIf="!iscredemISAMSupport"
                mat-icon-button
                color="primary"
                aria-label="{{ 'updateUser' | translate }}"
                matTooltip="{{ 'updateUser' | translate }}"
                (click)="onUpdateOrganizationUserBtnClicked(organizationUser)">
                <mat-icon>edit</mat-icon>
              </button>
              <button
                *ngIf="!iscredemISAMSupport"
                mat-icon-button
                color="primary"
                aria-label="{{ 'addUserToMailAccounts' | translate }}"
                matTooltip="{{ 'addUserToMailAccounts' | translate }}"
                (click)="onAddUserToMailAccountsBtnClicked(organizationUser)">
                <mat-icon>person_add</mat-icon>
              </button>
              <button
                mat-icon-button
                aria-label="{{ 'addUserToGroups' | translate }}"
                matTooltip="{{ 'addUserToGroups' | translate }}"
                (click)="onAddUserToGroupsBtnClicked(organizationUser)">
                <mat-icon>groups</mat-icon>
              </button>
              <button
                *ngIf="!isRta(organizationUser.roleId) && organizationUser.state === 1 && !iscredemISAMSupport"
                mat-icon-button
                color="primary"
                aria-label="{{ 'addUserToMailAccounts' | translate }}"
                matTooltip="{{ 'diactivateUser' | translate }}"
                (click)="openConfirmDialog(organizationUser.userOrganizationId, organizationUser.state, organizationUser)">
                <mat-icon>dangerous</mat-icon>
              </button>
              <button
                *ngIf="!isRta(organizationUser.roleId) && organizationUser.state === 2 && !iscredemISAMSupport"
                mat-icon-button
                color="primary"
                aria-label="{{ 'addUserToMailAccounts' | translate }}"
                matTooltip="{{ 'activateUser' | translate }}"
                (click)="openConfirmDialog(organizationUser.userOrganizationId, organizationUser.state, organizationUser)">
                <mat-icon>power_settings_new</mat-icon>
              </button>
              <!-- <button mat-icon-button aria-label="{{'delete' | translate}}" [disabled]="isRta(organizationUser.roleId)"
                (click)="onDeleteUserFromOrganizationBtnClicked(organizationUser.userOrganizationId, organizationUser.name)">
                <mat-icon>delete_outline</mat-icon>
              </button> -->
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns" class="cursor-default"></tr>
        </table>
        <app-no-data *ngIf="organizationUsers.length === 0 && !isLoading && !hasError"></app-no-data>
      </div>
    </div>
  </div>
  <div *ngIf="hasError" class="half-of-screen">
    <app-something-went-wrong
      [showPicture]="false"
      description="Cannot load company users"
      (retryEmitter)="loadOrganizationUsers(true) && loadNavigation()"></app-something-went-wrong>
  </div>
  <app-loader *ngIf="isLoading && !hasError"></app-loader>
</ng-container>
