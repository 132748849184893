import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { TranslateModule } from '@ngx-translate/core';
import { MatListModule, MatNavList } from '@angular/material/list';
import { OrganizationContract } from '../../sdk/contracts/organization/organization.contract';
import { UserContract } from '../../sdk/contracts/user/user.contract';
import { Location, NgStyle } from '@angular/common';
import { RouterLink } from '@angular/router';
import { FerreroWebCodes } from '../../sdk/contracts/plugins/ferrero-webcodes';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { LocalStorageService } from '../../services/local-storage.service';
import { STORAGE_NAMES } from '../../shared/constants/constants';
import { SpaceInfo } from '../../sdk/contracts/common';
import { MatSelectModule } from '@angular/material/select';

const { USER_LOCALE } = STORAGE_NAMES;

@Component({
  selector: 'app-nav-sidenav',
  standalone: true,
  imports: [
    MatSelectModule,
    MatSidenavModule,
    TranslateModule,
    MatNavList,
    MatListModule,
    RouterLink,
    NgStyle
  ],
  templateUrl: './nav-sidenav.component.html',
  styleUrls: ['./nav-sidenav.component.scss']
})
export class NavSidenavComponent {
  @Input() userOrganisaions: OrganizationContract[] = [];
  @Input() webCode = '';
  @Input() userRole = '';
  @Input() settingRoute = '';
  @Input() organizationName = '';
  @Input() user: UserContract;
  @Input() spaceInfo: SpaceInfo;
  @Output() emailsClick = new EventEmitter();
  @Output() rightMenuOpen = new EventEmitter();

  constructor(
    private location: Location,
    private authenticationService: AuthenticationService,
    private localStorageService: LocalStorageService,
  ) {
  }

  public onChangeOrganization($event): void {
    document.location.href = `/${$event.value}`;
  }

  public isCurrentSelect(path: string): boolean {
    const locationPath = this.location.path();
    const isCurrent = locationPath.toString().split('/');
    return isCurrent[1] === this.webCode && isCurrent[2] === path;
  }

  onEmailsClick(): void {
    this.emailsClick.emit();
  }

  sidenavOpen(): void {
    this.rightMenuOpen.emit();
  }

  public pluginsPermission(): boolean {
    const ferreroWebCodes = new FerreroWebCodes();
    return ferreroWebCodes.codes.includes(this.webCode);
  }

  public get accountSettingsRoute(): string {
    return `/${this.webCode}/account-settings`;
  }

  async logout(): Promise<void> {
    this.localStorageService.removeData(USER_LOCALE);
    await this.authenticationService.logout(this.webCode);
  }
}
