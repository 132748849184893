import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { OrganizationSettingsClient } from '../../sdk/clients/organization-settings.client';
import { OrganizationAdfsUrl } from '../../sdk/contracts/organization/settings/organization-adfs-url';
import { Router } from '@angular/router';
import { TabItem } from '../../shared/tab-item';
const GOOGLE_CLIENT_ID = environment.googleClientId;
const GOOGLE_REDIRECT_URL = environment.googleRedirectUrl;

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  public orgs: OrganizationAdfsUrl[] = [];
  public selectedTabRoute: TabItem;
  public tabsRoutes: TabItem[] = [];

  constructor(private organizationSettingsClient: OrganizationSettingsClient, private router: Router) {}

  async ngOnInit(): Promise<any> {
    // const response = await this.organizationSettingsClient.getOrganizationsWithAdfs();
    // this.orgs = response.data;
  }
  // addGoogleIdentity(): void {
  // tslint:disable-next-line:max-line-length
  //   window.location.href = `${environment.googleAuthUrl}?scope=https://www.googleapis.com/auth/userinfo.email+https://www.googleapis.com/auth/userinfo.profile+openid&access_type=offline&include_granted_scopes=true&response_type=code&redirect_uri=${GOOGLE_REDIRECT_URL}&state=addIdentity&client_id=${GOOGLE_CLIENT_ID}`;
  // }
  //
  // async addAzureIdentity(org: OrganizationAdfsUrl): Promise<any> {
  //   window.location.href = `${org.redirectUrl}&state=addIdentity;${org.organizationId}`;
  // }
  //
  // changePassword(): void {
  //   this.router.navigate(['/passwordchange'], {queryParams: {returnUrl: '/settings'}});
  // }
}
