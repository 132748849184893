<div class="close-button">
  <button tabIndex="-1" class="" mat-icon-button (click)="cancel()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<h1 mat-dialog-title>{{ data.title ? (data.title | translate) : ("moveToFolderTitle" | translate) }}</h1>
<mat-dialog-content class="mat-mdc-dialog-content mdc-dialog__content mat-container">
  <div *ngIf="isLoading">
    <app-loader height="100%" diameter="32"></app-loader>
  </div>

  <div *ngIf="cannotLoad">
    <app-something-went-wrong [showPicture]="false" description="{{ 'cannotLoadDataError' | translate }}" (retryEmitter)="loadFolders()">
    </app-something-went-wrong>
  </div>

  <div *ngIf="!isLoading && !cannotLoad">
    <mat-form-field *ngIf="selectedFolderId" >
      <mat-label>{{ "folder" | translate }}</mat-label>
      <mat-select [(value)]="selectedFolderId">
        <mat-option *ngFor="let folder of folders" [value]="folder.mailFolderId">
          {{ folder.creationType === 1 ? ("folderType" + folder.folderType | translate) : folder.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <app-error-hint *ngIf="cannotSave" errorText="{{ 'cannotMoveErrorPopupSubtitle' | translate }}" [centerAlign]="false"></app-error-hint>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end" class="mat-mdc-dialog-actions mdc-dialog__actions">
  <button mat-button mat-flat-button color="primary" (click)="confirm()" [disabled]="moveInProgress || isLoading || cannotLoad">
<!--    <app-loading-button text="{{ 'move' | translate }}" [loading]="moveInProgress"></app-loading-button>-->
  </button>
</mat-dialog-actions>
