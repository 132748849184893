import { Injectable } from '@angular/core';
import { LoggingClient } from 'src/app/sdk/clients/logging.client';
import { LoggingContract } from 'src/app/sdk/contracts/logging/logging.contract';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import packageInfo from '../../../../package.json';
import { LocalStorageService } from '../local-storage.service';
import { STORAGE_NAMES } from '../../shared/constants/constants';

const { CLIENT_ID } = STORAGE_NAMES;

@Injectable()
export class LoggingHandler {
  config = {
    error: 'client_error',
    information: 'client_info'
  };

  constructor(
    private loggingClient: LoggingClient,
    private cookieService: CookieService,
    private localStorageService: LocalStorageService,
  ) {}

  getClientId(): string {
    return this.localStorageService.getData(CLIENT_ID);
  }

  getTokenAnchor(): string {
    return this.cookieService.get(environment.cookieAnchor);
  }

  async create(level: string, message: string, stackTrace = ''): Promise<void> {
    const clientId = this.getClientId();
    const tokenAnchor = this.getTokenAnchor();

    const loggingContract: LoggingContract = {
      level,
      stackTrace,
      message: `CLIENT_LOG FE_APP_VERSION=${packageInfo.version} CLIENT_ID=${clientId} client_anchor=${tokenAnchor} ${this.config[level]}: ${message}`,
    };
    if (level === 'error') {
      console.error(loggingContract);
    } else {
      console.info(loggingContract);
    }

    await this.loggingClient.create(loggingContract);
  }
}
