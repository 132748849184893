import { BaseGetRequest } from './base-get.request';

export class BaseGetSearchCountRequest extends BaseGetRequest {
  search: string | null;

  constructor(search: string | null) {
    super();
    this.search = search;
  }
}
