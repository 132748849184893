<div class="h-full flex flex-col justify-between">
  @if(cannotLoadAvailableData) {
    <div class="flex flex-col justify-center h-full">
      <app-something-went-wrong
        [title]="errorText | translate"
        [showPicture]="false"
        (retryEmitter)="ngOnInit()"
      ></app-something-went-wrong>
    </div>
  }

  @if(loadingAvailableData) {
    <app-loader class="flex flex-col justify-center h-full"></app-loader>
  }

  @if(!loadingAvailableData && !cannotLoadAvailableData) {
    <div>
      <div class="text-2xl mb-10 font-medium">{{ mailFolderName ? ("modifyFolder" | translate) :  ("createFolder" | translate) }}</div>
      <div class="w-full mb-2 mt-5">
        <div>
          <form [formGroup]="form" autocomplete="off" novalidate (ngSubmit)="onSubmit(form.value)">
            @if(mailFolderCreationType !== mailCreationType.System) {
              <mat-form-field>
                <mat-label>{{ "name" | translate }}</mat-label>
                <input matInput id="name" formControlName="name" />
                @if(hasError('name', 'required')) {
                  <mat-error>{{ "nameIsRequired" | translate }}</mat-error>
                }
                @if(hasError('name', 'maxlength')) {
                  <mat-error>{{ "moreThan256Char" | translate }}</mat-error>
                }
              </mat-form-field>
            }

            @if((parentMailFolderType === 2 || parentMailFolderType === 3) && isSettings) {
              <mat-form-field>
                <mat-label>{{ "providerFolder" | translate }}</mat-label>
                <mat-select [(value)]="selectedProviderFolder">
                  @for(folder of providerFolders; track folder) {
                    <mat-option [value]="folder.value">
                      {{ folder.name }}
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
            }
          </form>
        </div>
      </div>
    </div>
  }

  @if(!errorText) {
  <div class="w-full">
    <div class="flex justify-end">
      <button mat-flat-button color="primary" [disabled]="isLoading || !form.valid" (click)="onSubmit(form.value)">
        <app-loading-button text="{{ 'save' | translate }}" [loading]="isLoading"></app-loading-button>
        </button>
      </div>
    </div>
  }
</div>
