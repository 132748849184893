import { EmailValidationState } from '../../sdk/contracts/Email/email.validation-state';
import { DateTimePeriod } from '../../sdk/contracts/common/datetime.period';
import { EmailSearchOrder } from '../../sdk/contracts/Email/email.search-order';
import { OrderDirection } from '../../sdk/contracts/common/order-direction';

export class SearchParams {
  hideNotifications: boolean = true;
  parentId: number | null = null;
  reassignedId: number | null = null;
  reassigned: boolean | null = null;
  deleted: boolean | null = null;
  isSpam: boolean | null = null;
  isArchive: boolean | null = null;
  seen: boolean | null = null;
  validated: EmailValidationState[] = [];
  sentDateTime: DateTimePeriod = new DateTimePeriod(null, null);
  downloadDateTime: DateTimePeriod = new DateTimePeriod(null, null);
  withAttachments: boolean | null = null;
  source: boolean | null = null;
  subject: string | null = null;
  body: string | null = null;
  from: string | null = null;
  to: string | null = null;
  fullText: string | null = null;
  attachmentName: string | null = null;
  tags: string[] = [];
  searchOrder: EmailSearchOrder = EmailSearchOrder.OrderBySentDateTime;
  orderDirection: OrderDirection = OrderDirection.Descending;
}
