import { BaseGetRequest } from '../common/base-get.request';

export class MailAccountGetUnreadCountRequest extends BaseGetRequest {
  mailAccountId: number;
  hideNotification: boolean;

  constructor(mailAccountId: number, hideNotification: boolean) {
    super();
    this.mailAccountId = mailAccountId;
    this.hideNotification = hideNotification;
  }
}
