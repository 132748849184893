import { Component, Input } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  standalone: true,
  imports: [
    MatProgressSpinnerModule
  ],
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  @Input() height = '15rem';
  @Input() diameter = '64';
  @Input() padding = '1.25rem';

  constructor() {}
}
