<h1 mat-dialog-title>{{ "confirmDispatchingRuleDeletion" | translate }}</h1>
<mat-dialog-content class="mat-mdc-dialog-content mdc-dialog__content mat-container">
  <p>
    {{ "deleteDispatchingRule" | translate }} <span class="font-bold italic">{{ data.dispatchingRuleName }}</span> ?
  </p>
  <!-- <app-error-hint *ngIf="hasError"
                  errorText="Cannot delete dispatching rule: something went wrong" [centerAlign]="false"></app-error-hint> -->
</mat-dialog-content>
<mat-dialog-actions class="mat-mdc-dialog-actions mdc-dialog__actions">
  <div class="w-full flex justify-between">
    <button mat-button (click)="onCancelBtnClicked()">{{ "no" | translate }}</button>
    <button mat-flat-button color="primary" [disabled]="isLoading" (click)="onDeleteBtnClicked()">
<!--      <app-loading-button text="{{ 'yes' | translate }}" [loading]="isLoading"></app-loading-button>-->
    </button>
  </div>
</mat-dialog-actions>
