import { ErrorCode } from '../../api-errors/error-code';

export interface ImportEmlUploadResponse {
  data: FileInfo[];
}

export interface FileInfo {
  importEmlId: number;
  organizationId: number;
  mailAccountId: number;
  mailFolderId: number;
  fileName?: string;
  parentFileName?: string;
  userId?: number;
  state: ImportEmlState;
  stateDateTime: string;
  message?: ErrorCode;
}

export enum ImportEmlState {
  Uploaded = 1,
  InProgress = 2,
  Imported = 3,
  ImportError = 4,
  UploadError = 5
}
