import { Component, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { EmailClient } from 'src/app/sdk/clients/email-client';
import { ErrorDialogComponent } from '../error/error-dialog/error-dialog.component';
import { MatchError } from 'src/app/services/errors/error-matcher';

@Component({
  selector: 'app-final-deletion-confirm-dialog',
  templateUrl: './final-deletion-confirm-dialog.component.html',
  styleUrls: ['./final-deletion-confirm-dialog.component.scss'],
})
export class ConfirmFinalDeletionDialogComponent {
  public isLoading = false;

  constructor(
    public dialogRef: MatDialogRef<ConfirmFinalDeletionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private emailClient: EmailClient,
    public dialog: MatDialog,
    private matchError: MatchError
  ) {}

  async ngOnInit() {
    this.dialogRef.keydownEvents().subscribe((event) => {
      if (event.key === 'Escape') {
        this.onNoClick();
      }
    });
  }

  onNoClick() {
    this.dialogRef.close();
  }

  async onOkClick() {
    try {
      this.isLoading = true;
      const result = await this.emailClient.finalDelete({ emailIds: this.data.ids });
      if (result.countUnsuccessDelete && result.countUnsuccessDelete > 0) {
        this.openErrorDialog('error', 'messagesNotDeleted', result.countUnsuccessDelete);
      }
      this.dialogRef.close(result.successDeleteEmailIds ?? []);
    } catch (e) {
      this.dialogRef.close([]);
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    }
  }

  openErrorDialog(title: string, description: string, value?: number): void {
    this.dialog.open(ErrorDialogComponent, {
      width: '300px',
      data: { title: title, description: description, value: value },
    });
  }
}
