export enum MailFolderType {
  Custom = 1,
  Inbox = 2,
  Sent = 3,
  Draft = 4,
  Outbox = 5,
  Validation = 6,
  Spam = 7,
  Trash = 8,
  Archive = 9,
}
