import { BaseGetCollectionRequest } from './base-get-collection.request';

export class BaseCollectionSearchRequest extends BaseGetCollectionRequest {
  search: string | null;

  constructor(page: number, pageSize: number, search: string | null) {
    super(page, pageSize);
    this.search = search;
  }
}
