@if(isLoadingPage) {
  <app-loader height="100%"></app-loader>
} @else {
  <app-drawer>
    <app-main-header
      (navMenuOpen)="rightMenuOpen()"
      (mailAccountMenuOpen)="leftMenuOpen()"
    ></app-main-header>
    <mat-sidenav-container (backdropClick)="rightMenuOpen()">
      <mat-sidenav #sidenav position="end" [autoFocus]="false">
        @if (isError) {
          <div class="h-full">
            <app-something-went-wrong (retryEmitter)="loadData()"></app-something-went-wrong>
          </div>
        }
        @if (isLoading) {
          <app-loader></app-loader>
        }

        @if(!isLoading && !isError) {
          <ng-container>
            <app-nav-sidenav
              [userOrganisaions]="userOrganisaions"
              [webCode]="webCode"
              [settingRoute]="settingRoute"
              [user]="user"
              [userRole]="userRole"
              [organizationName]="organizationName"
              [spaceInfo]="spaceInfo"
              (emailsClick)="onEmailsClick()"
              (rightMenuOpen)="rightMenuOpen()"
            />
          </ng-container>
        }
      </mat-sidenav>

      <mat-sidenav-content>
        <mat-drawer-container
          class="fill"
          [hasBackdrop]="!visibleFullScreen"
        >
          <mat-drawer
            class="fill"
            [autoFocus]="false"
            [mode]="visibleFullScreen ? 'side' : 'over'"
            [opened]="isOpenLeftMenu"
          >
            <app-emails-sidenav
              [userFolders]="userFolders"
              [mailAccounts]="mailAccounts"
              [getMailAccountsError]="getMailAccountsError"
            />
          </mat-drawer>
          <mat-drawer-content>
            <div class="fill">
              <router-outlet></router-outlet>
            </div>
          </mat-drawer-content>
        </mat-drawer-container>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </app-drawer>
}

