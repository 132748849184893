import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { ApiRoutes } from '../routes/api.routes';
import { ApiRequest } from '../../services/api/api.request';
import { UserOrganizationGetUserOrganizationsResponse } from '../contracts/user-organization/user-organization.get-user-organizations.response';
import { GetAuthenticationTypesRequest } from '../contracts/authentication/get-authentication-types.request';

const API_URL = environment.feUrl;

@Injectable({
  providedIn: 'root',
})
export class FeClient {
  constructor(private apiRequest: ApiRequest) {}

  async getUserOrganizations(
    request: GetAuthenticationTypesRequest
  ): Promise<UserOrganizationGetUserOrganizationsResponse> {
    return this.apiRequest.get(`${API_URL}${ApiRoutes.fe.getUserOrganizations}?${request.format()}`);
  }
}
