import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { GetAuthenticationTypesRequest } from '../../sdk/contracts/authentication/get-authentication-types.request';
import { AuthenticationClient } from '../../sdk/clients/authentication.client';
import { PermissionService } from '../../services/permission/permission.service';
import { PermissionType } from '../../sdk/contracts/permission/permission-type';
import { ApiRequest } from '../../services/api/api.request';
import { environment } from '../../../environments/environment';
import { MatchError } from 'src/app/services/errors/error-matcher';
import { FeClient } from 'src/app/sdk/clients/fe.client';
import { LoggingHandler } from '../../services/errors/logging.service';
import { LocalStorageService } from '../../services/local-storage.service';
import { OrganizationContract } from '../../sdk/contracts/organization/organization.contract';
import { makeId } from '../../shared/utils/common';
import packageInfo from '../../../../package.json';
import { STORAGE_NAMES, WEB_CODE } from '../../shared/constants/constants';
import { IntervalService } from '../../services/interval.service';
import { UserOrganizationClient } from '../../sdk/clients/user-organization.client';

const { CLIENT_ID, ORGANISATIONS } = STORAGE_NAMES;

@Component({
  selector: 'app-web-code-context',
  standalone: true,
  imports: [
    RouterOutlet,
  ],
  templateUrl: './web-code-context.component.html',
  styleUrls: ['./web-code-context.component.scss'],
})
export class WebCodeContextComponent implements OnInit, OnDestroy {
  public isLogged = false;
  // public isError = false;
  public isUserActive = true;
  public userActiveTimer;
  public webCode;
  public tokenRefreshInterval;
  clientId;
  constructor(
    public matchError: MatchError,
    private route: ActivatedRoute,
    private router: Router,
    private apiRequest: ApiRequest,
    private loggingHandler: LoggingHandler,
    private authenticationService: AuthenticationService,
    private feClient: FeClient,
    private authenticationClient: AuthenticationClient,
    private permissionService: PermissionService,
    private localStorageService: LocalStorageService,
    private intervalService: IntervalService,
    private userOrganizationClient: UserOrganizationClient,
  ) {}

  @HostListener('mousemove', ['$event'])
  @HostListener('keydown', ['$event'])
  @HostListener('click', ['$event'])
  userActivityDetected(): void {
    this.userActivityTimer();
  }

  async ngOnInit(): Promise<void> {
    this.webCode = this.route.snapshot.paramMap.get(WEB_CODE);
    await this.getOrganisations();
    console.log(`CLIENTID=${this.clientId}`);
    console.log(`PM3 ${packageInfo.version}`);
  }

  async getOrganisations(): Promise<void> {
    try {
      const organizations = (await this.userOrganizationClient.getUserOrganizations())?.organizations;
      if (organizations) {
        this.setNewOrganisation(organizations);
      }

      const isExistedWebCode = organizations.map((e) => e.webCode).includes(this.webCode);
      if (!isExistedWebCode) {
        const hasPermissionOver = await this.permissionService.hasPermissionOver(
          null,
          null,
          PermissionType.CanEverything
        );

        if (hasPermissionOver) {
          if (this.webCode === 'admin') {
            this.isLogged = true;
          } else {
            this.router.navigateByUrl(`/${this.webCode}/403`);
          }
        } else {
          this.router.navigateByUrl(`/${this.webCode}/403`);
        }
      } else {
        this.isLogged = true;
      }

      let clientId = this.localStorageService.getData(CLIENT_ID);
      if (!clientId) {
        clientId = makeId(20);
        this.localStorageService.setData(CLIENT_ID, JSON.stringify(clientId));
      }
      this.clientId = clientId;
      this.userActivityTimer();
      this.tokenRenew();
    } catch (e) {
      if (e.Status === 500) {
        await this.router.navigateByUrl(`/${this.webCode}/500`);
      }
      this.matchError.logError(e);
    }
  }

  setNewOrganisation(organizations: OrganizationContract[]): void {
    let currentOrgs = this.localStorageService.getData(ORGANISATIONS) || [];
    if (!Array.isArray(currentOrgs)) {
      currentOrgs = [];
    }
    organizations.forEach((item) => {
      if (this.webCode === item.webCode) {
        currentOrgs = currentOrgs.filter((e) => e.webCode !== item.webCode);
        currentOrgs.unshift({
          name: item.name,
          webCode: item.webCode,
        });
        this.apiRequest.setOrganisationId(item.organizationId);
      }
    });
    currentOrgs = currentOrgs.slice(0, 3);
    this.localStorageService.setData(ORGANISATIONS, currentOrgs);
  }

  public userActivityTimer(): void {
    this.isUserActive = true;
    clearTimeout(this.userActiveTimer);
    this.userActiveTimer = setTimeout(() => {
      this.isUserActive = false;
    }, environment.userActivityTimeoutMs);
  }

  public tokenRenew(): void {
    this.intervalService.startInterval('tokenRefreshInterval',
      () => {
        if (this.isUserActive) {
          this.loggingHandler.create('information', 'renew token');
          this.apiRequest.get(environment.renewUri);
        } else {
          console.log(`User is logged out (inactive anymore ${environment.userActivityTimeoutMs}ms)`);
          this.authenticationService.logout(this.webCode);
        }},
        environment.userRenewTokenIntervalMs
      );
  }

  ngOnDestroy(): void {
    this.intervalService.clearAllIntervals();
  }
}
