<div class="flex items-center gap-3">
  <div class="logo relative inline-block tooltip w-[42px]">
    <img class="w-[200px]" src="../../../assets/logo.svg" alt="Logo" />
    <span class="tooltiptext">
      {{ "pathToDigitalTransformation" | translate }}
      <div class="block">
        <a style="color: #a1ccfe; border-bottom: 1px solid #a1ccfe" target="_blank" href="https://www.credemtel.it">www.credemtel.it</a>
      </div>
    </span>
  </div>

  <div [class]="'search-main bg-white flex items-center text-gray-600 p-0.5 border border-gray-300 rounded-md' + widthClass">
    <div class="flex-none h-full">
      <button
        [disabled]="!isAnyFolder"
        mat-icon-button
        type="submit"
        class="h-full flex flex-wrap content-center px-2"
        (click)="onSearchBtnClicked()">
        <mat-icon>search</mat-icon>
      </button>
    </div>
    <div class="flex items-center w-full h-full">
      <input
        #topInput
        style="background: white"
        class="w-full h-8 text-sm focus:outline-none focus:ring-0 pr-2"
        type="search"
        [disabled]="!isAnyFolder"
        name="search"
        placeholder="{{ 'search' | translate }}"
        [value]="search"
        (change)="onValueChanged($event)"
        (keyup.enter)="onSearchBtnClicked()" />
      <button matTooltip="{{ 'resetAllFilters' | translate }}" [disabled]="!isAnyFolder" mat-icon-button (click)="onXClicked()">
        <mat-icon class="cursor-pointer mr-[3px] scale-[0.65]">close</mat-icon>
      </button>
    </div>
    <div class="flex-none border-l border-gray-300 h-full">
      <button
        mat-icon-button
        [disabled]="!isAnyFolder"
        type="submit"
        class="h-full flex flex-wrap content-center px-2"
        (click)="openAdvancedSearch()">
        <mat-icon [color]="!isDefaultSearchParams && isAnyFolder ? 'primary' : ''">tune</mat-icon>
      </button>
    </div>
  </div>

  <mat-slide-toggle
    [disabled]="!isAnyFolder"
    [checked]="!hideNotifications"
    (change)="onHideNotificationsChanged(!$event.checked)"
    class="mr-3"
    color="primary"
    matTooltip="{{ 'showNotificationSearchInput' | translate }}"
  ></mat-slide-toggle>
</div>

<ng-template #advancedSearch>
  <app-email-search-advanced-dialog (closeEventEmitter)="closeAdvancedSearch()">
  </app-email-search-advanced-dialog>
</ng-template>
