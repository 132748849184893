<mat-progress-bar mode="indeterminate" *ngIf="showLoader"></mat-progress-bar>
<div id="print-div-id">
  <div>
    <b>{{ "from" | translate }}: </b> {{ emailDetails.from }}
  </div>
  <div>
    <b>{{ "to" | translate }}: </b> {{ emailDetails.to }}
  </div>
  <div>
    <b>{{ "cc" | translate }}: </b> {{ emailDetails.cc }}
  </div>
  <div>
    <b>{{ "date" | translate }}: </b> {{ emailDetails.sentDateTime }}
  </div>
  <div class="row">
    <div class="col-12">
      <div class="mail-print-delimiter"></div>
    </div>
  </div>

  <div>
    <b>{{ "subject" | translate }}: </b> {{ emailDetails.subject }}
  </div>
  <div>
    <b>{{ "body" | translate }}: </b>
  </div>
  <div [innerHTML]="emailDetails.body"></div>
  <br />
  <div *ngIf="emailDetails.attachmentFiles">
    <b>{{ "attachments" | translate }}:</b>
  </div>
  <div *ngIf="emailDetails.attachmentFiles">
    <div *ngFor="let attachment of emailDetails.attachmentFiles">
      {{ attachment.fileName }}
    </div>
  </div>
</div>
