import { ApiException } from '../errors/api.exception';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { LoggingHandler } from '../errors/logging.service';

@Injectable({ providedIn: 'root' })
export class ApiRequest {
  private organizationId: number;

  constructor(
    private http: HttpClient,
    protected loggingHandler: LoggingHandler,
  ) {}

  setOrganisationId(organizationId: number): void {
    this.organizationId = organizationId;
  }

  public async post<TRequest, TResponse>(request: TRequest, url: string, headers: any = null): Promise<TResponse> {
    try {
      const ClientId = this.loggingHandler.getClientId();
      const data = this.organizationId ? { ...request, organizationId: this.organizationId } : request;
      const response$: Observable<TResponse> = await this.http
        .post<TResponse>(url, data, { headers: { ...headers, ClientId }, withCredentials: true });
      return !!response$ && await lastValueFrom(response$);
    } catch (e) {
      this.throwApiException(e, { url }, { request }, { headers });
    }
  }

  public async get<TResponse>(url: string, headers: any = null): Promise<TResponse> {
    try {
      const ClientId = this.loggingHandler.getClientId();
      const newUrl = new URL(url);
      this.organizationId && (newUrl.searchParams.set('organizationId', String(this.organizationId)));
      const response$: Observable<TResponse> = await this.http
        .get<TResponse>(newUrl.toString(), { headers: { ...headers, ClientId }, withCredentials: true });
      return await lastValueFrom(response$);
    } catch (e) {
      this.throwApiException(e, { url }, { headers });
    }
  }

  public async download(url: string): Promise<ArrayBuffer> {
    try {
      const ClientId = this.loggingHandler.getClientId();
      const newUrl = new URL(url);
      this.organizationId && (newUrl.searchParams.set('organizationId', String(this.organizationId)));
      const response$ = await this.http
        .get(newUrl.toString(), { headers: { ClientId }, responseType: 'arraybuffer', withCredentials: true });
      return await lastValueFrom(response$);
    } catch (e) {
      this.throwApiException(e, { url });
    }
  }

  public async downloadPost<TRequest>(url: string, request: TRequest): Promise<ArrayBuffer> {
    try {
      const ClientId = this.loggingHandler.getClientId();
      const data = this.organizationId ? { ...request, organizationId: this.organizationId } : request;
      const response$ = await this.http
        .post(url, data, { headers: { ClientId }, responseType: 'arraybuffer', withCredentials: true });
      return await lastValueFrom(response$);
    } catch (e) {
      this.throwApiException(e, { url }, { request });
    }
  }

  public upload<TRequest, TResponse>(request: TRequest, url: string, headers: any = null): Observable<any> {
    const ClientId = this.loggingHandler.getClientId();
    const data = this.organizationId ? { ...request, organizationId: this.organizationId } : request;
    return this.http
      .post(url, data, { headers: { ...headers, ClientId }, observe: 'events', reportProgress: true, withCredentials: true })
      .pipe(catchError((err) => throwError(err))) as Observable<TResponse>;
  }

  private throwApiException(e, ...args): void {
    const errorResponse = JSON.stringify(e?.error);
    const errorInformation = [];
    args.forEach((e) => errorInformation.push(e));
    const message = JSON.stringify(errorInformation);
    throw new ApiException(
      `${e.status} ${e.message} ${message}, Response: ${errorResponse}`,
      e.error?.code,
      e.error?.description,
      e.error?.meta,
      e.status
    );
  }
}
