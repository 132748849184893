export class EmailSearchByWidgetRequest {
  page: number;
  pageSize: number;
  searchWidgetId: number;
  mailFolderIds: number[];

  constructor(page: number, pageSize: number, searchWidgetId: number, mailFolderIds: number[]) {
    this.page = page;
    this.pageSize = pageSize;
    this.searchWidgetId = searchWidgetId;
    this.mailFolderIds = mailFolderIds;
  }
}
