<mat-drawer-container [hasBackdrop]="true">
  <mat-drawer #drawer mode="over" position="end" [disableClose]="disableClose">
    <div class="h-full p-[1.5rem]">
      <div class="close-button">
        <button tabIndex="-1" class="" mat-icon-button (click)="closeDrawer()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <ng-template #drawerContent></ng-template>
    </div>
  </mat-drawer>
  <ng-content></ng-content>
</mat-drawer-container>
