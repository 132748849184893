import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { ApiRequest } from '../../services/api/api.request';
import { ApiRoutes } from '../routes/api.routes';
import { BaseGetCollectionByIdRequest } from '../contracts/common/base-get-collection-by-id.request';
import { BaseGetCollectionResponse } from '../contracts/common/base-get-collection.response';
import { EmailAuditContract } from '../contracts/email-audit/email-audit-contract';

const API_URL = environment.apiUrl;

@Injectable()
export class EmailAuditClient {
  constructor(private apiRequest: ApiRequest) {}
  async getByEmail(request: BaseGetCollectionByIdRequest): Promise<BaseGetCollectionResponse<EmailAuditContract>> {
    return this.apiRequest.get(`${API_URL}${ApiRoutes.emailAudit.getByEmail}?${request.format()}`);
  }
}
