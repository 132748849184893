import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiRequest } from '../../services/api/api.request';
import { ApiRoutes } from '../routes/api.routes';
import { BaseGetByIdRequest } from '../contracts/common/base-get-by-id.request';
import { BaseGetCollectionResponse } from '../contracts/common/base-get-collection.response';
import { RemoteFolder } from '../contracts/remote/remote-folder.contract';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class RemoteClient {
  constructor(private apiRequest: ApiRequest) {}

  async fetchRemoteFolders(request: BaseGetByIdRequest): Promise<BaseGetCollectionResponse<RemoteFolder>> {
    return this.apiRequest.post<BaseGetByIdRequest, BaseGetCollectionResponse<RemoteFolder>>(
      request,
      `${API_URL}${ApiRoutes.remote.fetchRemoteFolders}`
    );
  }
}
