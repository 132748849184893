import { PermissionClient } from '../../sdk/clients/permission.client';
import { Injectable } from '@angular/core';
import { PermissionsOverResources } from '../../sdk/contracts/permission/permissions-over-resources';
import { PermissionType } from '../../sdk/contracts/permission/permission-type';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class PermissionService {
  private permissionsOverResources$: PermissionsOverResources;
  private showAdvancedSettings = !!localStorage.getItem('showAdvancedSettings');

  constructor(private permissionClient: PermissionClient) {}

  private async fetchPermissionsOverResources(): Promise<PermissionsOverResources> {
    return await this.permissionClient.get();
  }

  public clearPermissionsOverResources(): void {
    this.permissionsOverResources$ = null;
  }

  private async getPermissionsOverResources(): Promise<PermissionsOverResources> {
    if (!this.permissionsOverResources$) {
      this.permissionsOverResources$ = await this.fetchPermissionsOverResources();
    }
    return this.permissionsOverResources$;
  }

  public async hasPermissionOver(
    organizationId: number | null,
    mailAccountId: number | null,
    requiredPermission: PermissionType
  ): Promise<boolean> {
    const permissions = await this.getPermissionsOverResources();

    if (permissions.isRoot) {
      return true;
    }

    if (mailAccountId) {
      return permissions.mailAccountPermissions[mailAccountId]?.some((p) => p === requiredPermission);
    }

    if (organizationId) {
      return permissions.organizationPermissions[organizationId]?.some((p) => p === requiredPermission);
    }

    return false;
  }

  public async hasPermissionOverAtLeastOneMailAccount(requiredPermission: PermissionType): Promise<boolean> {
    const permissions = await this.getPermissionsOverResources();

    if (permissions.isRoot) {
      return true;
    }

    for (const organizationId of Object.keys(permissions.organizationPermissions)) {
      if (permissions.organizationPermissions[organizationId]?.some((p) => p === requiredPermission)) {
        return true;
      }
    }

    for (const mailAccountId of Object.keys(permissions.mailAccountPermissions)) {
      if (permissions.mailAccountPermissions[mailAccountId]?.some((p) => p === requiredPermission)) {
        return true;
      }
    }

    return false;
  }

  public hasPermissionToSeeUserByUserEmail(email: string): boolean {
    if (this.showAdvancedSettings) {
      return true;
    } else {
      return !environment.systemUsers.includes(email);
    }
  }
}
