import { SearchContextStorageService } from '../search/search-context-storage.service';
import { UserFolderNode } from '../search/user-folder-node';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FoldersUtilityService {
  constructor(public searchContextStorageService: SearchContextStorageService) {}

  public getAllFoldersId(selectedMailFolders: number[]) {
    let mailsAccountId: any = [];
    // const userFolders = this.searchContextStorageService.userFolders;
    const userFolders = [];

    function getMailsId(id: number) {
      const mailAccountId = userFolders.filter((e) => e.mailFolderId === id).map((e) => e.mailAccountId);
      mailsAccountId.push(mailAccountId[0]);
    }
    selectedMailFolders.forEach((e) => getMailsId(e));
    mailsAccountId = mailsAccountId.filter((e, i, arr) => arr.indexOf(e) === i);
    // let allFoldersId = this.searchContextStorageService.userFolders
    //   .filter((e) => mailsAccountId.includes(e.mailAccountId))
    //   .map((e) => e.mailFolderId);
    const allFoldersId = [];
    return allFoldersId;
  }

  public getFoldersAndSubfoldersId(selectedMailFolders: number[]) {
    let foldersTreeChildren = [];
    const currentFolersId = [];
    let foldersAndSubfoldersId = [];

    // let mailAccountsId = this.searchContextStorageService.userFolders
    //   .filter((e) => selectedMailFolders.includes(e.mailFolderId))
    //   .map((e) => e.mailAccountId);
    let mailAccountsId = [];
    mailAccountsId = mailAccountsId.filter((e, i, arr) => arr.indexOf(e) === i);
    foldersTreeChildren = this.searchContextStorageService.userFoldersTree
      .filter((e) => mailAccountsId.includes(e.id))
      .map((e) => e.children);
    foldersTreeChildren.forEach((e) => e.forEach((x) => findFolders(x)));

    function findFolders(UserFolderNode: UserFolderNode) {
      if (selectedMailFolders.includes(UserFolderNode.id)) {
        currentFolersId.push(UserFolderNode);
      }
      UserFolderNode.children.length !== 0 && UserFolderNode.children.forEach((e) => findFolders(e));
    }
    function getFoldersIds(UserFolderNode: UserFolderNode) {
      if (UserFolderNode.children.length !== 0) {
        foldersAndSubfoldersId.push(UserFolderNode.id);
        UserFolderNode.children.forEach((e) => getFoldersIds(e));
      } else {
        foldersAndSubfoldersId.push(UserFolderNode.id);
      }
    }
    currentFolersId.forEach((e) => getFoldersIds(e));
    foldersAndSubfoldersId = foldersAndSubfoldersId.filter((e, i, arr) => arr.indexOf(e) === i);
    return foldersAndSubfoldersId;
  }
  // public getMailAccountIdByFolderId(folderId: number) {
  // let mailAccountId = this.searchContextStorageService.userFolders.filter(
  // (e) => folderId === e.mailFolderId).map((e) => e.mailAccountId);
  //
  // return mailAccountId[0];
  // }
  // public tags = []
  // public saveTagsFromTagsDialog(tags: any[]){
  //   this.tags = tags
  // }
}
