<div class="flex justify-between h-full">
  <div *ngIf="isError" class="h-full w-full">
    <app-something-went-wrong
      [showPicture]="false"
      (retryEmitter)="ngOnInit()"></app-something-went-wrong>
  </div>
  <div class="canvas" *ngIf="isLoading">
    <app-loader height="100%"></app-loader>
  </div>
</div>
<div *ngIf="!isLoading && !isError">
  <div class="close-button">
    <button tabIndex="-1" class="" mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <h1 mat-dialog-title>{{ "addTags" | translate }}</h1>
  <mat-dialog-content class="mat-mdc-dialog-content mdc-dialog__content mat-container">
    <!-- <p>{{'addTagsToEmail' | translate}}</p> -->
    <div class="tags-dialog">
      <app-tags
        [needSaveTags]="false"
        (setTags)="setTags($event)"
        (setIsSave)="setIsSave()"
        [emailId]="data.ids"
        [existedTags]="existedTags"
        [thisTags]="thisTags"></app-tags>
    </div>
  </mat-dialog-content>
</div>
<mat-dialog-actions align="end" class="mat-mdc-dialog-actions mdc-dialog__actions">
  <button mat-flat-button [disabled]="isSavingTags" color="primary" (click)="saveTags()">
<!--    <app-loading-button text="{{ 'save' | translate }}" [loading]="isSavingTags"></app-loading-button>-->
  </button>
</mat-dialog-actions>
