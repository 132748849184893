import { Pipe, PipeTransform } from '@angular/core';
import { ImportEmlState } from '../../sdk/contracts/importEml/importEml.upload.response';

@Pipe({
  name: 'formatStateImportEml',
})
export class FormatStateImportEml implements PipeTransform {
  transform(state: ImportEmlState): string {
    switch (state) {
      case ImportEmlState.Uploaded:
        return 'uploaded';
      case ImportEmlState.InProgress:
        return 'inProgress';
      case ImportEmlState.Imported:
        return 'imported';
      case ImportEmlState.ImportError:
        return 'importError';
      case ImportEmlState.UploadError:
        return 'uploadError';
      default:
        return '';
    }
  }
}
