@if (isLogged) {
  <router-outlet />
}

<!--<div *ngIf="isError" class="h-screen w-screen flex justify-center flex-wrap content-center p-5">-->
<!--  <div class="h-2/3 text-center">-->
<!--    <div class="font-medium text-3xl mb-5">{{ "companyDoesntRegistered" | translate }}</div>-->
<!--    <div class="text-gray-700 text-lg mb-5">-->
<!--      {{ "contactOurSalesDepartment" | translate }}!-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
