@if(!mailAccounts?.length) {
  <div class="flex justify-center items-center h-full">
    <p class="text-center">
      {{ getMailAccountsError ? "getMailAccountsError" : ("noMailAccountIsAvailableYet" | translate) }}
    </p>
  </div>
} @else {
  <mat-accordion>
    @for (mailAccount of mailAccounts; track mailAccount) {
      <mat-expansion-panel
        hideToggle
        matRipple
        (afterExpand)="opened(this, mailAccount)"
        [expanded]="openMailAccountId === mailAccount.mailAccountId">
        <mat-expansion-panel-header matRipple class="account-name">
          <mat-panel-title
            matTooltip="{{ getTitle(mailAccount) }}"
            class="w-full justify-between mr-0"
          >
            <span class="overflow-hidden whitespace-nowrap text-ellipsis mr-[1rem]">
              {{ getTitle(mailAccount) }}
            </span>
            <span class="default-theme-color text-end"> {{ getBadge(mailAccount) }} </span>
          </mat-panel-title>
        </mat-expansion-panel-header>

        @if(isTreeLoaded) {
          <app-mail-account-folders
            #folders
            [mailAccountId]="mailAccount.mailAccountId"
            [isOpen]="openMailAccountId === mailAccount.mailAccountId"
            [tree]="getMailAccountTree(mailAccount.mailAccountId)"
            [folderCounts]="folderCounts?.[mailAccount.mailAccountId] ?? []"
            [infoOpenedMailAccount]="infoOpenedMailAccount"
            (refreshUnread)="refreshUnread($event)"
            (getBadgesForMailAccounts)="getBadgesForMailAccounts($event)"
            (updateDataSideNav)="updateDataSideNav()"
          ></app-mail-account-folders>
        }
        <div class="flex flex-col gap-[0.5rem]">
          <div class="flex justify-between mt-[1rem]">
            {{"spaceUsed" | translate}} <span class="space-text"> {{mailAccount.spaceUsed}} GB</span>
          </div>
          @if(!!mailAccountBadges.length) {
            <div class="flex flex-col gap-[0.25rem]">
              <div class="flex justify-between">
                <div>{{ "connect" | translate }}</div>
                <div class="font-bold {{ getColorText(mailAccount.mailAccountId) }}">
                  {{ getConnectionStatusTitle(mailAccount.mailAccountId)  | translate }}
                </div>
              </div>
              @if(getConnectStatusInfo(mailAccount.mailAccountId)) {
                <div class="flex gap-[0.5rem] items-center {{ getColorText(mailAccount.mailAccountId) }}">
                  <mat-icon>warning</mat-icon>
                  <div>{{ getConnectStatusInfo(mailAccount.mailAccountId) | translate }}</div>
                </div>
              }
              @if(isConnectionStatusInfo(mailAccount.mailAccountId)) {
                <div class="flex gap-[0.5rem] items-center">
                  <mat-icon class="text-red-600">warning</mat-icon>
                  <div class="text-red-600">{{ getConnectionStatusInfo(mailAccount.mailAccountId) | translate }}</div>
                </div>
              }
            </div>
          }
        </div>
      </mat-expansion-panel>
    }
  </mat-accordion>
}
