<div class="toolbar relative">
  <div class="flex-none">
    <button (click)="leftMenuClicked()" mat-icon-button>
      <mat-icon>menu</mat-icon>
    </button>
  </div>
  <div class="flex flex-grow justify-center px-1 md:px-3 w-full">
    <div [class.w-full]="useMobileView">
      <app-email-search-input
        (searchEventEmitter)="search()"
        (valueChangedEventEmitter)="onSearchValueChanged($event)"
        (hideNotificationsEmitter)="onHideNotificationsChanged($event)"
        [search]="searchValue"
        [isAnyFolder]="isAnyFolder"
        [hideNotifications]="hideNotifications"
        [isDefaultSearchParams]="isDefaultSearchParams"
        [fullWidth]="useMobileView">
      </app-email-search-input>
    </div>
  </div>
  <div class="flex-none flex">
    <button mat-icon-button (click)="rightMenuClicked()">
      <mat-icon>settings</mat-icon>
    </button>
  </div>
</div>
