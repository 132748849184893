<div class="primary-container w-full">
  <mat-tab-group style="height: 100%; width: 100%" class="items-start">
    <mat-tab label="{{ 'accountSettings' | translate }}">
      <div>
        <!-- <div class="breadcrumbs-container">
          <app-breadcrumb [items]="breadcrumbItems"></app-breadcrumb>
        </div> -->

        <div *ngIf="isError" class="half-of-screen">
          <app-something-went-wrong [showPicture]="true" (retryEmitter)="loadUser()"></app-something-went-wrong>
        </div>

        <app-loader *ngIf="isLoading"></app-loader>

<!--        <app-drawer [isOpen]="updateDrawerIsOpen" (closeEventEmitter)="onCloseUpdatingModalForm()">-->
<!--          <app-account-update-dialog *ngIf="updateDrawerIsOpen" [user]="user"-->
<!--            [locales]="locales" (closeEventEmitter)="onCloseUpdatingModalForm()"-->
<!--            (updateEventEmitter)="onUpdateUser($event)">-->
<!--          </app-account-update-dialog>-->
<!--        </app-drawer>-->

        <!-- <app-drawer
          [isOpen]="changePasswordDrawerIsOpen"
          (closeEventEmitter)="onCloseChangePasswordModalForm()"
        >
          <app-change-password-dialog
            *ngIf="changePasswordDrawerIsOpen"
            (closeEventEmitter)="onCloseChangePasswordModalForm()"
            (submitEventEmitter)="onChangePassword()"
          >
          </app-change-password-dialog>
        </app-drawer> -->

        <div class="page-content" *ngIf="!isError && !isLoading">
          <div class="page-block box-container relative">
            <!-- <h1>General settings</h1> -->
            <table>
              <tr>
                <td class="item-header">{{ "name" | translate }}:</td>
                <td>{{ user.firstName + " " + user.lastName }}</td>
              </tr>
              <tr>
                <td class="item-header">{{ "emailAccountSettings" | translate }}:</td>
                <td>{{ user.email }}</td>
              </tr>
              <tr>
                <td class="item-header">{{ "language" | translate }}:</td>
                <td>{{ user.localizationCode | formatAccountLanguage }}</td>
              </tr>
            </table>
          </div>

          <div *ngIf="!iscredemISAMSupport" class="page-block box-container relative">
            <!-- <h1>Additional settings</h1> -->
            <div class="buttons-panel">
              <button color="primary" aria-label="Edit" (click)="openUpdateUserDialog()"
                mat-raised-button>
                <span class="flex gap-[0.2rem] items-center justify-center">
                  {{ "updateUser" | translate }}
                  <mat-icon style="font-size: 20px">border_color</mat-icon>
                </span>
              </button>
              <button color="primary" (click)="onChangePassword()" mat-raised-button>
                <span class="flex gap-[0.2rem] items-center justify-center">
                  {{ "changePass" | translate }}
                  <mat-icon>password</mat-icon>
                </span>
              </button>
              <!-- <div class="absolute top-0 right-0 mt-1 mr-1">
                <button mat-icon-button aria-label="Edit" (click)="openUpdateUserDialog()">
                  <mat-icon>edit</mat-icon>
                </button>
              </div> -->

              <!--      <button mat-stroked-button>-->
              <!--        Add google identity-->
              <!--        <mat-icon>person_add</mat-icon>-->
              <!--      </button>-->
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
