import { BaseGetRequest } from '../common/base-get.request';

export class GetOrganizationMailAccountsRequest extends BaseGetRequest {
  webCode: string;

  constructor(webCode: string) {
    super();
    this.webCode = webCode;
  }
}
