import { UserFolderContract } from '../../sdk/contracts/mail-folder/user-folder.contract';
import { MailFolderType } from '../../sdk/contracts/mail-folder/mail-folder.type';

export const makeId = (length: number): string => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

export const getFolderByType = (
  userFolders: UserFolderContract[],
  mailAccountId: number,
  folderType: MailFolderType
): number | null => {
  const foundFolder = userFolders.filter(
    (x) => x.folderType === folderType && x.mailAccountId === mailAccountId && !x.parentFolderId
  );
  if (foundFolder && foundFolder.length > 0) {
    return foundFolder[0].mailFolderId;
  }
  return null;
}
